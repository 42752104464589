import PropTypes from 'prop-types'
import React, { Component } from "react"
import { DateRangePicker, DateRange } from "react-date-range"

import {
    Grid,
    Button,
    Typography,
    Collapse,
    Paper,
    Divider,
    Hidden
} from "@material-ui/core"

import TuneIcon from "@material-ui/icons/Tune"

const moment = require("moment-timezone")

class DateRangeInput extends Component {

    constructor(props) {
        super(props)
        const now = moment()

        this.state = {
            visible: false,
            currentYear: new Date().getUTCFullYear(),
            dateRangePicker: {
                startDate: props.initStartDate ? props.initStartDate.toDate() : now.toDate(),
                endDate: props.initEndDate ? props.initEndDate.toDate() : now.toDate(),
                key: "selection",
                color: "#FF832D"
            },
        }
    }

    _onClickShowDatePicker = () => this.setState(prevState => ({visible: !prevState.visible}))
    _onChangeDateRange = (range) => this.setState({dateRangePicker: range.selection})
    _onClickUpdateDateRange = () => {
        this.setState({visible: false}, () => {
            this.props.onClickUpdate(
                moment(this.state.dateRangePicker.startDate),
                moment(this.state.dateRangePicker.endDate),
        )})
    }

    render = () => {
        const options = { year: "numeric", month: "numeric", day: "numeric", timeZone: "UTC" }
        const startDateLabel = this.state.dateRangePicker.startDate.toLocaleDateString(undefined, options)
        const endDateLabel = this.state.dateRangePicker.endDate.toLocaleDateString(undefined, options)

        return (
            <div>
                <Button variant="text" color="secondary" onClick={this._onClickShowDatePicker}>
                    <TuneIcon/>
                    <Typography variant="button">{`Date Range (${startDateLabel} - ${endDateLabel})`}</Typography>
                </Button>
                <Collapse in={this.state.visible} style={{position: "absolute", zIndex: 10}}>
                    <Paper>
                        <Hidden smUp>
                            <DateRange
                                ranges={[this.state.dateRangePicker]}
                                minDate={new Date(`${this.state.currentYear-1}-01-01`)}
                                maxDate={new Date(`${this.state.currentYear}-12-31`)}
                                onChange={this._onChangeDateRange}/>
                        </Hidden>
                        <Hidden xsDown lgUp>
                            <DateRangePicker
                                ranges={[this.state.dateRangePicker]}
                                months={1}
                                minDate={new Date(`${this.state.currentYear-1}-01-01`)}
                                maxDate={new Date(`${this.state.currentYear}-12-31`)}
                                direction="vertical"
                                scroll={{ enabled: true }}
                                onChange={this._onChangeDateRange}/>
                        </Hidden>
                        <Hidden mdDown>
                            <DateRangePicker
                                ranges={[this.state.dateRangePicker]}
                                direction="horizontal"
                                months={2}
                                minDate={new Date(`${this.state.currentYear-1}-01-01`)}
                                maxDate={new Date(`${this.state.currentYear}-12-31`)}
                                onChange={this._onChangeDateRange}/>
                        </Hidden>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button color="secondary" variant="contained" onClick={this._onClickUpdateDateRange}>Update</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Collapse>
                <Divider />
            </div>
        )
    }
}

DateRangeInput.propTypes = {
    onClickUpdate: PropTypes.func.isRequired,
    initStartDate: PropTypes.instanceOf(moment),
    initEndDate: PropTypes.instanceOf(moment)
}

DateRangeInput.defaultProps = {
    onClickUpdate: (startDate, endDate) => {}
}

export default DateRangeInput