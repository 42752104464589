import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import { isEmptyString } from "../common/utils"
import { FoxAccountAPI, FoxSessionAPI } from "../common/requests/concierge/account"
import FetchyFoxLogo from "../../assets/logo.png"
import { setAccountName, removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"
import { UPDATE_VIEW, CREATE_VIEW } from "../globals"
import { BasicInfo } from "./views/BasicInfo"
import { isNull } from "util"

const countryLookup = require("country-data").lookup

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class BasicInfoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            email: this.props.credentials.email,
            birthdate: "",
            imageData: null,
            imageUrl: null,
            mimeType: null,
            selectedSize: "na",
            selectedGender: "na",

            loading: false,
            snackMessage: "",
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            birthdateError: "",
            profilePictureError: "",
            location: props.location,
            redirectParams: null,
            menuItems: this.props.account.menuItems || [],

            credentials: props.credentials,
            account: props.account,
            airport: props.airport            
        }

    }

    componentWillMount = () => {
        if(this.props.location.viewMode === UPDATE_VIEW) {
            this._getProfileData()
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this._resetErrors()
    }

    _resetErrors = () => {
        this.setState({
            snackMessage: "",
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            birthdateError: "",
            profilePictureError: ""
        })
    }

    _onChangeTextField = (id, value) => this.setState({[id]: value})
    _onSelectSize = (selectedSize) => this.setState({selectedSize})
    _onSelectGender = (selectedGender) => this.setState({selectedGender})
    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onSelectImageHandler = (imageData, mimeType) => this.setState({imageData, mimeType})

    _onClickCancel = () => {
        if(this.props.location.viewMode === UPDATE_VIEW)
            this.setState({redirectParams: {pathname: "/dashboard"}})
        else
            this.setState({redirectParams: {pathname: "/"}})
    }

    _onClickSubmit = () => {
        this._resetErrors()
        if(isEmptyString(this.state.firstName)){
            this.setState({ firstNameError: "Required" })
        }
        else if(isEmptyString(this.state.lastName)){
            this.setState({ lastNameError: "Required" })
        }
        else if (isEmptyString(this.state.email)) {
            this.setState({ emailError: "Required" })
        }
        else if (isEmptyString(this.state.birthdate)) {
            this.setState({ birthdateError: "Required" })
        }
        else {
            this._updateProfileData()
        }
    }

    _onClickDrawerItemHandler = (redirectParams) => {
        this.setState({redirectParams})
    }

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        const api = new FoxSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.clearAccount()
                this.props.clearPlaces()
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirectParams: {pathname: "/"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    /** network requests  */

    _updateProfileData = () => {
        this.setState({loading: true})
        const api = new FoxAccountAPI(this.props.server.host,
            this.props.credentials.username, this.props.credentials.password)

        const extra = {
            "gender": this.state.selectedGender,
            "size": this.state.selectedSize
        }
        let country = countryLookup.countries({ name: this.props.airport.country })[0];


        api.requestUpdateProfile(this.state.firstName, this.state.lastName, this.state.birthdate,
            this.state.email, country.alpha2, extra,
            (response) => {
                this.props.setAccountName(this.state.firstName, this.state.lastName)
                this.setState({loading: false})

                if( !isNull(this.state.imageData) && !isNull(this.state.mimeType))
                    this._updateProfilePicture()
                else {
                    if(country.alpha2 === "US")
                        this.setState({loading: false, redirectParams: {pathname: "/fox/platform"}})
                    else
                        this.setState({loading: false, redirectParams: {pathname: "/dashboard"}})
                }
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })

    }

    _updateProfilePicture = () => {
        this.setState({loading: true})
        const api = new FoxAccountAPI(this.props.server.host,
            this.props.credentials.username, this.props.credentials.password)

        let country = countryLookup.countries({ name: this.props.airport.country })[0]
        api.requestUpdateProfilePicture(this.state.imageData, this.state.mimeType,
            (response) => {
                if(this.props.location.viewMode === CREATE_VIEW) {
                    if(country.alpha2 === "US")
                        this.setState({loading: false, redirectParams: {pathname: "/fox/platform"}})
                    else
                        this.setState({loading: false, redirectParams: {pathname: "/dashboard"}})
                }
                else
                    this.setState({loading: false, redirectParams: {pathname: "/dashboard"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _getProfileData = () => {
        this.setState({loading: true})
        const api = new FoxAccountAPI(this.props.server.host,
            this.props.credentials.username, this.props.credentials.password)

        api.requestProfile(
            (success) => {
                const data = success.data
                this.setState({
                    firstName: data.first_name,
                    lastName: data.last_name,
                    email: data.email,
                    birthdate: data.birthdate,
                    selectedSize: data.misc.size || "na",
                    selectedGender: data.misc.gender || "na",
                    imageUrl: data.image_url,
                    loading: false
                })
            },
            (error) => {
                // do nothing, it's ok if an error or no data is returned
                this.setState({loading: false})
            }
        )
    }
    /** end network requests  */

    render = () => {
        return <BasicInfo classes={this.props.classes} state={this.state}
                    onClickDrawerItem={this._onClickDrawerItemHandler}
                    onClickLogout={this._onClickLogoutHandler}
                    onChangeTextField={this._onChangeTextField}
                    onSelectImage={this._onSelectImageHandler}
                    onSelectSize={this._onSelectSize}
                    onSelectGender={this._onSelectGender}
                    onCloseSnack={this._onCloseSnack}
                    onClickCancel={this._onClickCancel}
                    onClickSubmit={this._onClickSubmit}/>
    }
}

function mapStateToProps(state) {
    const { server, credentials, account, airport } = state
    return { server, credentials, account, airport }
}

 const mapActionCreatorsToProps = {
    setAccountName,
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
 }

BasicInfoContainer.propTypes = {
    classes: PropTypes.object.isRequired,
 }

export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(BasicInfoContainer)
