import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { compose } from "recompose"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import { withStyles } from "@material-ui/core/styles"

import FetchyFoxLogo from "../common/assets/logo.png"
import PrepaidCardImage from "../common/assets/example-card.png"

import { FetchyIntercom } from "../common/components/FetchyIntercom"
import { VerticalLabeledTextField } from "../common/components/LabeledTextField"
import LoadingDialog from "../common/components/LoadingDialog"
import NavDrawer from "../common/components/NavDrawer"
import ConfirmActionDialog from "../common/components/ConfirmActionDialog"
import { PrepaidCardAPI } from "../common/requests/concierge/prepaidcard"
import { FoxSessionAPI } from "../common/requests/concierge/account"
import { isEmptyString } from "../common/utils"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"

const styles = theme => ({
    prepaidCardImage: {
        backgroundImage: `url(${PrepaidCardImage})`,
        height: 170,
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class PrepaidCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            last4: "",
            uniqueId: "",

            loading: false,
            snackMessage: "",
            last4ErrorText: "",
            uniqueIdErrorText: "",
            redirectParams: null,

            showCardConfirm: false,
            cardPin: "",
            cardPostalCode: "",
            activeCard: null,
            location: props.location,
        }
    }

    componentDidMount = () => {
        this._getActivePrepaidCard()
    }

    _clearErrorTexts = () => {
        this.setState({
            last4ErrorText: "",
            uniqueIdErrorText: ""
        })
    }

    _onClickDrawerItemHandler = (redirectParams) => {
        this.setState({redirectParams})
    }

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        const api = new FoxSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.clearAccount()
                this.props.clearPlaces()
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirectParams: {pathname: "/"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onTypeLast4Digits = (last4) => this.setState({last4})
    _onTypeUniqueId = (uniqueId) => this.setState({uniqueId})

    _onClickCompleteActivation = () => {
        this.setState({
            showCardConfirm: false,
            cardPin: "",
            cardPostalCode: "",
            last4: "",
            uniqueId: ""
        })
        this._getActivePrepaidCard()
    }

    _onClickActivate = () => {
        this._clearErrorTexts()
        if(isEmptyString(this.state.last4)){
            this.setState({last4ErrorText: "Required"})
        }
        else if(isEmptyString(this.state.uniqueId)) {
            this.setState({uniqueIdErrorText: "Required"})
        }
        else {
            this._activatePrepaidCard()
        }
    }

    _onClickBlockCard = () => this.setState({showBlockConfirm: true})

    _onCloseBlockCardHandler = () => this.setState({showBlockConfirm: false})

    /**  network requests */

    _getActivePrepaidCard = () => {
        this.setState({loading: true})
        const api = new PrepaidCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestPrepaidCard(
            (success) => {
                if(success.data)
                    this.setState({loading: false, activeCard: success.data})
                else
                    this.setState({loading: false, activeCard: null})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false})
            }
        )
    }

    _activatePrepaidCard = () => {
        this.setState({loading: true})
        const api = new PrepaidCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        // TODO: the iata code needs to come from the application instead of env vars once this
        // dashboard is used by more airports
        api.requestActivatePrepaidCard(this.state.last4, this.state.uniqueId, this.props.airport.iataCode,
            (success) => {
                const cardPin = success.data.pin
                const cardPostalCode = success.data.postal_code
                this.setState({loading: false, cardPin, cardPostalCode, showCardConfirm: true})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false})
            })


    }

    _blockPrepaidCard = () => {
        this.setState({loading: true})
        const api = new PrepaidCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestBlockPrepaidCard(
            (success) => {
                this.setState({loading: false, showBlockConfirm: false})
                this._getActivePrepaidCard()
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false, showBlockConfirm: false})
            }
        )
    }

    /** END network requests  */


    _renderBlockConfirm = () => {
        if(this.state.activeCard)
            return (
                <ConfirmActionDialog
                    open={this.state.showBlockConfirm}
                    message="Blocking your card is an immediate and irreversible action. A blocked card can't be used to
                    make purchases and can't be re-activated yourself. If you want to block your card, then please type
                    in the last four digits of the card number below."
                    confirmValue={this.state.activeCard.last4}
                    onSuccessCallback={this._blockPrepaidCard}
                    onCloseCallback={this._onCloseBlockCardHandler}
                />
            )
        else
            return <div/>
    }

    _renderCardConfirm = () => {
        return (
            <Dialog open={this.state.showCardConfirm} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle><Typography variant="h5">Your Card Details</Typography></DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" paragraph>
                        These numbers will not be shown again. Note them down for future reference as they may be necessary to complete
                        purchases.
                    </Typography>
                    <Typography variant="h6">PIN</Typography>
                    <Typography variant="h4" paragraph>{this.state.cardPin}</Typography>
                    <Typography variant="h6">Zip Code</Typography>
                    <Typography variant="h4" paragraph>{this.state.cardPostalCode}</Typography>
                    <DialogActions>
                        <Button
                            onClick={() => this._onClickCompleteActivation()}
                            color="primary">Complete Activation</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    _renderActiveCard = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Current Card</Typography>
                            <div style={{height: 20}}/>
                        </Grid>
                    </Grid>
                    <Card> {
                        this.state.activeCard === null ?
                            <CardContent>
                                <Typography variant="body1">No Active Card</Typography>
                            </CardContent> :
                            <div>
                                <CardContent>
                                    <Typography variant="h5">{`****-${this.state.activeCard.last4}`}</Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <VerticalLabeledTextField
                                                label="Card Status"
                                                value={this.state.activeCard.card_status}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <VerticalLabeledTextField
                                                label="Card Issued"
                                                value={this.state.activeCard.card_issued}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions style={{flexDirection: "row"}}>
                                    <div style={{flexGrow: 1}}/>
                                    <Button
                                        disabled={this.state.activeCard.card_status !== "Active"}
                                        onClick={this._onClickBlockCard}
                                        variant={this.state.activeCard.card_status !== "Active" ? "outlined" : "contained"}
                                        style={{backgroundColor: "#f44336", color: "#fff"}}>Block</Button>
                                </CardActions>
                            </div>
                    }
                    </Card>
                </Grid>
            </Grid>
        )
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        const { classes } = this.props
        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItemHandler}
                    onClickLogoutCallback={this._onClickLogoutHandler}/>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                     
                <main style={{width: "100vw"}}>
                    <div className={classes.spacerTop}/>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={11} md={10} lg={8}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Activate Your Prepaid Card</Typography>
                                    <Typography variant="body1">Once active, this is the card that you will use to make purchases.
                                    Funds are dynamically allocated per delivery.</Typography>
                                    <div style={{height: 10}}/>
                                    <Typography variant="body1">Only one card can be active, and linked to your account, at a time.</Typography>
                                    <div style={{height: 20}}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.prepaidCardImage}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                label="Last 4 Digits"
                                                value={this.state.last4}
                                                onChange={(event) => {this._onTypeLast4Digits(event.target.value)}}
                                                error={!isEmptyString(this.state.last4ErrorText)}
                                                helperText={this.state.last4ErrorText}
                                                fullWidth/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                label="10 Digit ID"
                                                value={this.state.uniqueId}
                                                placehold="xxx-xxx-xxxx"
                                                onChange={(event) => { this._onTypeUniqueId(event.target.value)}}
                                                error={!isEmptyString(this.state.uniqueIdErrorText)}
                                                helperText={this.state.uniqueIdErrorText}
                                                fullWidth/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button
                                        onClick={() => this._onClickActivate()}
                                        variant="contained"
                                        color="primary">Activate</Button>
                                </Grid>
                            </Grid>
                            <Divider/>
                            { this._renderActiveCard() }
                            { this._renderBlockConfirm() }
                            { this._renderCardConfirm() }
                        </Grid>
                    </Grid>
                    <LoadingDialog show={this.state.loading}/>
                    <Snackbar
                        autoHideDuration={5000}
                        message={this.state.snackMessage}
                        open={!isEmptyString(this.state.snackMessage)}
                        onClose={this._onCloseSnack}
                        action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />
                </main>

            </div>
        )
    }
}

PrepaidCard.propTypes = {
    classes: PropTypes.object.isRequired,
 }

function mapStateToProps(state) {
    const { account, server, credentials, airport } = state
    return { account, server, credentials, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

 export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
 )(PrepaidCard)
