import axios from "axios"
import { handleFetchyAPIError,
        formatFetchyAPIFailure,
        formatFetchyAPISuccess } from "../base"

export class FoxStatisticsAPI {

    constructor(host, fetchyUuid, fetchyToken){
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promiseDeliveryHistory = (n) => {
        return axios({
            url: `${this.host}/v1/concierge/history`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                n
            }
        })
    }

    promiseSummaryStatistics = () => {
        return axios({
            url: `${this.host}/v1/concierge/summary`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                // get a summary of all delivery data
                range: "ALL",
                epoch_utc: Math.floor(Date.now() / 1000) // current UTC time
            }
        })
    }

    tryPromiseDeliveryHistory = (n) => {
        return this.promiseDeliveryHistory(n)
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    tryPromiseSummaryStatistics = () => {
        return this.promiseSummaryStatistics()
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    requestDeliveryHistory = (n, onRequestSuccess, onRequestError) => {
        this.promiseDeliveryHistory(n).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestSummaryStatistics = (onRequestSuccess, onRequestError) => {
        this.promiseSummaryStatistics().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
