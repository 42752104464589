import React from "react"
import PropTypes from "prop-types"
import { findCurrency, format as currencyFormatter } from "currency-formatter"

import Image from "material-ui-image"

import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@material-ui/core"

const moment = require("moment-timezone")

export const CouponDetailsDialog = (props) => {
    const coupon = props.coupon

    let discountText = ""
    if(coupon.discount.discount_type === "PERCENT") {
        discountText = `Save ${coupon.discount.discount}%`
    }
    else {
        const currency = findCurrency(coupon.discount.iso_4217)
        let amount = coupon.discount.discount / 10 ** currency.decimalDigits
        amount = currencyFormatter(amount, {code: coupon.discount.iso_4217})
        discountText = `Save ${amount}`
    }

    const start = moment(coupon.start_utc).tz("UTC").format("ll")
    const expire = moment(coupon.expire_utc).tz("UTC").format("ll")
    const quantity = coupon.quantity === null ? "Unlimited" : coupon.quantity

    return (
        <Dialog open maxWidth="md" fullWidth>
            <DialogTitle>Coupon Details</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4">{coupon.code}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        { 
                            coupon.target ? (
                                <Card style={{display: "flex", boxShadow: "none"}}>
                                    <CardMedia title={coupon.target.name}>
                                        <Image src={coupon.target.media_url} aspectRatio={1.5} style={{objectFit: "contain", width: "200px"}} />
                                    </CardMedia>
                                    <CardContent style={{flex: "1 1 auto"}}>
                                        <Typography variant="h5">{`${coupon.target.name} -- ${discountText}`}</Typography>
                                        <div style={{height: 10}}/>
                                        <Typography variant="subtitle1">{coupon.description}</Typography>
                                    </CardContent>
                                </Card>
                            ) : (
                                <div>
                                    <Typography variant="h5">{`${discountText}`}</Typography>
                                    <div style={{height: 10}}/>
                                    <Typography variant="subtitle1">{coupon.description}</Typography>
                                </div>
                            )
                         }
                    </Grid>
                    <Grid item xs={12}>
                        
                        <Typography variant="body1">{`Remaining uses: ${quantity}`}</Typography>
                        <div style={{height: 5}}/>
                        <Typography variant="body1">{`Valid between: ${start} - ${expire}`}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={props.onClickClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

CouponDetailsDialog.propTypes = {
    coupon: PropTypes.object.isRequired,
    onClickClose: PropTypes.func.isRequired
}

CouponDetailsDialog.defaultProps = {
    onClickClose: () => {}
}

export default CouponDetailsDialog