import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from '@material-ui/core/styles'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Hidden,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core"
import {
    Home as HomeIcon,
    ExitToApp as ExitToAppIcon,
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons"
import FetchyFoxLogo from "../assets/white-logo.png"

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: "linear-gradient(to right, #FF832D, #f65954)"
      },
    drawer: {
        width: 250,
        flexDirection: "column",
        height: "100vh",
        display: "flex",
        backgroundColor: "#27282B",
        color: "#FFF"
    },
    icon: {
        color: "inherit"
    },
    iconContainer: {
        color: "inherit"
    },
    drawerItem: {
        color: "#FFF",
        "&:hover": {
            color: "#FFB37D",
        }
    },
    selectedIcon: {
        color: "#FF832D"
    },
    fetchyFoxLogo: {
        margin: "10px",
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: "150px",
        height: theme.mixins.toolbar.minHeight - 20,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },

})

class NavDrawer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileOpen: false,
        }
    }

    _onToggleMobileDrawer = () => this.setState(state => ({ mobileOpen: !state.mobileOpen }));

    _renderDrawerContents = () => {
        const { classes } = this.props

        return (
            <div>
                <List>
                    {
                        this.props.listItems.map((drawerItem, idx) => {
                            const elem = React.cloneElement(drawerItem.icon, {
                                className: this.props.currentPath === drawerItem.redirectParams.pathname ? classes.selectedIcon : classes.icon
                            })
                            return (
                                <ListItem button 
                                    className={ classes.drawerItem }
                                    onClick={() => this.props.onClickDrawerItemCallback(drawerItem.redirectParams)} 
                                    key={idx}>
                                    <ListItemIcon className={classes.iconContainer}>{elem}</ListItemIcon>
                                    <ListItemText>{drawerItem.label}</ListItemText>
                                </ListItem>
                            )
                        })
                    }
                    <Divider />
                    <ListItem button className={classes.drawerItem} onClick={() => this.props.onClickLogoutCallback() }>
                        <ListItemIcon className={classes.iconContainer}><ExitToAppIcon className={classes.icon}/></ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </ListItem>
                </List>
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Hidden mdUp>
                            <IconButton
                                aria-label="Open drawer"
                                onClick={this._onToggleMobileDrawer}
                                >
                                <MenuIcon style={{color: "#FFF"}}/>
                            </IconButton>
                        </Hidden>                    
                        <span className={classes.fetchyFoxLogo}/>
                    </Toolbar>
                </AppBar>                
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        open={this.state.mobileOpen}
                        onClose={this._onToggleMobileDrawer}
                        className={classes.drawer} 
                        classes={{paper: classes.drawer}}>
                            <Toolbar className={classes.toolbar} style={{display: "flex", justifyContent: "flex-end"}}>
                                <span className={classes.fetchyFoxLogo}/>
                                <IconButton onClick={this._onToggleMobileDrawer}>
                                    <ChevronLeftIcon style={{color: "#FFF"}}/>
                                </IconButton>
                            </Toolbar>                        
                            { this._renderDrawerContents() }
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer 
                        variant="permanent"
                        open
                        className={classes.drawer}
                        classes={{paper: classes.drawer}}>
                            <div className={classes.toolbar}/>
                            { this._renderDrawerContents() }
                    </Drawer>
                </Hidden>
            </div>
        )

    }
}

NavDrawer.propTypes = {
    currentPath: PropTypes.string,
    classes: PropTypes.object.isRequired,
    listItems: PropTypes.array.isRequired,
    onClickDrawerItemCallback: PropTypes.func.isRequired,
    onClickLogoutCallback: PropTypes.func.isRequired
}

NavDrawer.defaultProps = {
    currentPath: "/",
    listItems: [
        {
            icon: <HomeIcon />,
            label: "Home",
            redirectParams: {pathname: "/"}
        }
    ],
    onClickDrawerItemCallback: (params) => console.log("default onClickDrawerItemCallback"),
    onClickLogoutCallback: () => console.log("default onClickLogoutCallback")
}

export default withStyles(styles)(NavDrawer)
