import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"

import { isEmptyString } from "../utils"

class DialogFormInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value || "",
            errorText: ""
        }
    }

    _onChangeValue = (value) => {
        this.setState({ value })
    }

    _onClickUpdate = () => {
        this.setState({errorText: ""})
        if(isEmptyString(this.state.value)){
            this.setState({errorText: "Required"})
        }
        else {
            this.props.onUpdateCallback(this.state.value)
        }
    }

    render = () => {
        return (
            <Dialog
                open={true}
                onClose={this.props.onCancelCallback}
                disableBackdropClick
                fullWidth
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">{this.props.description}</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Value"
                value={this.state.value}
                type={this.props.type}
                fullWidth
                multiline={this.props.multiline}
                onChange={(event) => this._onChangeValue(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onCancelCallback}>
                Cancel
              </Button>
              <Button onClick={this._onClickUpdate  } color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
}

DialogFormInput.defaultProps = {
    title: "Lorem Ipsum",
    description: "",
    type: "text",
    multiline: false,
}

DialogFormInput.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.oneOf(["text", "number"]),
    multiline: PropTypes.bool,
    onCancelCallback: PropTypes.func.isRequired,
    onUpdateCallback: PropTypes.func.isRequired
}

export default DialogFormInput
