import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Snackbar from "@material-ui/core/Snackbar"
import { withStyles } from "@material-ui/core/styles"

import { isEmptyString } from "../common/utils"
import { OnboardingAPI } from "../common/requests/concierge/onboarding"
import LoadingDialog from "../common/components/LoadingDialog"
import CountryAutoCompleteField from "../common/components/CountryAutoCompleteField"


const stepLabels = ["Mobile Phone Number", "Verify Device"]

const styles = theme => ({
})

class MobileVerify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stepperIdx: 0,
            countryCallingCode: "",     // the intl calling code. e,g. "+1"
            countryAlpha2: "",          // an ISO-3166 Alpha-2 value. e.g. "US"
            phoneNumber: "",
            verificationCode: "",
            deviceId: "",
            resends: 4,

            countryCallingCodeErrorText: "",
            phoneNumberErrorText: "",
            verificationCodeErrorText: "",

            showSMSSentDialog: false,
            loading: false,
            snackMessage: ""
        }
    }

    _clearErrorTexts = () => {
        this.setState({
            countryCallingCodeErrorText: "",
            phoneNumberErrorText: "",
            verificationCodeErrorText: ""
        })
    }

    _onTypePhoneNumber = (phoneNumber) => this.setState({phoneNumber})
    _onTypeVerificationCode = (verificationCode) => this.setState({verificationCode})
    _onClickConfirmSMSSent = () => this.setState({showSMSSentDialog: false})
    _onCloseSnack = () => this.setState({snackMessage: ""})

    _onSelectCountryHandler = (country) => {
        this.setState({countryCallingCode: country.countryCallingCodes[0], countryAlpha2: country.alpha2})
    }

    _onClickSendSMS = () => {
        this._clearErrorTexts()
        if(isEmptyString(this.state.countryCallingCode)){
            this.setState({countryCallingCodeErrorText: "Required"})
        }
        else if(isEmptyString(this.state.phoneNumber)) {
            this.setState({phoneNumberErrorText: "Required"})
        }
        else {
            this._sendSMSVerificationCode()
        }
    }

    _onClickVerify = () => {
        this._clearErrorTexts()
        if(this.state.resends <= 0){
            this.setState({snackMessage: "You are out of attempts. Please contact support@fetchyfox.com"})
        }
        else if(isEmptyString(this.state.verificationCode)){
            this.setState({verificationCodeErrorText: "Required"})
        }
        else {
            this._validateSMSCode()
        }
    }

    _updateStepperIndex = (delta) => {
        if(this.state.stepperIdx + delta >= 0 && this.state.stepperIdx + delta < stepLabels.length){
            this.setState({
                stepperIdx: this.state.stepperIdx + delta
            })
        }
    }

    /** Network Requests */

    _sendSMSVerificationCode = () => {
        this.setState({loading: true})
        const api = new OnboardingAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)

        const fullNumber = `${this.state.countryCallingCode}${this.state.phoneNumber}`

        api.requestSMSVerificationCode(fullNumber, this.state.countryAlpha2,
            (success) => {
                this.setState({
                    loading: false,
                    deviceId: success.data.device_id,
                    showSMSSentDialog: true,
                    resends: this.state.resends - 1})
                this._updateStepperIndex(1)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    _validateSMSCode = () => {
        this.setState({loading: true})
        const api = new OnboardingAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)

            const fullNumber = `${this.state.countryCallingCode}${this.state.phoneNumber}`
        api.requestSMSVerify(this.state.verificationCode, fullNumber, this.state.countryAlpha2, this.state.deviceId,
            (success) => {
                this.setState({loading: false})
                this.props.onSuccessCallback()
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    /** END network reuqests */

    _renderSMSSentDialog = () => {
        return (
            <Dialog open={this.state.showSMSSentDialog} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle><Typography variant="h6">FetchyFox Code Sent</Typography></DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        Check your phone for a text message containing a 6-digit confirmation code.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this._onClickConfirmSMSSent()}
                        color="accent">Continue</Button>
                </DialogActions>
            </Dialog>
        )
    }

    _renderStepperContent = (stepNumber) => {
        switch(stepNumber) {
            case 0:
                return (
                    <div>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <CountryAutoCompleteField
                                    inputFieldLabel="Country"
                                    onSelectCallback={this._onSelectCountryHandler}
                                    errorText={this.state.countryCallingCodeErrorText}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Phone Number"
                                            onChange={(event) => this._onTypePhoneNumber(event.target.value)}
                                            value={this.state.phoneNumber}
                                            error={!isEmptyString(this.state.phoneNumberErrorText)}
                                            helperText={this.state.phoneNumberErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{height: 20}}/>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="text"
                                    size="large"
                                    onClick={() => this.props.onCancelCallback()}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this._onClickSendSMS}>Send Text</Button>
                            </Grid>
                        </Grid>
                    </div>
                )
            case 1:
                return (
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    You should have received a text message from FetchyFox. Enter the
                                    given 6-digit code to verify your device.
                                </Typography>
                                <div style={{height: 20}}/>
                                <TextField
                                    variant="standard"
                                    value={this.state.verificationCode}
                                    inputProps={{ style: {textAlign: "center", fontSize: "22px", fontWeight: "bold"}}}
                                    onChange={(event) => this._onTypeVerificationCode(event.target.value)}
                                    error={!isEmptyString(this.state.verificationCodeErrorText)}
                                    helperText={this.state.verificationCodeErrorText}/>
                            </Grid>
                        </Grid>
                        <div style={{height: 20}}/>
                        <Grid container justify="flex-start">
                            <Grid item>
                                <Button
                                    disabled={this.state.resends <= 0}
                                    variant="text"
                                    size="large"
                                    onClick={() => this._updateStepperIndex(-1)}>Back</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={this.state.resends <= 0}
                                    variant="contained"
                                    size="large"
                                    onClick={() => this._onClickSendSMS()}>{`Resend (${this.state.resends})`}</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => this._onClickVerify()}>Verify</Button>
                            </Grid>
                        </Grid>
                    </div>
                )
            default:
                return <div />
        }
    }

    render = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Stepper activeStep={this.state.stepperIdx} orientation="vertical">{
                        stepLabels.map((label, idx) => {
                            return (
                                <Step key={idx}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        {this._renderStepperContent(idx)}
                                    </StepContent>
                                </Step>
                            )
                        })
                    }</Stepper>
                </Grid>
                {this._renderSMSSentDialog() }
                <LoadingDialog show={this.state.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={this.state.snackMessage}
                    open={!isEmptyString(this.state.snackMessage)}
                    onClose={this._onCloseSnack}
                    action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />
            </Grid>
        )
    }
}

MobileVerify.propTypes = {
    classes: PropTypes.object.isRequired,
    onSuccessCallback: PropTypes.func.isRequired,
    onCancelCallback: PropTypes.func.isRequired,
    onFailureCallback: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    const { server, credentials } = state
    return { server, credentials }
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, null)
)(MobileVerify)
