import { SET_CREDENTIALS, SET_EMAIL, REMOVE_CREDENTIALS, REMOVE_EMAIL } from "../constants"

export default (state = {}, action) => {
    switch(action.type) {
        case SET_CREDENTIALS:
            const { username, password } = action
            return {
                ...state,
                username,
                password,

            }
        case REMOVE_CREDENTIALS:
            return {
                ...state,
                username: null,
                password: null
            }
        case SET_EMAIL:
            const { email } = action
            return {
                ...state,
                email
            }
        case REMOVE_EMAIL:
            return {
                ...state,
                email: null
            }
        default:
            return state
    }
}
