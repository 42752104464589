import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import { LegalDocsAPI } from "../common/requests/legal"
import { FoxSessionAPI } from "../common/requests/concierge/account"
import FetchyFoxLogo from "../../assets/logo.png"
import { CREATE_VIEW } from "../globals"
import { Legal } from "./views/Legal"

import { removeCredentials, removeEmail, setCredentials } from "../../actions"


const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }
})

class LegalContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTabIdx: 0,

            privacyText: "",
            termsText: "",


            dataChecked: false,
            privacyChecked: false,
            termsChecked: false,

            snackMessage: "",
            loading: false,
            redirectParams: null,
            viewMode: props.location.viewMode || CREATE_VIEW,
            currentPath: props.location.pathname,
        }
    }

    componentWillMount = () => {
        this._getPrivacyText()
    }

    _onChangeTab = (event, selectedTabIdx) => this.setState({selectedTabIdx})
    _onSnackClose = () => this.setState({snackMessage: ""})
    _onCheck = (event, checked) => {
        this.setState({
            [event.target.value]: checked
        })
    }

    _onClickCancel = () => {
        this.props.removeCredentials()
        this.props.removeEmail()
        this.setState({redirectParams: {pathname: "/"}})
    }

    _onClickContinue = () => {
        if (this.state.dataChecked && this.state.privacyChecked && this.state.termsChecked) {
            this._createFoxAccount()
        }
        else {
            this.setState({snackMessage: "You must agree to all documents."})
        }
    }

    /** network requests */

    _createFoxAccount = () => {
        this.setState({loading: true})
        const api = new FoxSessionAPI(this.props.server.host)
        api.requestCreateAccount(this.props.credentials.username, this.props.credentials.password,
            this.props.airport.iataCode,            
            (success) => {
                this.props.setCredentials(success.data.uuid, success.data.token)
                this.setState({loading: false, redirectParams: {pathname: "/fox/mobile"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _getPrivacyText = () => {
        this.setState({loading: true})
        const api = new LegalDocsAPI()
        api.requestPrivacyPolicyText(
            (text) => {
                this.setState({privacyText: text, loading: false})
                this._getTermsText()
            },
            (error) => {
                const message = `${error.status}: ${error.statusText}`
                this.setState({snackMessage: message})
            }
        )
    }

    _getTermsText = () => {
        this.setState({loading: true})
        const api = new LegalDocsAPI()
        api.requestTermsOfAgreementText(
            (text) => {
                this.setState({termsText: text, loading: false})
            },
            (error) => {
                const message = `${error.status}: ${error.statusText}`
                this.setState({snackMessage: message})
            }
        )
    }

    /** END network requests */

    render = () => {
        const { classes } = this.props
        return (
            <Legal classes={classes} data={this.state}
                airportName={this.props.airport.name}
                onChangeTab={this._onChangeTab}
                onSnackClose={this._onSnackClose}
                onCheck={this._onCheck}
                onClickCancel={this._onClickCancel}
                onClickContinue={this._onClickContinue}/>
        )
    }
}

LegalContainer.propTypes = {
    classes: PropTypes.object.isRequired,
 }

function mapStateToProps(state) {
    const { server, credentials, airport } = state
    return { server, credentials, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    setCredentials
}

export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(LegalContainer)
