// information about the personal data we collect

export function getPersonalDataDisclaimer(airportName) {
    const personalData = [
        {
            dataType: "First and Last Name",
            reason: `Used as basic identity information by FetchyFox and ${airportName} when approving
            Foxes for duty. Your first name can be shared with travelers to facilitate communication
            and is used by FetchyFox when communicating with you. FetchyFox DOES NOT share the full name
            collected here with any other entity.`,
            infolink: ""
        },
        {
            dataType: "Phone Number",
            reason: `This is saved and used by FetchyFox to contact couriers in urgent or problematic delivery situations.
            It may also be shared with Twilio to connect couriers directly with a customer in a masked and anonymous
            manner.`,
            infolink: "https://www.twilio.com/proxy"
        },
        {
            dataType: "Email Address",
            reason: `This is saved and used by FetchyFox and ${airportName} to communicate non-urgent business related topics to
            Foxes. It is also used for basic identity verification. The email address submitted during this
            signup process WILL NOT be used for marketing or advertising purposes. FetchyFox DOES NOT share
            the email collected here with any other entity.`,
            infolink: ""
        },
        {
            dataType: "Profile Picture",
            reason: `This is used to help customers identify the courier making his/her delivery. It is shared
            with customers the moment he/she places an order. It also appears in web dashboards
            to identify an active courier. This picture must be work-appropriate and something you're comfortable
            with being shared in a professional setting.`,
            infolink: ""
        },
        {
            dataType: "Social Security Number (SSN)",
            reason: `FetchyFox uses Stripe to store and distribute tips earned by our Foxes. Stripe requires that we
            provide them with identifying information so they can verify who is earning and receiving this money.
            We DO NOT store your SSN anywhere in our servers and we DO NOT share it with any other entity besides
            Stripe. FetchyFox ONLY forwards your SSN to Stripe.`,
            infoLink: "https://support.stripe.com/questions/date-of-birth-and-last-4-digits-of-social-security-number-ssn-requirement-for-us-stripe-accounts"
        },
        {
            dataType: "Date of Birth (DOB)",
            reason: `This information is used along with the SSN by Stripe to satisfy its mandatory identitity 
            verification requirements. FetchyFox also saves this information to determine which of our Foxes can
            handle age-restricted purchases. FetchyFox DOES NOT share this information with any other entity 
            besides Stripe.`,
            infolink: "https://support.stripe.com/questions/date-of-birth-and-last-4-digits-of-social-security-number-ssn-requirement-for-us-stripe-accounts"
        },      
        {
            dataType: "Home Address",
            reason: `In addition to the SSN, Stripe also requires the personal address of the account owner before
            payments (tips) can be sent to the owner. FetchyFox DOES NOT store your personal address in any of our
            servers and we DO NOT share it with any other entity besides Stripe. FetchyFox ONLY forwards this
            information to Stripe.`,
            infoLink: `https://support.stripe.com/questions/know-your-customer#what-if-im-creating-custom-connect-accounts-on-behalf-of-my-users`
        },
        {
            dataType: "Bank Acccount (Optional)",
            reason: `A verified account and routing number are required by Stripe before tip payments can be paid 
            to a Fox. You DO NOY have to provide this information now, but it MUST be provided and verfied before you
            can collect any earned tips. FetchyFox DOES NOT store your bank information on any server and DOES NOT
            share it with any other entity besides Stripe.`,
            infoLink: ``
        }
    ]

    return personalData
}

