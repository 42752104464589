export function isEmptyString(value) {
    // returns true if the string is null, empty, or just white space
    value = "" + value
    return (value === null || !value || 0 === value.length || !value.trim())
}

export function isDevelopmentEnv() {
    return process.env.REACT_APP_DEVELOPMENT === "1"
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toTitleCase(text) {
    return text.replace(/\w\S*/g, function(s){return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();});
}

export function cleanCut(sentence, maxLength) {
    // a naive approach to cutting a sentence down to size without cutting a 
    // word in half
    const BreakException = {};

    
    let result = ""
    let l = 0

    if(maxLength >= sentence.length)
        result = sentence
    else {
        let words = sentence.split(" ")
        try {
            words.forEach((word, idx) => {
                if (l + (word.length + 1) >= maxLength) {
                    result = `${words.slice(0, idx).join(" ")}...`;
                    throw BreakException;
                } 
                else {
                    l += word.length + 1;
                }
            });
        } catch (e) {}
    }

    return result
}