import {
    SET_BACKEND, SET_ACCOUNT_TYPE, SET_CREDENTIALS, SET_EMAIL,
    REMOVE_CREDENTIALS, REMOVE_EMAIL, SET_NAME, SET_AIRPORT,
    LOAD_ITEMS, ADD_PLACE, LOAD_PLACES, SET_DASHBOARD,
    SET_DRAWER_MENU, SET_SELECTED_MENU_ID, CLEAR_ITEMS, CLEAR_PLACES,
    CLEAR_ACCOUNT, CLEAR_AIRPORT } from "../constants"

export function setBackendHost(isDevelopment) {
    const host = process.env.REACT_APP_API_HOST
    const action = {
        type: SET_BACKEND,
        host
    }
    return action
}

export function setAccountType(accountType) {
    // accountType can be "fox", "vendor", or "admin" (airport)
    const action = {
        type: SET_ACCOUNT_TYPE,
        accountType
    }
    return action
}

export function setAccountName(firstName, lastName) {
    const action = {
        type: SET_NAME,
        firstName,
        lastName
    }
    return action
}

export function setDrawerMenu(menuItems) {
    const action = {
        type: SET_DRAWER_MENU,
        menuItems,
    }
    return action
}

export function clearAccount() {
    const action = {
        type: CLEAR_ACCOUNT
    }
    return action
}


export function setCredentials(username, password) {
    const action = {
        type: SET_CREDENTIALS,
        username,
        password
    }
    return action
}

export function removeCredentials() {
    const action = {
        type: REMOVE_CREDENTIALS
    }
    return action
}

export function setEmail(email) {
    const action = {
        type: SET_EMAIL,
        email
    }
    return action
}

export function removeEmail() {
    const action = {
        type: REMOVE_EMAIL
    }
    return action
}

export function setAirport(airportId, iataCode, name, iso4217, country, timezone) {
    const action = {
        type: SET_AIRPORT,
        airportId,
        iataCode,
        name,
        iso4217,
        country,
        timezone,
    }
    return action
}

export function clearAirport() {
    const action = {
        type: CLEAR_AIRPORT
    }
    return action
}


export function loadItems(items) {
    const action = {
        type: LOAD_ITEMS,
        items
    }
    return action
}

export function clearItems() {
    const action = {
        type: CLEAR_ITEMS
    }
    return action
}

export function addPlace(place_id, data) {
    // add a new place
    const action = {
        type: ADD_PLACE,
        place_id,
        data
    }
    return action
}

export function loadPlaces(places){
    // load a pulled list of places from the backend into memory
    const action = {
        type: LOAD_PLACES,
        places
    }
    return action
}

export function clearPlaces() {
    const action = {
        type: CLEAR_PLACES
    }

    return action
}

export function setListingsDashboard(airportId, terminalId) {
    // set global state for the primary dashboard so it doesn't reset everrryyy time
    const action = {
        type: SET_DASHBOARD,
        airportId,
        terminalId
    }
    return action
}

// set global state of selected menu index

export function setSelectedMenuId(menuId) {
    const action = {
        type: SET_SELECTED_MENU_ID,
        menuId
    }
    return action
}
