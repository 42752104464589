import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class AirportAPI {
    constructor(host, merchant_id, fetchy_token) {
        this.host = host
        this.username = merchant_id
        this.password = fetchy_token
    }

    promiseAirportInfo = (airport_id) => {
        return axios({
            url: `${this.host}/v1/merchant/airport`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id
            }
        })
    }

    tryPromiseAirportInfo = (airport_id) => {
        return this.promiseAirportInfo(airport_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestAirportInfo = (airport_id, onRequestSuccess, onRequestError) => {
        this.promiseAirportInfo(airport_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })

    }
}
