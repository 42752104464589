import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class ItemAPI {
    constructor(host, merchantId, fetchyToken) {
        this.host = host
        this.username = merchantId
        this.password = fetchyToken
    }

    promiseGetProductLibrary = (place_id) => {
        return axios({
            url: `${this.host}/v1/merchant/library`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })
    }

    tryPromiseGetProductLibrary = (placeId) => {
        return this.promiseGetProductLibrary(placeId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetProductLibrary = (placeId, onRequestSuccess, onRequestError) => {
        this.promiseGetProductLibrary(placeId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateOptionGroup = (item_id, group_name, uitype, listed, required, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/optiongroup`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                item_id,
                cascade
            },
            data: {
                group_name,
                uitype,
                listed,
                required
            }
        })
    }

    tryPromiseCreateOptionGroup = (item_id, group_name, uitype, listed, required, cascade) => {
        return this.promiseCreateOptionGroup(item_id, group_name, uitype, listed, required, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateOptionGroup = (item_id, group_name, uitype, listed, required, cascade, onRequestSuccess, onRequestError) => {
        this.promiseCreateOptionGroup(item_id, group_name, uitype, listed, required, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateItemOptionGroup = (option_group_id, group_name, uitype, listed, required, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/optiongroup`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                option_group_id,
                cascade
            },
            data: {
                group_name,
                uitype,
                listed,
                required
            }
        })
    }

    tryPromiseUpdateItemOptionGroup = (option_group_id, group_name, uitype, listed, required, cascade) => {
        return this.promiseUpdateItemOptionGroup(option_group_id, group_name, uitype, listed, required, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateItemOptionGroup = (option_group_id, group_name, uitype, listed, required, cascade, onRequestSuccess, onRequestError) => {
        this.promiseUpdateItemOptionGroup(option_group_id, group_name, uitype, listed, required, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteItemOptionGroup = (option_group_id, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/optiongroup`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                option_group_id,
                cascade
            }
        })
    }

    tryPromiseDeleteItemOptionGroup = (option_group_id, cascade) => {
        return this.promiseDeleteItemOptionGroup(option_group_id, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteItemOptionGroup = (option_group_id, cascade, onRequestSuccess, onRequestError) => {
        this.promiseDeleteItemOptionGroup(option_group_id, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseItemOption = (option_id) => {
        return axios({
            url: `${this.host}/v1/merchant/item/option`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                option_id
            }
        })
    }

    tryPromiseItemOption = (option_id) => {
        return this.promiseItemOption(option_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestItemOption = (option_id, onRequestSuccess, onRequestError) => {
        this.promiseItemOption(option_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateItemOption = (option_id, name, price_base_unit, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/option`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                option_id,
                cascade
            },
            data: {
                name,
                price_base_unit
            }
        })
    }

    tryPromiseUpdateItemOption = (option_id, name, price_base_unit, cascade) => {
        return this.promiseUpdateItemOption(option_id, name, price_base_unit, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateItemOption = (option_id, name, price_base_unit, cascade, onRequestSuccess, onRequestError) => {
        this.promiseUpdateItemOption(option_id, name, price_base_unit, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateItemOption = (option_group_id, name, price_base_unit, iso_4217, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/option`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                option_group_id,
                cascade
            },
            data: {
                name,
                price_base_unit,
                iso_4217
            }
        })
    }

    tryPromiseCreateItemOption = (option_group_id, name, price_base_unit, iso_4217, cascade) => {
        return this.promiseCreateItemOption(option_group_id, name, price_base_unit, iso_4217, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateItemOption = (option_group_id, name, price_base_unit, iso_4217, cascade, onRequestSuccess, onRequestError) => {
        this.promiseCreateItemOption(option_group_id, name, price_base_unit, iso_4217, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteItemOption = (option_id, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/item/option`,
            method: "DELETE",
            auth:{
                username: this.username,
                password: this.password
            },
            params:{
                option_id,
                cascade
            }
        })
    }

    tryPromiseDeleteItemOption = (option_id, cascade) => {
        return this.promiseDeleteItemOption(option_id, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteItemOption = (option_id, cascade, onRequestSuccess, onRequestError) => {
        this.promiseDeleteItemOption(option_id, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteItem = (item_id, cascade) => {
        return axios({
            url: `${this.host}/v1/merchant/product`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                item_id,
                cascade
            }
        })
    }

    tryPromiseDeleteItem = (item_id, cascade) => {
        return this.promiseDeleteItem(item_id, cascade).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteItem = (item_id, cascade, onRequestSuccess, onRequestError) => {
        this.promiseDeleteItem(item_id, cascade).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateItem = (place_id, payload) => {
        return axios({
            url: `${this.host}/v1/merchant/product`,
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                place_id
            },
            data: payload
        })
    }

    tryPromiseCreateItem = (placeId, payload) => {
        return this.promiseCreateItem(placeId, payload).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateItem = (placeId, payload, onRequestSuccess, onRequestError) => {
        this.promiseCreateItem(placeId, payload).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateItem = (item_id, payload, updateSellables) => {
        return axios({
            url: `${this.host}/v1/merchant/product`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                item_id,
                cascade: updateSellables
            },
            data: payload
        })
    }

    tryPromiseUpdateItem = (itemId, payload, updateSellables) => {
        return this.promiseUpdateItem(itemId, payload, updateSellables).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateItem = (itemId, payload, updateSellables, onRequestSuccess,
        onRequestError) => {
        this.promiseUpdateItem(itemId, payload, updateSellables).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateItemImage = (item_id, image_data, mime_type) => {
        return axios({
            url: `${this.host}/v1/merchant/product/media`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": mime_type
            },
            params: {
                item_id
            },
            data: image_data
        })
    }

    tryPromiseUpdateItemImage = (item_id, image_data, mime_type) => {
        return this.promiseUpdateItemImage(item_id, image_data, mime_type).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateItemImage = (item_id, image_data, mime_type, onRequestSuccess, onRequestError) => {
        this.promiseUpdateItemImage(item_id, image_data, mime_type).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseItemDetails = (item_id) => {
        return axios({
            url: `${this.host}/v1/merchant/product`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                item_id
            }
        })
    }

    tryPromiseItemDetails = (item_id) => {
        return this.promiseItemDetails(item_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestItemDetails = (item_id, onRequestSuccess, onRequestError) => {
        this.promiseItemDetails(item_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
