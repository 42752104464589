/*
    a material-ui Checkbox component with an additional description field
*/

import PropTypes from 'prop-types'
import React, { Component } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"

import { isEmptyString } from "../utils"

class DescriptiveCheckbox extends Component {
    render = () => {
        return (
            <FormControlLabel
                value={this.props.value}
                control={
                    <Checkbox
                        color="primary"
                        checked={this.props.checked}
                        onChange={ (event, checked) => this.props.onChecked(event.target.value, checked)}/>
                }
                label={
                    <span>
                        <Typography variant="body1">{this.props.label}</Typography>
                        <Typography variant="caption">{this.props.description}</Typography>
                    </span>
                }
            />
        )
    }
}

DescriptiveCheckbox.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChecked: PropTypes.func.isRequired
}

DescriptiveCheckbox.defaultProps = {
    value: "",
    label: "",
    description: "",
    checked: false,
    onChecked: null
}



class DescriptiveCheckboxGroup extends Component {
    render = () => {
        return (
            <FormControl error={!isEmptyString(this.props.errorText)}>
                <Typography variant="body2">{this.props.description}</Typography>
                <div style={{height: "10px"}}/>
                <FormGroup>{
                    this.props.options.map((option, index) => {
                        return <DescriptiveCheckbox
                                    key={index}
                                    value={option.value}
                                    label={option.label}
                                    description={option.description}
                                    checked={this.props.checkedValues.has(option.value)}
                                    onChecked={this.props.onCheckCallback}/>

                    })
                }</FormGroup>
                <FormHelperText>{this.props.errorText}</FormHelperText>
            </FormControl>
        )
    }
}

DescriptiveCheckboxGroup.propTypes = {
    checkedValues: PropTypes.instanceOf(Set).isRequired,
    onCheckCallback: PropTypes.func.isRequired,
    options: PropTypes.array,
    errorText: PropTypes.string,
    description: PropTypes.string
}

DescriptiveCheckboxGroup.defaultProps = {
    options: [
        {
            value: "HELLO",
            label: "hello",
            description: ""
        }
    ],
    onCheckCallback: (value, checked) => {console.log(`${value}: ${checked}`)},
    errorText: "",
    description: ""
}




export { DescriptiveCheckbox, DescriptiveCheckboxGroup}
