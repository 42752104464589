import { isNull } from "util"
import PropTypes from "prop-types"
import React from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import MenuList from "@material-ui/core/MenuList"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { useTheme } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"


import { OrderLookup } from "./OrderLookup"

import { StatusSnackbar } from "../../common/components/StatusSnackbar"
import Spinner from "../../common/components/Spinner"
import NavDrawer from "../../common/components/NavDrawer"
import LoadingDialog from "../../common/components/LoadingDialog"
import { VerticalLabeledTextField } from "../../common/components/LabeledTextField"

import { isEmptyString } from "../../common/utils"

var moment = require("moment-timezone")

function _renderOrderDetails(props, isMobileScreen) {
    const { state, classes } = props
    return (
        <Dialog
            open={state.showOrderDetailsDialog}
            fullScreen={isMobileScreen} 
            fullWidth={!isMobileScreen} 
            maxWidth="lg" 
            onClose={props.onCloseOrderDetails}>
            <DialogTitle>
                <IconButton edge="start" onClick={props.onCloseOrderDetails} aria-label="close">
                    <CloseIcon />
                </IconButton>
                {`Order Details`}
            </DialogTitle>
            <DialogContent>
            {
                isNull(state.orderDetails) ? 
                    <Spinner label="Fetching Data..."/> :
                    <OrderLookup state={state} classes={classes} />
            }
            </DialogContent>
        </Dialog>
    )
}

function _renderOrderCard(props, order) {
    const { state } = props
    const { timezone } = state

    let createdLocal = moment.tz(order.submittedUtc, "YYYY-MM-DD HH:mm:ss.sss", "UTC")
    .tz(timezone)
    .format("MMMM DD, YYYY hh:mmA")  

    return (
        <Card style={{marginTop: "20px"}}>
        <CardHeader
            title={
                <Typography variant="body2">{order.orderId}</Typography>
            }
            subheader={`${createdLocal} (${moment.tz.zone(timezone).name})`}
        />
        <CardContent>
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <VerticalLabeledTextField label={"Assigned Fox"} value={order.assignedFoxId || "None"} />
                </Grid>
                <Grid item xs={6}>
                    <VerticalLabeledTextField label={"Priority"} value={`${order.priority}`} />
                </Grid>
                <Grid item xs={6}>
                    <VerticalLabeledTextField label={"Order State"} value={order.orderState} />
                </Grid>

                <Grid item xs={4}>
                    <VerticalLabeledTextField label={"Customer"} value={order.userName} />
                </Grid>
                <Grid item xs={4}>
                    <VerticalLabeledTextField label={"Pick-up"} value={order.placeName} />
                </Grid>
                <Grid item xs={4}>
                    <VerticalLabeledTextField label={"Drop-off"} value={`${order.terminalName}: ${order.gateName}`} />
                </Grid>
            </Grid>
        </CardContent>
        <CardActions>
            <Grid container justify="flex-end">
                <Grid item>
                    <Button 
                        color="primary"
                        variant="text"
                        onClick={() => props.onClickOrder(order.orderId)}
                        >View Details</Button>
                </Grid>
            </Grid>
        </CardActions>
    </Card>        
    )
}

function _renderOrdersColumn(props, columnTitle, columnDesc, orders, isMobileScreen) {
    const style = isMobileScreen ? {height: "300px"} : {height: "70vh"}

    return (
        <Grid item xs={12} lg={6} xl={4} style={{height: "inherit"}}>
            <Typography variant="h6">{columnTitle}</Typography>
            <Typography variant="body2">{columnDesc}</Typography>
            <Paper style={{overflow: "auto", backgroundColor: "#FFD5B9"}}>
                <Grid container style={style}>
                    <MenuList style={{overflow: "auto"}}>
                    {
                        orders.map((order, idx) => {
                            return (
                                <Grid item xs={12} key={idx}>
                                {
                                    _renderOrderCard(props, order)
                                }
                                </Grid>
                            )
                        })
                    }
                    </MenuList>
                </Grid>
            </Paper>
        </Grid>        
    )
}


function _renderOrders(props, isMobileScreen) {
    const { state } = props

    if (state.orders.length === 0) {
        return (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="body1">No orders :(</Typography>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container justify="flex-start">
                { _renderOrdersColumn(props, "Pending Orders", "Orders awaiting assignment", state.pendingOrders, isMobileScreen)}
                { _renderOrdersColumn(props, "Active Orders", "Orders being fulfilled", state.activeOrders, isMobileScreen)}
            </Grid>
        )
    }
}

function _renderHeadline() {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h4">Deliveries</Typography>
                <Typography variant="body1">
                    Real-time status of all submitted, in-progress orders.
                </Typography>
                <div style={{height: 10}}/>
                <Divider/>
                <div style={{height: 20}}/>
                <Spinner label="Monitoring deliveries..."/>
            </Grid>
        </Grid>
    )
}

export const ActiveDeliveries = (props) => {
    const { classes, state } = props

    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <main style={{width: "99vw"}}>
                <div className={classes.spacerTop}/>
                <Grid container>
                    <Grid item xs={12}>
                        { _renderHeadline() } 
                    </Grid>
                    <Grid item xs={12}>
                        { _renderOrders(props, isMobileScreen) }
                    </Grid>
                    <Grid item xs={12}>
                        { _renderOrderDetails(props, isMobileScreen) }
                    </Grid>
                </Grid>
                
                <LoadingDialog show={state.loading}/>
                <StatusSnackbar
                    open={!isEmptyString(state.snackMessage.message)}
                    variant={state.snackMessage.level}
                    message={state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={props.onCloseSnack}/>
            </main>     
        </div>
    )
}

ActiveDeliveries.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickOrder: PropTypes.func,
    onCloseSnack: PropTypes.func,
    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func,
}

ActiveDeliveries.defaultProps = {
    onClickOrder: (orderId) => console.log(`default onClickOrder ${orderId}`),
    onCloseOrderDetails: () => console.log(`default onCloseOrderDetails`),
    onCloseSnack: () => console.log(`default onCloseSnack`),
    onClickDrawerItem: (redirectParams) => console.log(` default onClickDrawerItem ${JSON.stringify(redirectParams)}`),
    onClickDrawerLogout: () => console.log("default onClickDrawerLogout"),
}