import PropTypes from "prop-types"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import { isEmptyString } from "../utils"

class AutoCompleteField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    _onChangeSearchTerm = (searchPhrase) => {
        this.setState({ isOpen:  !isEmptyString(searchPhrase)})
        this.props.onTypeSearchPhrase(searchPhrase)
    }

    _onClickSuggestion = (suggestion) => {
        this.setState({isOpen: false})
        this.props.onSelectCallback(suggestion.value)
        this.props.onTypeSearchPhrase(suggestion.label)
    }

    renderInput = () => {
        return (
            <TextField
                variant="outlined"
                error={!isEmptyString(this.props.errorText)}
                helperText={this.props.errorText}
                label={this.props.label}
                value={this.props.searchPhrase}
                placeholder="Type to search..."
                onChange={(event) => {this._onChangeSearchTerm(event.target.value)}}
                size={this.props.size}
                fullWidth />
        )
    }

    renderSuggestion = (suggestion, index) => {
        return (
            <MenuItem
                key={index}
                value={suggestion.value}
                onClick={() => this._onClickSuggestion(suggestion)}>
                {suggestion.label}
            </MenuItem>
        )
    }

    renderSuggestions = () => {
        let count = 0

        if (isEmptyString(this.state.searchTerm)) {
            return <div/>
        }
        else {
            const suggestions = this.props.suggestions.filter((suggestion) => {
                const suggest = (suggestion.label.toLowerCase().includes(this.props.searchPhrase.toLowerCase())
                    && count < 5)

                if (suggest)
                    count++

                return suggest
            })
            return (
                this.state.isOpen ?
                <Paper style={{position: "relative", zIndex: 10}}> {
                    suggestions.map((suggestion, index) => {
                        return (
                            this.renderSuggestion(suggestion, index)
                        )
                    })
                }</Paper>
                : <div/>
            )
        }
    }

    render = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    { this.renderInput() }
                </Grid>
                <Grid item xs={12}>
                    { this.renderSuggestions() }
                </Grid>
            </Grid>
        )
    }
}

AutoCompleteField.propTypes = {
    label: PropTypes.string.isRequired,
    searchPhrase: PropTypes.string.isRequired,
    suggestions: PropTypes.array.isRequired,
    onSelectCallback: PropTypes.func.isRequired,
    onTypeSearchPhrase: PropTypes.func.isRequired,
    errorText: PropTypes.string,
}

AutoCompleteField.defaultProps = {
    errorText: "",
}

export default AutoCompleteField;
