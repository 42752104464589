
export function getPersonalDataDisclaimer(airportName) {
    const personalData = [
        {
            dataType: "First and Last Name",
            reason: `Used as identifying information by FetchyFox and ${airportName} when approving
            merchants to use the FetchyFox platform. Your full name is not shared with any entity outside
            FetchyFox and ${airportName}.`,
            infolink: ""
        },
        {
            dataType: "Birthdate",
            reason: `Used as identifying information by FetchyFox and ${airportName} when approving merchants
            to use the FetchyFox platform. This data is not shared with any entity beyond FetchyFox and
            ${airportName}.`,
            infolink: ""
        },
        {
            dataType: "Business/Primary Phone Number",
            reason: `Used as identifying information by FetchyFox and ${airportName} when approving merchants
            to use the FetchyFox platform. This is also used by either FetchyFox or ${airportName} to contact
            merchants about business related topics. It may also be shared with Twilio to connect merchants
            directly with a customer in a masked and anonymous manner.`,
            infolink: "https://www.twilio.com/proxy"
        },
        {
            dataType: "Business/Primary Email Address",
            reason: `Used by FetchyFox and ${airportName} to communicate non-urgent business related topics to
            merchants. It is also used for basic identity verification. Email addresses submitted during this
            signup process WILL NOT be used for marketing or advertising purposes. It is not shared with any
            other entity outside FetchyFox and ${airportName}.`,
            infolink: ""
        },
        {
            dataType: "Profile Picture",
            reason: `Used as identifying information by FetchyFox and ${airportName} when approving merchants
            to use the FetchyFox platform. This picture must be work-appropriate and something you're comfortable
            with being shared in professional settings. It is not shared with entities beyond FetchyFox and
            ${airportName}.`,
            infolink: ""
        }
    ]
    
    return personalData
}

