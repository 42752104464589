import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class MerchantSessionAPI {
    constructor(host) {
        this.host = host
    }

    promiseCreateAccount = (username, password, iataCode) => {
        return axios({
            url: `${this.host}/v1/merchant/account`,
            method: "POST",
            auth:{
                username,
                password
            },
            params: {
                iata_code: iataCode
            }
        })
    }

    tryPromiseCreateAccount = (username, password, iataCode) => {
        return this.promiseCreateAccount(username, password, iataCode).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateAccount = (username, password, iataCode, onResponseSuccess, onResponseError) => {
        this.promiseCreateAccount(username, password, iataCode).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseSignIn = (username, password, iata_code) => {
        return axios({
            url: `${this.host}/v1/merchant/session`,
            method: "GET",
            auth: {
                username,
                password
            },
            params: {
                iata_code
            }
        })
    }

    tryPromiseSignIn = (username, password, iata_code) => {
        return this.promiseSignIn(username, password, iata_code).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSignIn = (username, password, iata_code, onResponseSuccess, onResponseError) => {
        this.promiseSignIn(username, password, iata_code).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseLogout = (fetchyUuid, fetchyToken) => {
        return axios({
            url: `${this.host}/v1/merchant/session`,
            method: "DELETE",
            auth: {
                username: fetchyUuid,
                password: fetchyToken
            }
        })
    }

    tryPromiseLogout = (fetchyUuid, fetchyToken) => {
        return this.promiseLogout(fetchyUuid, fetchyToken).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestLogout = (fetchyUuid, fetchyToken, onResponseSuccess, onResponseError) => {
        this.promiseLogout(fetchyUuid, fetchyToken).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }
}

export class MerchantAccountAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promiseIsVerified = () => {
        return axios({
            url: `${this.host}/v1/merchant/activated`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseIsVerified = () => {
        return this.promiseIsVerified().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestIsVerified = (onResponseSuccess, onResponseError) => {
        this.promiseIsVerified().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseUpdateProfile = (firstName, lastName, email, phone_number, iso_3166a2, birthdate) => {
        return axios({
            url: `${this.host}/v1/merchant/profile`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                name: `${firstName} ${lastName}`,
                email,
                phone_number,
                iso_3166a2,
                birthdate
            }
        })
    }

    tryPromiseUpdateProfile = (firstName, lastName, email, phone_number, iso_3166a2, birthdate) => {
        return this.promiseUpdateProfile(firstName, lastName, email, phone_number, iso_3166a2, birthdate).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateProfile = (firstName, lastName, email, phone_number, iso_3166a2, birthdate,
        onResponseSuccess, onResponseError) => {
        this.promiseUpdateProfile(firstName, lastName, email, phone_number, iso_3166a2, birthdate).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseProfile = () => {
        return axios({
            url: `${this.host}/v1/merchant/profile`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseProfile = () => {
        return this.promiseProfile().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestProfile = (onResponseSuccess, onResponseError) => {
        this.promiseProfile().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseUpdateProfilePicture = (imgData, mimeType) => {
        return axios({
            url: `${this.host}/v1/merchant/profileimage`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": mimeType
            },
            data: imgData
        })
    }

    tryPromiseUpdateProfilePicture = (imgData, mimeType) => {
        return this.promiseUpdateProfilePicture(imgData, mimeType).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateProfilePicture = (imgData, mimeType, onResponseSuccess, onResponseError) => {
        this.promiseUpdateProfilePicture(imgData, mimeType).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseOperatingAirports = () => {
        return axios({
            url: `${this.host}/v1/merchant/profile/airports`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseOperatingAirports = () => {
        return this.promiseOperatingAirports().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestOperatingAirports = (onRequestSuccess, onRequestError) => {
        this.promiseOperatingAirports().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseTerminalList = (airport_id) => {
        return axios({
            url: `${this.host}/v1/merchant/airport/terminals`,
            method: "get",
            params: {
                airport_id
            },
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseTerminalList = (airport_id) => {
        return this.promiseTerminalList(airport_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestTerminalList = (airport_id, onRequestSuccess, onRequestError) => {
            this.promiseTerminalList(airport_id).then((response) => {
                onRequestSuccess(response)
            }).catch((error) => {
                const result = handleFetchyAPIError(error)
                onRequestError(result)
            })
        }
}
