import React, { Component } from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import ListItem from "@material-ui/core/ListItem"
import Avatar from "@material-ui/core/Avatar"
import ListItemText from "@material-ui/core/ListItemText"

import { isEmptyString } from "../utils"

const countries = require('country-data').countries




class CountryAutoCompleteField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countries: countries.all,
            searchTerm: "",
            selectedCountry: null,
            isOpen: true,
        }
    }

    onChangeCountryName = (searchTerm) => {
        this.setState({ searchTerm, isOpen: true})
        if(this.props.onChangeCallback) {
            this.props.onChangeCallback(searchTerm)
        }
    }
    onClickCountry = (value) => {
        // value is an element from the countries.all array
        this.setState({searchTerm: value.name, isOpen: false, selectedCountry: value })
        this.props.onSelectCallback(value)
    }

    renderInput = () => {
        return (
            <TextField
                variant="outlined"
                label={this.props.inputFieldLabel ? this.props.inputFieldLabel : "Country Name"}
                value={this.state.searchTerm}
                onChange={(event) => {this.onChangeCountryName(event.target.value)}}
                autoComplete="shipping country-name"
                error={!isEmptyString(this.props.errorText)}
                helperText={this.props.errorText}
                fullWidth />
        )
    }

    renderSuggestion = (suggestion, index) => {
        return (
            <ListItem
                key={index}
                value={suggestion.alpha2}
                onClick={() => this.onClickCountry(suggestion)}>
                <Avatar>
                    {suggestion.emoji}
                </Avatar>
                {
                    this.props.condensed ? <div/> :  <ListItemText primary={suggestion.name}/>
                }
            </ListItem>
        )
    }

    renderSuggestions = () => {
        let count = 0

        if (isEmptyString(this.state.searchTerm)) {
            return <div/>
        }
        else {
            const suggestions = this.state.countries.filter((country) => {
                const suggest = (country.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) &&
                    country.status === "assigned" && count < 5)

                if (suggest)
                    count++

                return suggest
            })
            return (
                this.state.isOpen ?
                <Paper square>{
                    suggestions.map((suggestion, index) => {
                        return (
                            this.renderSuggestion(suggestion, index)
                        )
                    })
                }</Paper>
                : <div/>
            )
        }
    }

    render = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    { this.renderInput() }
                    { this.renderSuggestions() }
                </Grid>
            </Grid>
        )

    }
}

CountryAutoCompleteField.propTypes = {
    onSelectCallback: PropTypes.func.isRequired,
    inputFieldLabel: PropTypes.string.isRequired,
    onChangeCallback: PropTypes.func,
    condensed: PropTypes.bool,
    errorText: PropTypes.string
}

export default CountryAutoCompleteField
