import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class PEXCardAPI {
    constructor(host, username, password) {
        this.host = host
        this.username = username
        this.password = password
    }

    promiseCardAccounts = () => {
        return axios({
            url: this.host + "/v1/airport/cards",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseCardAccounts = () => {
        return this.promiseCardAccounts().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCardAccounts = (onRequestSuccess, onRequestError) => {
        this.promiseCardAccounts().then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCardAccountDetails = (account_id) => {
        return axios({
            url: this.host + "/v1/airport/card",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                account_id
            }
        })
    }

    tryPromiseCardAccountDetails = (account_id) => {
        return this.promiseCardAccountDetails(account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCardAccountDetails = (account_id, onRequestSuccess, onRequestError) => {
        this.promiseCardAccountDetails(account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseActiveCards = () => {
        return axios({
            url: this.host + "/v1/airport/cards/active",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
        })
    }

    tryPromiseActiveCards = () => {
        return this.promiseActiveCards().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestActiveCards = (onRequestSuccess, onRequestError) => {
        this.promiseActiveCards().then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseFundCard = (authorizer, fox_id, pex_account_id) => {
        return axios({
            url: this.host + "/v1/airport/cards/fund",
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                fox_id,
                pex_account_id,
                authorizer
            }
        })
    }

    tryPromiseFundCard = (authorizer, fox_id, pex_account_id) => {
        return this.promiseFundCard(authorizer, fox_id, pex_account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestFundCard = (authorizer, fox_id, pex_account_id, onRequestSuccess, onRequestError) => {
        this.promiseFundCard(authorizer, fox_id, pex_account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseZeroCard = (authorizer, fox_id, pex_account_id) => {
        return axios({
            url: this.host + "/v1/airport/cards/fund",
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                fox_id,
                pex_account_id,
                authorizer
            }
        })
    }

    tryPromiseZeroCard = (authorizer, fox_id, pex_account_id) => {
        return this.promiseZeroCard(authorizer, fox_id, pex_account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestZeroCard = (authorizer, fox_id, pex_account_id, onRequestSuccess, onRequestError) => {
        this.promiseZeroCard(authorizer, fox_id, pex_account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseBlockCard = (authorizer, fox_id, pex_account_id) => {
        return axios({
            url: this.host + "/v1/airport/card/state",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                state: "BLOCKED"
            },
            data: {
                fox_id,
                pex_account_id,
                authorizer
            }
        })
    }

    tryPromiseBlockCard = (authorizer, fox_id, pex_account_id) => {
        return this.promiseBlockCard(authorizer, fox_id, pex_account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestBlockCard = (authorizer, fox_id, pex_account_id, onRequestSuccess, onRequestError) => {
        this.promiseBlockCard(authorizer, fox_id, pex_account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseReactivateCard = (authorizer, fox_id, pex_account_id) => {
        return axios({
            url: this.host + "/v1/airport/card/state",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                state: "ACTIVE"
            },
            data: {
                fox_id,
                pex_account_id,
                authorizer
            }
        })
    }

    tryPromiseReactivateCard = (authorizer, fox_id, pex_account_id) => {
        return this.promiseReactivateCard(authorizer, fox_id, pex_account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestReactivateCard = (authorizer, fox_id, pex_account_id, onRequestSuccess, onRequestError) => {
        this.promiseReactivateCard(authorizer, fox_id, pex_account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseRevokeCard = (authorizer, fox_id, pex_account_id) => {
        return axios({
            url: this.host + "/v1/airport/cards/active",
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                fox_id,
                pex_account_id,
                authorizer
            }
        })
    }

    tryPromiseRevokeCard = (authorizer, fox_id, pex_account_id) => {
        return this.promiseRevokeCard(authorizer, fox_id, pex_account_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestRevokeCard = (authorizer, fox_id, pex_account_id, onRequestSuccess, onRequestError) => {
        this.promiseRevokeCard(authorizer, fox_id, pex_account_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseAssignCard = (authorizer, email, last4, unique10, iata_code) => {
        return axios({
            url: `${this.host}/v1/airport/cards/active`,
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                authorizer,
                email,
                last4,
                iden_code: unique10,
                iata_code
            }
        })
    }

    tryPromiseAssignCard = (authorizer, email, last4, unique10, iata_code) => {
        this.promiseAssignCard(authorizer, email, last4, unique10, iata_code).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestAssignCard = (authorizer, email, last4, unique10, iata_code, onRequestSuccess,
        onRequestError) => {
        this.promiseAssignCard(authorizer, email, last4, unique10, iata_code).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
