import firebase from "firebase/app"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { compose } from "recompose"
import Grid from "@material-ui/core/Grid"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { HomeView } from "./views/Home"
import FetchyFoxLogo from "../components/common/assets/logo.png"


import { isEmptyString } from "./common/utils"
import LoadingDialog from "./common/components/LoadingDialog"
import { MerchantSessionAPI } from "./common/requests/merchant/account"
import { FoxSessionAPI } from "./common/requests/concierge/account"
import { AdminAirportAPI } from "./common/requests/admin/airport"
import { CREATE_ACCOUNT, LOGIN_ACCOUNT, FOX_ACCOUNT, MERCHANT_ACCOUNT, AIRPORT_ACCOUNT } from "./globals"
import { setAccountType, setEmail, setCredentials, setBackendHost, setAirport } from "../actions"
import { firebaseApp } from "../firebase"

const styles = theme => ({
    root: {
        display: "flex"
    },
    toolbarSpacer: {
        height: theme.mixins.toolbar.minHeight
    },
    welcomeImageContainer: {
        [theme.breakpoints.up('md')]: {
            height: "100vh"
        },
        [theme.breakpoints.down('sm')]: {
            height: "400px"
        },         
    },
    welcomeImage: {
        backgroundImage: `url(${process.env.REACT_APP_HOME_IMAGE})`,
        
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            backgroundPosition: "50% 0",          
            width: "100%",
            height: "100%"
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: "cover",
            width: "100%",
            height: "400px"
        },        
    },
    fetchyFoxLogo: {
        marginTop: "20px",
        marginBottom: "20px",
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: "100%",
        height: "50px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }    

})

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            airports: [],
            role: "",
            username: "",
            passphrase: "",
            iataCode: "",
            searchPhrase: "",
            rememberMe: false,
            
            airportErrorText: "",
            roleErrorText: "",
            usernameErrorText: "",
            passphraseErrorText: "",

            redirect: "",
            loading: false,
            snackMessage: ""
        }
    }

    /* START app lifecycle */

    componentWillMount = () => {
        this.props.setBackendHost(process.env.REACT_APP_DEVELOPMENT === "1")   
    }

    componentDidUpdate = (prevProps) => {
        // api info set up
        if(prevProps.server.host !== this.props.server.host) {
            this._getActiveAirportList(() => {
                let iataCode = this.props.match.params.iata ? this.props.match.params.iata.toUpperCase() : ""
                let searchPhrase = ""

                if(!isEmptyString(iataCode)) {
                    const airport = this.state.airports.filter((airport) => {
                        return airport.value === iataCode
                    })

                    if(airport.length === 1) {
                        searchPhrase = airport[0].label
                    }
                    else {
                        iataCode =  ""
                    }
                }
                this.setState({iataCode, searchPhrase})
            })
        }
    }

    componentDidMount = () => {
        if(this.props.server.host) {
            this._getActiveAirportList(() => {
                let iataCode = this.props.match.params.iata ? this.props.match.params.iata.toUpperCase() : ""
                let searchPhrase = ""

                if(!isEmptyString(iataCode)) {
                    const airport = this.state.airports.filter((airport) => {
                        return airport.value === iataCode
                    })

                    if(airport.length === 1) {
                        searchPhrase = airport[0].label
                    }
                    else {
                        iataCode =  ""
                    }
                }
                this.setState({iataCode, searchPhrase})
            })
        }

        firebaseApp.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdToken().then((idToken) => {
                    this.props.setCredentials(user.email, idToken)
                    this.setState({role: AIRPORT_ACCOUNT, username: user.email}, () => {
                        firebaseApp.firestore().collection("airport-admins").doc(user.uid).get()
                            .then((document) => {
                                const { iataCode } = document.data()
                                this.props.setAccountType(AIRPORT_ACCOUNT)
                                this.props.setEmail(user.email)
                                this._getAirportInfo(iataCode, () => {
                                    this.setState({loading: false, iataCode, redirect: "/dashboard"})
                                })                                
                            })
                    })
                })
            }
            else {
                this.props.setCredentials(null, null)
            }
        })   
    }

    _clearErrorText = () => {
        this.setState({
            airportErrorText: "",
            roleErrorText: "",
            usernameErrorText: "",
            passphraseErrorText: ""            
        })
    }

    _validateForm = (mode, onSuccess = () => {}) => {
        if(isEmptyString(this.state.iataCode)) {
            const airportErrorText = mode === LOGIN_ACCOUNT ? "Please select your airport" : "Which airport do you want to join?"
            this.setState({airportErrorText})
        }
        else if(isEmptyString(this.state.role)) {
            const roleErrorText = mode === LOGIN_ACCOUNT ? "Please select your role" : "What's your role?"
            this.setState({roleErrorText})
        }
        else if(isEmptyString(this.state.username)) {
            const usernameErrorText = mode === LOGIN_ACCOUNT ? "Required" : "Provide your email here"
            this.setState({usernameErrorText})
        }
        else if(isEmptyString(this.state.passphrase)) {
            const passphraseErrorText  = mode === LOGIN_ACCOUNT ? "Required" : "Don't forget a passphrase! It can be a phrase, or sentence, that's easily remembered"
            this.setState({passphraseErrorText})
        }
        else if(this.state.passphrase.length < 10 && mode === CREATE_ACCOUNT) {
            this.setState({passphraseErrorText: "Your passphrase must be at least 10 characters/numbers long"})
        }
        else {
            onSuccess()
        }
    }

    /* END app lifecycle */

    /* START event listeners */

    _onSelectAirport = (iataCode) => this.setState({iataCode})
    _onTypeSearchPhrase = (searchPhrase) => this.setState({searchPhrase}) 
    _onSelectRole = (role) => this.setState({role})
    _onTypeUsername = (username) => this.setState({username})
    _onTypePassphrase = (passphrase) => this.setState({passphrase})
    _onCheckRememberMe = (rememberMe) => this.setState({rememberMe})
    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onClickJoin = () => {
        this._clearErrorText()
        if(this.state.role === AIRPORT_ACCOUNT) {
            this.setState({snackMessage: "Airport admin accounts can't be created here."})
        } 
        else {
            this._validateForm(CREATE_ACCOUNT, () => {
                this._onSubmit(CREATE_ACCOUNT)
            })
        }
    }
    _onClickLogin = () => {
        this._clearErrorText()
        this._validateForm(LOGIN_ACCOUNT, () => {
            this._onSubmit(LOGIN_ACCOUNT)
        })
    }

    _onSubmit = (mode) => {
        this.props.setAccountType(this.state.role)
        this.props.setEmail(this.state.username)
        this.props.setCredentials(this.state.username, this.state.passphrase)


        if(mode === LOGIN_ACCOUNT) {
            if(this.state.role === FOX_ACCOUNT){
                this._getAirportInfo(this.state.iataCode, () => {
                    this._foxLogin(this.state.username, this.state.passphrase)
                })
            }
            else if (this.state.role === MERCHANT_ACCOUNT){
                this._getAirportInfo(this.state.iataCode, () => {
                    this._merchantLogin(this.state.username, this.state.passphrase)    
                })
            }
            else if (this.state.role === AIRPORT_ACCOUNT) {
                this._airportAdminLogin(this.state.username, this.state.passphrase)
            }
        }
        else if(mode === CREATE_ACCOUNT) {
            if(this.state.role === FOX_ACCOUNT){
                this._getAirportInfo(this.state.iataCode, () => this.setState({redirect: "/fox/legal"}))
                
            }
            else if (this.state.role === MERCHANT_ACCOUNT){
                this._getAirportInfo(this.state.iataCode, () => this.setState({redirect: "/merchant/legal"}))
            }
            else if (this.state.role === AIRPORT_ACCOUNT) {
                // airport accounts can't be created through this app
            }
        }
    }

    /* END event listeners */
    

    /** START network requests */

    _foxLogin = (username, password) => {
        this.setState({loading: true})
        const api = new FoxSessionAPI(this.props.server.host)
        api.requestSignIn(username, password, this.state.iataCode,
            (success) => {
                this.props.setCredentials(success.data.uuid, success.data.token)
                this.setState({loading: false, redirect: "/dashboard"})        
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })

    }

    _merchantLogin = (username, password) => {
        this.setState({loading: true})
        const api = new MerchantSessionAPI(this.props.server.host)
        api.requestSignIn(username, password, this.state.iataCode,
            (success) => {
                this.props.setCredentials(success.data.uuid, success.data.token)
                this.setState({loading: false, redirect: "/dashboard"})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _airportAdminLogin = (email, password) => {
        // uses google email/password auth for verification
        this.setState({loading: true})
        const authMode = this.state.rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE 
        firebaseApp.auth().setPersistence(authMode)
            .then(() => {
                firebaseApp.auth().signInWithEmailAndPassword(email, password)
                .then(success => {
                    const user = firebaseApp.auth().currentUser
                    user.getIdToken().then((idToken) => {
                        firebaseApp.firestore().collection("airport-admins").doc(user.uid).get()
                        .then((document) => {
                            const { iataCode } = document.data()
                            
                            // NOTE TODO: this check needs to be done with a firestore function or in
                            // our backend. so verification code can sit on client
                            if(iataCode === this.state.iataCode) {
                                this.props.setCredentials(email, idToken)
                                this._getAirportInfo(this.state.iataCode, () => {
                                    this.setState({redirect: "/dashboard"})
                                })
                            }
                            else {
                                this.setState({snackMessage: `Admin not found for ${this.state.iataCode}`}, () => {
                                    setTimeout(firebaseApp.auth().signOut(), 5000)
                                })
                                
                            }

                        })
                    })
                })
                .catch(error => {
                    this.setState({snackMessage: error.message, loading: false})
                })
            })
            .catch((error) => {
                this.setState({snackMessage: error.message, loading: false})
            })
    }

    _getAirportInfo = (iataCode, successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(this.props.server.host, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestAirportDetails(iataCode,
            (success) => {
                const { id, iata_code, name, primary_iso_4217, country, db_tz_string } = success.data
                this.props.setAirport(id, iata_code, name, primary_iso_4217, country, db_tz_string)
                this.setState({loading: false}, successCallback)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _getActiveAirportList = (successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(this.props.server.host, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestAllAirports(
            (success) => {
                const data  = success.data
                const airports = data.map((airport) => {
                    return {
                        value: airport.iata_code,
                        label: `${airport.iata_code} - ${airport.name}`
                    }
                })
                this.setState({loading: false, airports}, successCallback)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})                
            }
        )
    }

    /** END network requests */

    render = () => {
        const { classes } = this.props

        if(this.state.redirect){
            return <Redirect push to={this.state.redirect}/>
        }

        return (
            <div style={{height: "99vh", width: "100vw"}}>
                <main>
                    <Grid container>
                        <Grid item xs={12} md={7} style={{padding: 0, margin: 0}}>
                            <Grid container className={classes.welcomeImageContainer}>
                                <Grid item xs={12} style={{padding: 0}}>
                                    <div className={classes.welcomeImage}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} style={{padding: 0}}>
                            <HomeView 
                                classes={this.props.classes} 
                                data={this.state}
                                onSelectAirport={(iataCode) => this._onSelectAirport(iataCode)}
                                onTypeSearchPhrase={(value) => this._onTypeSearchPhrase(value)}
                                onSelectRole={(role) => this._onSelectRole(role)}
                                onTypeUsername={(username) => this._onTypeUsername(username)}
                                onTypePassphrase={(passphrase) => this._onTypePassphrase(passphrase)}
                                onCheckRememberMe={(checked) => this._onCheckRememberMe(checked)}
                                onClickJoin={() => this._onClickJoin()}
                                onClickLogin={() => this._onClickLogin()}
                                />
                        </Grid>
                    </Grid>    
                </main>
                <Snackbar
                    autoHideDuration={5000}
                    message={this.state.snackMessage}
                    open={!isEmptyString(this.state.snackMessage)}
                    onClose={this._onCloseSnack}
                    action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />                
                <LoadingDialog show={this.state.loading}/>
            </div>                    
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
  };

function mapStateToProps(state) {
    const { server, credentials } = state
    return { server, credentials }
}

const mapActionCreatorsToProps = {
    setAccountType,
    setEmail,
    setCredentials,
    setBackendHost,
    setAirport
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(Home)
