import React, { Component } from "react"
import { connect } from "react-redux"
import { compose } from "recompose"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import Typography from "@material-ui/core/Typography"

import { withStyles } from "@material-ui/core/styles"

import { FoxSessionAPI } from "../common/requests/concierge/account"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import NavDrawer from "../common/components/NavDrawer"
import LoadingDialog from "../common/components/LoadingDialog"
import FetchyFoxLogo from "../../assets/logo.png"
import MobileVerify from "../pieces/MobileVerify"
import { isEmptyString } from "../common/utils"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"
import { CREATE_VIEW, UPDATE_VIEW } from "../globals"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})


class VerifyPhone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            snackMessage: "",

            redirectParams: null,
            location: props.location,
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    _onCloseSnack = () => this.setState({snackMessage: ""})

    _onClickDrawerItemHandler = (redirectParams) => {
        this.setState({redirectParams})
    }

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        const api = new FoxSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.clearAccount()
                this.props.clearPlaces()
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirectParams: {pathname: "/"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }


    _onFailureHandler = (errorMessage) => {
        this.setState({snackMessage: errorMessage})
    }

    _onCancelHandler = () => {
        if(this.props.location.viewMode === UPDATE_VIEW)
            this.setState({redirectParams: {pathname: "/dashboard"}})
        else
            this.setState({redirectParams: {pathname: "/"}})
    }

    _onSuccessHandler = () => {
        if(this.props.location.viewMode === UPDATE_VIEW)
            this.setState({redirectParams: {pathname: "/dashboard"}})
        else
            this.setState({redirectParams: {pathname: "/fox/basic", viewMode: CREATE_VIEW }})
    }

    _renderBars = () => {
        const { classes } = this.props
        if(this.props.location.viewMode === UPDATE_VIEW) {
            return (
                <div>
                    <NavDrawer
                        listItems={this.props.account.menuItems}
                        currentPath={this.props.location.pathname}
                        onClickDrawerItemCallback={this._onClickDrawerItemHandler}
                        onClickLogoutCallback={this._onClickLogoutHandler}/>
                    <FetchyIntercom 
                        userId={this.props.credentials.username}
                        email={this.props.credentials.email} 
                        name={this.props.credentials.username}
                        airport={this.props.airport.name}
                        route={this.props.location.pathname}
                        accountType={this.props.account.accountType} 
                        customData={{source: "Web Dashboard"}}/>                         
                </div>
            )
        }
        else
            return (
                <AppBar color="default">
                    <Toolbar>
                        <div className={classes.fetchyFoxLogo}/>
                    </Toolbar>
                </AppBar>
            )
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} />

        const { classes } = this.props
        return (
            <div style={{display: "flex"}}>
                { this._renderBars() }
                <main style={{ width: "99vw"} }>
                    <div className={classes.spacerTop}/>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={11} md={10} lg={8}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Mobile Contact</Typography>
                                    <Typography variant="body1">We need to verify a method of contacting you.</Typography>
                                </Grid>
                            </Grid>
                            <MobileVerify
                                onSuccessCallback={this._onSuccessHandler}
                                onCancelCallback={this._onCancelHandler}
                                onFailureCallback={this._onFailureHandler}/>
                        </Grid>
                    </Grid>
                    <LoadingDialog show={this.state.loading}/>
                    <Snackbar
                        autoHideDuration={5000}
                        message={this.state.snackMessage}
                        open={!isEmptyString(this.state.snackMessage)}
                        onClose={this._onCloseSnack}
                        action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />
                </main>
            </div>


        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, account, airport } = state
    return { server, credentials, account, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(VerifyPhone)
