import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class OnboardingAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promiseApplicationStatus = () => {
        return axios({
            url: this.host + "/v1/concierge/onboarding/status",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseApplicationStatus = () => {
        return this.promiseApplicationStatus().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestApplicationStatus = (onResponseSuccess, onResponseError) => {
        // get the completion status of each portion of a Fox's application
        this.promiseApplicationStatus().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseActivatePrepaidCard = (last4, uniqueId) => {
        return axios({
            url: this.host + "/v1/concierge/pex",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                last4,
                iden_code: uniqueId
            }
        })
    }

    tryPromiseActivatePrepaidCard = (last4, uniqueId) => {
        return this.promiseActivatePrepaidCard(last4, uniqueId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestActivatePrepaidCard = (last4, uniqueId, onResponseSuccess, onResponseError) => {
        this.promiseActivatePrepaidCard(last4, uniqueId).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseSMSVerificationCode = (mobileNumber, countryCode) => {
        return axios({
            url: this.host + '/v1/concierge/twilio/verification',
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
                },
            data: {
                phone_number: mobileNumber,
                iso_3166a2: countryCode
            }
        })
    }

    tryPromiseSMSVerificationCode = (mobileNumber, countryCode) => {
        return this.promiseSMSVerificationCode(mobileNumber, countryCode).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSMSVerificationCode = (mobileNumber, countryCode, onResponseSuccess, onResponseError) => {
        // request a Twilio SMS verification code to be sent to the Fox's device.
        // - the mobile number needs to include the calling code. e.g. +11234561213
        // - countryCode is an ISO-3166-1 Alpha 2 value
        this.promiseSMSVerificationCode(mobileNumber, countryCode).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseSMSVerify = (code, mobileNumber, countryCode, deviceId) => {
        return axios({
            url: this.host + '/v1/concierge/twilio/verify',
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                phone_number: mobileNumber,
                code,
                device_id: deviceId,
                iso_3166a2: countryCode
            }
        })
    }

    tryPromiseSMSVerify = (code, mobileNumber, countryCode, deviceId) => {
        return this.promiseSMSVerify(code, mobileNumber, countryCode, deviceId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSMSVerify = (code, mobileNumber, countryCode, deviceId, onResponseSuccess, onResponseError) => {
        // verify the given Twilio verification code and mobile number
        // - the mobile number needs to include the calling code. e.g. +11234561213
        // - countryCode is an ISO-3166-1 Alpha 2 value
        this.promiseSMSVerify(code, mobileNumber, countryCode, deviceId).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

}
