import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import { Applicants } from "./views/Applicants"

import FetchyFoxLogo from "../common/assets/logo.png"
import { ApplicantAPI } from "../common/requests/airport/applicants"
import { isEmptyString } from "../common/utils"
import { firebaseApp } from "../../firebase"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"
import { MERCHANT_ACCOUNT } from "../globals"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ApplicantsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            applicantType: this.props.match.params.applicantType,
            accounts: [],
            pendingAccounts: [],
            activeAccounts: [],
            accountDetails: null,

            showDenyDialog: false,
            showAccountDialog: false,
            accountAction: "", // suspend or deny

            reason: "",
            reasonErrorText: "",
            location: props.location,
            menuItems: props.account.menuItems,
            username: props.credentials.username,

            loading: false,
            redirectParams: null,
            snackMessage: "",
        }
    }

    componentWillMount = () => {
        this._getPendingApplicants()
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    /** network requests  */

    _getPendingApplicants = () => {
        this.setState({loading: true, applicants: []})
        const api = new ApplicantAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let apifunc = api.requestPendingFoxes
        if(this.state.applicantType === MERCHANT_ACCOUNT) {
            apifunc = api.requestPendingMerchants
        }
        apifunc(
            (success) => {
                this.setState({loading: false, accounts: success.data}, this._processAccountData)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    _getApplicantProfile = (fetchyUuid, successCallback = () => {} ) => {
        this.setState({showAccountDialog: true, accountDetails: null})
        const api = new ApplicantAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let apifunc = api.requestFoxApplication
        if(this.state.applicantType === MERCHANT_ACCOUNT) {
            apifunc = api.requestMerchantApplication
        }

        apifunc(fetchyUuid,
            (success) => {
                this.setState({accountDetails: success.data})
                successCallback()
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message})
            }
        )
    }

    _approveApplicant = (fetchyUuid, successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new ApplicantAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let apifunc = api.requestApproveFox
        if(this.state.applicantType === MERCHANT_ACCOUNT){
            apifunc = api.requestApproveMerchant
        }
        apifunc(fetchyUuid, this.props.credentials.username,
            (success) => {
                this.setState({loading: false})
                successCallback()
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    _suspendApplicant = (fetchyUuid, reason, successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new ApplicantAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let apifunc = api.requestSuspendFox
        if(this.state.applicantType === MERCHANT_ACCOUNT){
            apifunc = api.requestSuspendMerchant
        }

        apifunc(fetchyUuid, this.props.credentials.username, reason,
            (success) => {
                this.setState({loading: false}, successCallback)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )        
    }

    _denyApplicant = (fetchyUuid, reason, successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new ApplicantAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let apifunc = api.requestDenyFox
        if(this.state.applicantType === MERCHANT_ACCOUNT){
            apifunc = api.requestDenyMerchant
        }

        apifunc(fetchyUuid, this.props.credentials.username, reason,
            (success) => {
                this.setState({loading: false}, successCallback)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    /** end network requests */

    _processAccountData = () => {
        const { accounts } = this.state
        const pendingAccounts = accounts.filter((account) => account.state === "Pending" )
        const activeAccounts = accounts.filter((account) => account.state !== "Pending" )
        this.setState({pendingAccounts, activeAccounts})
    }
    
    /** event listeners */

    _onClickApplicant = (fetchyUuid) => this._getApplicantProfile(fetchyUuid)
    _onClickDeny = () => this.setState({showDenyDialog: true, accountAction: "deny"})
    _onClickSuspend = () => this.setState({showDenyDialog: true, accountAction: "suspend"})
    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})
    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onChangeDialogReason = (reason) => this.setState({reason})
    _onClickCancelDenial = () => this.setState({showDenyDialog: false, reason: "", accountAction: ""})
    _onCloseAccountDetails = () => this.setState({accountDetails: null, showAccountDialog: false})

    _onClickApprove = (fetchyUuid) => {
        this.setState({accountDetails: null, showAccountDialog: false})
        this._approveApplicant(fetchyUuid,
            () => {
                this._getPendingApplicants()
            })
    }

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: error})
        })
    }

    _onClickExecuteDenial = () => {
        if(isEmptyString(this.state.reason))
            this.setState({reasonErrorText: "Required"})
        else {
            if(this.state.accountAction === "deny") {
                this._denyApplicant(this.state.accountDetails.fetchy_uuid, this.state.reason,
                    () => {
                        this.setState({showDenyDialog: false, showAccountDialog: false, accountDetails: null, reason: ""})
                        this._getPendingApplicants()
                    })
            }
            else {
                this._suspendApplicant(this.state.accountDetails.fetchy_uuid, this.state.reason,
                    () => {
                        this.setState({showDenyDialog: false, showAccountDialog: false, accountDetails: null, reason: ""})
                        this._getPendingApplicants()
                    })
            }
        }
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        return (
            <div>
                <Applicants
                    state={this.state}
                    classes={this.props.classes}
                    onCloseAccountDetails={ this._onCloseAccountDetails }
                    onChangeDialogReason={ this._onChangeDialogReason }
                    onClickCancelDenial={ this._onClickCancelDenial }
                    onClickExecuteDenial={ this._onClickExecuteDenial }
                    onClickApplicant={ this._onClickApplicant }
                    onClickDeny={ this._onClickDeny }
                    onClickSuspend={ this._onClickSuspend }
                    onClickApprove={ this._onClickApprove }
                    onCloseSnack={ this._onCloseSnack }
                    onClickDrawerItem={ this._onClickDrawerItem}
                    onClickDrawerLogout={ this._onClickDrawerLogout}
                    />
            </div>
        )

    }
}

Applicants.propTypes = {
    classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    const { server, credentials, account, airport } = state
    return { server, credentials, account, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(ApplicantsContainer)
