// 
// 
// 
// 
// 


import uuid from "uuid4"
import { findCurrency } from "currency-formatter"
import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"

import { firebaseApp } from "../../firebase"

import { isEmptyString } from "../common/utils"
import LoadingDialog from "../common/components/LoadingDialog"
import NavDrawer from "../common/components/NavDrawer"
import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { ConstantsAPI } from "../common/requests/merchant/constants"
import { ItemAPI } from "../common/requests/merchant/item"
import { PlaceAPI } from "../common/requests/merchant/place"
import { SellableAPI } from "../common/requests/merchant/sellable"
import { ProductLibrary } from "./views/ProductLibrary"
import ProductDialog from "./modals/Product"

import { clearAccount, clearPlaces, removeCredentials, removeEmail } from "../../actions"

import FetchyFoxLogo from "../../assets/logo.png"
import Sellable from "../Sellable"
import SellableConfig from "../SellableConfig"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ProductLibraryContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currency: findCurrency(props.airport.iso4217),
            filterOpen: false,
            products: [],
            filteredProducts: [],
            productTypes: [],
            productTags: [],

            // filters for the side bar of locations
            listingSearchPhrase: "",
            listings: [],
            filteredListings: [],

            // filters for list of products
            searchPhrase: "",
            filteredProductTypes: new Set(),
            filteredProductTags: new Set(),

            dialogComponent: null,


            placeId: "",
            placeName: "",
            loading: false,
            redirectParams: null,
            location: props.location,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        }
    }

    componentWillMount = () => {
        this._populateData()
    }

    componentDidUpdate = (prevProps) => {}

    componentDidMount = () => {}


    _applyFilters = () => {
        let { products, filteredProductTypes, filteredProductTags} = this.state

        if(!isEmptyString(this.state.searchPhrase)) {
            // search phrase is a simple stirng containment operation
            let searchPhrase = this.state.searchPhrase.trim().toLowerCase()
            products = products.filter(product => product.name.toLowerCase().includes(searchPhrase))
        }

        const filters = new Set([...filteredProductTypes, ...filteredProductTags])

        // edge case, if no filters are selected then just return all products. this is treated
        // as all checkboxes selected

        if(filters.size !== 0) {
            // if at least one checkbox anywhere is selected, then products must match all
            // checkmarked values (AND of ANDs)            
            products = products.filter(product => {
                return ([...product.tags, product.type].filter(value => filters.has(value)).length === filters.size)
            })
        }


        this.setState({filteredProducts: products})
    }

    _refreshProductLibrary = () => {
        this._getProductLibrary(this.state.placeId, () => {
            this.setState({
                searchPhrase: "",
                filteredProductTypes: new Set(),
                filteredProductTags: new Set(),
            })
        })
    }

    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            } 
        })
    }

    _networkSnackMessage = (networkError) => {
        const message = networkError.response === undefined ? networkError.message : networkError.response.data.error
        const snackMessage = {
            level: StatusSnackbarTypes.ERROR,
            message
        }
        this.setState({loading: false, snackMessage})
    }

    /** network requests */

    

    _populateData = (callback=()=>{}) => {
        this.setState({loading: true})
        const constantsAPI = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const placeAPI = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        Promise.all([constantsAPI.promiseItemTypes(), constantsAPI.promiseDietaryTypes(), 
            placeAPI.promisePlaceList()])
            .then(([itemTypesResponse, dietaryTagsResponse, placeListResponse]) => {
                this.setState({
                    loading: false,
                    productTypes: itemTypesResponse.data.types,
                    productTags: dietaryTagsResponse.data.types,
                    listings: placeListResponse.data,
                    filteredListings: placeListResponse.data,
                }, callback)
            })
            .catch(this._networkSnackMessage)
    }

    _getProductLibrary = (placeId, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestGetProductLibrary(placeId,
            (success) => {
                this.setState({
                    loading: false,
                    products: success.data,
                    filteredProducts: success.data
                }, callback)
            },
            this._networkSnackMessage)
    }

    _getProductDetails = (itemId, callback=() => {}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestItemDetails(itemId,
            (success) => {
                const payload = success.data
                this.setState({loading: false}, () => callback(payload))
            },
            this._networkSnackMessage)
    }    

    _createProduct = (productData, mediaData, mimeType, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        
        api.requestCreateItem(this.state.placeId, productData,
            (success) => {
                const itemId = success.data.item_id
                if(mediaData && mimeType ) {
                    this._updateProductMedia(itemId, mediaData, mimeType, callback)
                }
                else{
                    this.setState({loading: false}, () => callback(itemId))
                }
            },
            this._networkSnackMessage)
    }

    _updateProduct = (productData, mediaData, mimeType, updateSellables, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const itemId = productData.item_id

        api.requestUpdateItem(itemId, productData, updateSellables,
            (success) => {
                if(mediaData && mimeType ) {
                    this._updateProductMedia(itemId, mediaData, mimeType, callback)
                }
                else{
                    this.setState({loading: false}, callback)
                }
            },
            this._networkSnackMessage)
    }

    _duplicateProduct = (itemId, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestItemDetails(itemId,
            (success) => {
                let itemDetails = success.data
                itemDetails.name = `${itemDetails.name} (copy)`
                api.requestCreateItem(itemDetails.place_id, itemDetails,
                    (success) => {
                        this.setState({
                            loading: false,
                            snackMessage: {
                                level: StatusSnackbarTypes.SUCCESS,
                                message: `${itemDetails.name} created!`
                            }
                        }, callback)
                    }, 
                    this._networkSnackMessage)
            },
            this._networkSnackMessage)
    }

    _deleteProduct = (itemId, deleteSellables, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeleteItem(itemId, deleteSellables,
            (success) => {
                const snackMessage = {
                    level: StatusSnackbarTypes.SUCCESS,
                    message: "Product deleted."
                }
                this.setState({loading: false, snackMessage}, callback) 
            },
            this._networkSnackMessage)
    }

    _updateProductMedia = (itemId, mediaData, mimeType, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        api.requestUpdateItemImage(itemId, mediaData, mimeType,
            (success) => {
                this.setState({loading: false}, () => callback(itemId))
            },
            this._networkSnackMessage)
    }

    _createSellable = (placeId, categoryId, name, description, priceBaseUnit, contents, sellableType, listed,
        fulfillmentSeconds, dietaryTags, mediaUrls, mediaData, mimeType) => {
        
        this.setState({loading: true})
        const iso4217 = this.state.currency.code
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        api.requestCreateSellable(placeId, categoryId, sellableType, name, description, listed, priceBaseUnit, iso4217,
            fulfillmentSeconds, contents, dietaryTags, mediaUrls,
            (success) => {
                const sellableId = success.data.sellable_id
                if(mediaData && mimeType ) {
                    this.setState({
                        loading: false,
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `${name} created!`
                        }
                    }, () => this._uploadSellableMedia(sellableId, mediaData, mimeType))                    
                }
                else {
                    this.setState({
                        loading: false,
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `${name} created!`
                        }
                    })
                }
            },
            this._networkSnackMessage)
    }    

    _uploadSellableMedia = (sellableId, mediaData, mimeType) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUploadMedia(sellableId, mediaData, mimeType,
            (success) => { this.setState({loading: false})},
            this._networkSnackMessage)
    }

    /** END network requests */

    /** event and action listeners */

    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            const snackMessage = {
                level: StatusSnackbarTypes.ERROR,
                message: error
            }
            this.setState({loading: false, snackMessage})
        })
    }    

    _onClickRefreshListings = () => this._populateData()

    _onClickAddProduct = () => {
        this.setState({
            dialogComponent: <ProductDialog placeId={this.state.placeId}
                                key={uuid()}
                                variant="create"
                                onCancelProductCallback={this._onClickCancelDialogListener}
                                onSubmitProductCallback={this._onClickCreateProductListener}/>

        })
    }

    _onClickEditProduct = (itemId) => {
        this.setState({
            dialogComponent: <ProductDialog placeId={this.state.placeId}
                                key={uuid()}
                                variant="update"
                                itemId={itemId}
                                onDeleteProductCallback={this._onClickDeleteProductListener}
                                onCancelProductCallback={this._onClickCancelDialogListener}
                                onSubmitProductCallback={this._onClickUpdateProductListener}/>

        })
    }

    _onClickDuplicateProduct = (itemId) => {
        // create a copy of the given item, this includes all details and customization
        // options. duplicating is gets the item's details and reuploads them to the create item
        // endpoint. a big different is an existing item will already have a URL for the image
        // so raw media isn't sent. endpoint has logic to handle that
        this._duplicateProduct(itemId, () => {
            this._refreshProductLibrary()
        })
    }

    _onChangeSearchPhrase = (searchPhrase) => this.setState({searchPhrase})

    _onCheckProductTypeFilter = (value, checked) => {
        if(checked) {
            this.setState(
                prevState => ({filteredProductTypes: prevState.filteredProductTypes.add(value)}),
                this._applyFilters)
        }
        else {
            this.setState(
                prevState => {
                    prevState.filteredProductTypes.delete(value)
                    return {filteredProductTypes: prevState.filteredProductTypes}
                },
                this._applyFilters)
        }

    }

    _onCheckProductTagFilter = (value, checked) => {
        if(checked) {
            this.setState(
                prevState => ({filteredProductTags: prevState.filteredProductTags.add(value)}),
                this._applyFilters
            )
        }
        else {
            this.setState(
                prevState => {
                    prevState.filteredProductTags.delete(value)
                    return {filteredProductTags: prevState.filteredProductTags}
                },
                this._applyFilters
            )
        }
    }

    _onClickFilter = () => this.setState(prevState => ({ filterOpen: !prevState.filterOpen }))

    // -----
    
    _onChangeListingSearchListener = (listingSearchPhrase) => {
        let { listings } = this.state    
        let searchPhrase = listingSearchPhrase.trim().toLowerCase()
        let filteredListings = listings
        if(!isEmptyString(searchPhrase)){
            filteredListings = listings.filter(listing => listing.name.toLowerCase().includes(searchPhrase))
        }
        this.setState({listingSearchPhrase, filteredListings})
    }

    _onClickCancelDialogListener = () => this.setState({dialogComponent: null})

    _onClickListingListener = (placeId) => {
        const listing = this.state.listings.filter(listing => listing.place_id === placeId)[0]
        this.setState({placeId, placeName: listing.name}, () => this._getProductLibrary(placeId))
    }

    _onClickCreateProductListener = (payload, mediaData, mimeType, createSellable) => {
        if(!createSellable) {
            // only create the item
            this._createProduct(payload, mediaData, mimeType, (itemId) => {
                this.setState({dialogComponent: null})
                this._refreshProductLibrary()
            })
        }
        else {
            // show UI to create a sellable using given item data
            this._createProduct(payload, mediaData, mimeType, (itemId) => {
                this._refreshProductLibrary()
                this._getProductDetails(itemId, (itemDetails) => {
                    let data = {
                        name: itemDetails.name,
                        description: itemDetails.description,
                        place_id: itemDetails.place_id,
                        contents: [itemDetails],
                        media_urls: [itemDetails.media_url]
                    }
                    const dialogComponent = <Sellable
                                                placeId={this.state.placeId}
                                                variant="create"
                                                onCancelCallback={this._onClickCancelDialogListener}
                                                onContinueCallback={this._onClickSellableContinueListener}
                                                data={data}/>
                    this.setState({dialogComponent})
                })
            })            
        }
    }

    _onClickUpdateProductListener = (payload, mediaData, mimeType, updateSellables) => {
        this._updateProduct(payload, mediaData, mimeType, updateSellables, () => {
            this.setState({dialogComponent: null})
            this._refreshProductLibrary()
        })
    }

    _onClickDeleteProductListener = (itemId, deleteSellables) => {
        this._deleteProduct(itemId, deleteSellables, () =>{
            this.setState({dialogComponent: null})
            this._refreshProductLibrary()     
        })
    }

    _onClickSellableContinueListener = (variant, categoryId, payload, mediaData, mimeType) => {
        let sellableFunc = (sellableDetails) => {
            const { name, description, price, contents, type, listed, fulfillment_seconds, dietary_tags, media_urls } = sellableDetails
            this._createSellable(this.state.placeId, categoryId, name, description, price.base_unit, contents,
                type, listed, fulfillment_seconds, dietary_tags, media_urls, mediaData, mimeType)
        }
        const dialogComponent = <SellableConfig
                                    variant={variant}
                                    data={payload}
                                    onCancelCallback={this._onClickCancelDialogListener}
                                    onSubmitCallback={(sellableDetails) => {
                                        sellableFunc(sellableDetails)
                                        this.setState({dialogComponent: null})
                                    }}/>
        this.setState({dialogComponent})        
    }

    /** END event and action listeners */

    render = () => {
        const { classes } = this.props

        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.state.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItem}
                    onClickLogoutCallback={this._onClickDrawerLogout}/>
                <main style={{width: "99vw"}}>
                    <ProductLibrary 
                        state={this.state} 
                        classes={classes}
                        onClickListing={this._onClickListingListener}
                        onClickRefreshListings={this._onClickRefreshListings}

                        onClickSearch={this._applyFilters}
                        onClickFilter={this._onClickFilter}
                        onClickRefreshLibrary={this._refreshProductLibrary}

                        onClickAddProduct={this._onClickAddProduct}
                        onClickEditProduct={this._onClickEditProduct}
                        onClickDuplicateProduct={this._onClickDuplicateProduct}

                        onChangeSearchPhrase={this._onChangeSearchPhrase}
                        onCheckProductTypeFilter={this._onCheckProductTypeFilter}
                        onCheckProductTagFilter={this._onCheckProductTagFilter}/>
                </main>
                <LoadingDialog show={this.state.loading} message="Loading..."/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>
            </div>
        )
    }
}

ProductLibraryContainer.propTypes = {

}

ProductLibraryContainer.defaultProps = {

}

function mapStateToProps(state) {
    const { credentials, server, airport, account } = state
    return { credentials, server, airport, account }
}

const mapActionCreators = {
    clearAccount,
    clearPlaces,
    removeCredentials,
    removeEmail,    
}


export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreators)
)(ProductLibraryContainer)
