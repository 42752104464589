import { LOAD_ITEMS, CLEAR_ITEMS } from "../constants"

export default (state = [], action) => {
    switch(action.type) {
        case LOAD_ITEMS:
            const { items } = action
            return items
        case CLEAR_ITEMS:
            return []
        default:
            return state
    }
}