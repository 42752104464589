// 
// dialog window for creating / updating a location (place of interest)
// 
// 
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"

import { isEmptyString } from "../common/utils"
import { ConstantsAPI } from "../common/requests/merchant/constants"
import { PlaceAPI } from "../common/requests/merchant/place"
import { AirportAPI } from "../common/requests/merchant/airport"
import LoadingDialog from "../common/components/LoadingDialog"

import { PointOfInterest } from "./views/Poi"

class PointOfInterestDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            place_types: {},
            airport_layout: {},
            iso_4217: "",
            iata_code: "",
            name: "",
            description: "",
            airportId: props.airport.airportId,
            terminal_id: "",
            dietaryTags: [],
            image: null,
            mimeType: "",
            finished: false,
            loading: false,

            businessType: "",

            mapZoom: 14,
            mapLng: 0,
            mapLat: 0,
            symbolLng: null,
            symbolLat: null,

            snackMessage: "",
        }
    }

    componentWillMount = () => {
        this._clearErrors()
    }

    componentDidMount = () => {
        this._getAirportLayout()
        this._getDietaryTypes()
        this._getPlaceTypes()
    }


    _clearErrors = () => {
        this.setState({
            nameError: null,
            descriptionError: null,
            airportIdError: null,
            terminalIdError: null,
            businessTypeError: null,
            imageError: null,
            snackMessage: "",
            mapError: null
        })
    }

    /** network requests */

    _createPlace = () => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestCreatePlace(this.state.airportId, this.state.terminal_id, this.state.businessType,
            this.state.name, this.state.description, this.state.symbolLat, this.state.symbolLng,
            this.state.dietaryTags,
            (success) => {
                const { place_id } = success.data
                if (this.state.image !== null)
                    this._uploadPlaceImage(place_id)
                else {
                    this.setState({loading: false}, this.props.onSuccess)
                }
            },
            (error) => {
                this.setState({snackMessage: error.message, loading: false})
            })
    }

    _uploadPlaceImage = (place_id) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdatePlaceImage(place_id, this.state.image, this.state.mimeType,
            (success) => {
                this.setState({loading: false}, this.props.onSuccess)
            },
            (error) => {
                this.setState({snackMessage: error.message, loading: false})
            }
        )
    }

    _getPlaceTypes = () => {
        const api = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestPlaceTypes(
            (success) => {
                const place_types = success.data.types
                this.setState({ place_types })
            },
            (error) => {
                this.setState({snackMessage: error.message})
            }
        )
    }

    _getDietaryTypes = () => {
        const api = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDietaryTypes(
            (success) => {
                const dietaryTypes = success.data.types
                this.setState({ dietaryTypes })
            },
            (error) => {
                this.setState( {snackMessage: error.message })
            }
        )
    }

    _getAirportLayout = () => {
        const api = new AirportAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestAirportInfo(this.state.airportId,
            (success) => {
                const airport_layout = success.data.layout_info.terminal_map
                const iata_code = success.data.iata_code
                const iso_4217 = success.data.iso_4217
                const mapLng = success.data.longitude
                const mapLat = success.data.latitude
                this.setState({ airport_layout, iata_code, iso_4217, mapLng, mapLat })
            },
            (error) => {
                this.setState({snackMessage: error.message})
            }
        )
    }

    /** END network requests */

    /** event listeners */

    _onChangeName = (name) => { this.setState({ name })}

    _onChangeDescription = (description) => { this.setState({ description })}

    _onClickMap = (map, event) => {
        const coordinates = event.lngLat
        const symbolLat = coordinates.lat
        const symbolLng = coordinates.lng
        this.setState({symbolLat, symbolLng})
    }

    _onMoveEndMap = (map, event) => {
        if (map._easeOptions && map._easeOptions.center) {
            const mapLat = map._easeOptions.center.lat
            const mapLng = map._easeOptions.center.lng
            this.setState({mapLat, mapLng})
        }
    }

    _onZoomEndMap = (map, event) => {
        if (map._easeOptions && map._easeOptions.zoom) {
            const mapZoom = map._easeOptions.zoom
            this.setState({mapZoom})
        }
    }

    _onSelectTerminal = (terminal_id) => { this.setState({ terminal_id })}
    _onSelectImage = (imageBytes, mimeType) => { this.setState({image: imageBytes, mimeType}) }
    _onSelectBusinessType = (businessType) => this.setState({ businessType, dietaryTags: [] })
    _onSelectDietaryType = (dietaryTags) => this.setState({dietaryTags})

    _onCloseSnack = () => {this.setState({snackMessage: ""})}

    _onClickCancel = () => this.props.onCancel()

    _onClickCreate = () => {
        this._clearErrors()
        if (isEmptyString(this.state.name))
            this.setState({nameError: "Required"})
        else if (isEmptyString(this.state.description))
            this.setState({descriptionError: "Required"})
        else if (isEmptyString(this.state.terminal_id))
            this.setState({ terminalIdError: "Required"})
        else if (isEmptyString(this.state.businessType))
            this.setState({ businessTypeError: "Required"})
        else if (this.state.symbolLat === null)
            this.setState({ mapError: "Please click on the location of this stall/service."})
        else {
            this._createPlace()
        }
    }

    /** END event listeners */

    render = () => {

        return (
            <div>
                <PointOfInterest
                    open={this.props.open}
                    classes={this.props.classes}
                    state={this.state}
                    onClickMap={ this._onClickMap }
                    onMoveEndMap={ this._onMoveEndMap }
                    onZoomEndMap={ this._onZoomEndMap }
                    onChangeName={ this._onChangeName }
                    onChangeDescription={ this._onChangeDescription }
                    onSelectDietaryType={ this._onSelectDietaryType }
                    onSelectTerminal={ this._onSelectTerminal }
                    onSelectBusinessType={ this._onSelectBusinessType }
                    onSelectImage={ this._onSelectImage }
                    onClickCancel={ this._onClickCancel }
                    onClickCreate={ this._onClickCreate }/>
                <LoadingDialog show={this.state.loading} message="Loading..."/>
                <Snackbar
                    autoHideDuration={5000}
                    message={this.state.snackMessage}
                    open={!isEmptyString(this.state.snackMessage)}
                    onClose={this._onCloseSnack}
                    action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />                
            </div>
        )
    }
}

PointOfInterestDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    const { credentials, server, airport } = state
    return { credentials, server, airport }
}


export default connect(mapStateToProps, null)(PointOfInterestDialog)
