import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class PasswordResetAPI {
    constructor(host) {
        this.host = host
    }

    promisePasswordResetEmail = (email, account_type) => {
        return axios({
            url: `${this.host}/v1/admin/password`,
            method: "POST",
            auth: {
                username: process.env.REACT_APP_ADMIN_USER,
                password: process.env.REACT_APP_ADMIN_SECRET
            },
            data: {
                email,
		account_type
            }
        })
    }

    tryPromisePasswordResetEmail = (email, account_type) => {
        return this.promisePasswordResetEmail(email, account_type).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPasswordResetEmail = (email, account_type, onResponseSuccess, onResponseError) => {
        this.promisePasswordResetEmail(email, account_type).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseResetPassword = (email, password, iata, token) => {
        return axios({
            url: `${this.host}/v1/admin/password`,
            method: "PUT",
            auth: {
                username: email,
                password: password
            },
            params: {
                token,
                iata,
            }
        })
    }

    tryPromiseResetPassword = (email, password, iata, token) => {
        return this.promiseResetPassword(email, password, iata, token).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestResetPassword = (email, password, iata, token, onResponseSuccess, onResponseError) => {
        this.promiseResetPassword(email, password, iata, token).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }
}
