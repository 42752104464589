import compose from "recompose/compose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"

import { ActiveDeliveries } from "./views/ActiveDeliveries"

import { OrderAPI } from "../common/requests/airport/order"
import { StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import FetchyFoxLogo from "../../assets/logo.png"
import { firebasePlatformApp, firebaseApp } from "../../firebase"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"


const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ActiveDeliveriesContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {

            orders: [],
            pendingOrders: [],
            activeOrders: [],
            cancelledOrders: [],
            
            // a mapping of order ids to doc listeners
            orderListener: () => {},
            orderStateListeners: {}, 

            // details about the selected order
            orderDetails: null,
            showOrderDetailsDialog: false,

            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },
            loading: false,
            redirectParams: null,
            location: props.location,
            drawerItems: props.account.menuItems,

            timezone: props.airport.timezone
        }
    }

    componentWillMount = () => {
        firebasePlatformApp.auth().onAuthStateChanged((user) => {
            if(user) {
                const query = firebasePlatformApp.firestore().collection("orders")
                    .where("status", "==", "active")
                    // TODO: need a filter on airport_id field
                    .orderBy("submitted_utc", "desc")
                const orderListener = query.onSnapshot(payload => {
                    payload.docChanges().forEach(change => {
                        if (change.type === "added" || change.type === "modified") {


                            // add a listener to the state document so we can listen to delivery progress
                            const orderStateQuery = firebasePlatformApp.firestore().collection(`orders/${change.doc.id}/state`)
                            const orderStateListener = orderStateQuery.onSnapshot(payload => {
                                payload.docChanges().forEach(stateChange => {
                                    if(stateChange.type === "modified") {
                                        // state changed, update the existing order data we have
                                        let order = this.state.orders.filter(order => order.orderId === change.doc.id)[0] 
                                        order.orderState = stateChange.doc.data().state

                                        let orders = this.state.orders.filter(order => order.orderId !== change.doc.id)
                                        orders.push(order)
                                        this.setState({orders}, this._processOrderData)
                                    }
                                })
                            })
                            const orderStateListeners = {
                                [change.doc.id]: orderStateListener,
                                ...this.state.orderStateListeners
                            }
                            this.setState({orderStateListeners})
                            







                            // pull the data
                            let orderDoc = change.doc
                            let promises = [
                                firebasePlatformApp.firestore().doc(`orders/${orderDoc.id}/delivery_info/data`).get(),
                                firebasePlatformApp.firestore().doc(`orders/${orderDoc.id}/state/data`).get()
                            ]
                            Promise.all(promises).then( async ([deliveryDoc, stateDoc]) => {
                                const orderData = await orderDoc.data()
                                const deliveryData = await deliveryDoc.data()
                                const stateData = await stateDoc.data()
                                
                                let order = {
                                    orderId: orderDoc.id,
                                    assignedFoxId: orderData.assigned_fox_id,
                                    priority: orderData.priority,
                                    userId: orderData.submitted_user_id,
                                    submittedUtc: orderData.submitted_utc.toDate(),
                                    userName: deliveryData.customer_name,
                                    placeId: deliveryData.place_id,
                                    placeName: deliveryData.place_name,
                                    terminalName: deliveryData.terminal_name,
                                    gateName: deliveryData.gate,
                                    orderState: stateData.state
                                }
                                const orders = this.state.orders.filter(order => order.orderId !== change.doc.id)
                                orders.push(order)
                                this.setState({orders}, this._processOrderData)
                            })
                        }
                        else if (change.type === 'removed') {
                            // stop listening for state updates
                            this.state.orderStateListeners[change.doc.id]()

                            // remove the order from the list of orders
                            const orders = this.state.orders.filter(order => order.orderId !== change.doc.id)
                            this.setState({orders}, this._processOrderData)
                        }
                    })
                },
                (error) => {
                    console.log(error)
                })
                this.setState({orderListener})
            }
        })        
    }

    componentDidMount = () => {
        firebasePlatformApp.auth().signInAnonymously()
    }

    componentWillUnmount = () => {
        // stop listening to firebase
        this.state.orderListener()
        for (let [ , listener] of Object.entries(this.state.orderStateListeners)) {
            listener()
        }
    }

    /** event handlers */

    _onCloseSnack = () => { 
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })}

    _onClickDrawerItemHandler = (redirectParams) => this.setState({redirectParams})

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            const snackMessage = {
                level: StatusSnackbarTypes.ERROR,
                message: error
            }

            this.setState({loading: false, snackMessage})
        })
    }

    _onClickOrder = (orderId) => {
        this.setState({showOrderDetailsDialog: true})
        const api = new OrderAPI(this.props.server.host, this.props.credentials.username,
                                this.props.credentials.password)
        api.requestOrderDetails(this.props.airport.airportId, orderId,
            (response) => {
                this.setState({ orderDetails: response.data})
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                    
                this.setState({snackMessage})   
            }
        )
    }

    _onCloseOrderDetails = () => this.setState({orderDetails: null, showOrderDetailsDialog: false})

    /** END event handlers */


    _processOrderData = () => {
        // filter orders into "waiting for assignment", "in-progress", and "cancelled" buckets
        const { orders } = this.state
        let pendingOrders = []
        let activeOrders = []

        orders.forEach(order => {
            if(order.orderState === "PENDING_FOX_ACCEPT" || order.orderState === "ACCEPTED")
                pendingOrders.push(order)
            else if (order.orderState === "CANCELLED") { /* no-op */ }
            else
                activeOrders.push(order)
        })
        this.setState({pendingOrders, activeOrders})
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>
        else
            return (
                <div>
                    <ActiveDeliveries
                        classes={this.props.classes}
                        state={this.state}
                        onClickOrder={this._onClickOrder}
                        onCloseOrderDetails={this._onCloseOrderDetails}
                        onCloseSnack={this._onCloseSnack}
                        onClickDrawerItem={this._onClickDrawerItemHandler}
                        onClickDrawerLogout={this._onClickLogoutHandler} />
                    <FetchyIntercom 
                        userId={this.props.credentials.username}
                        email={this.props.credentials.email} 
                        name={this.props.credentials.username}
                        airport={this.props.airport.name}
                        route={this.props.location.pathname}
                        accountType={this.props.account.accountType} 
                        customData={{source: "Web Dashboard"}}/>                        
                </div>
            )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account } = state
    return { server, credentials, airport, account }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(ActiveDeliveriesContainer)
