import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class InsightsAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid   // the email address of a firebase account
        this.password = fetchyToken // the accessToken for a firebase account
    }

    promiseAllTimeSalesSummary = () => {
        return axios({
            url: `${this.host}/v1/airport/insights/summary`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseAllTimeSalesSummary = () => {
        return this.promiseAllTimeSalesSummary().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseFeedbackSummary = () => {
        return axios({
            url: `${this.host}/v1/airport/insights/reviews`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseFeedbackSummary = () => {
        return this.promiseFeedbackSummary().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseFeedbackData = (start_date, end_date) => {
        return axios({
            url: `${this.host}/v1/airport/data/reviews`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                start_date,
                end_date
            }
        })
    }

    tryPromiseFeedbackData = (start_date, end_date) => {
        return this.promiseFeedbackData(start_date, end_date).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseItemSalesData = (start_date, end_date) => {
        return axios({
            url: `${this.host}/v1/airport/data/items`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                start_date,
                end_date
            }
        })
    }

    tryPromiseItemSalesdata = (start_date, end_date) => {
        return this.promiseItemSalesData(start_date, end_date).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseOrderData = (start_date, end_date, csv) => {
        return axios({
            url: `${this.host}/v1/airport/data/orders`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                start_date,
                end_date,
                csv
            }
        })
    }

    tryPromiseOrderData = (start_date, end_date, csv) => {
        return this.promiseOrderData(start_date, end_date, csv).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseFoxPerformanceData = (fox_id, start_date, end_date) => {
        return axios({
            url: `${this.host}/v1/airport/data/performance`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fox_id,
                start_date,
                end_date
            }
        })
    }

    requestFeedbackData = (startDate, endDate, onRequestSuccess, onRequestError) => {
        this.promiseFeedbackData(startDate, endDate).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestItemSalesData = (startDate, endDate, onRequestSuccess, onRequestError) => {
        this.promiseItemSalesData(startDate, endDate).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestOrderData = (startDate, endDate, csv, onRequestSuccess, onRequestError) => {
        this.promiseOrderData(startDate, endDate, csv).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestFoxPerformanceData = (foxId, startDate, endDate, onRequestSuccess, onRequestError) => {
        this.promiseFoxPerformanceData(foxId, startDate, endDate).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestAllTimeSalesSummary = (onRequestSuccess, onRequestError) => {
        this.promiseAllTimeSalesSummary().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestFeedbackSummary = (onRequestSuccess, onRequestError) => {
        this.promiseFeedbackSummary().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
