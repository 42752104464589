import PropTypes from "prop-types"
import React, { Component } from "react"
import { FormattedNumber } from "react-intl"
import NumberFormat from "react-number-format";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { findCurrency } from "currency-formatter"
import { isEmptyString } from "../utils"

class CurrencyInputField extends Component {

    _renderNumberFormatter = (props) => {
        const currency = findCurrency(this.props.iso4217)
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value * 10 ** currency.decimalDigits
                        }
                    });
                }}
                allowNegative={false}
                fixedDecimalScale
                decimalScale={currency.decimalDigits}
                thousandSeparator={currency.thousandsSeparator}
                decimalSeparator={currency.decimalSeparator}
            />
        )
    }

    render = () => {
        const currency = findCurrency(this.props.iso4217)
        let startAdornment, endAdornment, textAlign = null
        if (currency.symbolOnLeft) {
            startAdornment = <InputAdornment position="start">{currency.symbol}</InputAdornment>
            endAdornment = null
            textAlign = "left"
        }
        else {
            startAdornment = null
            endAdornment = <InputAdornment position="end">{currency.symbol}</InputAdornment>
            textAlign = "right"
        }

        return (
            <TextField
                variant={this.props.variant}
                size={this.props.size}
                error={ !isEmptyString(this.props.errorMessage) }
                helperText={this.props.errorMessage}
                label={this.props.label}
                placeholder={this.props.placeholder}
                value={this.props.value / 10 ** currency.decimalDigits}
                onChange={(event) => this.props.onChange(event.target.value)}
                fullWidth={this.props.fullWidth}
                disabled={this.props.disabled}
                InputProps={{
                    startAdornment,
                    endAdornment,
                    inputComponent: this._renderNumberFormatter
                }}
                inputProps={{
                    style: {textAlign}
                }}
                style={this.props.style}
            />
        )
    }
}

CurrencyInputField.propTypes = {
    errorMessage: PropTypes.string,
    iso4217: PropTypes.string,

    // these are the same properties used by TextField
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,    
    variant: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool

}

CurrencyInputField.defaultProps = {
    errorMessage: "",
    fullWidth: false,
    onChange: (value) => { console.log(`default onChange: ${value}`)},
}


class CurrencyTextField extends Component {
    render = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body1">{this.props.label}</Typography>
                    <FormattedNumber
                        value={ this.props.value }
                        style={`currency`}
                        currency={this.props.iso4217}/>
                </Grid>
            </Grid>
        )
    }
}

CurrencyTextField.propTypes = {
    iso4217: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number
}



export { CurrencyInputField, CurrencyTextField }
