import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"

import { isEmptyString } from "../common/utils"
import { MerchantAccountAPI, MerchantSessionAPI } from "../common/requests/merchant/account"
import FetchyFoxLogo from "../../assets/logo.png"
import { setAccountName, removeCredentials, removeEmail, clearItems, clearPlaces, clearAccount } from "../../actions"
import { UPDATE_VIEW } from "../globals"
import { BasicInfo } from "./views/BasicInfo"

const countryLookup = require("country-data").lookup

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class BasicInfoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            birthdate: "",
            phoneNumber: "",
            countryCode: "",
            countryCallingCode: "",
            pictureUrl: null,
            imageData: null,
            mimeType: null,

            loading: false,
            redirectParams: null,
            snackMessage: "",

            pathName: this.props.location.pathname,
            viewMode: this.props.location.viewMode,
            menuItems: this.props.account.menuItems || [],
            location: props.location,

            credentials: props.credentials,
            account: props.account,
            airport: props.airport
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this._resetErrorTexts()

        if(this.props.location.viewMode === UPDATE_VIEW)
            this._getProfile()
        else
            this.setState({email: this.props.credentials.email || ""})
    }

    _resetErrorTexts = () => {
        this.setState({
            firstNameErrorText: "",
            lastNameErrorText: "",
            emailErrorText: "",
            birthdateErrorText: "",
            phoneNumberErrorText: "",
            countryErrorText: "",
            profilePictureErrorText: ""
        })
    }

    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onTypeFirstName = (firstName) => this.setState({firstName})
    _onTypeLastName = (lastName) => this.setState({lastName})
    _onTypeEmail = (email) => this.setState({email})
    _onSelectBirthdate = (birthdate) => this.setState({birthdate})
    _onTypePhoneNumber = (phoneNumber) => this.setState({phoneNumber})
    _onSelectCountry = (country) => this.setState({countryCode: country.alpha2, countryCallingCode: country.countryCallingCodes[0]})
    _onSelectImage = (imageData, mimeType) => this.setState({imageData, mimeType})

    _onClickSubmit = () => {
        this._resetErrorTexts()
        if(isEmptyString(this.state.firstName)){
            this.setState({firstNameErrorText: "Required"})
        }
        else if(isEmptyString(this.state.lastName)) {
            this.setState({lastNameErrorText: "Required"})
        }
        else if(isEmptyString(this.state.birthdate)){
            this.setState({birthdateErrorText: "Required"})
        }
        else if(isEmptyString(this.state.countryCode)){
            this.setState({countryErrorText: "Required"})
        }
        else if(isEmptyString(this.state.phoneNumber)) {
            this.setState({phoneNumberErrorText: "Required"})
        }
        else if(isEmptyString(this.state.email)) {
            this.setState({emailErrorText: "Required"})
        }
        else {
            this.props.setAccountName(this.state.firstName, this.state.lastName)
            this._updateProfile()
        }
    }
    _onClickCancel = () => {
        if(this.props.location.viewMode === UPDATE_VIEW)
            this.setState({redirectParams: {pathname: "/dashboard"}})
        else
            this.setState({redirectParams: {pathname: "/"}})
    }

    _onClickDrawerItem = (redirectParams) => {
        this.setState({redirectParams})
    }

    _onClickLogout = () => {
        this.setState({loading: true})
        const api = new MerchantSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.clearItems()
                this.props.clearPlaces()
                this.props.clearAccount()
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirectParams: {pathname: "/"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    /** network requests */
    _getProfile = () => {
        this.setState({loading: true})
        const api = new MerchantAccountAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestProfile(
            (success) => {
                this.setState({
                    firstName: success.data.name.split(" ")[0],
                    lastName: success.data.name.split(" ")[1],
                    email: success.data.email,
                    birthdate: success.data.birthdate,
                    phoneNumber: success.data.phone_number,
                    pictureUrl: success.data.image_url,
                    loading: false
                })
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    _updateProfile = () => {
        this.setState({loading: true})
        const api = new MerchantAccountAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)

        let country = countryLookup.countries({ name: this.props.airport.country })[0]
        api.requestUpdateProfile(this.state.firstName, this.state.lastName, this.state.email, this.state.phoneNumber,
            this.state.countryCode, this.state.birthdate,
            (success) => {
                this.setState({loading: false})
                if(this.state.imageData !== null)
                    this._updateProfilePicture()
                else {
                    if(country.alpha2 === "US")
                        this.setState({loading: false, redirectParams: {pathname: "/merchant/platform"}})
                    else
                        this.setState({loading: false, redirectParams: {pathname: "/dashboard"}})
                }
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false})
            } )
    }

    _updateProfilePicture = () => {
        this.setState({loading: true})
        const api = new MerchantAccountAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        let country = countryLookup.countries({ name: this.props.airport.country })[0]
        api.requestUpdateProfilePicture(this.state.imageData, this.state.mimeType,
            (success) => {
                if(country.alpha2 === "US")
                    this.setState({loading: false, redirectParams: {pathname: "/merchant/platform"}})
                else
                    this.setState({loading: false, redirectParams: {pathname: "/dashboard"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false})
            })
    }
    /** end network requests */
    render = () => {
        return <BasicInfo classes={ this.props.classes }
                    data= { this.state }
                    onClickDrawerItem={ this._onClickDrawerItem }
                    onClickLogout={ this._onClickLogout }
                    onTypeFirstName={ this._onTypeFirstName}
                    onTypeLastName={ this._onTypeLastName }
                    onSelectBirthdate={ this._onSelectBirthdate }
                    onSelectCountry={ this._onSelectCountry }
                    onTypePhoneNumber={ this._onTypePhoneNumber }
                    onTypeEmail={ this._onTypeEmail }
                    onSelectImage={ this._onSelectImage }
                    onClickCancel={ this._onClickCancel }
                    onClickSubmit={ this._onClickSubmit }
                    onCloseSnack={ this._onCloseSnack }/>
    }
}

function mapStateToProps(state) {
    const { server, credentials, account, airport } = state
    return { server, credentials, account, airport }
}

const mapActionCreatorsToProps = {
    setAccountName,
    removeCredentials,
    removeEmail,
    clearItems,
    clearPlaces,
    clearAccount
}

BasicInfoContainer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(BasicInfoContainer)
