import uuid from "uuid4"
import { findCurrency } from "currency-formatter"
import React from "react"
import { Redirect } from "react-router-dom"
import CalendarHeatmap from "react-calendar-heatmap"
import ReactTooltip from "react-tooltip"
import PropTypes from "prop-types"
import {
    Grid,
    Typography,
    Divider,
    TextField,
    MenuList,
    MenuItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    IconButton
} from "@material-ui/core"

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"

import { StatusSnackbar } from "../../common/components/StatusSnackbar"
import { isEmptyString } from "../../common/utils"
import DateRangeInput from "../../common/components/DateRangeInput"
import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import { Histogram } from "../../common/components/Histogram"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import "../../common/react-calendar-heatmap.css"

function _renderStars(filled, maximum) {
    let stars = []
    for(let i = 0; i < maximum; i++){
        if(i < filled)
            stars.push(<StarIcon color="primary" key={i}/>)
        else
            stars.push(<StarBorderIcon color="primary" key={i}/>)
    }
    return (
        <div>{
            stars.map((star, idx) => {
                return star
            })
        }</div>
    )
}

function _renderHeatmapDialog(props) {
    const { state } = props
    const orders = state.rawPerformanceData.filter(row => {
        return row.order_date_utc === state.selectedHeatmapDate &&
            row.numeric_rating !== null
    })
    return (
        <Dialog 
            open={state.selectedHeatmapDate !== null} 
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            fullWidth
            onClose={props.onCloseHeatmapDialog}>
            <DialogTitle>{state.selectedHeatmapDate}</DialogTitle>
            <DialogContent>{
                orders.length > 0 ?
                    orders.map((row, idx) => {
                        return (
                            <div key={idx}>
                                {_renderStars(row.numeric_rating, 5)}
                                <div style={{height: 10}}/>
                                    <Typography variant="body1">{`"${row.comment}"`}</Typography>
                                <div style={{height: 10}}/>
                                <Divider style={{margin: 5}}/>                    
                            </div>
                        )
                    }) :
                    <Typography variant="body1">No reviews this day <span role="img" aria-label="sad-face">🙁</span></Typography>
            }</DialogContent>
        </Dialog>
    )
}


function _renderFoxPerformance(props, isMobileScreen) {
    const { classes, state } = props
    const style = isMobileScreen ? {} : {height: "100vh", display: "flex"}

    if(state.selectedFox) {

        const currency = findCurrency(state.iso4217)
    
        // this value is in seconds (int) so we want to convert it to MM:SS (string)
        const nDeliveries = state.summaryData.deliveries ? state.summaryData.deliveries : "-"
        const totalSales = state.summaryData.totalSales ? 
            `${currency.symbol}${state.summaryData.totalSales.toFixed(currency.decimalDigits)}` : 
            "-"
        const avgDeliveryTime = state.summaryData.avgDelverySeconds ? 
            `${Math.floor(state.summaryData.avgDelverySeconds / 60)}:${Math.floor(state.summaryData.avgDelverySeconds % 60).toString().padStart(2, "0")}` :
            "-"
        const nRatings = state.summaryData.ratings ? state.summaryData.ratings : "-"
        const cashSales = state.summaryData.cashSales ? 
            `${currency.symbol}${state.summaryData.cashSales.toFixed(currency.decimalDigits)}` : 
            "-"
        const avgRating = state.summaryData.avgRating ? state.summaryData.avgRating.toFixed(2) : "-"

        // heatmap timerange labels
        const startDateLabel = state.startHeatmapRange.format("lll")
        const endDateLabel = state.endHeatmapRange.format("lll")

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2" color="primary">{`${state.selectedFox.first_name} ${state.selectedFox.last_name}`}</Typography>
                            <Typography variant="body1">{`${state.selectedFox.fox_id}`}</Typography>
                            <div style={{height: 10}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {`Last Login: ${state.selectedFox.last_login.format("lll (z)")}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {`Last Completed Delivery: ${state.selectedFox.last_active.format("lll (z)")}`}
                            </Typography>
                        </Grid>                        
                        <Grid item xs={12}>
                            <DateRangeInput
                                initStartDate={state.startHistoricalRange}
                                initEndDate={state.endHistoricalRange}
                                onClickUpdate={props.onClickUpdateDateRange}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{nDeliveries}</Typography>
                            <Typography variant="h6">Deliveries</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{totalSales}</Typography>
                            <Typography variant="h6">Sales</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{nRatings}</Typography>
                            <Typography variant="h6">Ratings</Typography>
                        </Grid>        
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{cashSales}</Typography>
                            <Typography variant="h6">Cash Payments</Typography>
                        </Grid>                                
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{avgDeliveryTime}</Typography>
                            <Typography variant="h6">Avg. Delivery Time</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Typography variant="h3" className={classes.highlightText}>{avgRating}</Typography>
                            <Typography variant="h6">Avg. Rating</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item xs={12} lg={6}>
                            <Histogram title="Ratings" data={state.ratingDistribution} xLabel="Stars" yLabel="" key={uuid()}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Histogram title="Delivery Duration" data={state.deliveryTimeDistribution} xLabel="Time" yLabel="" key={uuid()}/>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{height: "10px"}}/>
                            <Typography variant="h4">Deliveries over Time</Typography>
                            <IconButton className={classes.margin} aria-label="back 3 months" size="small" edge="start" color="secondary"
                                onClick={() => {
                                    props.onClickUpdateHeatmapRange(
                                        state.startHeatmapRange.subtract(3, "months"),
                                        state.endHeatmapRange.subtract(3, "months")                            
                                    )
                                }}>
                                <NavigateBeforeIcon/>
                            </IconButton>
                            <Typography component="span" variant="button" color="secondary">{`${startDateLabel} - ${endDateLabel}`}</Typography>
                            <IconButton className={classes.margin} aria-label="forward 3 months" size="small"
                                color="secondary"
                                onClick={() => {
                                    props.onClickUpdateHeatmapRange(
                                        state.startHeatmapRange.add(3, "months"),
                                        state.endHeatmapRange.add(3, "months")                            
                                    )
                                }}>
                                <NavigateNextIcon />
                            </IconButton>  
                            <Divider/>
                            <div style={{height: 10}}/>

                            <CalendarHeatmap
                                startDate={state.startHeatmapRange.format("YYYY-MM-DD")}
                                endDate={state.endHeatmapRange.format("YYYY-MM-DD")}
                                showWeekdayLabels
                                onClick={(value) => {
                                    if(value)
                                        props.onClickHeatmapDate(value.date)
                                }}
                                values={state.deliveryDayDistribution}
                                classForValue={value => {
                                    if (!value) {
                                    return "color-fetchyfox-0";
                                    }
                                    else if (value.orders >= 9) {
                                        return "color-fetchyfox-max"
                                    }
                                    else {
                                        return `color-fetchyfox-${value.orders}`
                                    }
                                    
                                }}
                                tooltipDataAttrs={value => {
                                    if(value.orders)
                                        return {
                                            "data-tip": `${value.date}: ${value.orders} deliveries, ${value.reviews} reviews`
                                        }
                                    else
                                        return {
                                            "data-tip": "No Deliveries"
                                        }
                                }}                                                        
                            />
                            <ReactTooltip />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container justify="center" alignContent="center" style={style}>
                <Grid item>
                    <Typography variant="body1">No Fox selected.</Typography>
                </Grid>
            </Grid>
        )
    }
}

function _renderFoxList(props, isMobileScreen) {
    const { state } = props
    const style = isMobileScreen ? {height: "200px"} : {height: "100vh"}

    return (
        <Grid container style={style}>
            <Grid item xs={12}>
                <MenuList style={{overflow: "auto"}}>{
                    state.filteredFoxes.map((fox, idx) => {
                        return (
                            <MenuItem key={idx} onClick={() => props.onClickFox(fox.fox_id)}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={fox.first_name}
                                        src={fox.picture_uri}/>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={`${fox.first_name} ${fox.last_name}` }
                                    secondary={
                                        <Typography variant="body2">
                                            {`Last Active: ${fox.last_active.format("lll (z)")}`}
                                        </Typography>
                                    }/>
                            </MenuItem>
                        )
                    })

                }</MenuList>
            </Grid>
        </Grid>
    )
}

export const FoxPerformance = (props) => {
    const { classes, state } = props
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
    
    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <FetchyIntercom 
                userId={state.credentials.username}
                email={state.credentials.email} 
                name={state.credentials.username}
                airport={state.airport.name}
                route={state.location.pathname}
                accountType={state.account.accountType} 
                customData={{source: "Web Dashboard"}}/>                 
            <main style={{width: "100vw", height: "99vh"}}>
                <div className={classes.spacerTop}/>
                <Grid container justify="center">
                    <Grid item xs={12} sm={5} lg={4} xl={3}>
                        <TextField 
                            variant="outlined"
                            placeholder="Search Foxes..." 
                            fullWidth onChange={(event) => props.onTypeFoxSearch(event.target.value)}/>
                        <div style={{height: 10}}/>
                        <Card style={{maxHeight: "100%", overflow: "auto"}}>
                            <CardContent>
                                { _renderFoxList(props, isMobileScreen) }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} lg={8} xl={9}>
                        { _renderFoxPerformance(props, isMobileScreen) }
                    </Grid>
                </Grid>
                { _renderHeatmapDialog(props) }
                <StatusSnackbar
                        open={!isEmptyString(state.snackMessage.message)}
                        variant={state.snackMessage.level}
                        message={state.snackMessage.message}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        onClose={props.onCloseSnack}/>   
                <LoadingDialog show={state.loading} message="Loading..." />
            </main>
        </div>
    )
}

FoxPerformance.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onTypeFoxSearch: PropTypes.func,
    onClickHeatmapDate: PropTypes.func,
    onClickUpdateDateRange: PropTypes.func,
    onClickUpdateHeatmapRange: PropTypes.func,
    onCloseHeatmapDialog: PropTypes.func,
    onClickFox: PropTypes.func,
    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func,
    onCloseSnack: PropTypes.func
}

FoxPerformance.defaultProps = {
    onClickUpdateDateRange: (startDate, endDate) => console.log(`onClickUpdateDateRange`),
    onClickUpdateHeatmapRange: (startDate, endData) => console.log("onClickUpdateHeatmapRange"),
    onClickHeatmapDate: (date) => console.log(`onClickHeatmap ${date}`),
    onClickFox: (foxId) => console.log(`onClickFox: ${foxId}`),
    onTypeFoxSearch: (value) => console.log(`onTypeFoxSearch: ${value}`),
    onClickDrawerItem: (redirectParams) => console.log(`onClickDrawerItem ${JSON.stringify(redirectParams)}`),
    onClickDrawerLogout: () => console.log("onClickDrawerLogout"),
    onCloseSnack: () => console.log("onCloseSnack")
}
