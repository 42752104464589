import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { LegalDocsAPI } from "../common/requests/legal"
import { MerchantSessionAPI } from "../common/requests/merchant/account"
import FetchyFoxLogo from "../../assets/logo.png"
import { removeCredentials, removeEmail, setCredentials } from "../../actions"
import { Legal } from "./views/Legal"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }
})

class LegalContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTabIdx: 0,

            privacyText: "",
            termsText: "",

            dataChecked: false,
            privacyChecked: false,
            termsChecked: false,

            snackMessage: "",
            loading: false,
            redirect: ""
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    componentWillMount = () => {
        this._getPrivacyText()
    }

    _onChangeTab = (event, selectedTabIdx) => this.setState({selectedTabIdx})
    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onCheck = (event, checked) => {
        this.setState({
            [event.target.value]: checked
        })
    }

    _onClickCancel = () => {
        this.props.removeCredentials()
        this.props.removeEmail()
        this.setState({redirect: "/"})
    }

    _onClickContinue = () => {
        if (this.state.dataChecked &&
            this.state.privacyChecked && this.state.termsChecked ) {
            this._createMerchantAccount()
        }
        else {
            this.setState({snackMessage: "You must agree to all documents."})
        }
    }

    /** network requests */

    _createMerchantAccount = () => {
        this.setState({loading: true})
        const api = new MerchantSessionAPI(this.props.server.host)
        api.requestCreateAccount(this.props.credentials.username, this.props.credentials.password,
            this.props.airport.iataCode,
        (success) => {
            this.setState({loading: false, redirect: "/merchant/basic"})
            this.props.setCredentials(success.data.uuid, success.data.token)
        },
        (error) => {
            const message = `${error.responseCode}: ${error.message}`
            this.setState({loading: false, snackMessage: message})
        })
    }

    _getPrivacyText = () => {
        this.setState({loading: true})
        const api = new LegalDocsAPI()
        api.requestPrivacyPolicyText(
            (text) => {
                this.setState({privacyText: text, loading: false})
                this._getTermsText()
            },
            (error) => {
                const message = `${error.status}: ${error.statusText}`
                this.setState({snackMessage: message})
            }
        )
    }

    _getTermsText = () => {
        this.setState({loading: true})
        const api = new LegalDocsAPI()
        api.requestTermsOfAgreementText(
            (text) => {
                this.setState({termsText: text, loading: false})
            },
            (error) => {
                const message = `${error.status}: ${error.statusText}`
                this.setState({snackMessage: message})
            }
        )
    }

    /** END network requests */

    render = () => {
        const { classes } = this.props
        return <Legal
                    classes={ classes }
                    data={ this.state }
                    airportName={this.props.airport.name}
                    onChangeTab={ this._onChangeTab }
                    onCheck={ this._onCheck }
                    onClickCancel={ this._onClickCancel }
                    onClickContinue={ this._onClickContinue }
                    onSnackClose={ this._onCloseSnack }/>

    }
}

LegalContainer.propTypes = {
    classes: PropTypes.object.isRequired,
 }

function mapStateToProps(state) {
    const { server, credentials, airport } = state
    return { server, credentials, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    setCredentials
}

export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(LegalContainer)
