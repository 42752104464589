import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"


export class AdminAirportAPI {
    constructor(host, username, password){
        this.host = host
        this.username = username
        this.password = password
    }

    promiseAllAirports = () => {
        return axios({
            url: `${this.host}/v1/admin/airports`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    promiseAirportDetails = (iata) => {
        return axios({
            url: `${this.host}/v1/admin/airport`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                iata
            }
        })
    }

    promiseDepartureInfo = (origin_airport_id, flight_code) => {
        return axios({
            url: `${this.host}/v1/admin/departure`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                origin_airport_id,
                flight_code
            }
        })
    }

    tryPromiseAllAirports = () => {
        return this.promiseAllAirports()
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    tryPromiseAirportDetails = (iata) => {
        return this.promiseAirportDetails(iata)
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    tryPromiseDepatureInfo = (airportId, flightCode) => {
        return this.promiseDepartureInfo(airportId, flightCode)
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })        
    }


    requestAllAirports = (onRequestSuccess, onRequestError) => {
        // return info about all airports
        this.promiseAllAirports().then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestAirportDetails = (iata, onRequestSuccess, onRequestError) => {
        // returns basic info about the airport such as name, coordinates, terminal names,
        // gates, and more
        this.promiseAirportDetails(iata).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestDepartureInfo = (airportId, flightCode, onRequestSuccess, onRequestError) => {
        this.promiseDepartureInfo(airportId, flightCode).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })        
    }
}
