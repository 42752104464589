import { SET_BACKEND } from "../constants"

export default (state = {}, action) => {
    switch(action.type) {
        case SET_BACKEND:
            const { host } = action
            return {
                host
            }
        default:
            return state
    }
}
