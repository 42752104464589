import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import { withStyles } from '@material-ui/core/styles';

// pixel values
const padding = 5
const cardHeight = 200

const styles = theme => ({
    infoContainer: {
    },
    infoCard: {
        padding,
        marginBottom: 10,
        marginTop: 10,
        height: cardHeight,
    },
    infoContent: {
        padding,
        height: cardHeight
    }
})

class HighlightGrid extends Component {
    render = () => {
        const { classes } = this.props

        if (this.props.data.length === 0) {
            return (
                <Grid container className={classes.infoContainer} justify="center" alignContent="center">
                    <Grid item className={classes.infoCard} xs={12} >
                        <Card className={classes.infoContent}>
                            <Grid container justify="center" alignContent="center" className={classes.infoContent}>
                                <Grid item>
                                    <Typography variant="body1" align="center">No Data</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )
        }


        return (
            <Grid container className={classes.infoContainer}>{
                this.props.data.map((item, idx) => {
                    return (
                        <Grid item key={idx} className={classes.infoCard} xs={6} md={4}>
                            <Card className={classes.infoContent}>
                                <Grid container justify="center" alignContent="center" className={classes.infoContent}>
                                    <Grid item>
                                        <Typography variant="h4" align="center">{item.value}</Typography>
                                        <Typography variant="body1" align="center">{item.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )
                })
            }</Grid>
        )
    }
}

HighlightGrid.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired
}

HighlightGrid.defaultProps = {
    data: [
        { title: "Total Sales (Today)", value: "No Data" },
        { title: "Total Orders (Today)", value: "No Data" },
        { title: "Avg. Order Sales", value: "No Data" },
        { title: "Another Metric", value: "No Data" },
        { title: "Useful Metric", value: "No Data" },
        { title: "Fun Metric", value: "No Data" },
]
}

export default withStyles(styles)(HighlightGrid)
