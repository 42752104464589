import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class ApplicantAPI {
    constructor(host, email, idToken) {
        this.host = host
        this.username = email   // the email address of a firebase account
        this.password = idToken // the accessToken for a firebase account
    }

    promisePendingFoxes = () => {
        return axios({
            url: `${this.host}/v1/airport/foxes`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromisePendingFoxes = () => {
        return this.promisePendingFoxes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPendingFoxes = (onResponseSuccess, onResponseError) => {
        this.promisePendingFoxes().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseFoxApplication = (fox_id) => {
        return axios({
            url: `${this.host}/v1/airport/fox`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fox_id
            }
        })
    }

    tryPromiseFoxApplication = (fox_id) => {
        return this.promiseFoxApplication(fox_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestFoxApplication = (fox_id, onResponseSuccess, onResponseError) => {
        this.promiseFoxApplication(fox_id).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }


    promiseApproveFox = (fox_id, authorizer) => {
        return axios({
            url: `${this.host}/v1/airport/fox`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                fox_id,
                authorizer
            }
        })
    }

    tryPromiseApproveFox = (fox_id, authorizer) => {
        return this.promiseApproveFox(fox_id, authorizer).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestApproveFox = (fox_id, authorizer, onResponseSuccess, onResponseError) => {
        this.promiseApproveFox(fox_id, authorizer).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseDenyFox = (fox_id, authorizer, reason) => {
        return axios({
            url: `${this.host}/v1/airport/fox`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fox_id,
                action: "deny"
            },
            data: {
                reason,
                authorizer
            }
        })
    }

    tryPromiseDenyFox = (fox_id, authorizer, reason) => {
        return this.promiseDenyFox(fox_id, authorizer, reason).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDenyFox = (fox_id, authorizer, reason, onResponseSuccess, onResponseError) =>{
        this.promiseDenyFox(fox_id, authorizer, reason).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    requestSuspendFox = (fox_id, authorizer, reason, onResponseSuccess, onResponseError) => {
        axios({
            url: `${this.host}/v1/airport/fox`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fox_id,
                action: "suspend"
            },
            data: {
                reason,
                authorizer
            }            
        }).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promisePendingMerchants = () => {
        return axios({
            url: `${this.host}/v1/airport/merchants`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromisePendingMerchants = () => {
        return this.promisePendingMerchants().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPendingMerchants = (onResponseSuccess, onResponseError) => {
        this.promisePendingMerchants().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseMerchantApplication = (merchant_id) => {
        return axios({
            url: `${this.host}/v1/airport/merchant`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                merchant_id
            }
        })
    }

    tryPromiseMerchantApplication = (merchant_id) => {
        return this.promiseMerchantApplication(merchant_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestMerchantApplication = (merchant_id, onResponseSuccess, onResponseError) => {
        this.promiseMerchantApplication(merchant_id).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseApproveMerchant = (merchant_id, authorizer) => {
        return axios({
            url: `${this.host}/v1/airport/merchant`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                merchant_id,
                authorizer
            }
        })
    }

    tryPromiseApproveMerchant = (merchant_id, authorizer) => {
        return this.promiseApproveMerchant(merchant_id, authorizer).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestApproveMerchant = (merchant_id, authorizer, onResponseSuccess, onResponseError) => {
        this.promiseApproveMerchant(merchant_id, authorizer).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseDenyMerchant = (merchant_id, authorizer, reason) => {
        return axios({
            url: `${this.host}/v1/airport/merchant`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                merchant_id,
                action: "deny"
            },
            data: {
                reason,
                authorizer
            }
        })
    }

    tryPromiseDenyMerchant = (merchant_id, authorizer, reason) => {
        return this.promiseDenyMerchant(merchant_id, authorizer, reason).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDenyMerchant = (merchant_id, authorizer, reason, onResponseSuccess, onResponseError) =>{
        this.promiseDenyMerchant(merchant_id, authorizer, reason).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }
    requestSuspendMerchant = (merchant_id, authorizer, reason, onResponseSuccess, onResponseError) => {
        axios({
            url: `${this.host}/v1/airport/merchant`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                merchant_id,
                action: "suspend"
            },
            data: {
                reason,
                authorizer
            }
        }).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

}
