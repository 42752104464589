import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class ConstantsAPI {
    constructor(host, merchant_id, fetchy_token) {
        this.host = host
        this.username = merchant_id
        this.password = fetchy_token
    }

    promiseCouponTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/coupons`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseCouponTypes = () => {
        return this.promiseCouponTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })  
    }

    requestCouponTypes = (onRequestSuccess, onRequestError) => {
        this.promiseCouponTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })  
    }

    promiseDiscountTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/discounts`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })   
    }

    tryPromiseDiscountTypes = () => {
        return this.promiseDiscountTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })        
    }

    requestDiscountTypes = (onRequestSuccess, onRequestError) => {
        this.promiseDiscountTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })        
    }

    

    promiseDietaryTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/diets`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseDietaryTypes = () => {
        return this.promiseDietaryTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDietaryTypes = (onRequestSuccess, onRequestError) => {
        this.promiseDietaryTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promisePlaceTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/places`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromisePlaceTypes = () => {
        return this.promisePlaceTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPlaceTypes = (onRequestSuccess, onRequestError) => {
        this.promisePlaceTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseItemTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/items`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseItemTypes = () => {
        return this.promiseItemTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestItemTypes = (onRequestSuccess, onRequestError) => {
        this.promiseItemTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseOptionGroupTypes = () => {
        return axios({
            url: `${this.host}/v1/merchant/constants/optiongroups`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
        })
    }

    tryPromiseOptionGroupTypes = () => {
        return this.promiseOptionGroupTypes().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestOptionGroupTypes = (onRequestSuccess, onRequestError) => {
        this.promiseOptionGroupTypes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }


}
