import PropTypes from "prop-types"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

class LabeledTextField extends Component {
    render = () => {
        return (
            <div>
                <Grid container justify="flex-start">
                    <Grid item xs={10} sm={4} lg={4}>
                        <Typography variant="subtitle1">{this.props.label}</Typography>
                    </Grid>
                    <Grid item xs={10} sm={6} lg={6}>
                        <Typography variant="body1">{this.props.value}</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

class VerticalLabeledTextField extends Component {
    render = () => {
        return (
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="body1">{this.props.label}</Typography>
                    <Typography variant="body2">{this.props.value}</Typography>
                </Grid>
            </Grid>
        )
    }
}

VerticalLabeledTextField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

LabeledTextField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

export { LabeledTextField, VerticalLabeledTextField }
