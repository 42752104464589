import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class OrderAPI {
    constructor(host, username, password){
        this.host = host
        this.username = username
        this.password = password
    }

    promiseActiveOrders = (airport_id) => {
        return axios({
            url: this.host + "/v1/airport/orders",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id
            }
        })
    }

    tryPromiseActiveOrders = (airport_id) => {
        return this.promiseActiveOrders(airport_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestActiveOrders = (airport_id, onRequestSuccess, onRequestError) => {
        // return a list of unfulfilled orders
        this.promiseActiveOrders(airport_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseOrderDetails = (airport_id, order_id) => {
        return axios({
            url: this.host + "/v1/airport/order",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id,
                order_id
            }
        })
    }

    tryPromiseOrderDetails = (airport_id, order_id) => {
        return this.promiseOrderDetails(airport_id, order_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestOrderDetails = (airport_id, order_id, onRequestSuccess, onRequestError) => {
        this.promiseOrderDetails(airport_id, order_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseRefundOrder = (authorizer, airport_id, order_id, last4, reason) => {
        return axios({
            url: this.host + "/v1/airport/order/exception",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id,
                order_id,
                action: "REFUNDED"
            },
            data: {
                authorizer,
                last4,
                reason
            }
        })
    }

    tryPromiseRefundOrder = (authorizer, airport_id, order_id, last4, reason) => {
        return this.promiseRefundOrder(authorizer, airport_id, order_id, last4, reason).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestRefundOrder = (authorizer, airport_id, order_id, last4, reason, onRequestSuccess, onRequestError) => {
        this.promiseRefundOrder(authorizer, airport_id, order_id, last4, reason).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseOrderLookup = (airport_id, place_id, order_ref) => {
        return axios({
            url: this.host + "/v1/airport/order/lookup",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id,
                place_id,
                order_ref
            }
        })
    }

    tryPromiseOrderLookup = (airport_id, place_id, order_ref) => {
        return this.promiseOrderLookup(airport_id, place_id, order_ref).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestOrderLookup = (airport_id, place_id, order_ref, onRequestSuccess, onRequestError) => {
        this.promiseOrderLookup(airport_id, place_id, order_ref).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseOrderPaymentDetails = (airport_id, order_id) => {
        return axios({
            url: this.host + "/v1/airport/order/payment",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id,
                order_id
            }
        })
    }

    tryPromiseOrderPaymentDetails = (airport_id, order_id) => {
        return this.promiseOrderPaymentDetails(airport_id, order_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestOrderPaymentDetails = (airport_id, order_id, onRequestSuccess, onRequestError) => {
        this.promiseOrderPaymentDetails(airport_id, order_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
