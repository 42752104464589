import uuid from "uuid4"
import PropTypes from "prop-types"
import compose from "recompose/compose"
import React, { Component } from "react"
import { findCurrency } from "currency-formatter"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { ConstantsAPI } from "../common/requests/merchant/constants"
import { PlaceAPI } from "../common/requests/merchant/place"
import { MenuAPI } from "../common/requests/merchant/menu"
import { SellableAPI } from "../common/requests/merchant/sellable"
import FetchyFoxLogo from "../common/assets/logo.png"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import SimpleConfirmDialog from "../common/components/SimpleConfirmDialog"
import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"

import { loadItems, setSelectedMenuId, clearItems, clearPlaces, clearAccount,
    removeCredentials, removeEmail } from "../../actions"
import MenuSectionModal from "./modals/MenuSection"
import MenuModal from "./modals/Menu"

import Sellable from "../Sellable"
import SellableConfig from "../SellableConfig"

import { MerchantListing } from "./views/Listing"
import { isEmptyString } from "../common/utils"


const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ListingContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: "",
            selectedMenuId: props.placedashboard.menuId || "",
            selectedCategoryId: "",

            placeId: props.placeId,
            currency: findCurrency(props.airport.iso4217),
            place_details: null,
            itemTypes: [],
            menus: [],
            filteredMenus: [],


            symbolLng: -122.387466,
            symbolLat: 37.618116,

            confirmDeleteDialog: null,
            dialogComponent: null,

            loading: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },
        }
    }

    /** lifecycle methods */

    componentDidMount = () => {
        this._getPlaceDetails(this.state.placeId, () => {
            this.setState({
                symbolLng: this.state.place_details.longitude,
                symbolLat: this.state.place_details.latitude
            }, () => {
                this._getPlaceMenus()
                this._getItemTypeConstants()
            })
        })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.placeId !== prevProps.placeId) {
            // refetch the data
            this._getPlaceDetails(this.props.placeId, () => {
                this.setState({
                    placeId: this.props.placeId,
                    symbolLng: this.state.place_details.longitude,
                    symbolLat: this.state.place_details.latitude
                }, this._getPlaceMenus)
            })            
        }

    }
    
    /** END lifecycle methods */

    /** business logic */
    _processMenuData = () => {
        const searchText = this.state.searchText.toLowerCase()

        const filteredMenus = this.state.menus
            .filter(menu => {
                return isEmptyString(this.state.selectedMenuId) || menu.menu_id === this.state.selectedMenuId
            })
            .map(menu => {
                const categories = menu.categories
                    .filter(category => { 
                        return isEmptyString(this.state.selectedCategoryId) || category.category_id === this.state.selectedCategoryId
                    })
                    .map(category => {
                        const sellables = category.sellables.filter(sellable => {
                            return isEmptyString(searchText) || sellable.name.toLowerCase().includes(searchText.toLowerCase())
                        })
                        return {
                            ...category,
                            sellables
                        }
                    })
                return {
                    ...menu,
                    categories
                }
            })
        this.setState({filteredMenus})
    }

    /** END business logic */


    /** MerchantListing component event listeners */

    _onTypeSearch = (searchText) => {
        this.setState({searchText}, this._processMenuData)
    } 

    _onSelectMenu = (selectedMenuId) => {
        // const selectedMenu = this.state.menus.filter(menu => menu.menu_id === selectedMenuId)[0]
        // const items = selectedMenu.sections.map(section => {
        //     return section.items
        // }).reduce(function(results, element) {
        //     return results.concat(element)
        // }, [])
        // this.props.loadItems(items)
        this.props.setSelectedMenuId(selectedMenuId)
        this.setState({selectedMenuId, selectedCategoryId: ""}, this._processMenuData)
    }

    _onSelectMenuCategory = (selectedCategoryId) => {
        this.setState({selectedCategoryId}, this._processMenuData)
    }
     /** END MerchantListing listener */

    /** event listeners for the PlaceDetails component */

    _onUpdatePlaceName = (name) => {
        let place_details = this.state.place_details
        place_details.name = name
        this.setState({ place_details }, this._updatePlaceDetails)
    }

    _onUpdatePlaceDescription= (description) => {
        let place_details = this.state.place_details
        place_details.description = description
        this.setState({ place_details }, this._updatePlaceDetails)
    }

    _onConfirmDeletePlace = () => this._deletePlace(this.state.placeId, this.props.onDeletePlace)

    _onClickPlaceMap = (map, event) => {
        const coordinates = event.lngLat
        const symbolLat = coordinates.lat
        const symbolLng = coordinates.lng
        this.setState({symbolLat, symbolLng})
    }

    _onClickUpdatePlaceLocation = () => { this._updatePlaceDetails() }

    _onTogglePlaceListed = (listed) => {
        let place_details = this.state.place_details
        place_details.listed = listed
        this.setState({ place_details }, this._updatePlaceDetails)
    }

    /** END PlaceDetails event listeners */


    /** event listeners for PlaceMenu component */
    _onClickRenameMenu = (menuId) => {
        let menu = this.state.menus.filter(menu => menu.menu_id === menuId)
        if(menu.length === 1) {
            menu = menu[0]
            const modal = <MenuModal
                            key={uuid()}
                            variant="update"
                            menuId={menu.menu_id}
                            name={menu.menu_name}
                            listed={menu.listed}
                            onCloseCallback={ this._onCloseDialog }
                            onCloseUpdateCallback={ this._onCloseUpdateCategoryModal }/>
        this.setState({dialogComponent: modal})
        }
        else {
            this.setState({
                snackMessage: {
                    level: StatusSnackbarTypes.WARNING,
                    message: "Menu not found."
                }
            })
        }
    }

    _onClickCreateMenu = () => {
        const modal = <MenuModal
                        key={uuid()}
                        variant="create"
                        placeId={this.state.placeId}
                        onCloseCallback={ this._onCloseDialog }
                        onCloseUpdateCallback={ this._onCloseUpdateCategoryModal }/>
        this.setState({dialogComponent: modal})
    }

    _onClickAddCategory = () => {
        const modal = <MenuSectionModal
                        key={uuid()}
                        variant="create"
                        menuId={this.state.selectedMenuId}
                        onCloseCallback={ this._onCloseDialog }
                        onCloseUpdateCallback={ this._onCloseUpdateCategoryModal }/>
        this.setState({dialogComponent: modal})
    }    
    
    _onClickEditCategory = (categoryId, name, listed) => {
        const modal = <MenuSectionModal
                        key={uuid()}
                        variant="update"
                        categoryId={categoryId}
                        name={name}
                        listed={listed}
                        onCloseCallback={ this._onCloseDialog }
                        onCloseUpdateCallback={ this._onCloseUpdateCategoryModal }/>
        this.setState({dialogComponent: modal})
    }

    _onClickDeleteCategory = (categoryId) => {
        const confirmDeleteDialog = <SimpleConfirmDialog
                                        open={true}
                                        title="Are you sure?"
                                        message="Deleting this section will also delete all items within it. This action
                                                is immediate and irreversible, are you sure you want to do this?"
                                        onConfirmCallback={() => this._onConfirmCategoryDelete(categoryId)}
                                        onCloseCallback={this._onCancelCategoryDelete}/>
        this.setState({confirmDeleteDialog})
    } 

    _onClickDeleteMenu = (menuId) => {
        const confirmDeleteDialog = <SimpleConfirmDialog
                                        open={true}
                                        title="Are you sure?"
                                        message="Deleting this menu will also delete all sections and items within it. This action
                                                is immediate and irreversible, are you sure you want to do this?"
                                        onConfirmCallback={() => this._onConfirmDeleteMenu(menuId)}
                                        onCloseCallback={this._onCancelMenuDelete}/>
        this.setState({confirmDeleteDialog})
    }

    _onClickAddSellable = () => {
        const dialogComponent = <Sellable
                                    placeId={this.state.placeId}
                                    variant="create"
                                    onCancelCallback={this._onCloseDialog}
                                    onContinueCallback={this._onClickSellableContinueListener}/>
        this.setState({dialogComponent})
    }
    
    _onClickManageSellable = (sellableId) => {

        this._getSellableDetails(sellableId, (payload) => {
            const dialogComponent = <Sellable
                                        placeId={this.state.placeId}
                                        variant="update"
                                        data={payload}
                                        onCancelCallback={this._onCloseDialog}
                                        onDeleteCallback={this._onClickDeleteSellableListener}
                                        onContinueCallback={this._onClickSellableContinueListener}/>
            this.setState({dialogComponent})
        })
    }

    _onToggleSellableListingStatus = (sellableId, listed) => {
        this._updateSellableListingStatus(sellableId, listed)
    }
    
    _onClickDeleteSellableListener = (sellableId) => {
        this._deleteSellable(sellableId, () => {
            this._getPlaceMenus()
            this.setState({dialogComponent: null})
        })
    }


    _onClickSellableContinueListener = (variant, categoryId, payload, mediaData, mimeType) => {
        let sellableFunc = (sellableDetails) => {}
        if(variant === "create") {
            sellableFunc = (sellableDetails) => {
                const { name, description, price, contents, type, listed, fulfillment_seconds, dietary_tags } = sellableDetails
                this._createSellable(this.state.placeId, categoryId, name, description, price.base_unit, contents,
                    type, listed, fulfillment_seconds, dietary_tags, mediaData, mimeType)
            }
        }
        else if(variant === "update") {
            sellableFunc = (sellableDetails) => {
                const { sellable_id, type, name, description, price, listed, fulfillment_seconds, contents, dietary_tags } = sellableDetails
                this._updateSellable(sellable_id, categoryId, type, name, description, listed, price.base_unit, 
                    fulfillment_seconds, contents, dietary_tags, mediaData, mimeType )
            }
        }

        const dialogComponent = <SellableConfig
                                    variant={variant}
                                    data={payload}
                                    onCancelCallback={this._onCloseDialog}
                                    onSubmitCallback={(sellableDetails) => {
                                        sellableFunc(sellableDetails)
                                        this.setState({dialogComponent: null})
                                    }}/>
        this.setState({dialogComponent})
    }

    /** END PlaceMenu event listeners */

    _onCancelCategoryDelete = () => {
        this.setState({confirmDeleteDialog: null})
    }

    _onConfirmCategoryDelete = (categoryId) => {
        this.setState({confirmDeleteDialog: null})
        this._deleteMenuCategory(categoryId)
    }

    _onCancelMenuDelete = () => {
        this.setState({confirmDeleteDialog: null})
    }

    _onConfirmDeleteMenu = (menuId) => {
        this.setState({confirmDeleteDialog: null})
        this._deleteMenu(menuId)
    }

    _resetSnackMessage = () => this.setState({
        snackMessage: {
            level: StatusSnackbarTypes.STANDARD,
            message: ""
        }
    })

    _onCloseDialog = () => this.setState({dialogComponent: null})

    _onCloseDeleteSellableDialog = (sellableId) => {
        this.setState({dialogComponent: null}, () => {
            this.state.menus.forEach(menu => {
                menu.categories.forEach(category => {
                    category.sellables = category.sellables.filter(sellable => {
                        return sellable.sellable_id !== sellableId
                    })
                })
            })
            this._processMenuData()
        })
    }

    _onCloseSellableDetailsDialog = (modifiedSellable) => {
        this.setState({dialogComponent: null}, () => {
            this.state.menus.forEach(menu => {
                menu.categories.forEach(category => {
                    category.sellables = category.sellables.map(sellable => {
                        if(sellable.sellable_id === modifiedSellable.sellable_id)
                            return modifiedSellable
                        else
                            return sellable
                    })
                })
            })
            this._processMenuData()
        })
    }

    _onCloseUpdateCategoryModal = () => {
        this.setState({dialogComponent: null}, this._getPlaceMenus)
    }
    /** END event listeners */



    // -- API requests

    _getPlaceDetails = (placeId, successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestPlace(placeId,
            (success) => {
                const place_details = success.data
                this.setState({place_details, loading: false}, successCallback)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _getItemTypeConstants = () => {
        const api = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestItemTypes(
            (success) => {
                const itemTypes = success.data.types
                this.setState({ itemTypes })
            },
            (error) =>{
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })   
            }
        )        
    }

    _getSellableDetails = (sellableId, callback=(data)=>{}) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        
        api.requestGetSellableDetails(sellableId,
            (success) => {
                const sellableDetails = success.data
                this.setState({loading: false})
                callback(sellableDetails)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })   
            })


    }

    _createSellable = (placeId, categoryId, name, description, priceBaseUnit, contents, sellableType, listed,
        fulfillmentSeconds, dietaryTags, mediaData, mimeType) => {
        
        this.setState({loading:true})
        const iso4217 = this.state.currency.code
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        
        const mediaUrls = null // mediaUrls aren't supported with direct sellable creation

        api.requestCreateSellable(placeId, categoryId, sellableType, name, description, listed, priceBaseUnit, iso4217,
            fulfillmentSeconds, contents, dietaryTags, mediaUrls,
            (success) => {
                const sellableId = success.data.sellable_id
                if(mediaData && mimeType ) {
                    this.setState({
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `${name} created!`
                        }
                    }, () => this._uploadSellableMedia(sellableId, mediaData, mimeType))                    
                }
                else {
                    this.setState({
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `${name} created!`
                        }
                    }, this._getPlaceMenus)
                }
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _updateSellable = (sellableId, categoryId, sellableType, name, description, listed, baseUnitPrice, fulfillmentSeconds, contents, 
        dietary_tags, mediaData, mimeType) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdateSellable(sellableId, categoryId, sellableType, name, description, listed, baseUnitPrice, fulfillmentSeconds,
            contents, dietary_tags,
            (success) => {
                if(mediaData && mimeType ) {
                    this.setState({
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `Sellable updated!`
                        }
                    }, () => this._uploadSellableMedia(sellableId, mediaData, mimeType))                    
                }
                else {
                    this.setState({
                        snackMessage: {
                            level: StatusSnackbarTypes.SUCCESS,
                            message: `Sellable updated!`
                        }
                    }, this._getPlaceMenus)
                }                
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _uploadSellableMedia = (sellableId, mediaData, mimeType) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUploadMedia(sellableId, mediaData, mimeType,
            (success) => {
                this._getPlaceMenus()
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _deleteSellable = (sellableId, onSuccess=()=>{}) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeleteSellable(sellableId,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Sellable deleted."
                    }
                }, onSuccess)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _updateSellableListingStatus = (sellableId, listed) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdateSellableListingStatus(sellableId, listed,
            (success) => {
                // update the listing status in memory so we don't need another network request to do it
                let { menus } = this.state
                menus = menus.map((menu) => {
                    let { categories } = menu
                    categories = categories.map((category) => {
                        let { sellables } = category
                        sellables = sellables.map((sellable) => {
                            if(sellable.sellable_id === sellableId) {
                                sellable.listed = listed
                            }
                            return sellable
                        })
                        category.sellables = sellables
                        return category
                    })
                    menu.categories = categories
                    return menu
                })
                this.setState({
                    menus,
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Changes saved!"
                    }
                })
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _getPlaceMenus = () => {
        this.setState({loading: true})
        const api = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        api.requestPlaceMenus(this.state.placeId,
            (success) => {
                const menus = success.data
                const selectedMenuId = menus.length > 0 ? menus[0].menu_id : ""
                this.setState({menus, selectedMenuId, loading: false}, this._processMenuData)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            }
        )
    }

    _deleteMenu = (menuId) => {
        this.setState({loading: true})
        const api = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeleteMenu(menuId,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Menu deleted."
                    }
                }, this._getPlaceMenus)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            }
        )        
    }

    _deleteMenuCategory = (categoryId) => {
        this.setState({loading: true})
        const api = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeleteMenuCategory(categoryId,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Menu category deleted."
                    }
                }, this._getPlaceMenus)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            }
        )
    }

    _deletePlace = (placeId, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeletePlace(placeId,
            // TODO: refresh listings, not a redirect 
            (success) => {
                this.setState({loading: false}, callback)
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    _updatePlaceDetails = () => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdatePlace(this.state.placeId, this.state.place_details.name, this.state.place_details.description,
            this.state.symbolLat, this.state.symbolLng, this.state.place_details.listed,
            (success) => {
                let place_details = this.state.place_details
                place_details.latitude = this.state.symbolLat
                place_details.longitude = this.state.symbolLng
                this.setState({
                    place_details, 
                    loading: false, 
                    snackMessage: {
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Changes saved."
                    }
                })
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            }
        )

    }

    _updateMedia = (mediaData, mimeType) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdatePlaceImage(this.state.placeId, mediaData, mimeType,
            (success) => {
                let place_details = this.state.place_details
                place_details.picture_url = success.data.image_url
                this.setState({
                    place_details, 
                    loading: false, 
                    snackMessage: { 
                        level: StatusSnackbarTypes.SUCCESS,
                        message: "Image Updated."
                    }
                })
            },
            (error) => {
                const message = error.response === undefined ? error.message : error.response.data.error
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    // -- END api requests

    render = () => {
        return (
            <div>
                <MerchantListing
                    classes={this.props.classes}
                    state={this.state}
                    onTypeSearch={this._onTypeSearch}
                    onSelectMenu={this._onSelectMenu}
                    onClickCreateMenu={this._onClickCreateMenu}
                    onSelectMenuCategory={this._onSelectMenuCategory}
                    onCloseSnack={this._resetSnackMessage}


                    onUpdatePlaceName={this._onUpdatePlaceName}
                    onUpdatePlaceDescription={this._onUpdatePlaceDescription}
                    onConfirmDeletePlace={this._onConfirmDeletePlace}
                    onClickPlaceMap={this._onClickPlaceMap}
                    onClickUpdatePlaceLocation={this._onClickUpdatePlaceLocation}
                    onTogglePlaceListed={this._onTogglePlaceListed}
                    onSelectMedia={this._updateMedia}


                    onClickRenameMenu={this._onClickRenameMenu}
                    onClickDeleteMenu={this._onClickDeleteMenu}
                    onClickAddCategory={this._onClickAddCategory}
                    onClickEditCategory={this._onClickEditCategory}
                    onClickDeleteCategory={this._onClickDeleteCategory}
                    onClickAddSellable={this._onClickAddSellable}
                    onClickManageSellable={this._onClickManageSellable}
                    onToggleSellableListing={this._onToggleSellableListingStatus}
                    />
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>                    
                {/* <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customstate={{source: "Web Dashboard"}}/>                    */}
            </div>
        )
    }
}

ListingContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    placeId: PropTypes.string.isRequired,
    onDeletePlace: PropTypes.func
}

function mapStateToProps(state) {
    const { credentials, placelist, itemlist, server, placedashboard, airport, account } = state
    return { credentials, placelist, itemlist, server, placedashboard, airport, account }
}

const mapActionCreators = {
    clearItems,
    clearPlaces,
    clearAccount,
    removeCredentials,
    removeEmail,    
    loadItems,
    setSelectedMenuId
}

export default  compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreators)
)(ListingContainer)









