import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class InsightsAPI {
    constructor(host, merchantId, fetchyToken) {
        this.host = host
        this.username = merchantId
        this.password = fetchyToken
    }

    promiseAllTimeSalesSummary = () => {
        return axios({
            url: `${this.host}/v1/merchant/insights/summary`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseAllTimeSalesSummary = () => {
        return this.promiseAllTimeSalesSummary().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseItemSalesData = (start_date, end_date) => {
        return axios({
            url: `${this.host}/v1/merchant/data/items`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                start_date,
                end_date
            }
        })
    }

    tryPromiseItemSalesData = (start_date, end_date) => {
        return this.promiseItemSalesData().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    promiseOrderData = (start_date, end_date, csv) => {
        return axios({
            url: `${this.host}/v1/merchant/data/orders`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                start_date,
                end_date,
                csv
            }
        })
    }

    tryPromiseOrderData = (start_date, end_date, csv) => {
        return this.promiseOrderData(start_date, end_date, csv).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestAllTimeSalesSummary = (onRequestSuccess, onRequestError) => {
        this.promiseAllTimeSalesSummary().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestItemSalesData = (startDate, endDate, onRequestSuccess, onRequestError) => {
        this.promiseItemSalesData(startDate, endDate).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestOrderData = (startDate, endDate, csv, onRequestSuccess, onRequestError) => {
        this.promiseOrderData(startDate, endDate, csv).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

}
