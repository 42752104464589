// a password textfield that allows the user to toggle the visibility of the
// password

import React, { Component } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import { isEmptyString } from "../utils";

class MaskedPasswordField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    _onToggleVisibility = () => this.setState({ visible: !this.state.visible });

    render = () => {
        const { variant, fullWidth } = this.props
        return (
            <TextField
                type={this.state.visible ? "text" : "password"}
                label={this.props.label}
                value={this.props.value}
                onChange={(event) => this.props.onTypePassword(event.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={this._onToggleVisibility}>
                                {this.state.visible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )
                }}
                error={!isEmptyString(this.props.errorText)}
                helperText={this.props.errorText}
                variant={variant}
                fullWidth={fullWidth}/>
        );
    };
}

MaskedPasswordField.propTypes = {
    value: PropTypes.string.isRequired,
    onTypePassword: PropTypes.func.isRequired,
    label: PropTypes.string,
    errorText: PropTypes.string

    // and any other prop used by TextField
}

MaskedPasswordField.defaultProps = {
    label: "Password",
    errorText: ""
}


export default MaskedPasswordField;
