import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"


export class FoxSessionAPI {

    constructor(host) {
        this.host = host
    }

    promiseCreateAccount = (username, password, iata_code) => {
        return axios({
            url: this.host + "/v1/concierge/account",
            method: "POST",
            auth: {
                username,
                password
            },
            params: {
                iata_code
            }
        })
    }

    tryPromiseCreateAcount = (username, password, iata_code) => {
        return this.promiseCreateAccount(username, password, iata_code).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateAccount = (username, password, iata_code, onResponseSuccess, onResponseError) => {
        this.promiseCreateAccount(username, password, iata_code).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseSignIn = (username, password, iata_code) => {
        return axios({
            url: this.host + "/v1/concierge/tempsession",
            method: "GET",
            auth: {
                username,
                password
            },
            params: {
                iata_code
            }
        })
    }

    tryPromiseSignIn = (username, password, iata_code) => {
        return this.promiseSignIn(username, password, iata_code).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSignIn = (username, password, iata_code, onResponseSuccess, onResponseError) => {
        this.promiseSignIn(username, password, iata_code).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseLogout = (fetchyUuid, fetchyToken) => {
        return axios({
            url: `${this.host}/v1/concierge/tempsession`,
            method: "DELETE",
            auth: {
                username: fetchyUuid,
                password: fetchyToken
            }
        })
    }

    tryPromiseLogout = (fetchyUuid, fetchyToken) => {
        return this.promiseLogout(fetchyUuid, fetchyToken).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestLogout = (fetchyUuid, fetchyToken, onResponseSuccess, onResponseError) => {
        this.promiseLogout(fetchyUuid, fetchyToken).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }
}


export class FoxAccountAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promiseIsVerified = () => {
        return axios({
            url: this.host + "/v1/concierge/activated",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
        })
    }

    tryPromiseIsVerified = () => {
        return this.promiseIsVerified().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestIsVerified = (onResponseSuccess, onResponseError) => {
        this.promiseIsVerified().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseUpdateEmail = (email) => {
        return axios({
            url: this.host + "/v1/concierge/profile",
            method: "put",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                email
            }
        })
    }

    tryPromiseUpdateEmail = (email) => {
        return this.promiseUpdateEmail(email).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateEmail = (email, onResponseSuccess, onResponseError) => {
        this.promiseUpdateEmail(email).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseProfile = () => {
        return axios({
            url: this.host + "/v1/concierge/profile",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseProfile = () => {
        return this.promiseProfile().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestProfile = (onResponseSuccess, onResponseError) => {
        this.promiseProfile().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseUpdateProfile = (firstName, lastName, birthdate, email, country, metadata) => {
        return axios({
            url: this.host + "/v1/concierge/profile",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                first_name: firstName,
                last_name: lastName,
                birthdate,
                email,
                resident_country: country,
                metadata // an object containing any additional fields
            }
        })
    }

    tryPromiseUpdateProfile = (firstName, lastName, birthdate, email, country, metadata) => {
        return this.promiseUpdateEmail(firstName, lastName, birthdate, email, country, metadata).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateProfile = (firstName, lastName, birthdate, email, country,
        metadata, onResponseSuccess, onResponseError) => {
        this.promiseUpdateProfile(firstName, lastName, birthdate, email, country, metadata).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseUpdateProfilePicture = (imgData, mimeType) => {
        return axios({
            url: this.host + "/v1/concierge/profileimage",
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": mimeType
            },
            data: imgData
        })
    }

    tryPromiseUpdateProfilePicture = (imgData, mimeType) => {
        return this.promiseUpdateProfilePicture(imgData, mimeType).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateProfilePicture = (imgData, mimeType, onResponseSuccess, onResponseError) => {
        this.promiseUpdateProfilePicture(imgData, mimeType).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

}
