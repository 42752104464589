import uuid from "uuid4"
import PropTypes from "prop-types"

import React, { Component } from "react"
import { connect } from "react-redux"

import {
    Grid,
    Typography,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Divider,
    Tooltip,
    Select,
    MenuItem
} from "@material-ui/core"

import EditIcon from "@material-ui/icons/Edit"

import LoadingDialog from "../../common/components/LoadingDialog"
import Spinner from "../../common/components/Spinner"
import ImageCropPreview from "../../common/components/ImageCropPreview"
import { StatusSnackbar, StatusSnackbarTypes } from "../../common/components/StatusSnackbar"
import { DelayedActionButton, DelayedCircularButton } from "../../common/components/DelayedDelete"

import { ConstantsAPI } from "../../common/requests/merchant/constants"
import { ItemAPI } from "../../common/requests/merchant/item"
import { SellableAPI } from "../../common/requests/merchant/sellable"

import { isEmptyString, toTitleCase } from "../../common/utils"

import ProductOptions from "./ProductOptions"


const ConfirmUpdateDialog = (props) => {

    return (
        <Dialog open>
            <DialogContent>
                <Typography variant="body1">The following purchasable listings will be impacted by this library update:</Typography>
                <div style={{height: 10}}/>
                {
                    props.sellables.map((sellable, idx) => {
                        return <Typography variant="body2" key={idx}>{`- ${sellable.name}`}</Typography>
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClickCancel}>Cancel</Button>
                <Tooltip title="Changes will only be applied to this item in the product library" placement="top">
                    <Button onClick={props.onClickUpdateLibrary}>Update Library</Button>
                </Tooltip>
                <Tooltip title="Changes will be applied to the product library and the shown purchasable listings" placement="top">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={props.onClickUpdateAll}>Update All</Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    )
}

ConfirmUpdateDialog.propTypes = {
    sellables: PropTypes.array.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickUpdateLibrary: PropTypes.func.isRequired,
    onClickUpdateAll: PropTypes.func.isRequired
}



class ProductDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productTypes: [],
            dietaryTags: [],

            name: "",
            description: "",
            selectedProductType: "",
            optionGroups: [],
            referencingSellables: [], 
            mediaData: null,  // image binary
            mimeType: null, 
            createSellable: false,

            mediaUrl: null,
            selectedDietaryTags: new Set(),
            selectedOptionGroupIdx: -1,
            
            dialogComponent: null,

            // error and status messages
            nameErrorText: "",
            descriptionErrorText: "",
            mediaErrorText: "",
            productTypeErrorText: "",
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },

            loading: false,
            loadingConstants: false,
        }
    }

    componentWillMount = () => {
        this._getConstants()
        if(this.props.variant === "update") {
            this._getProductDetails(this.props.itemId)
        }
    }

    componentDidUpdate = (prevProps) => {}

    componentDidMount = () => {}


    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }            
        })
    }

    _resetErrorMessages = () => {
        this.setState({
            nameErrorText: "",
            descriptionErrorText: "",
            mediaErrorText: "",   
            productTypeErrorText: ""       
        })
    }


    /** network requests */

    _getConstants = () => {
        this.setState({loadingConstants: true})
        const api = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        Promise.all([api.promiseItemTypes(), api.promiseDietaryTypes()])
            .then(([itemTypesResponse, dietaryTagsResponse]) => {
                this.setState({
                    loadingConstants: false,
                    productTypes: itemTypesResponse.data.types,
                    dietaryTags: dietaryTagsResponse.data.types
                })
            })
            .catch((error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loadingConstants: false, snackMessage}) 
            })
    }

    _getProductDetails = (itemId) => {
        this.setState({loading: true})
        const itemAPI = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const sellableAPI = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        Promise.all([itemAPI.promiseItemDetails(itemId), sellableAPI.promiseGetImpacted(itemId)])
            .then(([itemDetailsResponse, itemImpactResponse]) => {
                this.setState({
                    loading: false,
                    name: itemDetailsResponse.data.name,
                    description: itemDetailsResponse.data.description,
                    selectedProductType: itemDetailsResponse.data.type,
                    optionGroups: itemDetailsResponse.data.options,
                    mediaUrl: itemDetailsResponse.data.media_url,
                    selectedDietaryTags: new Set(itemDetailsResponse.data.dietary_tags),                    
                    referencingSellables: itemImpactResponse.data
                })
            })
            .catch((error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})   
            })
    }

    _getProductImpact = (itemId, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestGetImpacted(itemId, 
            (success) => {
                this.setState({loading: false}, callback(success.data))
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({
                    loading: false,
                    snackMessage: {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                })
            })
    }

    /** END network requests */

    _onChangeName = name => this.setState({name})
    _onChangeDescription = description => this.setState({description})

    _onClickEditGroup = optionGroupIdx => {
        const { optionGroups } = this.state
        const data = optionGroups.filter((group, idx) => idx === optionGroupIdx)[0]
        this.setState({
            selectedOptionGroupIdx: optionGroupIdx,
            dialogComponent: <ProductOptions 
                                key={uuid()}
                                variant="update"
                                data={data}
                                onCancelGroupCallback={this._onCancelGroupListener}
                                onAddGroupCallback={this._onAddGroupListener}/>            
        })
    }

    _onClickDeleteGroup = optionGroupIdx => {
        // TODO confirm box
        let { optionGroups } = this.state
        optionGroups = optionGroups.filter((group, idx) => idx !== optionGroupIdx)
        this.setState({optionGroups})
    }

    _onClickAddGroup = () => {
        this.setState({
            dialogComponent: <ProductOptions 
                                key={uuid()}
                                variant="create"
                                onCancelGroupCallback={this._onCancelGroupListener}
                                onAddGroupCallback={this._onAddGroupListener}/>
        })
    }

    _onClickDelete = () => {
        this._getProductImpact(this.props.itemId, (sellables) => {
            if(sellables.length > 0) {
                this.setState({
                    dialogComponent: <ConfirmUpdateDialog
                                        onClickCancel={() => {this.setState({dialogComponent: null})}}
                                        onClickUpdateLibrary={() => this.props.onDeleteProductCallback(
                                                                        this.props.itemId, 
                                                                        false)}
                                        onClickUpdateAll={() => this.props.onDeleteProductCallback(
                                                                        this.props.itemId, 
                                                                        true)}
                                        sellables={sellables}/>
                })
            }
            else {
                this.props.onDeleteProductCallback(this.props.itemId, false)                
            }
        })        
    }

    _onChangeCreateSellable = () => {
        this.setState(prevState => ({
            createSellable: !prevState.createSellable
        }))
    }

    _onClickSubmit = () => {
        this._resetErrorMessages()
        // network call to create item along with all options.
        if(!this.state.mediaData && !this.state.mediaUrl && !this.state.mimeType)
            this.setState({mediaErrorText: "Required"})
        else if(isEmptyString(this.state.name))
            this.setState({nameErrorText: "Required"})
        else if(isEmptyString(this.state.description))
            this.setState({descriptionErrorText: "Required"})
        else if(isEmptyString(this.state.selectedProductType))
            this.setState({productTypeErrorText: "Required"})
        else {
            let payload = {
                item_id: "",
                place_id: this.props.placeId,
                name: this.state.name,
                description: this.state.description,
                type: this.state.selectedProductType,
                dietary_tags: [...this.state.selectedDietaryTags],
                options: this.state.optionGroups
            }
            
            if(this.props.variant === "create") {
                this.props.onSubmitProductCallback(payload, this.state.mediaData, this.state.mimeType, 
                    this.state.createSellable)
            }
            else {
                payload.item_id = this.props.itemId
                this._getProductImpact(this.props.itemId, (sellables) => {
                    if(sellables.length > 0) {
                        this.setState({
                            dialogComponent: <ConfirmUpdateDialog
                            onClickCancel={() => {this.setState({dialogComponent: null})}}
                            onClickUpdateLibrary={() => this.props.onSubmitProductCallback(
                                                            payload, 
                                                            this.state.mediaData, 
                                                            this.state.mimeType, 
                                                            false)}
                            onClickUpdateAll={() => this.props.onSubmitProductCallback(
                                                            payload, 
                                                            this.state.mediaData, 
                                                            this.state.mimeType, 
                                                            true)}
                            sellables={sellables}/>
                        })
                    }
                    else {
                        this.props.onSubmitProductCallback(payload, this.state.mediaData, this.state.mimeType, true)
                    }
                })
            }
        }
    }

    _onSelectProductTypeListener = selectedProductType => this.setState({selectedProductType})

    _onCheckDietaryTagsListener = (value, checked) => {

        if(checked) {
            this.setState(prevState => ({selectedDietaryTags: prevState.selectedDietaryTags.add(value)}))
        }
        else {
            this.setState(prevState => {
                prevState.selectedDietaryTags.delete(value)
                return {selectedDietaryTags: prevState.selectedDietaryTags}
            })
        }
    }

    _onSelectMediaListener = (mediaData, mimeType) => this.setState({mediaData, mimeType})

    _onCancelGroupListener = () => this.setState({dialogComponent: null, selectedOptionGroupIdx: -1})

    _onAddGroupListener = (payload) => {
        let { optionGroups } = this.state
        if(isEmptyString(payload.option_group_id)) {
            
            if(this.state.selectedOptionGroupIdx === -1) {
                // this is a brand new option group
                optionGroups.push(payload)
            }
            else {
                // this is an unsaved option group thats been modified again    
                optionGroups[this.state.selectedOptionGroupIdx] = payload
            }
        }
        else {
            // this is an existing option group, overwrite data with new data
            optionGroups = optionGroups.map(group => {
                if(group.option_group_id === payload.option_group_id)
                    return payload
                else
                    return group
            })
        }
        this.setState({ optionGroups, dialogComponent: null, selectedOptionGroupIdx: -1 })
    }

    _renderDeleteButton = () => {
        if(this.props.variant === "update") {
            return (
                <Grid item xs={12}>
                    <Card style={{display: "flex", boxShadow: "none"}}>
                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                            <Typography variant="body1">Delete</Typography>
                            <Typography variant="caption">This action is immediate, irreversible, and will delete all customizations groups.</Typography>
                        </CardContent>
                        <CardActions style={{paddingRight: "0px"}}>
                            <DelayedActionButton
                                showConfirmDialog={false}
                                onConfirmAction={ this._onClickDelete }
                                onCancelAction={() => {}}/>
                        </CardActions>
                    </Card>
                </Grid>
            )
        }
        else
            return <div/>
    }

    _renderConstants = () => {
        if(this.state.loadingConstants) {
            return <Spinner label=""/>
        }
        else {

            const productTypes = Object.keys(this.state.productTypes).map((key) => {
                return {
                    value: key,
                    label: this.state.productTypes[key].nicename,
                    description: this.state.productTypes[key].description
                }
            })

            // const tags_ = Object.keys(this.state.dietaryTags).map((key) => {
            //     return {
            //         value: key,
            //         label: this.state.dietaryTags[key].nicename,
            //         description: this.state.dietaryTags[key].description
            //     }
            // })

            return (
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Product Type</Typography>
                        <Typography variant="caption">This ensures proper handling of products.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <FormControl
                            style={{width: "200px"}}
                            error={!isEmptyString(this.state.productTypeErrorText)}
                            fullWidth>
                                <Select
                                    value={this.state.selectedProductType}
                                    onChange={event => this._onSelectProductTypeListener(event.target.value)}>
                                {
                                    productTypes.map((type_, typeIdx) => (
                                        <MenuItem key={typeIdx} value={type_.value}>{type_.label}</MenuItem>
                                    ))
                                }
                                </Select>
                            <FormHelperText>{this.state.productTypeErrorText}</FormHelperText>
                        </FormControl>
                    </CardActions>
                </Card>
            )

            // return (
            //     <Grid container>
            //         <Grid item xs={12} md={6}>
            //             <DescriptiveRadioGroup
            //                 selectedValue={this.state.selectedProductType}
            //                 onChangeCallback={this._onSelectProductTypeListener}
            //                 options={types_}
            //                 description="What best describes this product?"
            //                 errorText={this.state.productTypeErrorText}/>
            //         </Grid>
            //         <Grid item xs={12} md={6}>{
            //             ["FOOD_SHELF_STABLE", "FOOD_REFRIDGERATED", "FOOD_HOT"].includes(this.state.selectedProductType) ?
            //                 <DescriptiveCheckboxGroup 
            //                     checkedValues={this.state.selectedDietaryTags}
            //                     onCheckCallback={this._onCheckDietaryTagsListener}
            //                     options={tags_}
            //                     description="What best describes the food?"/> :
            //                 <div />
            //         }</Grid>
            //     </Grid>
            // )
        }
    }

    _renderDialogComponent = () => {
        if(this.state.dialogComponent !== null)
            return this.state.dialogComponent
        else
            return <div/>
    }

    _renderCustomizationGroups = () => {

        if(this.state.optionGroups.length === 0 ) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{height: "10px"}}/>
                        <Typography variant="body1">This product has no customizations.</Typography>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container>{
                    this.state.optionGroups.map((group, idx) => {
                        const required = group.required ? "Required" : "Optional"
                        const uitype = toTitleCase(group.uitype)
                        const isNew = isEmptyString(group.option_group_id) ? "Unsaved" : ""
                        let choices = "1 Choice"
                        if(group.choices.length !== 1)
                            choices = `${group.choices.length} Choices`

                        return (
                            <Grid item xs={12} key={idx}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Grid container justify="flex-start" alignItems="center">
                                            <Grid item>
                                                <Typography variant="caption" style={{color: "#FF832D"}}>{` ${isNew}`}</Typography>
                                                <Typography variant="body1">{group.name}</Typography>
                                                <Typography variant="button">{`${uitype}, ${required}`}</Typography>
                                                <Typography variant="body2">{choices}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-end" alignItems="center">
                                            <Grid item>
                                                <IconButton onClick={() => this._onClickEditGroup(idx)}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <DelayedCircularButton onConfirmAction={() => this._onClickDeleteGroup(idx)}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })

                }</Grid>
            )
        }
    }

    _renderFooter = () => {
        if(this.props.variant === "create") {
            return (
                <Grid container>
                    <Divider />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.createSellable}
                                onChange={this._onChangeCreateSellable}
                                value="foo"
                                color="primary"/>
                        }
                        label="Sold Individually"/>                    
                </Grid>
            )
        }
        else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Divider />
                        <div style={{height: 10}}/>
                        {
                            this.state.referencingSellables.length === 0 ?
                                <Typography variant="body1">This product is not being sold. Go to "Manage Listings" to change this.</Typography> :
                                <div>
                                    <Typography variant="h6">Listing References</Typography>
                                    <Typography variant="body2">These are the listings that contain this product/service.</Typography>
                                    <div style={{height: 10}}/>
                                    {
                                        this.state.referencingSellables.map((e, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <Typography variant="body2"  component="span">{`- ${e.menu_name} ➔ ${e.category_name} ➔ `}</Typography>
                                                    <Typography variant="body1" component="span" color="primary">{`${e.name}`}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </Grid>
                </Grid>
            )

        }
    }

    render = () => {
        const title = this.props.variant === "create" ? "Create" : "Update"
        return (
            <Dialog
                open
                maxWidth="md"
                fullWidth
                fullScreen={false} // TODO
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>{`${title} Product`}</DialogTitle>
                <DialogContent>   
                    <Grid container justify="center">
                        <Typography variant="body1">A product or service (an "item"). This item is added to your
                            library and can be used and re-used to create deals, combos, or just sold individually.</Typography>
                        { this._renderDeleteButton() }
                        <ImageCropPreview 
                            pictureUrl={this.state.mediaUrl}
                            onSelectImage={this._onSelectMediaListener} 
                            errorText={this.state.mediaErrorText}/>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                value={this.state.name}
                                placeholder="e.g. Wireless Headphones, Croissant, Beef and Broccoli"
                                onChange={(event) => this._onChangeName(event.target.value)}
                                error={!isEmptyString(this.state.nameErrorText)}
                                helperText={this.state.nameErrorText}
                                fullWidth/>
                        </Grid>                     
                        <Grid item xs={12} style={{marginTop: "10px"}}>
                            <TextField
                                multiline
                                rows={2}
                                rowsMax={2}
                                label="Brief Description"
                                value={this.state.description}
                                placeholder="A revolutionary smart widget!"
                                onChange={(event) => this._onChangeDescription(event.target.value)}
                                error={!isEmptyString(this.state.descriptionErrorText)}
                                helperText={this.state.descriptionErrorText}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            { this._renderConstants() }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Customization Groups</Typography>
                            <Button
                                onClick={this._onClickAddGroup}
                                variant="contained" 
                                size="small" 
                                color="primary">Add Group</Button>
                            { this._renderCustomizationGroups() }
                        </Grid>
                        <Grid item xs={12}>
                            { this._renderFooter() }
                        </Grid>                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancelProductCallback}>Cancel</Button>
                    <Button color="primary" onClick={this._onClickSubmit}>Save Product</Button>
                </DialogActions>
                { this._renderDialogComponent() }
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>    
                <LoadingDialog show={this.state.loading} message="Loading..."/>            
            </Dialog>            
        )
    }
}

ProductDialog.propTypes = {
    placeId: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    onDeleteProductCallback: PropTypes.func.isRequired,
    onCancelProductCallback: PropTypes.func.isRequired, 
    onSubmitProductCallback: PropTypes.func.isRequired, 
    itemId: PropTypes.string // used if the update variant of this component is used
}

ProductDialog.defaultProps = {
    onDeleteProductCallback: (itemId, cascade) => console.log(`default onDeleteProductCallback ${itemId}`),
    onCancelProductCallback: () => {console.log(`default onCancelProductCallback`)},
    onSubmitProductCallback: (payload, mediaData, mimeType, cascade) => {console.log(`default: ${payload}`)},
}

function mapStateToProps(state) {
    const { credentials, server, airport } = state
    return { credentials, server, airport }
}

export default connect(mapStateToProps, null)(ProductDialog)
