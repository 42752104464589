import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Switch from "@material-ui/core/Switch"

import { MenuAPI } from "../../common/requests/merchant/menu"
import LoadingDialog from "../../common/components/LoadingDialog"
import { isEmptyString } from "../../common/utils"


class MenuModal extends Component {
    // component for creating and updating the name and listing status of a menu

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            listed: true,
            open: true,
            loading: false,

            nameError: null,
            generalError: null
        }
    }

    componentWillMount = () => {
        this.clearErrors()
    }

    componentDidMount = () => {
        this.setState({
            name: this.props.name,
            listed: this.props.listed
        })
    }

    clearErrors = () => {
        this.setState({
            nameError: null,
            generalError: null
        })
    }

    // --- event listeners
    onChangeName = (name) => { this.setState({ name }) }
    onCloseSnackbar = () => { this.setState({generalError: null})}
    onToggleListed = (event, checked) => this.setState({listed: checked})

    onClickSubmit = () => {
        this.clearErrors()
        if (isEmptyString(this.state.name)) {
            this.setState({nameError: "Required"})
        }
        else {
            if (this.props.variant === "create")
                this.createPlaceMenu()
            else if(this.props.variant === "update")
                this.updatePlaceMenu()
        }
    }
    // --- end

    // -- network requests

    createPlaceMenu = () => {
        this.setState({loading: true})
        const api = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestCreateMenu(this.props.placeId, this.state.name, this.state.listed,
            (success) => {
                this.setState({loading: false, open: false})
                this.props.onCloseUpdateCallback()
            },
            (error) => {
                this.setState({loading: false, snackMessage: error.message})
            })
    }

    updatePlaceMenu = () => {
        this.setState({loading: true})
        const api = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdateMenu(this.props.menuId, this.state.name, this.state.listed,
            (success) => {
                this.setState({loading: false, open: false})
                this.props.onCloseUpdateCallback()
            },
            (error) => {
                this.setState({loading: false, snackMessage: error.message})
            })
    }

    // -- end network requests

    render = () => {
        if(this.state.loading){
            return <LoadingDialog message={"Getting coffee..."} show={this.state.loading}/>
        }

        const title = this.props.variant.charAt(0).toUpperCase() + this.props.variant.slice(1)

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.props.onCloseCallback}
                    disableBackdropClick
                    fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle>{title + " Menu"}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            A menu is the highest level of organization for similar items.
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop: "10px"}}>
                                <TextField
                                    label="Menu Name"
                                    value={this.state.name}
                                    placeholder="e.g. Breakfast, Paperback, Women's..."
                                    onChange={(event) => this.onChangeName(event.target.value)}
                                    error={this.state.nameError !== null}
                                    helperText={this.state.nameError}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} style={{marginTop: "10px"}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.state.listed}
                                            onChange={this.onToggleListed}/>
                                    }
                                    label="Listed" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.onCloseCallback() }>Cancel</Button>
                        <Button onClick={() => this.onClickSubmit()} color="primary">{this.props.variant}</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.generalError !== null}
                    onClose={this.onCloseSnackbar}
                    autoHideDuration={5000}
                    message={this.state.generalError}/>
            </div>
        )
    }
}

MenuModal.defaultProps = {
    placeId: "plc_default",
    menuId: "mnu_default",
    name: "",
    listed: true,
    onCloseCallback: () => { console.log("default onCloseCallback") },
    onCloseUpdateCallback: () => { console.log("default onCloseUpdateCallback") }
}

MenuModal.propTypes = {
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    placeId: PropTypes.string,      // needed when creating
    menuId: PropTypes.string,       // needed when updating
    name: PropTypes.string,         // needed when updating
    listed: PropTypes.bool,         // needed when updating
    onCloseCallback: PropTypes.func,
    onCloseUpdateCallback: PropTypes.func
}

function mapStateToProps(state) {
    const { credentials, server } = state
    return { credentials, server }
}

export default connect(mapStateToProps, null)(MenuModal)
