import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class ListingsAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promiseUploadMedia = (sellable_id, media_data, mime_type) => {
        return axios({
            url: `${this.host}/v1/airport/sellable/media`,
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": mime_type
            },            
            params: {
                sellable_id
            },
            data: media_data
        })           
    }

    tryPromiseUploadMedia = (sellableId, mediaData, mediaType) => {
        return this.promiseUploadMedia(sellableId, mediaData, mediaType).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUploadMedia = (sellableId, mediaData, mediaType, onRequestSuccess, onRequestError) => {}

    promiseDeleteMedia = (sellable_id, media_url) => {
        return axios({
            url: `${this.host}/v1/airport/sellable/media`,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },              
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            },
            data: {
                media_url
            }
        })           
    }

    tryPromiseDeleteMedia = (sellableId, mediaUrl) => {
        return this.promiseDeleteMedia(sellableId, mediaUrl).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteMedia = (sellableId, mediaUrl, onRequestSuccess, onRequestError) => {
        this.promiseDeleteMedia(sellableId, mediaUrl).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseSetDefaultMedia = (sellable_id, media_url) => {
        return axios({
            url: `${this.host}/v1/airport/sellable/media`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },            
            params: {
                sellable_id
            },
            data: {
                media_url
            }
        })           
    }

    tryPromiseSetDefaultMedia = (sellableId, mediaUrl) => {
        return this.promiseSetDefaultMedia(sellableId, mediaUrl).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSetDefaultMedia = (sellableId, mediaUrl, onRequestSuccess, onRequestError) => {
        this.promiseSetDefaultMedia(sellableId, mediaUrl).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetImpacted = (fetchy_uuid) => {
        return axios({
            url: `${this.host}/v1/airport/sellable/impact`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fetchy_uuid
            }
        })          
    }

    tryPromiseGetImpacted = (fetchyUuid) => {
        return this.promiseGetImpacted(fetchyUuid).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetImpacted = (fetchyUuid, onRequestSuccess, onRequestError) => {
        this.promiseGetImpacted(fetchyUuid).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateSellable = (sellable_id, name, description, base_unit_price, listed, contents) => {
        return axios({
            url: `${this.host}/v1/airport/sellable`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },            
            params: {
                sellable_id
            },
            data: {
                name,
                description,
                base_unit_price,
                listed,
                contents
            }
        })          
    }

    tryPromiseUpdateSellable = (sellableId, name, description, baseUnitPrice, listed, contents) => {
        return this.promiseUpdateSellable(sellableId, name, description, baseUnitPrice, listed, contents).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateSellable = (sellableId, name, description, baseUnitPrice, listed, contents, onRequestSuccess, onRequestError) => {
        this.promiseUpdateSellable(sellableId, name, description, baseUnitPrice, listed, contents).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestUpdateSellableListingStatus = (sellableId, listed, onRequestSuccess, onRequestError) => {
        this.promiseUpdateSellable(sellableId, null, null, null, listed, null).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetSellableDetails = (sellable_id) => {
        return axios({
            url: `${this.host}/v1/airport/sellable`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            }
        })          
    }

    tryPromiseGetSellableDetails = (sellableId) => {
        return this.promiseGetSellableDetails(sellableId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetSellableDetails = (sellableId, onRequestSuccess, onRequestError) => {
        this.promiseGetSellableDetails(sellableId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetMenus = (place_id) => {
        return axios({
            url: `${this.host}/v1/airport/sellables`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })          
    }

    tryPromiseGetMenus = (placeId) => {
        return this.promiseGetMenus(placeId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetMenus = (placeId, onRequestSuccess, onRequestError) => {
        this.promiseGetMenus(placeId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
