import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Snackbar from "@material-ui/core/Snackbar"
import { withStyles } from "@material-ui/core/styles"


import FetchyFoxLogo from "../assets/logo.png"
import LoadingDialog from "./common/components/LoadingDialog"
import { PasswordResetAPI } from "./common/requests/admin/password"
import { isEmptyString } from "./common/utils"
import { setBackendHost } from "../actions"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }
})

class RequestPasswordReset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            emailErrorText: "",
            loading: false,
            snackMessage: "",
            accountType: this.props.match.params.accountType
        }
    }

    componentDidMount = () => {
        this.props.setBackendHost(process.env.REACT_APP_DEVELOPMENT === "1")
    }

    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onTypeEmail = (email) => this.setState({email})

    _onClickRequest = () => {
        this.setState({emailErrorText: ""})
        if(isEmptyString(this.state.email))
            this.setState({emailErrorText: "Required"})
        else
            this._sendEmail()
    }

    _sendEmail = () => {
        this.setState({loading: true})
        const api = new PasswordResetAPI(this.props.server.host)
        api.requestPasswordResetEmail(this.state.email, this.state.accountType,
            (success) => {
                const snackMessage = `Reset link sent to: ${this.state.email}`
                this.setState({loading: false, snackMessage })
            },
            (error) => {
                const snackMessage = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage })
            }
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div style={{height: "100vh", width: "100vw"}}>
                <AppBar color="default">
                    <Toolbar>
                        <div className={classes.fetchyFoxLogo}/>
                    </Toolbar>
                </AppBar>
                <main>
                    <Grid container justify="center" alignItems="center" style={{marginTop: 150}}>
                        <Grid item xs={10} md={8} lg={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Forgotten Password</Typography>
                                    <Typography variant="body1">Type in the email address you use for your FetchyFox {`${this.state.accountType}`} account and we'll email you a one-time 
                                    password reset link.
                                    </Typography>
                                    <div style={{height: 20}}/>
                                    <TextField
                                        label="Email Address" 
                                        value={this.state.email}
                                        variant="outlined"
                                        error={!isEmptyString(this.state.emailErrorText)}
                                        helperText={this.state.emailErrorText}
                                        fullWidth
                                        onChange={(event) => {this._onTypeEmail(event.target.value)}}/>                                
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => this._onClickRequest()}>Request Reset Link</Button>
                                </Grid>                
                            </Grid>                            
                        </Grid>
                    </Grid>
                    <LoadingDialog show={this.state.loading} message="Sending Email..."/>
                    <Snackbar
                        autoHideDuration={10000}
                        message={this.state.snackMessage}
                        open={!isEmptyString(this.state.snackMessage)}
                        onClose={this._onCloseSnack}
                        action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />                    
                </main>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { server } = state
    return { server }
}

const mapActionCreatorsToProps = {
    setBackendHost
}


export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(RequestPasswordReset)
