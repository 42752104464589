export const SET_BACKEND = "SET_BACKEND"

/* settings specific to an account type */
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE"
export const SET_NAME = "SET_NAME"
export const SET_DRAWER_MENU = "SET_DRAWER_MENU"
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT"

export const SET_CREDENTIALS = "SET_CREDENTIALS"
export const REMOVE_CREDENTIALS = "REMOVE_CREDENTIALS"
export const SET_EMAIL = "SET_EMAIL"
export const REMOVE_EMAIL = "REMOVE_EMAIL"

export const SET_AIRPORT = "SET_AIRPORT"
export const CLEAR_AIRPORT = "CLEAR_AIRPORT"

export const SET_DASHBOARD = "SET_DASHBOARD"

export const LOAD_ITEMS = "LOAD_ITEMS"
export const CLEAR_ITEMS = "CLEAR_ITEMS"

export const ADD_PLACE = "ADD_PLACE"
export const LOAD_PLACES = "LOAD_PLACES"
export const CLEAR_PLACES = "CLEAR_PLACES"




// constants for remembering the state of the place dashboard
export const SET_SELECTED_MENU_ID = "SET_SELECTED_MENU_ID"
