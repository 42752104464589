import { SET_SELECTED_MENU_ID } from "../constants"

export default (state = [], action) => {
    switch(action.type) {
        case SET_SELECTED_MENU_ID:
            const { menuId } = action
            return {
                ...state,
                menuId
            }
        default:
            return state
    }
}