import PropTypes from "prop-types"
import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { MerchantListings } from "./views/Listings"

import { loadPlaces, addPlace, clearPlaces, clearItems, setAirport, setListingsDashboard,
    removeCredentials, removeEmail, clearAccount } from "../../actions"
import { isEmptyString } from "../common/utils"
import { MerchantAccountAPI, MerchantSessionAPI } from "../common/requests/merchant/account"
import { PlaceAPI } from "../common/requests/merchant/place"
import FetchyFoxLogo from "../../assets/logo.png"

import PointOfInterestDialog from "./Poi"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class MerchantListingsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            terminals: [],
            searchText: "",
            selectedTerminalId: "",
            places: [],
            filteredPlaces: [],

            loading: false,

            addListingDialog: null,
            selectedPlaceId: "",

            redirectParams: null,
            drawerItems: props.account.menuItems,
            location: props.location,
            airport: props.airport,
            snackMessage: ""
        }
    }

    componentWillMount = () => {
        this.clearErrors()
    }

    componentDidMount = () => {
        this._getTerminalList()
    }

    clearErrors = () => { this.setState({snackMessage: ""}) }

    /** network requests */
    _getTerminalList = () => {
        this.setState({loading: true})
        const api = new MerchantAccountAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestTerminalList(this.props.airport.airportId,
            (success) => {
                let terminals = success.data
                terminals = terminals.map(terminal => {
                    return {
                        terminalId: terminal.terminal_id,
                        terminalName: terminal.terminal_name
                    }
                })

                const terminalId = this.props.listings.terminalId || terminals[0].terminal_id

                this.setState({ terminals, selectedTerminal: terminalId })
                this._getPlaceList()
                this.props.setListingsDashboard(this.props.airport.airportId, terminalId)

            },
            (error) => {
                this.setState({ snackMessage: error.message, loading: false })
            }
        )
    }

    _getPlaceList = () => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestPlaceList(
            (success) => {
                const places = success.data
                this.setState({ loading: false, places }, () => {
                    this.props.loadPlaces(places)
                    this._updateFilter()
                })
                
            },
            (error) => {
                this.setState({ snackMessage: error.message, loading: false })
            }
        )
    }

    _updatePlaceListedStatus = (placeId, listed) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestUpdatePlaceListingStatus(placeId, listed,
            (success) => {
                let { placelist } = this.props
                placelist = placelist.map((place) => {
                    if (place.place_id === placeId )
                        place.listed = listed
                    return place
                })
                this.props.loadPlaces(placelist)
                this.setState({loading: false, snackMessage: "Changes saved."})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    /** end network requests */

    /** event listeners */

    _onSelectTerminal = (selectedTerminalId) => {
        this.setState({ selectedTerminalId }, this._updateFilter)
        this.props.setListingsDashboard(this.props.airport.airport_id, selectedTerminalId)

    }
    _onClickResetFilter = () => this.setState({searchText: "", selectedTerminalId: ""}, this._updateFilter)
    
    _onClickRefresh = () => this.setState({searchText: "", selectedTerminalId: ""}, this._getPlaceList)

    _onToggleListed = (placeId, listed) => this._updatePlaceListedStatus(placeId, listed)

    _onCloseSnack = () => { this.setState({snackMessage: ""})}

    _onSuccessListingDialog = () => { this.setState({addListingDialog: null}, this._getPlaceList) }

    _onCancelListingDialog = () => this.setState({addListingDialog: null})

    _onClickAddListing = () => this.setState({
        addListingDialog: <PointOfInterestDialog
                            classes={this.props.classes}
                            open={true}
                            onSuccess={this._onSuccessListingDialog}
                            onCancel={this._onCancelListingDialog}/>
    })

    _onTypeSearch = (searchText) => this.setState({searchText}, this._updateFilter)

    _onClickManageListing = (placeId) => this.setState({selectedPlaceId: placeId})

    _onClickDeleteListing = () => this.setState({selectedPlaceId: ""}, this._getPlaceList)

    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        const api = new MerchantSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.clearItems()
                this.props.clearPlaces()
                this.props.clearAccount()
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirectParams: {pathname: "/"}})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }    
    /** END event listeners */


    _updateFilter = () => {
        let filteredPlaces = this.state.places.filter((place) => {
            return isEmptyString(this.state.searchText) || place.name.toLowerCase().includes(this.state.searchText.toLowerCase())
        })

        if(!isEmptyString(this.state.selectedTerminalId)) {
            filteredPlaces = filteredPlaces.filter((place) => {
                return place.terminal_id === this.state.selectedTerminalId
            })
        }
        this.setState({filteredPlaces})
    }

    render = () => {
        const { classes } = this.props
        return (
            <div>
                <MerchantListings
                    classes={classes}
                    state={this.state}
                    
                    onTypeSearch={ this._onTypeSearch }
                    onSelectTerminal={ this._onSelectTerminal }
                    onClickAddListing={ this._onClickAddListing }
                    onClickManageListing={ this._onClickManageListing }
                    onClickDeleteListing={this._onClickDeleteListing}
                    onToggleListed={ this._onToggleListed }
                    onClickRefresh={ this._onClickRefresh }
                    onClickResetFilter={ this._onClickResetFilter }
                
                    onClickDrawerItem={ this._onClickDrawerItem }
                    onClickDrawerLogout={ this._onClickDrawerLogout }
                    onCloseSnack={ this._onCloseSnack }/>
                { 
                    this.state.addListingDialog ? this.state.addListingDialog : <div/>
                }
                {/* <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/> */}
            </div>

        )
    }
}

MerchantListingsContainer.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { credentials, placelist, server, listings, account, airport } = state
    return { credentials, placelist, server, listings, account, airport }
}

const mapActionCreators = {
    loadPlaces,
    addPlace,
    clearPlaces,
    clearItems,
    setAirport,
    setListingsDashboard,
    removeCredentials,
    removeEmail,
    clearAccount
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreators)
)(MerchantListingsContainer);
