
import React from "react"
import PropTypes from "prop-types"

import {
    Grid,
    Typography,
    Divider,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Switch,
    FormControlLabel
} from "@material-ui/core"

import AddIcon from "@material-ui/icons/AddBox"

import { DelayedCircularButton } from "../../common/components/DelayedDelete"

function _renderCouponCards(props, coupons) {

    if(coupons.length === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">No coupons.</Typography>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container>
                {
                    coupons.map((coupon, idx) => (
                        <Grid item xs={12} md={6} lg={4} key={idx}>
                            <Card >
                                <CardHeader
                                    action={
                                        <DelayedCircularButton onConfirmAction={() => props.onClickDeleteCoupon(coupon.id)}/>
                                    }/>
                                <CardContent style={{height: 100}}>
                                    <Typography variant="h4">{coupon.code}</Typography>
                                    <Typography variant="body1">{coupon.description}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="flex-end">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={coupon.active}
                                                    onChange={(event) => props.onChangeActiveStatus(coupon.id, event.target.checked)}/>
                                            }
                                            label={<Typography variant="subtitle1">Active</Typography>}
                                            />
                                        <Button
                                            color="primary"
                                            variant="text"
                                            onClick={() => props.onClickViewDetails(coupon.id)}>Details</Button>
                                    </Grid>                                    
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        )
    }
}

export const CouponLibrary = (props) => {
    const { classes, state } = props
    // const theme = useTheme()
    // const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <div className={classes.spacerTop}/>
            <Grid container>
                <Grid item xs={12} md={10} lg={10}>
                    <Typography variant="body1">Coupon Stats</Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Typography variant="body1">Coupon Filters</Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Grid container justify="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onClickAddCoupon}
                            startIcon={<AddIcon/>}>Add Coupon</Button>
                    </Grid>
                    <div style={{height :10}}/>
                    <Divider/>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Typography variant="h4">Active Coupons</Typography>
                    { _renderCouponCards(props, state.activeCoupons) }
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Typography variant="h4">Inactive Coupons</Typography>
                    { _renderCouponCards(props, state.inactiveCoupons) }
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Typography variant="h4">Expired Coupons</Typography>
                    { _renderCouponCards(props, state.expiredCoupons) }
                </Grid> 
            </Grid>
        </div>
    )
}

CouponLibrary.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickAddCoupon: PropTypes.func.isRequired,
    onClickDeleteCoupon: PropTypes.func.isRequired,
    onClickViewDetails: PropTypes.func.isRequired,
    onChangeActiveStatus: PropTypes.func.isRequired
}

CouponLibrary.defaultProps = {
    onClickAddCoupon: () => {},
    onClickDeleteCoupon: (couponId) => {},
    onClickViewDetails: (couponId) => {},
    onChangeActiveStatus: (couponId, active) => {}
}