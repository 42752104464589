import compose from "recompose/compose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"

import { OrderLookup } from "./views/OrderLookup.js"

import { isEmptyString } from "../common/utils"
import { OrderAPI } from "../common/requests/airport/order"
import { StatusSnackbarTypes, StatusSnackbar } from "../common/components/StatusSnackbar"
import NavDrawer from "../common/components/NavDrawer"
import Spinner from "../common/components/Spinner"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import FetchyFoxLogo from "../../assets/logo.png"
import { firebaseApp } from "../../firebase"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"


const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})



class OrderLookupContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: "",
            orderDetails: null,
            isLoading: false,

            redirectParams: null,
            location: props.location,
        }
    }

    componentWillMount = () => {
        this._clearErrors()
    }

    _clearErrors = () => {
        this.setState({
            orderErrorText: "",
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    _onCloseSnack = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    _onTypeOrderId = (orderId) => {
        this.setState({ orderId })
    }

    _onClickLookup = () => {
        this._clearErrors()
        if(isEmptyString(this.state.orderId)){
            this.setState({orderErrorText: "Order ID Required"})
        }
        else if(!this.state.orderId.startsWith("ord_")) {
            this.setState({ orderErrorText: "Invalid Order ID"})
        }
        else {
            this.setState({isLoading: true})
            const api = new OrderAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestOrderDetails(this.props.airport.airportId, this.state.orderId,
                (response) => {
                    this.setState({ orderDetails: response.data, isLoading: false})
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({isLoading: false, snackMessage})                      
                }
            )
        }
    }

    /** drawer event handlers */
    _onClickDrawerItemHandler = (redirectParams) => this.setState({redirectParams})

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            const snackMessage = {
                level: StatusSnackbarTypes.ERROR,
                message: error
            }
            this.setState({loading: false, snackMessage})
        })
    }
    /** END drawer event handlers */

    renderSearchResults = () => {
        if(this.state.isLoading){
            return (<Spinner label="Fetching order..." visible={true}/>)
        }
        else if(this.state.orderDetails === null) {
            return (
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="body1">Enter an order to lookup.</Typography>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <OrderLookup state={this.state} classes={this.props.classes} />
            )
        }
    }

    renderSearchBar = () => {
        return (
            <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Order ID"
                        placeholder="ord_"
                        error={!isEmptyString(this.state.orderErrorText)}
                        helperText={this.state.orderErrorText}
                        onChange={(event) => this._onTypeOrderId(event.target.value)}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ () => this._onClickLookup() }>Lookup</Button>

                </Grid>
            </Grid>
        )
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        const { classes } = this.props
        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItemHandler}
                    onClickLogoutCallback={this._onClickLogoutHandler}/>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                     
                <main style={{width: "99vw"}}>
                    <div className={classes.spacerTop}/>
                    <Grid container justify="center">
                        <Grid item xs={11} md={10}>
                            { this.renderSearchBar() }
                            <div style={{height: "40px"}}/>
                            { this.renderSearchResults() }
                        </Grid>
                        <StatusSnackbar
                            open={!isEmptyString(this.state.snackMessage.message)}
                            variant={this.state.snackMessage.level}
                            message={this.state.snackMessage.message}
                            anchorOrigin={{vertical: "top", horizontal: "center"}}
                            onClose={this._onCloseSnack}/>                        
                    </Grid>
                </main>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account } = state
    return { server, credentials, airport, account }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(OrderLookupContainer)
