import React, {Component} from "react"
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid"

import CanvasJSReact from "../common/canvasjs.react"
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const salesColor = "#431cc7"
const ordersColor = "#30aaa8"

class DailySalesGraph extends Component {
    render = () => {
        let titleText = this.props.hourly ? "Hourly Performance" : "Daily Performance"
        let axisXTitle = this.props.hourly ? "Hour" : "Date"
        let intervalType = this.props.hourly ? "hour" : "day"

        const graphOptions = {
            animationEnabled: true,
            exportEnabled: true,
            zoomEnabled: true,
            theme: "light2",
            title: { text: titleText },
            axisX: { title: axisXTitle, intervalType },
            axisY: [
                {
                    lineColor: ordersColor,
                    titleFontColor: ordersColor,
                    labelFontColor: ordersColor
                }
            ],
            axisY2: [
                {   
                    prefix: "£",
                    lineColor: salesColor,
                    titleFontColor: salesColor,
                    labelFontColor: salesColor,
                    maximum: 1.25 * Math.max.apply(Math, this.props.salesData.map(function(o) { return o.y; })), 
                },
            ],
            data: [
                {
                    type: "column",
                    name: "Sales",
                    showInLegend: true,
                    axisYType: "secondary",
                    yValueFormatString: "£######.##",
                    dataPoints: this.props.salesData,
                    color: salesColor,
                    
                },                
                {
                    type: "line",
                    name: "Orders",
                    showInLegend: true,
                    dataPoints: this.props.ordersData,
                    color: ordersColor
                },
             
            ]
        }
        return (
            <Grid item xs={12}>
                <CanvasJSChart options={graphOptions} style={{width: "100%"}}/>
            </Grid>
        )
    }
}

 DailySalesGraph.propTypes = {
    salesData: PropTypes.array.isRequired,
    ordersData: PropTypes.array.isRequired,
    hourly: PropTypes.bool.isRequired
}

DailySalesGraph.defaultProps = {
    ordersData: [
        {label: 1, y: Math.random() * 2000},
        {label: 2, y: Math.random() * 2000},
        {label: 3, y: Math.random() * 2000},
        {label: 4, y: Math.random() * 2000},
        {label: 5, y: Math.random() * 2000},
    ],
    salesData: [
        {label: 1, y: Math.random() * 2000},
        {label: 2, y: Math.random() * 2000},
        {label: 3, y: Math.random() * 2000},
        {label: 4, y: Math.random() * 2000},
        {label: 5, y: Math.random() * 2000},
    ],
    hourly: false
}

export default DailySalesGraph
