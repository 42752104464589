import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class PlaceAPI {
    constructor(host, username, password){
        this.host = host
        this.username = username
        this.password = password
    }

    promiseAllPlaces = (airport_id) => {
        return axios({
            url: `${this.host}/v1/airport/places`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id
            }
        })
    }

    tryPromiseAllPlaces = (airport_id) => {
        return this.promiseAllPlaces(airport_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestAllPlaces = (airport_id, onRequestSuccess, onRequestError) => {
        // return a list of unfulfilled orders
        this.promiseAllPlaces(airport_id).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateListingStatus = (airport_id, place_id, authorizer, listed, reason) => {
        return axios({
            url: `${this.host}/v1/airport/place`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id
            },
            data: {
                place_id,
                authorizer,
                listed,
                reason
            }
        })
    }

    tryPromiseUpdateListingStatus = (airport_id, place_id, authorizer, listed, reason) => {
        return this.promiseUpdateListingStatus(airport_id, place_id, authorizer, listed, reason).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateListingStatus = (airport_id, place_id, authorizer, listed, reason, onRequestSuccess,
        onRequestError) => {
        // update the listing status of
        this.promiseUpdateListingStatus(airport_id, place_id, authorizer, listed, reason).then(response => {
            onRequestSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}
