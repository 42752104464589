import React from "react"
import PropTypes from "prop-types"
import ReactMapboxGl, { ZoomControl, Layer, Feature } from "react-mapbox-gl"

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    TextField,
    Select,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Button,
    Card,
    CardContent,
    CardActions,
    Input,
    Checkbox,
    ListItemText
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import ImageSelectPreview from "../../common/components/ImageCropPreview"

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_KEY,
    dragRotate: false,
    dragPan: true,
    scrollZoom: false,
    doubleClickZoom: false,
    logoPosition: "top-left"
});

function _renderMap(props) {
    const { state } = props

    return (
        <Grid container justify="center" style={{height: "inherit"}}>
            <Grid item xs={12} style={{height: "inherit"}}>
                <Typography variant="h6">Pin your shop on the map</Typography>
                <Typography variant="body1">Accuracy will ensure proper pickup of your items.</Typography>
                <Typography variant="body1" style={{"color": "red"}}>{ state.mapError }</Typography>
                <Map
                    style={`mapbox://styles/mapbox/streets-v9`}
                    center={[state.mapLng, state.mapLat]}
                    zoom={[state.mapZoom]}
                    movingMethod="jumpTo"
                    onClick={ props.onClickMap }
                    onMoveEnd={ props.onMoveEndMap }
                    onZoomEnd={ props.onZoomEndMap }
                    containerStyle={{height: "100%"}}>
                        <ZoomControl/>{
                            state.symbolLat ?
                                <Layer
                                    type="symbol"
                                    id="shop-location"
                                    source="mapbox-streets"
                                    layout={{
                                        "icon-image": "star-15",
                                        "icon-size": 1,
                                        "text-field": state.name,
                                        "text-size": 12,
                                        "text-anchor": "top",
                                        "icon-anchor": "bottom"
                                    }}>
                                    <Feature coordinates={[state.symbolLng, state.symbolLat]}/>
                                </Layer> : <div/>
                        }
                </Map>
            </Grid>
        </Grid>
    )

}

function _renderDietaryTypes(props) {
    const { state } = props

    let dietaryOptions = [] 
    if (state.dietaryTypes) {
        dietaryOptions = Object.keys(state.dietaryTypes).map(key => {
            return {
                value: key,
                label: state.dietaryTypes[key].nicename,
                description: state.dietaryTypes[key].description
            }
        })
    }

    return (
        <Card style={{display: "flex", boxShadow: "none"}}>
            <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                <Typography variant="body1">Dietary Options</Typography>
                <Typography variant="caption">Select all dietary options this listing offers.</Typography>
            </CardContent>
            <CardActions style={{paddingRight: "0px"}}>
                <FormControl style={{width: "200px"}}
                    disabled={state.businessType === null || !(["COOKED_TO_ORDER", "FAST_CASUAL", "FAST_FOOD", "READY_TO_EAT"].includes(state.businessType))}>
                    <Select
                        multiple
                        value={state.dietaryTags}
                        renderValue={() => {return `${state.dietaryTags.length} selected`}}
                        onChange={event => props.onSelectDietaryType(event.target.value)}
                        input={<Input/>}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 500
                                }
                            }
                        }}>                            
                    {   
                        dietaryOptions.map((option, optionIdx) => (
                            <MenuItem key={optionIdx} value={option.value}>
                                <Checkbox checked={state.dietaryTags.indexOf(option.value) > -1} color="primary"/>
                                <ListItemText primary={option.label}/>
                            </MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
            </CardActions>
        </Card>
    )
}

export const PointOfInterest = (props) => {
    const { state } = props
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const businessOptions = Object.keys(state.place_types).map((key, index) => {
        return {
            value: key,
            label: state.place_types[key].nicename,
            description: state.place_types[key].description
        }
    })

    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            fullScreen={isMobileScreen}>
            <DialogTitle>
                <Typography variant="h6">Create Listing</Typography>
                <Typography variant="body1">Your virtual presense for selling goods or providing a service.</Typography>                        
            </DialogTitle>
            <DialogContent>        
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            placeholder="The Puncake Shop"
                            onChange={(event) => props.onChangeName(event.target.value)}
                            error={state.nameError !== null}
                            helperText={state.nameError}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Short Description"
                            placeholder="#1 Stop for Puns and Pancakes."
                            multiline
                            rows={2}
                            rowsMax={2}
                            onChange={(event) => props.onChangeDescription(event.target.value)}
                            error={state.descriptionError !== null}
                            helperText={state.descriptionError}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={state.airportIdError !== null} fullWidth>
                            <InputLabel>Airport</InputLabel>
                            <Select
                                value={state.iata_code}>
                                <MenuItem value={state.iata_code}>{state.iata_code}</MenuItem>
                            </Select>
                            <FormHelperText>{state.airportIdError}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={state.terminalIdError !== null} fullWidth>
                            <InputLabel>Terminal</InputLabel>
                            <Select
                                value={state.terminal_id}
                                onChange={(event) => props.onSelectTerminal(event.target.value)}>{
                                Object.keys(state.airport_layout).map((key, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={state.airport_layout[key].id}
                                        >{state.airport_layout[key].name}</MenuItem>
                                    )
                                })
                            }</Select>
                            <FormHelperText>{state.terminalIdError}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <div style={{height: "20px"}}/>
                    <Grid item xs={12}>
                        <Card style={{display: "flex", boxShadow: "none"}}>
                            <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                <Typography variant="body1">Primary Product Category</Typography>
                                <Typography variant="caption">This gives customers a general idea of what this listing offers.</Typography>
                            </CardContent>
                            <CardActions style={{paddingRight: "0px"}}>
                                <FormControl
                                    style={{width: "200px"}}
                                    error={state.businessTypeError !== null}
                                    fullWidth>
                                        <Select
                                            value={state.businessType}
                                            onChange={event => props.onSelectBusinessType(event.target.value)}>
                                        {
                                            businessOptions.map((option, optionIdx) => (
                                                <MenuItem key={optionIdx} value={option.value}>{option.label}</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    <FormHelperText>{state.businessTypeError}</FormHelperText>
                                </FormControl>
                                    
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        { _renderDietaryTypes(props) }
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Typography variant="h6">Optional Storefront Image</Typography>
                        <FormControl error={state.imageError !== null} fullWidth>
                            <Typography variant="body2">Select Image</Typography>
                            <Typography variant="body1">JPG or PNG, 2 MB size limit</Typography>
                            <FormHelperText>{state.imageError}</FormHelperText>
                            <ImageSelectPreview 
                                onSelectImage={props.onSelectImage}
                                maxFilesizeMB={2}/>
                        </FormControl>
                    </Grid>                    
                    <Grid item md={12} lg={6} style={{height: "400px"}}>
                        { _renderMap(props) }
                    </Grid>
                </Grid>
            </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClickCancel()}>Cancel</Button>
                    <Button onClick={() => props.onClickCreate()} variant="contained" color="primary">Create</Button>
                </DialogActions>
            </Dialog>
    )
    
}


PointOfInterest.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickMap: PropTypes.func,
    onMoveEndMap: PropTypes.func,
    onZoomEndMap: PropTypes.func,
    onChangeName: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onSelectDietaryType: PropTypes.func,
    onSelectTerminal: PropTypes.func,
    onSelectBusinessType: PropTypes.func,
    onSelectImage: PropTypes.func,
}

PointOfInterest.defaultProps = {
    onClickMap: (map, event) => {},
    onMoveEndMap: (map, event) => {},
    onZoomEndMap: (map, event) => {},
    onChangeName: (value) => {},
    onChangeDescription: (value) => {},
    onSelectDietaryType: () => {},
    onSelectTerminal: () => {},
    onSelectBusinessType: () => {},
    onSelectImage: () => {},
}
