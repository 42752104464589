import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import { isNull } from "util"


function _renderStars(filled) {
    if (isNull(filled)) {
        return <Typography variant="body1">No rating.</Typography>
    }

    let stars = []
    const maxStars = 5
    for (let i = 0; i < maxStars; i++) {
        stars.push(
            i < filled ? <StarIcon color="primary" key={i}/> : <StarBorderIcon color="primary" key={i}/>
        )
    }
    return (
        <div>{
            stars.map((star) => {
                return star
            })
        }</div>
    )
}

function _renderDeliveryHistory(props) {
    const { deliveries } = props

    if(deliveries.length === 0)
        return (
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="h4">Delivery History</Typography>
                    <div style={{height: 20}}/>
                    <Typography variant="body1">Your first happy customer begins with a "hello".</Typography>
                </Grid>
            </Grid>
        )
    else
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">Delivery History</Typography>
                    <Typography variant="body1">10 Most Recent</Typography>
                </Grid>
                {
                    deliveries.map((delivery, idx) => {
                        return (
                            <Grid item xs={12} sm={6} key={idx}>
                                <Card style={{display: "flex", height: 160}}>
                                    <CardContent>
                                        <Typography variant="h6">{delivery.name}</Typography>
                                        { _renderStars(delivery.numeric_rating) }
                                        <Typography variant="body1">{delivery.completed_date_utc}</Typography>
                                        <Typography variant="caption">{delivery.order_id}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
}

function _renderDeliveryStatistics(props) {
    const { classes, stats } = props


    const rating = !isNull(stats.avg_rating) ? `${Math.round(stats.avg_rating * 100)}%` : "--"
    const n_deliveries = !isNull(stats.n_deliveries) ? stats.n_deliveries : "--"
    const n_ratings = !isNull(stats.n_ratings) ? stats.n_ratings : "--"



    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4">Delivery Statistics</Typography>
                <Typography variant="body1">All Time</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={12}>
                <Typography variant="h6" component="span">Happiness Rating </Typography>
                <Typography variant="h3" className={classes.highlightText}>{rating}</Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={12}>
                <Typography variant="h6"># Deliveries</Typography>
                <Typography variant="h3" className={classes.highlightText}>{n_deliveries}</Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={12}>
                <Typography variant="h6"># Ratings</Typography>
                <Typography variant="h3" className={classes.highlightText}>{n_ratings}</Typography>
            </Grid>
        </Grid>
    )
}

export const ConciergeDashboard = (props) => {
    return (
        <Grid container justify="center">
            <Grid item xs={11}>
                <Grid container style={{maxHeight: 600}}>
                    <Grid item xs={12} lg={4}>
                        { _renderDeliveryStatistics(props) }
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        { _renderDeliveryHistory(props) }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ConciergeDashboard.propTypes = {
    classes: PropTypes.object,
    deliveries: PropTypes.array,
    stats: PropTypes.object
}

