import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import FetchyFoxLogo from "../../assets/logo.png"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"
import { firebaseApp } from "../../firebase"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import { StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { ListingsAPI } from "../common/requests/airport/listings"

import { AirportMenu } from "./views/Menu"
import SellableConfig from "../SellableConfig"

// import SellableDetailsDialogContainer from "./SellableDetailsDialog"

const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class MenuContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            placeId: this.props.match.params.placeId,
            placeDetails: {},
            menus: [],

            searchText: "",
            selectedMenuId: "",
            selectedSectionId: "",
            
            sellableDialog: null, // dialog window to show sellable details

            redirectParams: null,
            drawerItems: props.account.menuItems,
            loading: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },
            location: props.location,

            credentials: props.credentials,
            account: props.account,
            airport: props.airport            
        }
    }

    componentWillMount = () => {
        this._getPlaceOfferings()
    }

    componentDidMount = () => {
        const placeDetails = this.props.placelist.filter((place) => {
            return place.place_id === this.props.match.params.placeId
        })[0]
        this.setState({
            placeDetails,
        })
    }

    /** event listeners */
    _onClickRefresh = () => this._getPlaceOfferings()
    _onTypeSearch = (searchText) => this.setState({searchText})
    _onSelectMenu = (selectedMenuId) => this.setState({selectedMenuId})
    _onSelectSection = (selectedSectionId) => this.setState({selectedSectionId})
    
    _onClickManageSellable = (sellableId) => {
        this._getSellableDetails(sellableId, (sellableDetails) => {
            this.setState({
                sellableDialog: <SellableConfig variant="update" data={sellableDetails}
                                    onCancelCallback={this._onClickCloseSellableDetails}
                                    onSubmitCallback={this._onClickSubmitSellableDetails}/>
            })
        })
    }

    _onClickCloseSellableDetails = () => { this.setState({sellableDialog: null})}

    _onClickSubmitSellableDetails = (sellableData) => {
        const { sellable_id, name, description, price, listed, contents } = sellableData
        this._updateSellable(sellable_id, name, description, price.base_unit, listed, contents)
    }

    _onChangeSellablePrice = (sellableId, value) => {
        // value is in the local currency's base unit, the rounding is to remove fractional
        // base units
        let { menus } = this.state
        menus = menus.map((menu) => {
            menu.categories = menu.categories.map((category) => {
                category.sellables = category.sellables.map((sellable) => {
                    if(sellable.sellable_id === sellableId) {
                        sellable.price.base_unit = Math.round(value)
                    }
                    return sellable
                })
                return category
            })
            return menu
        })
        this.setState({menus})
    }

    _onClickUpdateSellablePrice = (sellableId) => {
        let { menus } = this.state
        // get the price from memory
        let sellable = menus.map((menu) => {
            return menu.categories.map((category) => {
                return category.sellables.filter((sellable) => {
                    return sellable.sellable_id === sellableId
                })
            })
        }).flat(2)[0]
        
        this._updateSellablePrice(sellableId, sellable.price.base_unit)
    }

    _onToggleSellableListingStatus = (sellableId, listed) => this._updateSellableListingStatus(sellableId, listed)

    _onCloseSnack = () => this.setState({
        snackMessage: {
            level: StatusSnackbarTypes.STANDARD,
            message: ""
        }
    })

    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})
    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            const snackMessage = {
                level: StatusSnackbarTypes.ERROR,
                message: error
            }
            this.setState({loading: false, snackMessage})
        })        
    }

    /** end event listeners */


    /** network requests */

    _getPlaceOfferings = () => {
        this.setState({loading: true})
        const api = new ListingsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestGetMenus(this.state.placeId,
            (success) => {
                const menus = success.data
                this.setState({loading: false, menus})
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})
            })
    }

    _updateSellable = (sellableId, name, description, baseUnitPrice, listed, contents) => {
        this.setState({loading: true})
        const api = new ListingsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestUpdateSellable(sellableId, name, description, baseUnitPrice, listed, contents,
            (success) => {
                const snackMessage = {
                    level: StatusSnackbarTypes.SUCCESS,
                    message: `${name} updated.`
                }                
                this.setState({loading: false, sellableDialog: null, snackMessage})
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                
                this.setState({loading: false, snackMessage})
            })        
    }

    _updateSellablePrice = (sellableId, priceBaseUnit) => {
        this.setState({loading: true})
        const api = new ListingsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestUpdateSellable(sellableId, null, null, priceBaseUnit, null, null,
            (success) => {
                const snackMessage = {
                    level: StatusSnackbarTypes.SUCCESS,
                    message: "Price updated."
                }                            
                this.setState({loading: false, snackMessage})
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                            
                this.setState({loading: false, snackMessage})                
            })
    }

    _updateSellableListingStatus = (sellableId, listed) => {
        this.setState({loading: true})
        const api = new ListingsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestUpdateSellableListingStatus(sellableId, listed,
            (success) => {
                // update listing status in memory
                let { menus } = this.state
                menus = menus.map((menu) => {
                    menu.sections = menu.categories.map((category) => {
                        category.sellables = category.sellables.map((sellable) => {
                            if(sellable.sellable_id === sellableId) {
                                sellable.listed = listed
                            }
                            return sellable
                        })
                        return category
                    })
                    return menu
                })
                
                const word = listed ? "listed" : "unlisted"
                const snackMessage = {
                    level: StatusSnackbarTypes.SUCCESS,
                    message: `The sellable is now ${word}`
                }
                this.setState({loading: false, snackMessage, menus})
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                            
                this.setState({loading: false, snackMessage})
            })
    }

    _getSellableDetails = (sellableId, callback=(sellableDetails)=>{}) => {
        this.setState({loading: true})
        const api = new ListingsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestGetSellableDetails(sellableId,
            (success) => {
                this.setState({loading: false}, () => callback(success.data))
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                            
                this.setState({loading: false, snackMessage})
            })
    }

    /** end network requests */

    _renderSellableDetails = () => {
        if(this.state.sellableDialog)
            return this.state.sellableDialog
        else
            return <div/>
    }

    render = () => {
        return (
            <div>
                <AirportMenu classes={this.props.classes} state={this.state}
                    onClickRefresh={this._onClickRefresh}
                    onTypeSearch={this._onTypeSearch}
                    onSelectMenu={this._onSelectMenu}
                    onSelectSection={this._onSelectSection}

                    onToggleSellableListingStatus={this._onToggleSellableListingStatus}
                    onChangeSellablePrice={this._onChangeSellablePrice}
                    onClickUpdateSellablePrice={this._onClickUpdateSellablePrice}
                    onClickManageSellable={this._onClickManageSellable}

                    onCloseSnack={this._onCloseSnack}
                    onClickDrawerItem={this._onClickDrawerItem}
                    onClickDrawerLogout={this._onClickDrawerLogout}
                    />
                { this._renderSellableDetails() }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account, placelist } = state
    return { server, credentials, airport, account, placelist }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}


export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(MenuContainer);
