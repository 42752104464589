import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class SellableAPI {
    constructor(host, merchantId, fetchyToken) {
        this.host = host
        this.username = merchantId
        this.password = fetchyToken
    }


    promiseUploadMedia = (sellable_id, media_data, mime_type) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable/media`,
            method: "POST",
            headers: {
                "Content-Type": mime_type
            },
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            },
            data: media_data
        })
    }

    tryPromiseUploadMedia = (sellableId, mediaData, mimeType) => {
        return this.promiseUploadMedia(sellableId, mediaData, mimeType).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUploadMedia = (sellableId, mediaData, mimeType, onRequestSuccess, onRequestError) => {
        this.promiseUploadMedia(sellableId, mediaData, mimeType).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteMedia = (sellable_id, media_url) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable/media`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            },
            data: {
                media_url
            }
        })
    }

    tryPromiseDeleteMedia = (sellableId, mediaUrl) => {
        return this.promiseDeleteMedia(sellableId, mediaUrl).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteMedia = (sellableId, mediaUrl, onRequestSuccess, onRequestError) => {
        this.promiseDeleteMedia(sellableId, mediaUrl).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseSetDefaultMedia = (sellable_id, media_url) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable/media`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },            
            params: {
                sellable_id
            },
            data: {
                media_url
            }
        })
    }

    tryPromiseSetDefaultMedia = (sellableId, mediaUrl) => {
        return this.promiseSetDefaultMedia(sellableId, mediaUrl).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestSetDefaultMedia = (sellableId, mediaUrl, onRequestSuccess, onRequestError) => {
        this.promiseSetDefaultMedia(sellableId, mediaUrl).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetImpacted = (fetchy_uuid) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable/impact`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                fetchy_uuid
            }
        })        
    }

    tryPromiseGetImpacted = (fetchyUuid) => {
        return this.promiseGetImpacted(fetchyUuid).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetImpacted = (fetchyUuid, onRequestSuccess, onRequestError) => {
        this.promiseGetImpacted(fetchyUuid).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetAllSellables = () => {
        return axios({
            url: `${this.host}/v1/merchant/sellables`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseGetAllSellables = () => {
        return this.promiseGetAllSellables.then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })        
    }

    requestGetAllSellables = (onRequestSuccess, onRequestError) => {
        this.promiseGetAllSellables().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }


    promiseGetSellableDetails = (sellable_id) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            }
        })
    }

    tryPromiseGetSellableDetails = (sellableId) => {
        return this.promiseGetSellableDetails(sellableId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestGetSellableDetails = (sellableId, onRequestSuccess, onRequestError) => {
        this.promiseGetSellableDetails(sellableId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateSellable = (place_id, category_id, sellable_type, name, description, listed, base_unit_price,
        iso_4217, fulfillment_seconds, contents, dietary_tags, media_urls) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable`,
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },            
            params: {
                place_id,
                category_id,
                sellable_type
            },
            data: {
                name,
                description,
                listed,
                fulfillment_seconds,
                contents,
                dietary_tags,
                base_unit_price,
                iso_4217,
                media_urls
            }
        })            
    }

    tryPromiseCreateSellable = (placeId, categoryId, sellableType, name, description, listed, baseUnitsPrice,
        iso4217, fulfillmentSeconds, contents, dietaryTags, mediaUrls) => {
        return this.promiseCreateSellable(placeId, categoryId, sellableType, name, description, listed, baseUnitsPrice,
            iso4217, fulfillmentSeconds, contents, dietaryTags, mediaUrls).then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
        }
    
    requestCreateSellable = (placeId, categoryId, sellableType, name, description, listed, baseUnitsPrice,
        iso4217, fulfillmentSeconds, contents, dietaryTags, mediaUrls, onRequestSuccess, onRequestError) => {
        this.promiseCreateSellable(placeId, categoryId, sellableType, name, description, listed, baseUnitsPrice,
            iso4217, fulfillmentSeconds, contents, dietaryTags, mediaUrls).then((response) => {
                onRequestSuccess(response)
            }).catch((error) => {
                const result = handleFetchyAPIError(error)
                onRequestError(result)
            })
        }

    promiseUpdateSellable = (sellable_id, category_id, type, name, description, listed, base_unit_price, fulfillment_seconds, 
        contents, dietary_tags) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": "application/json"
            },            
            params: {
                sellable_id
            },
            data: {
                category_id,
                name,
                description,
                type,
                base_unit_price,
                listed,
                fulfillment_seconds,
                contents,
                dietary_tags
            }
        })
    }

    tryPromiseUpdateSellable = (sellableId, categoryId, type, name, description, listed, baseUnitPrice, fulfillmentSeconds, contents, dietaryTags) => {
        return this.promiseUpdateSellable(sellableId, categoryId, type, name, description, listed, baseUnitPrice, fulfillmentSeconds, contents, dietaryTags)
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    requestUpdateSellable = (sellableId, categoryId, type, name, description, listed, baseUnitPrice, fulfillmentSeconds, contents, dietaryTags, onRequestSuccess, onRequestError) => {
        this.promiseUpdateSellable(sellableId, categoryId, type, name, description, listed, baseUnitPrice, fulfillmentSeconds, contents, dietaryTags)
            .then((response) => {
                onRequestSuccess(response)
            }).catch((error) => {
                const result = handleFetchyAPIError(error)
                onRequestError(result)
            })
    }

    requestUpdateSellableListingStatus = (sellableId, listed, onRequestSuccess, onRequestError) => {
        this.promiseUpdateSellable(sellableId, null, null, null, null, listed, null, null, null, null).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteSellable = (sellable_id) => {
        return axios({
            url: `${this.host}/v1/merchant/sellable`,
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                sellable_id
            }
        })
    }

    tryPromiseDeleteSellable = (sellableId) => {
        return this.promiseDeleteSellable(sellableId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteSellable = (sellableId, onRequestSuccess, onRequestError) => {
        this.promiseDeleteSellable(sellableId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}