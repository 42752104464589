import PropTypes from "prop-types"
import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { FormControl } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"

import LoadingDialog from "../../common/components/LoadingDialog"
import PlaceMenu from "../pieces/PlaceMenu"
import PlaceDetails from "../pieces/PlaceDetails"

function _renderModal(props) {
    const { state } = props

    if (state.dialogComponent){
        return state.dialogComponent
    }
    else {
        return <div/>
    }
}

function _renderListing(props) {
    const {state} = props

    if(state.place_details === null) {
        return <div/>
    }
    else {
        const { symbolLat, symbolLng } = state
        const mapDetails = {
            symbolLat,
            symbolLng
        }

        return (
            <div style={{height: "90vh", overflow: 'auto'}}>
                <PlaceDetails 
                    details={state.place_details}
                    mapDetails={mapDetails}
                    onUpdateName={props.onUpdatePlaceName}
                    onUpdateDescription={props.onUpdatePlaceDescription}
                    onConfirmDelete={props.onConfirmDeletePlace}
                    onClickMap={props.onClickPlaceMap}
                    onClickUpdateLocation={props.onClickUpdatePlaceLocation}
                    onToggleListed={props.onTogglePlaceListed}
                    onSelectMedia={props.onSelectMedia}/>
                <div style={{height: "40px"}}/>            
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={state.searchText}
                            label="Search sellables..."
                            color="primary"
                            size="small"
                            onChange={(event) => props.onTypeSearch(event.target.value)}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>All Menus</InputLabel>
                            <Select
                                value={state.selectedMenuId}
                                onChange={(event) => props.onSelectMenu(event.target.value)}>
                                <MenuItem value="">All</MenuItem>
                                {
                                    state.menus.map((menu, idx) => {
                                        return <MenuItem value={menu.menu_id} key={idx}>{menu.menu_name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>All Sections</InputLabel>
                            <Select
                                value={state.selectedCategoryId}
                                onChange={(event) => props.onSelectMenuCategory(event.target.value)}>
                                <MenuItem value="">All</MenuItem>
                                {
                                    state.menus.filter((menu) => {
                                        return menu.menu_id === state.selectedMenuId
                                    }).map((menu) => {
                                        return (
                                            menu.categories.map((category, categoryIdx) => {
                                                return <MenuItem value={category.category_id} key={categoryIdx}>{category.category_name}</MenuItem>
                                            })
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={props.onClickCreateMenu}>Create Menu</Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={props.onClickAddSellable}>Create Sellable</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PlaceMenu
                            menus={state.filteredMenus}
                            onClickRenameMenu={props.onClickRenameMenu}
                            onClickDeleteMenu={props.onClickDeleteMenu}
                            onClickAddCategory={props.onClickAddCategory}
                            onClickEditCategory={props.onClickEditCategory}
                            onClickDeleteCategory={props.onClickDeleteCategory}
                            onClickManageSellable={props.onClickManageSellable}
                            onToggleSellableListing={props.onToggleSellableListing}/>
                    </Grid>
                </Grid>
            </div>    
        )
    }
}

function _renderConfirmDeleteDialog(props) {
    const { state } = props
    if( state.confirmDeleteDialog !== null ) {
        return state.confirmDeleteDialog
    }
    else {
        return <div/>
    }
}

export const MerchantListing = (props) => {
    const { state } = props

    return (
        <main>
            { _renderListing(props) }
            { _renderModal(props) }
            { _renderConfirmDeleteDialog(props) }
            <LoadingDialog show={state.loading}/>
        </main>
    )
}

MerchantListing.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickDrawerItem: PropTypes.func.isRequired,
    onClickDrawerLogout: PropTypes.func.isRequired,
    onTypeSearch: PropTypes.func.isRequired,
    onSelectMenu: PropTypes.func.isRequired,
    onClickCreateMenu: PropTypes.func.isRequired,
    onSelectMenuCategory: PropTypes.func.isRequired,
    onCloseSnack: PropTypes.func.isRequired,
    

    // Place Details callbacks
    onUpdatePlaceName: PropTypes.func.isRequired,
    onUpdatePlaceDescription: PropTypes.func.isRequired,
    onConfirmDeletePlace: PropTypes.func.isRequired,
    onClickPlaceMap: PropTypes.func.isRequired,
    onClickUpdatePlaceLocation: PropTypes.func.isRequired,
    onTogglePlaceListed: PropTypes.func.isRequired,
    onSelectMedia: PropTypes.func.isRequired,

    // Place Menu callbacks
    onClickDeleteMenu: PropTypes.func.isRequired,
    onClickRenameMenu: PropTypes.func.isRequired,
    onClickAddCategory: PropTypes.func.isRequired,
    onClickEditCategory: PropTypes.func.isRequired,
    onClickDeleteCategory: PropTypes.func.isRequired,
    onClickAddSellable: PropTypes.func.isRequired,
    onClickManageSellable: PropTypes.func.isRequired,
    onToggleSellableListing: PropTypes.func.isRequired,
}

MerchantListing.defaultProps = {
    onClickDrawerItem: (redirectParams) => {},
    onClickDrawerLogout: () => {},
    onTypeSearch: (value) => {},
    onSelectMenu: (menuId) => {},
    onSelectMenuCategory: (sectionId) => {},
    

    onUpdatePlaceName: () => {},
    onUpdatePlaceDescription: () => {},
    onConfirmDeletePlace: () => {},
    onClickPlaceMap: (map, event) => {},
    onClickUpdatePlaceLocation: () => {},
    onTogglePlaceListed: (listed) => {},
    onSelectMedia: (mediaData, mimeType) => {},

    onClickDeleteMenu: (menuId) => {console.log(`default onClickDeleteMenu ${menuId}`)},
    onClickRenameMenu: (menuId) => {},
    onClickAddCategory: () => {},
    onClickEditCategory: (categoryId, sectionName, listed) => {},
    onClickDeleteCategory: (categoryId) => {},
    onClickAddSellable: () => {},
    onClickManageSellable: (itemId) => {},
    onToggleSellableListing: (itemId, listed) => {}
}
