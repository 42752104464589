import React, { Component } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

class TableCard extends Component {
    render = () => {
        const metricKeys = this.props.data.length > 0 ? Object.keys(this.props.data[0]) : []

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={<Typography variant="h6">{this.props.title}</Typography>}>
                        </CardHeader>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>{
                                        this.props.columnTitles.map((title, idx) => {
                                            return (
                                                <TableCell key={idx} align="left">{title}</TableCell>
                                            )
                                        })
                                    }</TableRow>
                                </TableHead>
                                <TableBody>{
                                    this.props.data.length > 0 ?
                                        this.props.data.map((row, ridx) => {
                                            return (
                                                <TableRow key={ridx}>{
                                                    metricKeys.map((key, cidx) => {
                                                        return (
                                                            <TableCell key={cidx} align="left">{row[key] || "none"}</TableCell>
                                                        )
                                                    })
                                                }</TableRow>
                                            )
                                        }) :
                                        <TableRow>
                                            <TableCell>No Data</TableCell>
                                        </TableRow>
                                }</TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

TableCard.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array
}

TableCard.defaultProps = {
    title: "No Data",
    columnTitles: ["Name", "Metric", "Metric2"],
    data: [
        {name: "No Data", metric: 0, metric2: 0},
    ]
}

export default TableCard
