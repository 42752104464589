import { isNull } from "util"
import PropTypes from "prop-types"
import React, { Component } from "react"
import ReactMapboxGl, { ZoomControl, Layer, Feature } from "react-mapbox-gl"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"

import ImageCropPreview from "../../common/components/ImageCropPreview"
import DialogFormInput from "../../common/components/DialogFormInput"
import ConfirmActionDialog from "../../common/components/ConfirmActionDialog"
import { DelayedCircularButton } from "../../common/components/DelayedDelete"

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_KEY,
    dragRotate: false,
    dragPan: true,
    scrollZoom: false,
    doubleClickZoom: false,
    logoPosition: "top-left"
});



class PlaceDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadedDialog: null,

            mapZoom: 17,
            mapLngCenter: this.props.mapDetails.symbolLng || -122.271100,
            mapLatCenter: this.props.mapDetails.symbolLat || 37.804360,
        }
    }

    _onMoveEnd = (map, event) => {
        if (map._easeOptions && map._easeOptions.center) {
            const mapLatCenter = map._easeOptions.center.lat
            const mapLngCenter = map._easeOptions.center.lng
            this.setState({mapLatCenter, mapLngCenter})
        }        
    }
    _onZoomEnd = (map, event) => {
        if (map._easeOptions && map._easeOptions.zoom) {
            const mapZoom = map._easeOptions.zoom
            this.setState({mapZoom})
        }
    }

    _onClickEditTitle = () => this.setState({
        loadedDialog: <DialogFormInput
                        title="Edit Title"
                        value={this.props.details.name}
                        onCancelCallback={() => this.setState({loadedDialog: null})}
                        onUpdateCallback={(name) => { this.setState({loadedDialog: null}, this.props.onUpdateName(name)) }} />
    })

    _onClickEditDescription = () => this.setState({
        loadedDialog: <DialogFormInput
                        title="Edit Description"
                        value={this.props.details.description}
                        multiline
                        onCancelCallback={() => this.setState({loadedDialog: null})}
                        onUpdateCallback={(description) => this.setState({loadedDialog: null}, this.props.onUpdateDescription(description))}/>
    })

    _onClickDeleteListing = () => this.setState({
        loadedDialog: <ConfirmActionDialog
                        open
                        message="Deleting a place and its menus is irreversible. Enter the place's name
                        below to confirm you want to permanently delete it:"
                        confirmValue={this.props.details.name}
                        onCloseCallback={() => this.setState({loadedDialog: null})}
                        onSuccessCallback={ () => this.setState({loadedDialog: null}, this.props.onConfirmDelete) }/>
    })

    _renderDialog = () => {
        if (!isNull(this.state.loadedDialog))
            return this.state.loadedDialog
        else
            return <div/>
    }

    _renderMap = () => {
        return (
            <Grid container justify="center" style={{height: "inherit"}}>
                <Grid item xs={12} style={{height: "inherit"}}>
                    <Map
                        style={`mapbox://styles/mapbox/streets-v9`}
                        center={[this.state.mapLngCenter, this.state.mapLatCenter]}
                        zoom={[this.state.mapZoom]}
                        movingMethod="jumpTo"
                        onClick={ this.props.onClickMap }
                        onMoveEnd={ this._onMoveEndMap }
                        onZoomEnd={ this._onZoomEndMap }
                        containerStyle={{height: "100%"}}>
                            <ZoomControl/>{
                                this.props.details ?
                                    <Layer
                                        type="symbol"
                                        id="shop-location"
                                        source="mapbox-streets"
                                        layout={{
                                            "icon-image": "star-15",
                                            "icon-size": 1,
                                            "text-field": this.props.details.name,
                                            "text-size": 12,
                                            "text-anchor": "top",
                                            "icon-anchor": "bottom"
                                        }}>
                                        <Feature coordinates={[this.props.mapDetails.symbolLng, this.props.mapDetails.symbolLat]}/>
                                    </Layer> : <div/>
                            }
                    </Map>
                </Grid>
                <Grid item>
                    <Button color="primary" onClick={(event) => this.props.onClickUpdateLocation() }>Save Location</Button>
                </Grid>
            </Grid>
        )        
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={10}>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid item>
                            <Typography variant="h3">
                                {this.props.details.name}
                                <IconButton aria-label="Edit Title" onClick={this._onClickEditTitle}>
                                    <EditIcon />
                                </IconButton>                                  
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                            <DelayedCircularButton
                                onConfirmAction={this._onClickDeleteListing}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1">
                                {this.props.details.description}
                                <IconButton aria-label="Edit Description" onClick={this._onClickEditDescription}>
                                    <EditIcon />
                                </IconButton>                                    
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={this.props.details.listed}
                                onChange={(event) => this.props.onToggleListed(event.target.checked) }
                            />
                        }
                        label={<Typography variant="subtitle1">Listed</Typography>}/>
                </Grid>
                <Grid item xs={12} lg={8} xl={6}>
                    <ImageCropPreview
                        pictureUrl={this.props.details.picture_url}
                        onSelectImage={this.props.onSelectMedia}
                        errorText={""}/>
                </Grid>
                <Hidden mdDown>
                    <Grid item lg={4} xl={6} style={{height: "400px"}}>
                        { this._renderMap() }
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                    { this._renderDialog() }
                </Grid>
            </Grid>
        )
    }
}


PlaceDetails.propTypes = {
    details: PropTypes.object.isRequired,
    mapDetails: PropTypes.object.isRequired,

    onUpdateName: PropTypes.func,
    onUpdateDescription: PropTypes.func,
    onConfirmDelete: PropTypes.func,
    onClickMap: PropTypes.func,
    onClickUpdateLocation: PropTypes.func,
    onToggleListed: PropTypes.func,
    onSelectMedia: PropTypes.func,

}

PlaceDetails.defaultProps = {
    mapDetails: {
        symbolLat: 37.804360,
        symbolLng: -122.271100
    },
    details: {
        name: "Lorem Ipsum",
        description: "This is the default description, wow.",
        picture_url: "https://s3.amazonaws.com/fetchy-images/defaultshop.png",
        listed: false,
    },
    onUpdateName: () => {},
    onUpdateDescription: () => {},
    onConfirmDelete: () => {},
    onClickMap: (map, event) => {},
    onClickUpdateLocation: () => {},
    onToggleListed:(listed) => {},
    onSelectMedia: (mediaData, mimeType) => {}
}



export default PlaceDetails
