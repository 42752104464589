import React, { Component } from "react"
import { connect } from "react-redux"
import CouponGenerator from "coupon-code"
import PropTypes from "prop-types"

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@material-ui/core"

import { CouponConfig } from "../views/CouponConfig"
import { StatusSnackbar, StatusSnackbarTypes } from "../../common/components/StatusSnackbar"
import LoadingDialog from "../../common/components/LoadingDialog"
import { ConstantsAPI } from "../../common/requests/merchant/constants"
import { PlaceAPI } from "../../common/requests/merchant/place"
import { SellableAPI } from "../../common/requests/merchant/sellable"
import { CouponAPI } from "../../common/requests/merchant/coupon"
import { isEmptyString } from "../../common/utils"

const moment = require("moment-timezone")

class CouponConfigDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            places: [],
            sellables: [],
            couponTypes: [],
            discountTypes: [],

            couponCode: "",
            description: "",
            couponType: "",
            selectedSearch: null,  // the place id or sellable id this coupon applies to, depends on couponType
            searchTerm: "",
            expirationDate: null,
            startDate: null,
            isActive: true,
            hasLimit: false,
            usageLimit: 1,

            discountType: "",
            discountAmount: 0,
            minSpend: 0,
            hasMaxSavings: false,
            maxSavings: 0,
            minQuantity: 1,
            hasMaxQuantity: false,
            maxQuantity: 9,

            loading: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""                
            },

            couponCodeError: "",
            descriptionError: "",
            couponTypeError: "",
            expirationDateError: "",
            discountTypeError: "",
            discountAmountError: "",
            selectedSearchError: "",
        }
    }

    componentWillMount = () => {
        this._populateData()
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {}

    /** Business Logic */

    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            } 
        })
    }

    _clearFormErrors = () => {
        this.setState({
            couponCodeError: "",
            descriptionError: "",
            couponTypeError: "",
            expirationDateError: "",
            discountTypeError: "",
            discountAmountError: "",
            selectedSearchError: "",
        })
    }

    _networkSnackMessage = (networkError) => {
        const message = networkError.response === undefined ? networkError.message : networkError.response.data.error || networkError.response.data.exception
        const snackMessage = {
            level: StatusSnackbarTypes.ERROR,
            message: `Network: ${message}`
        }
        this.setState({loading: false, snackMessage})
    }

    _createCouponPayload = () => {

        const payload = {
            code: this.state.couponCode,
            description: this.state.description,
            type: this.state.couponType,
            expiration_date: this.state.expirationDate.tz("UTC").format("YYYY-MM-DD"),
            start_date: this.state.startDate ? this.state.startDate.tz("UTC").format("YYYY-MM-DD") : 
                            moment().tz("UTC").format("YYYY-MM-DD"),
            active: this.state.isActive,
            limit: this.state.hasLimit ? this.state.usageLimit : null,
            discount: {
                type: this.state.discountType,
                amount: this.state.discountAmount,
                min_spend: this.state.minSpend,
                max_savings: this.state.hasMaxSavings ? this.state.maxSavings : null,
                min_quantity: this.state.couponType === "SELLABLE" ? this.state.minQuantity : null,
                max_quantity: this.state.couponType === "SELLABLE" && this.state.hasMaxQuantity ? this.state.maxSavings : null
            },
            scope: {
                airport_id: this.props.airport.airport_id,
                place_id: this.state.couponType === "PLACE" ? this.state.selectedSearch.id : "",
                sellable_id: this.state.couponType === "SELLABLE" ? this.state.selectedSearch.id : "",
                user_id: ""
            }
        }
        this._createCoupon(payload)
    }

    /** END business logic */

    /** event listeners */
    
    _onChangeForm = (name, value) => {
        let newState = {[name]: value}
        if(name === "couponCode") {
            newState[name] = value.toUpperCase()
        }
        else if(name === "minQuantity" && value > this.state.maxQuantity) {
            newState[name] = value
        }
        else if(name === "maxQuantity" && value < this.state.minQuantity) {
            newState[name] = value
        }
        else if(name === "discountType") {
            // reset values of discount dependent fields
            newState["discountAmount"] = 0
            newState["maxSavings"] = 0
        }
        else if(name === "discountAmount" && this.state.discountType === "PERCENT") {
            // discount percent value must be an integer
            newState[name] = parseInt(value)
        }
        else if(name === "couponType") {
            // reset values of coupon type dependent fields
            newState["minQuantity"] = 1
            newState["maxQuantity"] = 9
        }
        this.setState(newState)
    }
        
    _onClickGenerateCode = () => this.setState({couponCode: CouponGenerator.generate({parts : 2})})

    _onSelectSearch = (selectedSearch) => this.setState({selectedSearch})

    _onClickComplete = () => {
        // verify the fields in the coupon form. there are 4 catetories of fields
        this._clearFormErrors()
        let errorMessages = {}

        // universally required fields
        if(isEmptyString(this.state.couponCode)){
            errorMessages["couponCodeError"] = "Required"
        }
        if(isEmptyString(this.state.description)){
            errorMessages["descriptionError"] = "Required"
        }
        if(isEmptyString(this.state.couponType)){
            errorMessages["couponTypeError"] = "Required"
        }
        if(this.state.expirationDate === null){
            errorMessages["expirationDateError"] = "Required"
        }
        if(isEmptyString(this.state.discountType)){
            errorMessages["discountTypeError"] = "Required"
        }

        if(this.state.discountType && this.state.discountAmount === 0){
            errorMessages["discountAmountError"] = "Must be greater than 0."
        }

        // conditionally required
        if(["PLACE", "SELLABLE"].indexOf(this.state.couponType) > -1 && (!this.state.selectedSearch || !this.state.selectedSearch.id)){
            errorMessages["selectedSearchError"] = "Required"
        } 

        if(Object.keys(errorMessages).length) {
            this.setState(errorMessages)
        }
        else{
            this._createCouponPayload()
        }

    }

    /** END event listeners */

    /** network requests */

    _createCoupon = (payload, callback=()=>{}) => {
        this.setState({loading: true})
        const couponAPI = new CouponAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        couponAPI.requestCreateCoupon(payload,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: "Coupon created!",
                        level: StatusSnackbarTypes.SUCCESS
                    }
                }, callback)
            },
            (error) => this._networkSnackMessage(error))
    }

    _populateData = (callback=()=>{}) => {
        this.setState({loading: true})
        const constantsAPI = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const placeAPI = new PlaceAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const sellableAPI = new SellableAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        Promise.all([constantsAPI.promiseCouponTypes(), constantsAPI.promiseDiscountTypes(), placeAPI.promisePlaceList(),
                    sellableAPI.promiseGetAllSellables()])
                .then(([couponTypesResponse, discountTypesResponse, placeListResponse, sellablesResponse ]) => {
                    
                    // standardize the schemas of the places and sellables response
                    const places = placeListResponse.data.map(place => ({
                            id: place.place_id,
                            name: place.name,
                            renderName: (
                                <div>
                                    <Typography variant="caption" component="span">{`${place.terminal_name} / `}</Typography>
                                    <Typography variant="body1" component="span" color="primary">{`${place.name}`}</Typography>
                                </div>
                            ),
                            imageUrl: place.picture_url
                        }))

                    const sellables = sellablesResponse.data.map(sellable => ({
                        id: sellable.sellable_id,
                        name: sellable.name,
                        renderName: (
                            <div>
                                <Typography variant="caption" component="span">{`${sellable.place_name} / `}</Typography>
                                <Typography variant="caption" component="span">{`${sellable.menu_name} / `}</Typography>
                                <Typography variant="caption" component="span">{`${sellable.category_name} / `}</Typography>
                                <Typography variant="body1" component="span" color="primary">{`${sellable.name}`}</Typography>
                            </div>                            
                        ),
                        imageUrl: sellable.media_urls[0]
                        }))

                    this.setState({
                        loading: false,
                        places,
                        sellables,
                        couponTypes: couponTypesResponse.data.types,
                        discountTypes: discountTypesResponse.data.types
                    }, callback)
                })
                .catch(this._networkSnackMessage)
    }

    /** END network requests */

    render = () => {
        return (
            <div>
                <Dialog open maxWidth="md" fullWidth>
                    <DialogTitle>Create Coupon</DialogTitle>
                    <DialogContent>
                        <CouponConfig
                            classes={this.props.classes}
                            state={this.state}
                            onChangeForm={this._onChangeForm}
                            onClickGenerateCode={this._onClickGenerateCode}
                            onSelectSearch={this._onSelectSearch}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" onClick={this.props.onClickClose}>Cancel</Button>
                        <Button variant="text" onClick={this._onClickComplete} color="primary">Create Coupon</Button>
                    </DialogActions>
                </Dialog>
                <LoadingDialog show={this.state.loading} message="Loading..."/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>
            </div>                
        )        
    }

}

CouponConfigDialog.propTypes = {
    onClickClose: PropTypes.func.isRequired
}

CouponConfigDialog.defaultProps = {
    onClickClose: () => {}
}

function mapStateToProps(state) {
    const { credentials, server, airport } = state
    return { credentials, server, airport }
}

const mapActionCreators = {}

export default connect(mapStateToProps, mapActionCreators)(CouponConfigDialog)