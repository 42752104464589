import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"

import CouponConfigDialog from "./modals/CouponConfig"
import { CouponDetailsDialog } from "./modals/CouponDetails"
import { CouponLibrary } from "./views/CouponLibrary"

import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import NavDrawer from "../common/components/NavDrawer"
import LoadingDialog from "../common/components/LoadingDialog"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import { CouponAPI } from "../common/requests/merchant/coupon"
import { isEmptyString } from "../common/utils"

import FetchyFoxLogo from "../../assets/logo.png"
import { clearAccount, clearPlaces, removeCredentials, removeEmail } from "../../actions"
import { firebaseApp } from "../../firebase"

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

const moment = require("moment-timezone")

class CouponLibraryContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeCoupons: [],
            inactiveCoupons: [],
            expiredCoupons: [],

            dialog: null,

            redirectParams: null,
            location: props.location,
            loading: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""                
            },
        }
    }
    
    componentWillMount = () => {
        this._getCoupons(this._processCouponData)
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {}

    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            } 
        })
    }

    _networkSnackMessage = (networkError) => {
        const message = networkError.response === undefined ? networkError.message : networkError.response.data.error || networkError.response.data.exception
        const snackMessage = {
            level: StatusSnackbarTypes.ERROR,
            message: `Network: ${message}`
        }
        this.setState({loading: false, snackMessage})
    }

    _processCouponData = (coupons) => {
        const utcNow = moment().tz("UTC").startOf("day")
        let activeCoupons = [], inactiveCoupons = [], expiredCoupons = []

        expiredCoupons = coupons.filter(coupon => moment(coupon.expires).tz("UTC") < utcNow)
        activeCoupons = coupons.filter(coupon => coupon.active && moment(coupon.expires).tz("UTC") >= utcNow)
        inactiveCoupons = coupons.filter(coupon => !coupon.active && moment(coupon.expires).tz("UTC") >= utcNow)

        this.setState({ activeCoupons, inactiveCoupons, expiredCoupons})
    }

    /** event listeners */

    _onClickCreateCoupon = () => this.setState({dialog: <CouponConfigDialog onClickClose={this._onClickCloseDialog}/>})

    _onClickCloseDialog = () => this.setState({dialog: null})

    _onClickViewDetails = (couponId) => {
        this._getCouponDetails(couponId, (coupon) => {
            this.setState({
                dialog: <CouponDetailsDialog coupon={coupon} onClickClose={this._onClickCloseDialog}/>
            })
        })
    }

    _onClickDeleteCoupon = (couponId) => {
        this._deleteCoupon(couponId, () => {
            let { activeCoupons, inactiveCoupons, expiredCoupons } = this.state
            activeCoupons = activeCoupons.filter(coupon => coupon.id !== couponId)
            inactiveCoupons = inactiveCoupons.filter(coupon => coupon.id !== couponId)
            expiredCoupons = expiredCoupons.filter(coupon => coupon.id !== couponId)

            this._processCouponData([
                ...activeCoupons,
                ...inactiveCoupons,
                ...expiredCoupons
            ])
        })
    }

    _onChangeActiveStatus = (couponId, active) => {
        this._updateCouponActiveStatus(couponId, active, () => {
            let { activeCoupons, inactiveCoupons, expiredCoupons } = this.state
            activeCoupons = activeCoupons.map(coupon => {
                if (coupon.id === couponId)
                    coupon.active = active
                return coupon
            })

            inactiveCoupons = inactiveCoupons.map(coupon => {
                if (coupon.id === couponId)
                    coupon.active = active
                return coupon
            })

            expiredCoupons = expiredCoupons.map(coupon => {
                if (coupon.id === couponId)
                    coupon.active = active
                return coupon
            })

            this._processCouponData([
                ...activeCoupons,
                ...inactiveCoupons,
                ...expiredCoupons
            ])
        })
    }

    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            const snackMessage = {
                level: StatusSnackbarTypes.ERROR,
                message: error
            }
            this.setState({loading: false, snackMessage})
        })
    }    

    /** END event listeners */

    /** network requests */

    _getCoupons = (callback=(coupons)=>{}) => {
        this.setState({loading: true})
        const api = new CouponAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        api.requestGetCoupons(
            (success) => {
                this.setState({
                    loading: false
                }, () => callback(success.data))
            },
            (error) => this._networkSnackMessage(error)
        )

    }

    _deleteCoupon = (couponId, callback=()=>{}) => {
        this.setState({loading: true})
        const api = new CouponAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestDeleteCoupon(couponId,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: "Coupon permanently deleted.",
                        level: StatusSnackbarTypes.INFO
                    }
                }, callback)
            },
            (error) => this._networkSnackMessage(error)
        )
    }

    _getCouponDetails = (couponId, callback = (coupon) => {}) => {
        this.setState({loading: true})
        const api = new CouponAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        api.requestCouponDetails(couponId,
            (success) => {
                this.setState({ loading: false }, () => callback(success.data))
            },
            (error) => this._networkSnackMessage(error))
    }

    _updateCouponActiveStatus = (couponId, active, callback = () => {}) => {
        this.setState({loading: true})
        const api = new CouponAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)        

        api.requestUpdateActiveStatus(couponId, active,
            (success) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: "Coupon updated.",
                        level: StatusSnackbarTypes.SUCCESS
                    }
                }, callback)
            },
            (error) => this._networkSnackMessage(error))
    }
    /** END network requests */


    _renderDialog = () => {
        return this.state.dialog
    }


    render = () => {
        
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.state.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItem}
                    onClickLogoutCallback={this._onClickDrawerLogout}/>
                <main style={{width: "99vw"}}>
                    <CouponLibrary 
                        classes={this.props.classes} 
                        state={this.state}
                        onClickAddCoupon={this._onClickCreateCoupon}
                        onClickDeleteCoupon={this._onClickDeleteCoupon}
                        onClickViewDetails={this._onClickViewDetails}
                        onChangeActiveStatus={this._onChangeActiveStatus}/>
                </main>
                { this._renderDialog() }
                <LoadingDialog show={this.state.loading} message="Loading..."/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                                 
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { credentials, server, account, airport } = state
    return { credentials, server, account, airport }
}

const mapActionCreators = {
    clearAccount,
    clearPlaces,
    removeCredentials,
    removeEmail,    
}


export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreators)
)(CouponLibraryContainer)