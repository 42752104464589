import PropTypes from "prop-types"
import React from "react"
import { FormattedNumber } from "react-intl"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"


import Spinner from "../../common/components/Spinner"
import { VerticalLabeledTextField } from "../../common/components/LabeledTextField"
import { CurrencyTextField } from "../../common/components/CurrencyField"

function _renderFlightDetails(props) {
    const { state } = props
    return (
        <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Flight Info</Typography>
                        <Grid container>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Airport"
                                    value={state.orderDetails.airport.name}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Flight Code"
                                    value={state.orderDetails.airport.flight_code}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Estimated Flight Time"
                                    value={state.orderDetails.airport.estimated_local}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Flight Type"
                                    value={state.orderDetails.airport.flight_type}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )    
}

function _renderDeliveryDetails(props) {
    const { state } = props


    const proxySid = state.orderDetails.order.proxy_sid ? state.orderDetails.order.proxy_sid : "N/A"
    const stripeCardId = state.orderDetails.order.stripe_card_id ? state.orderDetails.order.stripe_card_id : "N/A"
    const couponCode = state.orderDetails.order.coupon_code ? state.orderDetails.order.coupon_code : "N/A"

    return (
        <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Delivery Details</Typography>
                        <Grid container>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Drop-Off Point"
                                    value={
                                        state.orderDetails.delivery.terminal_name + ", Gate " +
                                        state.orderDetails.delivery.gate} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Typography variant="body2">{"Total Price (" + state.orderDetails.order.price.iso_4217 + ")"}</Typography>
                                <FormattedNumber
                                    value={ 1.0 * state.orderDetails.order.price.total_base_unit / 10 ** state.orderDetails.order.price.exponent}
                                    style={`currency`}
                                    currency={state.orderDetails.order.price.iso_4217} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Order State"
                                    value={state.orderDetails.order.state}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <VerticalLabeledTextField
                                    label="Applied Coupon Code"
                                    value={couponCode}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <VerticalLabeledTextField
                                    label="Stripe Card ID"
                                    value={stripeCardId}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <VerticalLabeledTextField
                                    label="Twilio Proxy SID"
                                    value={proxySid}/>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )    
}

function _renderOrderDetails(props) {
    const { state } = props
    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardMedia
                        style={{height: "200px", width: "auto"}}
                        image={state.orderDetails.user.selfie}/>
                    <CardContent style={{flex: "1 1 auto"}}>
                        <Typography variant="body2">
                            {"Customer: " + state.orderDetails.user.first_name + " " +
                            state.orderDetails.user.last_name}
                        </Typography>
                        <Typography variant="body1"
                                    component="a"
                                    href={state.orderDetails.user.selfie}
                                    target="_blank"
                                    color="primary">
                            View Larger
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardMedia
                        style={{height: "200px", width: "auto"}}
                        image={state.orderDetails.order.place_picture_url}/>
                    <CardContent style={{flex: "1 1 auto"}}>
                        <Typography variant="body2">
                            {"Vendor: " + state.orderDetails.order.place}
                        </Typography>
                        <Typography variant="body1"
                                    component="a"
                                    href={state.orderDetails.order.place_picture_url}
                                    target="_blank"
                                    color="primary">
                            View Larger
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardMedia
                        style={{height: "200px", width: "auto"}}
                        image={state.orderDetails.fox.picture_url}/>
                    <CardContent style={{flex: "1 1 auto"}}>
                        <Typography variant="body2">
                            {"Fox: " + ((state.orderDetails.fox.first_name) || "Unassigned")}
                        </Typography>
                        <Typography variant="body1"
                                    component="a"
                                    href={state.orderDetails.fox.picture_url}
                                    target="_blank"
                                    color="primary">
                            View Larger
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )    
}

function _renderReceiptDetails(props) {
    const { state } = props
    const priceData = state.orderDetails.order.price
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h4">Receipt Details</Typography>
                        {
                            priceData.receipt_id ?
                                <Grid container justify="flex-start">
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1">Receipt Image</Typography>
                                        <Typography variant="body2"
                                            component="a"
                                            target="_blank" 
                                            href={priceData.receipt_url}
                                            color="primary">
                                            Temporary Link
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <VerticalLabeledTextField
                                            label="Receipt ID"
                                            value={ priceData.receipt_id }/>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <VerticalLabeledTextField
                                            label={ "Fox ID (" + state.orderDetails.fox.first_name  + ")" }
                                            value={ state.orderDetails.fox.id }/>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <CurrencyTextField
                                            label="Reported Receipt Total"
                                            value={ priceData.receipt_total_base_unit / 10**priceData.exponent}
                                            iso4217={ priceData.iso_4217 }/>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <CurrencyTextField
                                            label="Reported Receipt Tax"
                                            value={ priceData.receipt_tax_base_unit / 10**priceData.exponent}
                                            iso4217={ priceData.iso_4217 }/>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <CurrencyTextField
                                            label="Estimated Order Total"
                                            value={ priceData.total_base_unit / 10**priceData.exponent}
                                            iso4217={ priceData.iso_4217 }/>
                                    </Grid>
                                </Grid> :
                                <Typography variant="body1">No Receipt Data</Typography>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )    
}

function _renderSellableDetails(props) {
    const { state } = props
    return (
        <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h4">Ordered Items</Typography>
            </Grid>
            {
                state.orderDetails.contents.map((sellable, index) => {
                    return (
                        <Grid item xs={12} lg={12} key={index}>
                            <Card style={{display: "flex"}}>
                                <CardMedia
                                    style={{height:120, width: 160, flex: "0 0 auto"}}
                                    image={sellable.image_url}
                                    title={sellable.name}/>
                                <CardContent style={{flex: "1 1 auto"}}>
                                    <Typography variant="h6">{sellable.quantity + "x " + sellable.name}</Typography>
                                        {
                                            sellable.options && sellable.options.map((option, index) => {
                                                return (
                                                    <Typography variant="body1" key={index} style={{paddingLeft: "20px"}}>{option.quantity} {option.name}</Typography>
                                                )
                                            })
                                        }
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )    
}


export const OrderLookup = (props) => {
    const { state } = props

    if (state.isLoading){
        return (<Spinner label="Fetching order..." visible={true}/>)
    }
    else if(state.orderDetails === null) {
        return (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="body1">Enter an order to lookup.</Typography>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <div>
                { _renderFlightDetails(props) }
                { _renderDeliveryDetails(props) }
                { _renderOrderDetails(props) }
                { _renderReceiptDetails(props) }
                <Divider style={{marginTop: "20px", marginBottom: "20px"}}/>
                { _renderSellableDetails(props) }                    
            </div>
        )
    }
}

OrderLookup.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired
}

OrderLookup.defaultProps = {
}