import React, { Component } from "react"
import {FormattedNumber} from "react-intl"
import PropTypes from "prop-types"
import Image from "material-ui-image"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import EditIcon from "@material-ui/icons/Edit"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"

import { DelayedCircularButton } from "../../common/components/DelayedDelete"
import { cleanCut } from "../../common/utils"

class PlaceMenu extends Component {

    renderMenuCategory = (category) => {
        if(category.sellables.length === 0) {
            return (
                <Grid item xs={12}>
                    <Typography variant="body1">Add your first sellable to this section!</Typography>
                </Grid>
            )
        }
        else {
            return (
                category.sellables.map((sellable, sellableIdx) => {
                    return (
                        <Grid item xs={12} lg={6} xl={4} key={sellableIdx}>
                            <Card>
                                <CardMedia title={sellable.name}>
                                    <Image src={sellable.media_urls[0]} aspectRatio={1.5} style={{objectFit: "contain"}} />
                                </CardMedia>
                                <CardContent>
                                    <Grid container style={{ height: 100 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{sellable.name}</Typography>
                                            <Typography variant="caption" color="primary">{sellable.dietary_tags.join(", ")}</Typography>
                                            <Typography variant="body1">{cleanCut(sellable.description, 100)}</Typography>
                                            <FormattedNumber
                                                value={1.0 * sellable.price.base_unit / 10**sellable.price.exponent}
                                                style={`currency`}
                                                currency={sellable.price.iso_4217}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container style={{ height: 50 }} justify="flex-end">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={sellable.listed}
                                                        onChange={(event) => this.props.onToggleSellableListing(sellable.sellable_id, event.target.checked)}
                                                    />
                                                }
                                                label={<Typography variant="subtitle1">Listed</Typography>}/>
                                            <Button
                                                onClick={() => this.props.onClickManageSellable(sellable.sellable_id)}
                                                color="primary">Manage</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })
            )
        }
    }

    render = () => {

        const renderMenuNameButtons = (menu) => {
            return (
                <span>
                    <IconButton
                        aria-label="Edit Menu"
                        onClick={() => {this.props.onClickRenameMenu(menu.menu_id)}}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton
                        aria-label="Add Menu Section"
                        color={menu.categories.length === 0 ? "secondary": "default"}
                        onClick={() => this.props.onClickAddCategory()}>
                        <PlaylistAddIcon/>
                    </IconButton>
                </span>
            )
        }

        const renderCategoryNameButtons = (category) => {
            return (
                <IconButton
                    aria-label="Edit Category"
                    onClick={() => this.props.onClickEditCategory(
                        category.category_id,
                        category.category_name,
                        category.listed)}>
                    <EditIcon/>
                </IconButton>
            )
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs ={12}>{
                    this.props.menus.map((menu, idx) => {
                        return (
                            <Grid container key={idx} style={{marginBottom: "20px"}}>
                                <Grid item xs={10}>
                                    <Grid container justify="flex-start" alignItems="center">
                                        <Grid item>                                    
                                            <Hidden xsDown>
                                                <Typography variant="h3">{menu.menu_name}{renderMenuNameButtons(menu)}</Typography>
                                            </Hidden>
                                            <Hidden smUp>
                                                <Typography variant="h5">{menu.menu_name}{renderMenuNameButtons(menu)}</Typography>
                                            </Hidden>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{color: menu.listed ? "grey" : "red"}}>
                                                {menu.listed ? "LISTED" : "UNLISTED"}
                                            </Typography>
                                        </Grid>                                             
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container justify="flex-end" alignItems="center">
                                        <Grid item>
                                            <DelayedCircularButton
                                                onConfirmAction={() => this.props.onClickDeleteMenu(menu.menu_id)}/>
                                        </Grid>                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>{
                                    menu.categories.map((category, categoryIdx) => {
                                        return (
                                            <Grid container key={categoryIdx} style={{marginBottom: "20px"}}>
                                                <Grid item xs={10}>
                                                    <Grid container justify="flex-start" alignItems="center">
                                                        <Grid item>
                                                            <Hidden xsDown>
                                                                <Typography variant="h4">
                                                                    {category.category_name}
                                                                    {renderCategoryNameButtons(category)}
                                                                </Typography>
                                                            </Hidden>
                                                            <Hidden smUp>
                                                                <Typography variant="h5">
                                                                    {category.category_name}
                                                                    {renderCategoryNameButtons(category)}
                                                                </Typography>
                                                            </Hidden>                                                            
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" style={{color: category.listed ? "grey" : "red"}}>
                                                                {category.listed ? "LISTED" : "UNLISTED"}
                                                            </Typography>
                                                        </Grid>                                                                                                              
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Grid container justify="flex-end" alignItems="center">
                                                        <Grid item>
                                                            <DelayedCircularButton
                                                                onConfirmAction={() => this.props.onClickDeleteCategory(category.category_id)}/>
                                                        </Grid>                                                        
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>{
                                                        this.renderMenuCategory(category)
                                                    }</Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }</Grid>
                            </Grid>
                        )
                    })
                }</Grid>
            </Grid>
        )
    }
}

PlaceMenu.propTypes = {
    menus: PropTypes.array.isRequired,
    onClickRenameMenu: PropTypes.func,
    onClickDeleteMenu: PropTypes.func,
    onClickAddCategory: PropTypes.func,
    onClickEditCategory: PropTypes.func,
    onClickDeleteCategory: PropTypes.func,
    onClickManageSellable: PropTypes.func,
    onToggleSellableListing: PropTypes.func
}

PlaceMenu.defaultProps = {
    onClickRenameMenu: () => {},
    onClickDeleteMenu: (menuId) => {},
    onClickAddCategory: () => {},
    onClickEditCategory: (categoryId, sectionName, listed) => {},
    onClickDeleteCategory: (categoryId) => {},
    onClickManageSellable: (itemId) => {},
    onToggleSellableListing: (itemId, listed) => {}
}

export default PlaceMenu
