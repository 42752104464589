import compose from "recompose/compose"
import { withStyles } from "@material-ui/core/styles"

import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Snackbar from "@material-ui/core/Snackbar"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import { PEXCardAPI } from "../common/requests/airport/pex"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import LoadingDialog from "../common/components/LoadingDialog"
import ConfirmActionDialog from "../common/components/ConfirmActionDialog"
import { VerticalLabeledTextField } from "../common/components/LabeledTextField"
import NavDrawer from "../common/components/NavDrawer"
import { isEmptyString } from "../common/utils"
import FetchyFoxLogo from "../common/assets/logo.png"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import MaskedPasswordField from "../common/components/MaskedPasswordField"
import { removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"
import { firebaseApp } from "../../firebase"

const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ActivePaymentCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            foxes: [],
            filteredFoxes: [],
            searchTerm: "",

            // card assignment dialog
            showAssignmentDialog: false,
            assignmentEmail: "",
            assignmentLast4: "",
            assignmentUnique10: "",
            assignmentPassword: "",

            // confirm action dialog
            confirmDialog: false,
            confirmMessage: "",
            confirmValue: "",
            confirmSuccessCallback: () => {console.log("success")},

            dialogMessage: "",
            loading: false,
            redirectParams: null,
            location: props.location,
        }
    }

    componentWillMount = () => {
        this._getActivePEXCards()
        this._resetErrorMessages()
    }

    _resetErrorMessages = () => {
        this.setState({
            assignmentEmailErrorText: "",
            assignmentLast4ErrorText: "",
            assignmentUnique10ErrorText: "",
            assignmentPasswordErrorText: "",
            snackMessage: "",
        })
    }

    /** UI event listeners  */
    _onSnackClose = () => this.setState({snackMessage: ""})

    _onDialogClose = () => this.setState({dialogMessage: "", showAssignmentDialog: false, confirmDialog: false})

    _onClickRefresh = () => {
        this.setState({searchTerm: ""})
        this._getActivePEXCards()
    }

    _onTypeForm = (id, value) => this.setState({[id]: value})

    _onTypeSearch = (searchTerm) => {
        // filters the list of fox cards by matching search terms with fox name or email
        if(isEmptyString(searchTerm)) {
            this.setState({filteredFoxes: this.state.foxes, searchTerm})
        }
        else {
            searchTerm = searchTerm.toLowerCase()
            let filteredFoxes = this.state.foxes.filter(fox => {
                return fox.fox_name.toLowerCase().includes(searchTerm) || fox.fox_email.toLowerCase().includes(searchTerm)
            })
            this.setState({filteredFoxes, searchTerm})
        }
    }

    _onClickAssignCard = () => {
        this.setState({showAssignmentDialog: true})
    }

    _onClickConfirmAssignCard = () => {
        this._resetErrorMessages()
        if(isEmptyString(this.state.assignmentEmail))
            this.setState({assignmentEmailErrorText: "Required"})
        else if(isEmptyString(this.state.assignmentLast4))
            this.setState({assignmentLast4ErrorText: "Required"})
        else if(isEmptyString(this.state.assignmentUnique10))
            this.setState({assignmentUnique10ErrorText: "Required"})
        else if(isEmptyString(this.state.assignmentPassword))
            this.setState({assignmentPasswordErrorText: "Required"})
        else
            this._checkAdminCredentials(
                () => {
                    this.setState({assignmentPassword: ""})
                    this._assignPexCard(
                        () => {
                            this.setState({
                                showAssignmentDialog: false,
                                assignmentEmail: "",
                                assignmentLast4: "",
                                assignmentUnique10: "",
                                assignmentPassword: "",
                            })
                            this._getActivePEXCards()
                        }
                    )
                },
                () => {this.setState({assignmentPassword: ""})}
            )
    }

    _onClickAddFunds = (foxId, pexAccountId) => {
        this.setState({
            confirmDialog: true,
            confirmMessage: "Please type in the following value to confirm FUNDING the card:",
            confirmValue: pexAccountId,
            confirmSuccessCallback: () => this._fundPexCard(foxId, pexAccountId)
        })
    }

    _onClickZeroOut = (foxId, pexAccountId) => {
        this.setState({
            confirmDialog: true,
            confirmMessage: "Please type in the following value to confirm ZEROING the card:",
            confirmValue: pexAccountId,
            confirmSuccessCallback: () => this._zeroPexCard(foxId, pexAccountId)
        })
    }

    _onClickBlock = (foxId, pexAccountId) => {
        this.setState({
            confirmDialog: true,
            confirmMessage: "Please type in the following value to confirm BLOCKING the card:",
            confirmValue: pexAccountId,
            confirmSuccessCallback: () => this._blockPexCard(foxId, pexAccountId)
        })
    }

    _onClickReactivate = (foxId, pexAccountId) => {
        this.setState({
            confirmDialog: true,
            confirmMessage: "Please type in the following value to confirm REACTIVATING the card:",
            confirmValue: pexAccountId,
            confirmSuccessCallback: () => this._reactivatePexCard(foxId, pexAccountId)
        })
    }

    _onClickRevoke = (foxId, pexAccountId) => {
        this.setState({
            confirmDialog: true,
            confirmMessage: "Please type in the following value to confirm REVOKING the card:",
            confirmValue: pexAccountId,
            confirmSuccessCallback: () => this._revokePexCard(foxId, pexAccountId)
        })
    }

    /** end UI event listeners */

    /** drawer event handlers */
    _onClickDrawerItemHandler = (redirectParams) => this.setState({redirectParams})

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: error})
        })
    }
    /** END drawer event handlers */


    /** network request */
    _checkAdminCredentials = (onSuccess = () => {}, onError = () => {}) => {
        this.setState({loading: true})
        firebaseApp.auth().signInWithEmailAndPassword(this.props.credentials.username, this.state.assignmentPassword)
            .then(success => {
                this.setState({loading: false}, onSuccess)
            })
            .catch(error => {
                this.setState({assignmentPasswordErrorText: "Invalid Password", loading: false}, onError)
            })
    }

    _assignPexCard = (onSuccess = () => {}, onError = () => {}) => {
        this.setState({loading: true})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestAssignCard(this.props.credentials.username, this.state.assignmentEmail, this.state.assignmentLast4,
            this.state.assignmentUnique10, this.props.airport.iataCode,
            (success) => {
                this.setState({loading: false, dialogMessage: "This card has been assigned."}, onSuccess)
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message}, onError)
            })
    }


    _getActivePEXCards = () => {
        this.setState({loading: true})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestActiveCards(
            (success) => {
                const foxes = success.data
                this.setState({loading: false, foxes, filteredFoxes: foxes})
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            }
        )
    }

    _fundPexCard = (foxId, pexAccountId) => {
        this.setState({loading: true, confirmDialog: false})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestFundCard(this.props.credentials.username, foxId, pexAccountId,
            (success) => {
                const idx = this.state.foxes.findIndex((fox, index) => {
                    return fox.fox_id === foxId
                })
                let { foxes } = this.state
                foxes[idx].balance_usd = success.data.balance_usd
                this.setState({foxes, loading: false, dialogMessage: "Funds successfully added."})
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            })
    }

    _zeroPexCard = (foxId, pexAccountId) => {
        this.setState({loading: true, confirmDialog: false})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestZeroCard(this.props.credentials.username, foxId, pexAccountId,
            (success) => {
                const idx = this.state.foxes.findIndex((fox, index) => {
                    return fox.fox_id === foxId
                })
                let { foxes } = this.state
                foxes[idx].balance_usd = success.data.balance_usd
                this.setState({foxes, loading: false, dialogMessage: "All funds removed from card."})
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            })
    }

    _blockPexCard = (foxId, pexAccountId) => {
        this.setState({loading: true, confirmDialog: false})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestBlockCard(this.props.credentials.username, foxId, pexAccountId,
            (success) => {
                const idx = this.state.foxes.findIndex((fox, index) => {
                    return fox.fox_id === foxId
                })
                let { foxes } = this.state
                foxes[idx].card_state = "Blocked"
                this.setState({dialogMessage: "This card is now BLOCKED and can't be used to make purchases.", loading: false})
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            })
    }

    _reactivatePexCard = (foxId, pexAccountId) => {
        this.setState({loading: true, confirmDialog: false})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestReactivateCard(this.props.credentials.username, foxId, pexAccountId,
            (success) => {
                const idx = this.state.foxes.findIndex((fox, index) => {
                    return fox.fox_id === foxId
                })
                let { foxes } = this.state
                foxes[idx].card_state = "Active"
                this.setState({dialogMessage: "This card is reactivated and ready for use.", loading: false})
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            })
    }

    _revokePexCard = (foxId, pexAccountId) => {
        this.setState({loading: true, confirmDialog: false})
        const api = new PEXCardAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestRevokeCard(this.props.credentials.username, foxId, pexAccountId,
            (success) => {
                const foxes = this.state.foxes.filter((fox, index) => {
                    return fox.fox_id !== foxId
                })
                this.setState({foxes, loading: false, dialogMessage: "This card is now BLOCKED and REVOKED from the Fox's account."}, this._getActivePEXCards)
            },
            (error) => {
                const message = error.responseCode + ": " + error.message
                this.setState({loading: false, snackMessage: message})
            })
    }

    /** END network requests */

    _renderCardAssignmentDialog = () => {
        if(this.state.showAssignmentDialog) {
            return (
                <Dialog
                    open={this.state.showAssignmentDialog}
                    onClose={this._onDialogClose}>
                    <DialogTitle>PEX Card Assignment</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            A Fox with an existing card can't be assigned a new card until the existing card is revoked. Assigning a
                            card here also activates it for use immediately.
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    id="assignmentEmail"
                                    label="Fox Account Email"
                                    placeholder=""
                                    value={this.state.assignmentEmail}
                                    error={!isEmptyString(this.state.assignmentEmailErrorText)}
                                    helperText={this.state.assignmentEmailErrorText}
                                    onChange={(event) => { this._onTypeForm(event.target.id, event.target.value)}}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="assignmentLast4"
                                    label="Last 4 Digits"
                                    placeholder="1234"
                                    value={this.state.assignmentLast4}
                                    error={!isEmptyString(this.state.assignmentLast4ErrorText)}
                                    helperText={this.state.assignmentLast4ErrorText}
                                    onChange={(event) => { this._onTypeForm(event.target.id, event.target.value)}}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="assignmentUnique10"
                                    label="Unique ID"
                                    placeholder="123-456-7890"
                                    value={this.state.assignmentUnique10}
                                    error={!isEmptyString(this.state.assignmentUnique10ErrorText)}
                                    helperText={this.state.assignmentUnique10ErrorText}
                                    onChange={(event) => { this._onTypeForm(event.target.id, event.target.value)}}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{height: 20}}/>
                                <Typography variant="body2">Retype your admin password.</Typography>
                                <MaskedPasswordField
                                    id="assignmentPassword"
                                    variant="standard"
                                    label="Admin Password"
                                    value={this.state.assignmentPassword}
                                    onTypePassword={(value) => this._onTypeForm("assignmentPassword", value)}
                                    errorText={this.state.assignmentPasswordErrorText}
                                    fullWidth/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this._onClickConfirmAssignCard()}>Assign Card</Button>
                        <Button onClick={() => this._onDialogClose()}>Close</Button>
                    </DialogActions>
                </Dialog>
            )
        }
        else
            return <div/>
    }

    _renderFoxes = () => {
        if (this.state.foxes.length === 0) {
            return <div/>
        }
        else {
            return (
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <Typography variant="h6">{`${this.state.foxes.length} foxes with active cards.`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            value={this.state.searchTerm}
                            placeholder="Search Foxes..."
                            onChange={event => {this._onTypeSearch(event.target.value)}}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Button
                            onClick={ this._onClickRefresh }
                            variant="contained">Refresh</Button>
                    </Grid>
                    {
                    this.state.filteredFoxes.map((fox, idx) => {
                        return (
                            <Grid item xs={12} key={idx}>
                                <Card>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={6} md={4}>
                                                <VerticalLabeledTextField label="Name" value={fox.fox_name}/>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <VerticalLabeledTextField label="Card Status" value={fox.card_state}/>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <VerticalLabeledTextField label="Balance (USD)" value={`$${fox.balance_usd}`}/>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <VerticalLabeledTextField label="Contact Email" value={fox.fox_email}/>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <VerticalLabeledTextField label="Fox ID" value={fox.fox_id}/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => this._onClickAddFunds(fox.fox_id, fox.pex_account_id)}>Add $10</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => this._onClickZeroOut(fox.fox_id, fox.pex_account_id)}>Zero Out</Button>
                                            </Grid>
                                            {
                                                fox.card_state === "Active" ?
                                                    <Grid item>
                                                        <Button
                                                            className={this.props.classes.criticalButton}
                                                            variant="contained"
                                                            onClick={() => this._onClickBlock(fox.fox_id, fox.pex_account_id)}>Block</Button>
                                                    </Grid> :
                                                    <Grid item>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => this._onClickReactivate(fox.fox_id, fox.pex_account_id)}>Reactivate</Button>
                                                    </Grid>
                                            }
                                            <Grid item>
                                                <Button
                                                    className={this.props.classes.criticalButton}
                                                    variant="contained"
                                                    onClick={() => this._onClickRevoke(fox.fox_id, fox.pex_account_id)}>Revoke</Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })
                }</Grid>
            )
        }
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        const { classes } = this.props
        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItemHandler}
                    onClickLogoutCallback={this._onClickLogoutHandler}/>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                     
                <main style={{width: "99vw"}}>
                    <div className={classes.spacerTop}/>
                    <Grid container justify="center">
                        <Grid item xs={11} md={10}>
                            <Grid container justify="flex-start">
                                <Grid item xs={12}>
                                    <Typography variant="h6">Active PEX Cards</Typography>
                                    <Typography variant="body1">
                                        Manage all PEX cards being used by your Fleet. All actions performed
                                        here will be logged in our system with the account
                                        <Typography component="span" className={classes.highlightText} variant="body1">
                                            {` ${this.props.credentials.username}`}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this._onClickAssignCard()}>Assign New Card</Button>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                            </Grid>
                            { this._renderFoxes() }
                            { this._renderCardAssignmentDialog() }
                        </Grid>
                    </Grid>
                    <ConfirmActionDialog
                        open={this.state.confirmDialog}
                        message={this.state.confirmMessage}
                        confirmValue={this.state.confirmValue}
                        onSuccessCallback={this.state.confirmSuccessCallback}
                        onCloseCallback={ () => { this.setState({confirmDialog: false})} }/>
                    <Dialog
                        open={!isEmptyString(this.state.dialogMessage)}
                        onClose={this._onDialogClose}>
                        <DialogContent>
                            <Typography variant="body1">
                                {this.state.dialogMessage}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={() => this._onDialogClose()}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        autoHideDuration={5000}
                        message={this.state.snackMessage}
                        open={!isEmptyString(this.state.snackMessage)}
                        onClose={this._onSnackClose}
                        action={<Button onClick={() => this._onSnackClose()} color="secondary">Close</Button>} />
                    <LoadingDialog show={this.state.loading} message="Updating Card Info..."/>
                </main>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, account, airport } = state
    return { server, credentials, account, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(ActivePaymentCards)
