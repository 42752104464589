import { SET_DASHBOARD } from "../constants"

export default (state = {}, action) => {
    switch(action.type) {
        case SET_DASHBOARD:
            const { airportId, terminalId } = action
            return { airportId, terminalId }
        default:
            return state
    }
}
