import { SET_ACCOUNT_TYPE, SET_NAME, SET_DRAWER_MENU, CLEAR_ACCOUNT } from "../constants"

export default (state = {}, action) => {
    switch(action.type) {
        case SET_ACCOUNT_TYPE:
            const { accountType } = action
            return {
                ...state,
                accountType,
            }
        case SET_NAME:
            const { firstName, lastName } = action
            return {
                ...state,
                firstName,
                lastName
            }
        case SET_DRAWER_MENU:
            const { menuItems } = action
            return {
                ...state,
                menuItems
            }
        case CLEAR_ACCOUNT:
            return {}
        default:
            return state
    }
}
