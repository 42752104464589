import React from 'react'
import HomeIcon from "@material-ui/icons/Home"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import StoreIcon from "@material-ui/icons/Store"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import SmartPhoneIcon from "@material-ui/icons/Smartphone"
import BankIcon from "@material-ui/icons/AccountBalance"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import ActiveIcon from "@material-ui/icons/TransferWithinAStation"
import ReceiptIcon from "@material-ui/icons/Receipt"
import ListIcon from "@material-ui/icons/List"
import AssessmentIcon from "@material-ui/icons/Assessment"
import StarsIcon from "@material-ui/icons/Stars"
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import CouponIcon from "@material-ui/icons/LocalOffer"

// account actions
export const CREATE_ACCOUNT = "create"
export const LOGIN_ACCOUNT = "login"

// fetchyfox account types
export const FOX_ACCOUNT = "fox"
export const MERCHANT_ACCOUNT = "merchant"
export const AIRPORT_ACCOUNT = "admin"

// modes determining how a page behaves when "back" or "next" are selected
export const UPDATE_VIEW = "update_view"
export const CREATE_VIEW = "create_view"

export const DAILY_DATA = 1, HOURLY_DATA = 2


// the side menu
export function drawerItems(role, iso3166) {
    const pages = {
        [FOX_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: {pathname: "/dashboard"},
                listed: true,
            },
            {
                label: "Profile Info",
                icon: <AccountCircleIcon/>,
                redirectParams: {pathname: "/fox/basic", viewMode: UPDATE_VIEW},
                listed: true,
            },
            {
                label: "Mobile Number",
                icon: <SmartPhoneIcon />,
                redirectParams: {pathname: "/fox/mobile", viewMode: UPDATE_VIEW},
                listed: true,
            },
            {
                label: "Stripe Info",
                icon: <BankIcon/>,
                redirectParams: {pathname: "/fox/platform", viewMode: UPDATE_VIEW},
                listed: iso3166 === "US",
            },
            {
                label: "Payment Card",
                icon: <CreditCardIcon />,
                redirectParams: {pathname: "/fox/prepaid"},
                listed: true,
            }
        ],
        [MERCHANT_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: {pathname: "/dashboard"},
                listed: true,
            },
            {
                label: "Manage Listings",
                icon: <StoreIcon/>,
                redirectParams: {pathname: "/listings"},
                listed: true,
            },  
            {
                label: "Product Library",
                icon: <LibraryBooksIcon/>,
                redirectParams: {pathname: "/library"},
                listed: true,
            },
            {
                label: "Manage Coupons",
                icon: <CouponIcon/>,
                redirectParams: {pathname: "/merchant/coupons"},
                listed: true,
            },
            {
                label: "Update Profile",
                icon: <AccountCircleIcon/>,
                redirectParams: {pathname: "/merchant/basic", viewMode: UPDATE_VIEW},
                listed: true,
            },
            {
                label: "Configure Payouts",
                icon: <BankIcon/>,
                redirectParams: {pathname: "/merchant/platform"},
                listed: iso3166 === "US",
            }
        ],
        [AIRPORT_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: {pathname: "/dashboard"},
                listed: true,
            },
            {
                label: "Fox Performance",
                icon: <AssessmentIcon/>,
                redirectParams: {pathname: "/airport/fleet"},
                listed: true,
            },
            {
                label: "Reviews",
                icon: <StarsIcon />,
                redirectParams: {pathname: "/airport/reviews"},
                listed: true,
            },
            {
                label: "Airport Listings",
                icon: <ListIcon />,
                redirectParams: {pathname: "/airport/listings"},
                listed: true,
            },
            {
                label: "Payment Cards",
                icon: <CreditCardIcon />,
                redirectParams: {pathname: "/activecards"},
                listed: true,
            },
            {
                label: "Fox Applicants",
                icon: <DirectionsRunIcon />,
                redirectParams: {pathname: `/applicants/${FOX_ACCOUNT}`},
                listed: true,
            },
            {
                label: "Merchant Applicants",
                icon: <StoreIcon />,
                redirectParams: {pathname: `/applicants/${MERCHANT_ACCOUNT}`},
                listed: true,
            },
            {
                label: "Active Deliveries",
                icon: <ActiveIcon />,
                redirectParams: {pathname: "/activedeliveries"},
                listed: true,
            },
            {
                label: "Order Lookup",
                icon: <ReceiptIcon />,
                redirectParams: {pathname: "/lookup"},
                listed: true,
            },
        ]
    }
    let items = pages[role].filter(item => { return item.listed })
    return items
}
