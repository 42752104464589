/*
    A pop up dialog that asks the user to enter in a value before an
    action is performed. An example is asking an admin to retype their email
    address before allowing them to delete a user. Copy and pasting is not
    allowed by this component.
*/
import PropTypes from "prop-types"
import React, { Component } from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import { isEmptyString } from "../utils"

class ConfirmActionDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            errorText: ""
        }
    }

    _onClickCancel = () => {
        this.setState({value: "", errorText: ""})
        this.props.onCloseCallback()
    }

    _onClickConfirm = () => {
        if (this._validateInput()) {
            this.setState({value: "", errorText: ""})
            this.props.onSuccessCallback()
        }
        else {
            this.setState({errorText: "Try again."})
        }
    }

    _onChangeValue = (value) => {
        // the value can't change in length by more than 1. this prevents pasting the
        // confirmation value. the only bulk change allowed is clearing the value
        if (Math.abs(value.length - this.state.value.length) === 1 || value.length === 0) {
            this.setState({value, errorText: ""})
        }
    }

    _validateInput = () => {
        return this.state.value === this.props.confirmValue
    }

    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCloseCallback}
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{ this.props.message }</Typography>
                    <Typography variant="h5">{ this.props.confirmValue }</Typography>
                    <div style={{height: 20}}/>
                    <TextField
                        autoFocus
                        fullWidth
                        label="Confirm Value"
                        error={ !isEmptyString(this.state.errorText)}
                        helperText={this.state.errorText}
                        value={this.state.value}
                        type="text"
                        onChange={(event) => this._onChangeValue(event.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ this._onClickCancel }>Cancel</Button>
                    <Button color="primary" onClick={ this._onClickConfirm }>Confirm</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ConfirmActionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    confirmValue: PropTypes.string.isRequired,
    onSuccessCallback: PropTypes.func.isRequired,
    onCloseCallback: PropTypes.func
}

ConfirmActionDialog.defaultProps = {
    onCloseCallback: () => { console.log("verification failed")}
}

export default ConfirmActionDialog
