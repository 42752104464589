import axios from "axios"

export class LegalDocsAPI {
    // all the docs are hosted on S3 as TXT files. these methods return strings

    requestTermsOfAgreementText = (onRequestSuccess, onRequestError) => {
        axios({
            url: "https://s3-eu-west-1.amazonaws.com/fetchy-public-eu/terms.txt",
            method: "GET"
        }).then(response => {
            onRequestSuccess(response.data)
        }).catch(error => {
            onRequestError(error.response)
        })
    }

    requestPrivacyPolicyText = (onRequestSuccess, onRequestError) => {
        axios({
            url: "https://s3-eu-west-1.amazonaws.com/fetchy-public-eu/privacy.txt",
            method: "GET"
        }).then(response => {
            onRequestSuccess(response.data)
        }).catch(error => {
            onRequestError(error.response)
        })
    }

    requestLoremIpsumText = (onRequestSuccess, onRequestError) => {
        axios({
            url: "https://s3-eu-west-1.amazonaws.com/fetchy-public-eu/lorem.txt",
            method: "GET"
        }).then(response => {
            onRequestSuccess(response.data)
        }).catch(error => {
            onRequestError(error.response)
        })
    }
}
