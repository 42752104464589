import * as d3 from "d3"
import { findCurrency } from "currency-formatter"
import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { isEmptyString } from "../common/utils"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import { firebaseApp } from "../../firebase"
import FetchyFoxLogo from  "../../assets/logo.png"
import { FoxPerformance } from "./views/FoxPerformance"

import { clearPlaces, removeCredentials, removeEmail, clearAccount } from "../../actions"
import { FleetAPI } from "../common/requests/airport/fleet"
import { InsightsAPI } from "../common/requests/airport/insights"

const moment = require("moment-timezone")

const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    },
    margin: {
        margin: theme.spacing(1)
    }
})

class FoxPerformanceContainer extends Component {
    constructor(props) {
        super(props)
        const currentYear = new Date().getUTCFullYear()

        this.state = {
            
            activeFoxes: [],
            filteredFoxes: [],
            selectedFox: null,
            selectedHeatmapDate: null,
            
            rawPerformanceData: [],
            rawHeatmapData: [],

            summaryData: {},
            ratingDistribution: [],
            deliveryTimeDistribution: [],
            deliveryDayDistribution: [],

            iso4217: props.airport.iso4217,
            loading: false,
            dataLoading: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },
            redirectParams: null,
            drawerItems: props.account.menuItems,
            location: props.location,

            startHistoricalRange: moment.tz(`${currentYear}-01-01`, "UTC"),
            endHistoricalRange: moment.tz(`${currentYear}-12-31`, "UTC"),

            // heatmap values
            startHeatmapRange: moment.tz(`${currentYear}-01-01`,  "UTC"), 
            endHeatmapRange: moment.tz(`${currentYear}-12-31`,  "UTC"),

            credentials: props.credentials,
            account: props.account,
            airport: props.airport             
        }
    }

    componentDidMount = () => {
        this._getActiveFoxes((data) => {
            // convert to client timezone
            data.map((fox) => {
                let lastActive = moment.tz(fox.last_active, "UTC")
                let lastLogin = moment.tz(fox.last_login, "UTC")
                
                fox.last_active = lastActive.clone().tz(this.props.airport.timezone)
                fox.last_login = lastLogin.clone().tz(this.props.airport.timezone)
                return 1
            })

            this.setState({
                activeFoxes: data,
                filteredFoxes: data,
            })
        })
    }

    _resetHeatmapRange = () => {
        const year = new Date().getUTCFullYear()
        this.setState({
            startHeatmapRange: moment.tz(`${year}-01-01`,  "UTC"),
            endHeatmapRange: moment.tz(`${year}-12-31`,  "UTC")         
        })
    }

    _resetDatePicker = () => {
        const year = new Date().getUTCFullYear()
        this.setState({
            startHistoricalRange: moment.tz(`${year}-01-01`, "UTC"),
            endHistoricalRange: moment.tz(`${year}-12-31`, "UTC")
        })
    }

    /** event listeners */

    _onCloseSnack = () => this.setState({snackMessage: {
        message: "",
        level: StatusSnackbarTypes.STANDARD
    }})

    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: {
                message: error,
                level: StatusSnackbarTypes.ERROR
            }})
        })
    }

    _onTypeFoxSearch = (value) => {
        const filteredFoxes = this.state.activeFoxes.filter(row => {
            return isEmptyString(value) || 
                `${row.first_name.toLowerCase()} ${row.last_name.toLowerCase()}`.includes(value.toLowerCase()) ||
                row.email.toLowerCase().includes(value.toLowerCase())
        })
        this.setState({filteredFoxes})
    }

    _onCloseHeatmapDialog = () => {
        this.setState({selectedHeatmapDate: null})
    }

    _onClickHeatmapDate = (date) => this.setState({selectedHeatmapDate: date})    

    _onClickUpdateHeatmapRange = (startHeatmapRange, endHeatmapRange) => {
        let startDate = startHeatmapRange.tz("UTC").format("YYYY-MM-DD"),
            endDate = endHeatmapRange.tz("UTC").format("YYYY-MM-DD")
        this.setState({startHeatmapRange, endHeatmapRange}, () => { 
            this._getHeatmapData(this.state.selectedFox.fox_id, startDate, endDate)
        })
    }

    _onClickUpdateDateRange = (startHistoricalRange, endHistoricalRange) => {
        let startDate = startHistoricalRange.tz("UTC").format("YYYY-MM-DD"),
            endDate = endHistoricalRange.tz("UTC").format("YYYY-MM-DD")
        this.setState({startHistoricalRange, endHistoricalRange, showDateRangePicker: false}, () => {
            this._getFoxPerformance(this.state.selectedFox.fox_id, startDate, endDate)
        })
    }

    _onClickFox = (foxId) => {
        this._resetDatePicker()
        this._resetHeatmapRange()

        let selectedFox = this.state.activeFoxes.filter(row => row.fox_id === foxId)
        if(selectedFox.length === 1) {
            selectedFox = selectedFox[0]
            this.setState({selectedFox})
            const year = new Date().getUTCFullYear()
            this._getFoxPerformance(foxId, `${year}-01-01`, `${year}-12-31`)
            this._getHeatmapData(foxId, `${year}-01-01`, `${year}-12-31`)
        }
        else {
            this.setState({snackMessage: {
                message: "Fox data not found.",
                level: StatusSnackbarTypes.ERROR
            } })
        }
    }

    /* -- END event listeners */

    /** network requests */

    _getActiveFoxes = (successCalback=()=>{}) => {
        this.setState({loading: true})
        const api = new FleetAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestGetActiveFoxes(
            (success) => {
                const activeFoxes = success.data
                this.setState({activeFoxes, loading: false}, 
                    () => successCalback(success.data))
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                    
                this.setState({loading: false, snackMessage})
            }
        )
    }

    _getHeatmapData = (foxId, startDate, endDate) => {
        this.setState({loading: true})
        const api = new InsightsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestFoxPerformanceData(foxId, startDate, endDate,
            (success) => {
                const rawHeatmapData = success.data
                this.setState({loading: false, rawHeatmapData}, this._processHeatmapData)
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                    
                this.setState({loading: false, snackMessage})         
            })
    }

    _getFoxPerformance = (foxId, startDate, endDate) => {
        this.setState({loading: true})
        const api = new InsightsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestFoxPerformanceData(foxId, startDate, endDate,
            (success) => {
                const rawPerformanceData = success.data
                this.setState({loading: false, rawPerformanceData}, this._processPeformanceData)
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }                    
                this.setState({loading: false, snackMessage})             
            })
    }

    /* -- END network requests */
    _processHeatmapData = () => {
        let deliveryDayDistribution = d3.nest()
            .key(row => { return row.order_date_utc })
            .rollup(rows => {
                return {
                    nOrders: rows.length || 0,
                    nReviews: d3.sum(rows, row => { return row.numeric_rating !== null ? 1 : 0})
                }
            })
            .entries(this.state.rawHeatmapData)

        deliveryDayDistribution = deliveryDayDistribution.map(row => {
            return {
                date: row.key,
                orders: row.value.nOrders,
                reviews: row.value.nReviews
            }
        })

        this.setState({deliveryDayDistribution})
    }


    _processPeformanceData = () => {
        // transform, split the data into the expected datasets for the 
        // performance dashboard
        const currency = findCurrency(this.props.airport.iso4217)


        const summaryData = d3.nest()
            .rollup(rows => {
                return {
                    deliveries: rows.length || 0,
                    totalSales: d3.sum(rows, row => { return row.total_base_unit / 10.0**currency.decimalDigits }),
                    ratings: d3.sum(rows, row => { return row.numeric_rating !== null ? 1 : 0} ),
                    cashSales: d3.sum(rows, row => { return row.payment_method === "CASH" ? row.total_base_unit / 10.0**currency.decimalDigits : 0} ),
                    avgDelverySeconds: d3.mean(rows, row => { return row.delivery_duration_sec}),
                    avgRating: d3.mean(rows, row => { return row.numeric_rating })
                }
            })
            .entries(this.state.rawPerformanceData)
        
        const ratingData = this.state.rawPerformanceData.filter(row => row.numeric_rating !== null)
        let ratingDistribution = d3.nest()
            .key(row => { return row.numeric_rating})
            .rollup(rows => {
                return {
                    label: "" + d3.max(rows, row => { return row.numeric_rating}),
                    value: rows.length || 0
                }
            })
            .entries(ratingData)
        
        ratingDistribution = ratingDistribution.map(row => {
            return {
                key: row.key,
                label: row.value.label,
                value: row.value.value
            }
        })
        ratingDistribution.sort((a, b) => a.key - b.key)

        let deliveryTimeDistribution = d3.nest()
            .key(row => { return row.rounded_duration_sec})
            .rollup(rows => {
                return {
                    label: d3.max(rows, row => { return row.rounded_duration}),
                    value: rows.length || 0
                }
            })
            .entries(this.state.rawPerformanceData)
        deliveryTimeDistribution = deliveryTimeDistribution.map(row => {
            return {
                key: row.key,
                label: row.value.label,
                value: row.value.value
            }
        })        
        deliveryTimeDistribution.sort((a, b) => a.key - b.key)

        this.setState({summaryData, ratingDistribution, deliveryTimeDistribution})
    }


    render = () => {
        return (
            <FoxPerformance
                classes={this.props.classes}
                state={this.state}
                onClickUpdateDateRange={this._onClickUpdateDateRange}
                onClickUpdateHeatmapRange={this._onClickUpdateHeatmapRange}
                onTypeFoxSearch={this._onTypeFoxSearch}
                onClickHeatmapDate={this._onClickHeatmapDate}
                onCloseHeatmapDialog={this._onCloseHeatmapDialog}
                onClickFox={this._onClickFox}
                onClickDrawerItem={this._onClickDrawerItem}
                onClickDrawerLogout={this._onClickDrawerLogout}
                onCloseSnack={this._onCloseSnack}/>
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account } = state
    return { server, credentials, airport, account }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces,
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(FoxPerformanceContainer);
