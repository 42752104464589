import React, { Component } from "react"
import ReactCrop from "react-image-crop"
import PropTypes from "prop-types"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import AddPhotoIcon from "@material-ui/icons/AddAPhoto"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { withStyles } from "@material-ui/core/styles"

import { StatusSnackbar, StatusSnackbarTypes } from "./StatusSnackbar"
import { isEmptyString } from "../utils"

import "react-image-crop/dist/ReactCrop.css"


const styles = theme => ({
    preview: {
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: "375px"
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: "400px"
        }
    }
})

class ImageCropPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stage: "empty", // empty, cropping, done
            imgData: null,  // raw image bytes (array of bytes)
            mimeType: null,
            source: null,  // base64 image data

            cropData: null,
            cropProps: {
                unit: "px",
                height: props.height,
                width: props.width,
                aspect: props.width / props.height
            },

            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        }
    }

    componentWillMount = () => {
        const source = this.state.source || this.props.pictureUrl
        const stage = source !== null ? "done" : "empty"
        this.setState({source, stage})
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this._updateWindowDimensions);
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.pictureUrl !== this.props.pictureUrl) {
            const source = this.props.pictureUrl || this.state.source
            const stage = source !== null ? "done" : "empty"
            this.setState({source, stage})   
        }
    }

    componentDidMount = () => {
        this._updateWindowDimensions();
        window.addEventListener('resize', this._updateWindowDimensions);
    }

    _updateWindowDimensions = () => {
        // https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }


    _rawToBase64 = (mediaData, mimeType) => {
        const source = "data:" + mimeType + ";base64," + btoa(
            new Uint8Array(mediaData)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
        return source
    }

    _resetCropData = (callback = () => {}) => {
        this.setState({
            cropData: null,
            cropProps: {
                unit: "px",
                height: this.props.height,
                width: this.props.width,
                aspect: this.props.width / this.props.height
            }
        }, callback)
    }

    _onLoadedCrop = image => {
        this.setState({cropData: image})
    }

    _onChangeCrop = (crop, percentCrop) => {
        this.setState({cropProps: crop})
    }

    _onCompleteCrop = (crop) => {
        this.setState({cropProps: crop})
    }

    _onClickFinishCrop = () => {
        // create the cropped image using a canvas element
        const { cropData, cropProps } = this.state

        const canvas = document.createElement('canvas')
        const scaleX = cropData.naturalWidth / cropData.width
        const scaleY = cropData.naturalHeight / cropData.height

        canvas.width = cropProps.width
        canvas.height = cropProps.height
        const ctx = canvas.getContext('2d')      
        
        ctx.drawImage(
            cropData,
            cropProps.x * scaleX,
            cropProps.y * scaleY,
            cropProps.width * scaleX,
            cropProps.height * scaleY,
            0,
            0,
            cropProps.width,
            cropProps.height
        );
        
        // base64 image data
        const source = canvas.toDataURL(this.state.mimeType);

        canvas.toBlob((imgData) => {
            this.setState({source, stage: "done"})
            this._resetCropData()
            this.props.onSelectImage(imgData, this.state.mimeType)
        }, this.state.mimeType, 1)
    }

    _onCloseSnack = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    _onSelectImage = (file) => {
        if (file.size >= this.props.maxFilesizeMB * 1024 * 1024) {
            this.setState({
                snackMessage: {
                    message: `Image exceeds ${this.props.maxFilesizeMB}MB filesize limit.`,
                    level: StatusSnackbarTypes.WARNING
                }
            })
        }
        else {
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                this.setState({
                    snackMessage: {
                        message: "Image must be JPG or PNG",
                        level: StatusSnackbarTypes.WARNING
                    }
                })                
            }
            else {
                const reader = new FileReader(file)
                reader.readAsArrayBuffer(file)
                reader.onloadend = (event) => {
                    const imgData = reader.result
                    const mimeType = file.type
                    const source = this._rawToBase64(imgData, mimeType)
                    this.setState({imgData, source, mimeType, stage: "cropping"})
                }
            }
        }
    }

    _renderStage = () => {
        if (this.state.stage === "done") {
            const { classes } = this.props
            return (
                <div>
                    <Grid container justify="center">
                        <Grid item>
                            <img src={this.state.source} alt="center the product being sold" className={classes.preview}/>
                        </Grid>
                    </Grid>
                    
                    <input 
                        style={{display: "none"}}
                        accept=".jpg,.png"
                        type="file"
                        ref="imageUpload"
                        onChange={(event) => this._onSelectImage(event.target.files[0])}/>
                    <Grid container justify="center">
                        <Grid item>
                            <Button color="primary" onClick={(e) => this.refs.imageUpload.click()}>Select New Photo</Button>                    
                        </Grid>
                    </Grid>
                </div>
            )
        }
        else if (this.state.stage === "cropping") {
            return (
                <Dialog open>
                    <DialogTitle>Crop Image</DialogTitle>
                    <DialogContent>
                    <ReactCrop
                        src={this.state.source}
                        crop={this.state.cropProps}
                        ruleOfThirds
                        minHeight={this.props.height}
                        minWidth={this.props.width}
                        maxHeight={this.props.height * 3}
                        maxWidth={this.props.width * 3}
                        onImageLoaded={this._onLoadedCrop}
                        onComplete={this._onCompleteCrop}
                        onChange={this._onChangeCrop}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this._onClickFinishCrop()}}>Submit</Button>
                    </DialogActions>
                </Dialog>
            )
        }
        else {
            return (
                <Avatar style={{width: `${this.props.width}px`, height: `${this.props.height}px`}} aria-label="Upload" variant={this.props.shape}>
                {/* <Avatar style={{width: "100%", height: "200px"}} aria-label="Upload" variant={this.props.shape}> */}
                    <input
                        style={{display: "none"}}
                        accept=".jpg,.png"
                        type="file"
                        ref="imageUpload"
                        onChange={(event) => this._onSelectImage(event.target.files[0])} />
                    <IconButton onClick={(e) => this.refs.imageUpload.click()}>
                        <AddPhotoIcon style={{height: "48px", width: "48px"}}/>
                    </IconButton>
                </Avatar>                
            )
        }
    }

    render = () => {
        return (
            <div>
                <FormControl error={!isEmptyString(this.props.errorText)}>
                    <FormHelperText>{this.props.errorText}</FormHelperText>
                </FormControl>                    
                {this._renderStage()}
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    message={this.state.snackMessage.message}
                    variant={this.state.snackMessage.level}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._onCloseSnack}/>                      
            </div>
        )
    }
}

ImageCropPreview.propTypes = {
    onSelectImage: PropTypes.func.isRequired,
    maxFilesizeMB: PropTypes.number,
    pictureUrl: PropTypes.string, // url of media
    shape: PropTypes.string,
    errorText: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
}

ImageCropPreview.defaultProps = {
    onSelectImage: (mediaData, mimeType) => console.log(`default onSelectImage ${mimeType} ${mediaData.length}`),
    maxFilesizeMB: 1,
    pictureUrl: null,
    shape: "square",
    errorText: "",
    height: 200,
    width: 300
}

export default withStyles(styles)(ImageCropPreview)
