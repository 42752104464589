import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class PlaceAPI {
    constructor(host, merchant_id, fetchy_token) {
        this.host = host
        this.username = merchant_id
        this.password = fetchy_token
    }

    promisePlaceList = () => {
        return axios({
            url: `${this.host}/v1/merchant/places`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromisePlaceList = () => {
        return this.promisePlaceList().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPlaceList = (onRequestSuccess, onRequestError) => {
        this.promisePlaceList().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeletePlace = (place_id) => {
        return axios({
            url: `${this.host}/v1/merchant/place`,
            method: "delete",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })
    }

    tryPromiseDeletePlace = (place_id) => {
        return this.promiseDeletePlace(place_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeletePlace = (place_id, onRequestSuccess, onRequestError) => {
        this.promiseDeletePlace(place_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreatePlace = (airport_id, terminal_id, place_type, name, description, latitude, longitude, dietary_tags) => {
        return axios({
            url: `${this.host}/v1/merchant/place`,
            method: "post",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                airport_id,
                place_type
            },
            data: {
                name,
                description,
                terminal_id,
                latitude,
                longitude,
                dietary_tags
            }
        })
    }

    tryPromiseCreatePlace = (airport_id, terminal_id, place_type, name, description, latitude, longitude, dietary_tags) => {
        this.promiseCreatePlace(airport_id, terminal_id, place_type, name, description, latitude, longitude, dietary_tags).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreatePlace = (airport_id, terminal_id, place_type, name, description, latitude,
                          longitude, dietary_tags, onRequestSuccess, onRequestError) => {
        this.promiseCreatePlace(airport_id, terminal_id, place_type, name, description, latitude, longitude, dietary_tags).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseGetPlace = (place_id) => {
        return axios({
            url: `${this.host}/v1/merchant/place`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })        
    }

    tryPromiseGetPlace = (placeId) => {
        this.promiseGetPlace(placeId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPlace = (placeId, onRequestSuccess, onRequestError) => {
        this.promiseGetPlace(placeId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdatePlaceImage = (place_id, image_data, mime_type) => {
        return axios({
            url: `${this.host}/v1/merchant/placeimage`,
            method: "put",
            auth: {
                username: this.username,
                password: this.password
            },
            headers: {
                "Content-Type": mime_type
            },
            params: {
                place_id
            },
            data: image_data
        })
    }

    tryPromiseUpdatePlaceImage = (place_id, image_data, mime_type) => {
        return this.promiseUpdatePlaceImage(place_id, image_data, mime_type).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdatePlaceImage = (place_id, image_data, mime_type, onRequestSuccess, onRequestError) => {
        this.promiseUpdatePlaceImage(place_id, image_data, mime_type).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdatePlace = (place_id, name, description, latitude, longitude, listed) => {
        return axios({
            url: `${this.host}/v1/merchant/place`,
            method: "PUT",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            },
            data: {
                name,
                description,
                latitude,
                longitude,
                listed
            }
        })
    }

    tryPromiseUpdatePlace = (place_id, name, description, latitude, longitude, listed) => {
        return this.promiseUpdatePlace(place_id, name, description, latitude, longitude, listed).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdatePlace = (place_id, name, description, latitude, longitude, listed, onRequestSuccess, onRequestError) => {
        this.promiseUpdatePlace(place_id, name, description, latitude, longitude, listed).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    requestUpdatePlaceListingStatus = (place_id, listed, onRequestSuccess, onRequestError) => {
        this.requestUpdatePlace(place_id, null, null, null, null, listed, onRequestSuccess, onRequestError)
    }
}
