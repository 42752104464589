// 
// UI component used to render a list of locations (shops, services, amenities)
// available at an airport
// 
// used by airports admins and merchants
//
import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import InputLabel from "@material-ui/core/InputLabel"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"

function _renderPlaceCards(props) {
    const { state } = props
    return (
        <Grid container justify="flex-start">{
            state.filteredPlaces.map((place, idx) => {
                return (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={idx}>
                        <Card>
                            <CardMedia
                                style={{height:200}}
                                image={place.picture_url}
                                title={place.name}/>
                            <CardContent>
                                <Grid container>
                                    <Grid item>
                                        <Grid container align="flex-end" justify="center"  direction="column" style={{ height: 50 }}>
                                            <Grid item>
                                                <Typography variant="h6">{place.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={place.listed}
                                                    onChange={(event) => props.onToggleAvailability(place.place_id, event.target.checked) }
                                                />
                                            }
                                            label={<Typography variant="subtitle1">Listed</Typography>}/>
                                    </Grid>
                                </Grid>
                                {
                                    place.contact_email === undefined ? <div/> :
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">Merchant Info</Typography>
                                                <Typography variant="body2">{place.merchant_id}</Typography>
                                                <Typography variant="body2">{place.contact_email}</Typography>
                                            </Grid>
                                        </Grid>
                                }
                            </CardContent>
                            <CardActions>
                                <Grid container alignContent="flex-end" justify="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => { props.onClickManageListing(place.place_id)}}>Manage</Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })
        }</Grid>        
    )

}

export const Listings = (props) => {
    const { state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    return (
        <div>
            <Grid container justify="flex-start">
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        value={state.searchText}
                        label="Search Listings..."
                        onChange={(event) => props.onTypeSearch(event.target.value)}
                        fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Filter by Terminal</InputLabel>
                        <Select
                            value={state.selectedTerminalId}
                            onChange={(event) => props.onSelectTerminalFilter(event.target.value)}>
                            <MenuItem value="">All</MenuItem>
                            {
                                state.terminals.map((terminal, index) => {
                                    return (
                                        <MenuItem key={index} value={terminal.terminalId}>
                                            {terminal.terminalName}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="text"
                        onClick={props.onClickRefresh}>Refresh</Button>
                    <Button
                        variant="text"
                        onClick={props.onClickResetFilter}>Reset</Button>
                    <Divider />
                </Grid>
            </Grid>
            { _renderPlaceCards(props) }            
        </div>
    )

}

Listings.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    
    onToggleAvailability: PropTypes.func,
    onClickManageListing: PropTypes.func,
    onTypeSearch: PropTypes.func,
    onSelectTerminalFilter: PropTypes.func,
    onClickRefresh: PropTypes.func,
    onClickResetFilter: PropTypes.func,
}

Listings.defaultProps = {
    onToggleAvailability: (checked) => { console.log(`${checked}`)},
    onClickManageListing: (placeId) => { console.log(`${placeId}`)},
    onTypeSearch: (value) => { console.log(`${value}`)},
    onSelectTerminalFilter: (value) => { console.log(`${value}`)},
    onClickRefresh: () => {},
    onClickResetFilter: () => {},
}