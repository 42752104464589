// 
// new modal window for creating item option groups and choices
// 
// 
// 
// 

import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Switch from "@material-ui/core/Switch"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"

import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import CheckIcon from "@material-ui/icons/Check"

import Spinner from "../../common/components/Spinner"
import { StatusSnackbar, StatusSnackbarTypes } from "../../common/components/StatusSnackbar"
import DescriptiveRadioGroup from "../../common/components/DescriptiveRadioGroup"

import { ConstantsAPI } from "../../common/requests/merchant/constants"

import { isEmptyString, toTitleCase } from "../../common/utils"

class ProductOptionsDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.data === undefined ? "" : props.data.name,
            optionGroupTypes: {},
            selectedGroupType: props.data === undefined ? "" : props.data.uitype,
            choices: props.data === undefined ? [] : props.data.choices,
            required: props.data === undefined ? true : this.props.data.required,
            
            newOptionName: "",
            newOptionNameErrorText: "",

            editingOptionIdx: -1,
            editingOptionName: "", // temp place holder for new name before updating
            editingOptionNameErrorText: "",

            loading: false,
            loadingOptionTypes: false,
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },

            nameErrorText: "",
            selectedGroupTypeErrorText: "",
            choicesErrorText: ""
        }
    }

    componentWillMount = () => {}

    componentDidUpdate = (prevProps) => {}

    componentDidMount = () => {
        this._getOptionGroupTypes()
    }

    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    _resetErrorMessages = () => {
        this.setState({
            newOptionNameErrorText: "",
            editingOptionNameErrorText: "",
            nameErrorText: "",
            selectedGroupTypeErrorText: "",
            choicesErrorText: ""
        })
    }

    _getOptionGroupTypes = () => {
        this.setState({loadingOptionTypes: true})
        const api = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        api.requestOptionGroupTypes(
            (success) => {
                const optionGroupTypes = success.data.types
                this.setState({optionGroupTypes, loadingOptionTypes: false })
            },
            (error) => {
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message: error.message
                }
                this.setState({snackMessage, loadingOptionTypes: false})
            }
        )        
    }

    _onChangeGroupName = name => this.setState({name})

    _onChangeNewOptionName = newOptionName =>  this.setState({newOptionName})

    _onChangeEditOptionName = editingOptionName => this.setState({editingOptionName})


    _onClickDeleteOption = (optionIdx) => {
        let { choices } = this.state
        choices = choices.filter((option, idx) => idx !== optionIdx)
        //make network request to delete option
        this.setState({choices})
    }

    _onClickCancelEditOption = () => {
        this._resetErrorMessages()
        this.setState({editingOptionIdx: -1, editingOptionName: "" })
    }

    _onClickUpdateOption = (optionIdx) => {
        this._resetErrorMessages()
        const optionName = this.state.editingOptionName.trim()

        if(optionName.length === 0) {
            this.setState({editingOptionNameErrorText: "Option name can't be blank."})
        }
        else {
            const results = this.state.choices.filter( (option, idx) => option.name.toLowerCase() === optionName.toLowerCase() &&
                                                        idx !== optionIdx)
            if(results.length > 0) {
                this.setState({editingOptionNameErrorText: "Option name already exists in this group."})
            }
            else {
                // make network request here to update option name
                const choices = this.state.choices.map((option, idx) => {
                    if(idx === optionIdx)
                        option.name = this.state.editingOptionName
                    return option
                })
                
                this.setState({choices, editingOptionIdx: -1, editingOptionName: ""})
            }
        }

    }

    _onClickEditOption = (optionIdx) => {
        const { choices } = this.state
        const option = choices.filter((option, idx) => idx === optionIdx)[0]
        this.setState({
            editingOptionIdx: optionIdx,
            editingOptionName: option.name
        })

    }

    _onClickAddOption = () => {
        this._resetErrorMessages()
        const optionName = this.state.newOptionName.trim()
        if(optionName.length === 0) {
            this.setState({newOptionNameErrorText: "Option name can't be blank."})
        }
        else {
            const results = this.state.choices.filter(option => option.name.toLowerCase() === optionName.toLowerCase())
            if(results.length > 0) {
                this.setState({newOptionNameErrorText: "Option name already exists in this group."})
            }
            else {
                let { choices } = this.state
                // make network request here to add option
                choices.push({name: optionName, option_id: ""})
                this.setState({choices, newOptionName: ""})
            }
        }
    }

    _onSelectType = (selectedGroupType) => this.setState({selectedGroupType})
    _onToggleRequired = (event, required) => { this.setState({required}) }

    _onClickSubmitGroup = () => {
        this._resetErrorMessages()
        const { name, selectedGroupType, choices } = this.state

        if(isEmptyString(name))
            this.setState({nameErrorText: "Group name can't be empty."})
        else if(isEmptyString(selectedGroupType))
            this.setState({selectedGroupTypeErrorText: "Group selection is required."})
        else if(choices.length === 0)
            this.setState({choicesErrorText: "A group must have at least 1 option."})
        else {
            const payload = {
                option_group_id: this.props.data === undefined ? "" : this.props.data.option_group_id,
                name: this.state.name,
                uitype: this.state.selectedGroupType,
                required: this.state.required,
                listed: true,
                choices: this.state.choices
            }
            this.props.onAddGroupCallback(payload)
        }
    }

    _renderOptionActions = (optionIdx) => {
        if(optionIdx === this.state.editingOptionIdx) {
            return (
                <div>
                    <IconButton onClick={() => { this._onClickCancelEditOption()}}>
                        <CloseIcon />
                    </IconButton>
                    <IconButton onClick={() => { this._onClickUpdateOption(optionIdx) }}>
                        <CheckIcon/>
                    </IconButton>                    
                </div>
            )
        }
        else {
            return (
                <div>
                    <IconButton onClick={() => {this._onClickEditOption(optionIdx)}}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => {this._onClickDeleteOption(optionIdx)}}>
                        <DeleteIcon style={{color: "#f44336"}}/>
                    </IconButton>                    
                </div>
            )
        }
    }

    _renderOptions = () => {
        return (
            <Grid container>
                {
                    this.state.choices.map((option, idx) => {
                        const unsaved = isEmptyString(option.option_id) ? "Unsaved" : ""
                        return (
                            <Grid item xs={12} key={idx}>
                                <Card style={{display: "flex"}}>
                                    <CardContent style={{flex: "1 1 auto"}}>
                                        {
                                            idx === this.state.editingOptionIdx ?
                                                <TextField
                                                    onChange={(event) => { this._onChangeEditOptionName(event.target.value)}}
                                                    placeholder="Option name"
                                                    error={!isEmptyString(this.state.editingOptionNameErrorText)}
                                                    helperText={this.state.editingOptionNameErrorText}
                                                    value={this.state.editingOptionName}
                                                    fullWidth/> :
                                                <div>
                                                    <Typography variant="caption" style={{color: "#FF832D"}}>{` ${unsaved}`}</Typography>
                                                    <Typography variant="body1">{option.name}</Typography>
                                                </div>
                                        }
                                    </CardContent>
                                    <CardActions>
                                        { this._renderOptionActions(idx) }
                                    </CardActions>
                                </Card>
                            </Grid>
                        ) 
                    })
                }
                <Grid item xs={12}>
                    <Card style={{display: "flex"}}>
                        <CardContent style={{flex: "1 1 auto"}}>
                            <TextField
                                onChange={(event) => { this._onChangeNewOptionName(event.target.value)}}
                                placeholder="Option name"
                                error={!isEmptyString(this.state.newOptionNameErrorText)}
                                helperText={this.state.newOptionNameErrorText}
                                value={this.state.newOptionName}
                                fullWidth/>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" size="small" onClick={this._onClickAddOption}>Add</Button>                            
                        </CardActions>
                    </Card>
                </Grid>    
            </Grid>
        )
    }

    _renderOptionGroupTypes = () => {
        if (this.state.loadingOptionTypes) {
            return <Spinner />
        }
        else {
            const types_ = Object.keys(this.state.optionGroupTypes).map((key, index) => {
                return {
                    value: key,
                    label: this.state.optionGroupTypes[key].nicename,
                    description: this.state.optionGroupTypes[key].description
                }
            })
            return (
                <DescriptiveRadioGroup
                    selectedValue={this.state.selectedGroupType}
                    onChangeCallback={this._onSelectType}
                    options={types_}/>
            )
        }
    }

    render = () => {
        return (
            <Dialog
                open
                maxWidth="sm"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>{`${toTitleCase(this.props.variant)} Customization Group`}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Customization groups allow you to organize related choices together.
                    </Typography>                    
                    <Grid container>
                        <Grid item xs={12} style={{marginTop: "10px"}}>
                            <TextField
                                label="Customization Group Name"
                                placeholder="Dairy"
                                value={this.state.name}
                                onChange={(event) => this._onChangeGroupName(event.target.value)}
                                error={!isEmptyString(this.state.nameErrorText)}
                                helperText={this.state.nameErrorText}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: "10px"}}>
                            <FormControl error={!isEmptyString(this.state.selectedGroupTypeErrorText)} fullWidth>
                                <Typography variant="h6">Selection Type</Typography>
                                <FormHelperText>{this.state.selectedGroupTypeErrorText}</FormHelperText>
                                { this._renderOptionGroupTypes() }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Options</Typography>
                            <Typography variant="caption" style={{color: "#f44336"}}>{this.state.choicesErrorText}</Typography>
                            { this._renderOptions() }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={this.state.required}
                                        onChange={this._onToggleRequired}/>
                                }
                                label="Choice Required"/>                           
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancelGroupCallback}>Cancel</Button>
                    <Button onClick={this._onClickSubmitGroup} color="primary">Finish</Button>
                </DialogActions>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>
            </Dialog>
        )
    }

}

ProductOptionsDialog.propTypes = {
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    onAddGroupCallback: PropTypes.func.isRequired,
    onCancelGroupCallback: PropTypes.func.isRequired,
    data: PropTypes.object
}

ProductOptionsDialog.defaultProps = {
    onAddGroupCallback: (json) => {console.log(`default: ${json}`)},
    onCancelGroupCallback: () => {console.log(`default onCancelGroupCallback`)},
    variant: "create",

}

function mapStateToProps(state) {
    const { credentials, server, airport } = state
    return { credentials, server, airport }
}

export default connect(mapStateToProps, null)(ProductOptionsDialog)
