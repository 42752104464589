import axios from "axios"
import { handleFetchyAPIError,
    formatFetchyAPIFailure,
    formatFetchyAPISuccess } from "../base"

export class MenuAPI {

    constructor(host, merchantId, fetchyToken) {
        this.host = host
        this.username = merchantId
        this.password = fetchyToken
    }

    promiseMenusOutline = place_id => {
        return axios({
            url: `${this.host}/v1/merchant/menu/outline`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })
    }

    tryPromiseMenusOutline = placeId => {
        return this.promiseMenusOutline(placeId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestMenusOutline = (placeId, onRequestSuccess, onRequestError) => {
        // returns an outline of all menus and menu sections belonging to the given place
        this.promiseMenusOutline(placeId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promisePlaceMenus = (place_id) => {
        return axios({
            url: `${this.host}/v1/merchant/menu`,
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                place_id
            }
        })
    }

    tryPromisePlaceMenus = (placeId) => {
        return this.promisePlaceMenus(placeId).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestPlaceMenus = (placeId, onRequestSuccess, onRequestError) => {
        // returns all menu sections and all items details. does not include item customization optins
        this.promisePlaceMenus(placeId).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateMenu = (place_id, name, listed) => {
        return axios({
            url: `${this.host}/v1/merchant/menu`,
            method: "post",
            auth: {
                username: this.username,
                password: this.password
            },
            params:{
                place_id
            },
            data: {
                name,
                listed
            }
        })
    }

    tryPromiseCreateMenu = (place_id, name, listed) => {
        return this.promiseCreateMenu(place_id, name, listed).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateMenu = (place_id, name, listed, onRequestSuccess, onRequestError) => {
        this.promiseCreateMenu(place_id, name, listed).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseUpdateMenu = (menu_id, name, listed) => {
        return axios({
            url: `${this.host}/v1/merchant/menu`,
            method: "put",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                menu_id
            },
            data: {
                name,
                listed
            }
        })
    }

    tryPromiseUpdateMenu = (menu_id, name, listed) => {
        return this.promiseUpdateMenu(menu_id, name, listed).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateMenu = (menu_id, name, listed, onRequestSuccess, onRequestError) => {
        this.promiseUpdateMenu(menu_id, name, listed).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseDeleteMenu = (menu_id) => {
        return axios({
            url: `${this.host}/v1/merchant/menu`,
            method: "delete",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                menu_id
            },
        })
    }

    tryPromiseDeleteMenu = (menu_id) => {
        return this.promiseDeleteMenu(menu_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteMenu = (menu_id, onRequestSuccess, onRequestError) => {
        this.promiseDeleteMenu(menu_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }

    promiseCreateMenuCategory = (menu_id, name, listed) => {
        return axios({
            url: `${this.host}/v1/merchant/menu/section`,
            method: "post",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                menu_id
            },
            data: {
                name,
                listed
            }
        })
    }

    tryPromiseCreateMenuCategory = (menu_id, name, listed) => {
        return this.promiseCreateMenuCategory(menu_id, name, listed).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestCreateMenuCategory = (menu_id, name, listed, onRequestSuccess, onRequestError) => {
        this.promiseCreateMenuCategory(menu_id, name, listed).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }    

    promiseUpdateMenuCategory = (category_id, name, listed) => {
        return axios({
            url: `${this.host}/v1/merchant/menu/section`,
            method: "put",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                category_id
            },
            data: {
                name,
                listed
            }
        })
    }

    tryPromiseUpdateMenuCategory = (category_id, name, listed) => {
        return this.promiseUpdateMenuCategory(category_id, name, listed).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestUpdateMenuCategory = (category_id, name, listed, onRequestSuccess, onRequestError) => {
        this.promiseUpdateMenuCategory(category_id, name, listed).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }    

    promiseDeleteMenuCategory = (category_id) => {
        return axios({
            url: `${this.host}/v1/merchant/menu/section`,
            method: "delete",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                category_id
            }
        })
    }

    tryPromiseDeleteMenuCategory = (category_id) => {
        return this.promiseDeleteMenuCategory(category_id).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestDeleteMenuCategory = (category_id, onRequestSuccess, onRequestError) => {
        this.promiseDeleteMenuCategory(category_id).then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })

    }
}

