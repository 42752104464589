import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import Typography from "@material-ui/core/Typography"

import { Listings } from "../../pieces/Listings"
import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import { isEmptyString } from "../../common/utils"

export const AirportListings = (props) => {
    const { classes, state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItemHandler}
                onClickLogoutCallback={props.onClickLogoutHandler}/>
            <FetchyIntercom 
                userId={state.credentials.username}
                email={state.credentials.email} 
                name={state.credentials.username}
                airport={state.airport.name}
                route={state.location.pathname}
                accountType={state.account.accountType} 
                customData={{source: "Web Dashboard"}}/>          
            <main style={{width: "99vw"}}>
                <div className={classes.spacerTop}/>
                <Grid container justify="center">
                    <Grid item xs={11} md={10}>
                        <Grid container justify="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="h6">Airport Listings</Typography>
                                <Typography variant="body1">
                                    The listing status of all locations within this airport can be controlled
                                    from here. Unlisting a location only means it's hidden from travelers. It
                                    does not remove the location from our systems. All actions here will be linked
                                    to the account
                                    <Typography component="span" variant="body2" className={classes.highlightText}>
                                        {` ${state.adminEmail}`}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{height: 20}}/>
                        <Listings
                            classes={classes}
                            state={state}
                            onToggleAvailability={ props.onToggleAvailability }
                            onClickManageListing={ props.onClickManageListing }
                            onTypeSearch={ props.onTypeSearch }
                            onSelectTerminalFilter={ props.onSelectTerminalFilter }
                            onClickRefresh={ props.onClickRefresh }
                            onClickResetFilter={ props.onClickResetFilter }/>
                    </Grid>
                </Grid>
                { state.confirmModal ? state.confirmModal : <div/> }
                <LoadingDialog show={state.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={state.snackMessage}
                    open={!isEmptyString(state.snackMessage)}
                    onClose={props.onCloseSnack}
                    action={<Button onClick={() => props.onCloseSnack()} color="primary">Close</Button>} />
            </main>
        </div>
    )
}

AirportListings.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,

    onToggleAvailability: PropTypes.func,
    onClickManageListing: PropTypes.func,
    onClickDrawerItemHandler: PropTypes.func,
    onClickLogoutHandler: PropTypes.func,
    onTypeSearch: PropTypes.func,
    onSelectTerminalFilter: PropTypes.func,
    onClickRefresh: PropTypes.func,
    onClickResetFilter: PropTypes.func,
    onCloseSnack: PropTypes.func,
}

AirportListings.defaultProps = {
    onToggleAvailability: (checked) => { console.log(`${checked}`)},
    onClickManageListing: (placeId) => { console.log(`${placeId}`)},
    onTypeSearch: (value) => { console.log(`${value}`)},
    onSelectTerminalFilter: (value) => { console.log(`${value}`)},
    onClickRefresh: () => {},
    onClickResetFilter: () => {},
    onClickDrawerItemHandler: (redirectParams) => {console.log(`${redirectParams}`)},
    onClickLogoutHandler: () => {},
    onCloseSnack: () => {}
}