
import React from "react"
import PropTypes from "prop-types"

import Image from "material-ui-image"

import {
    Grid,
    Typography,
    TextField,
    Button,
    Divider,
    Collapse,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    MenuList,
    MenuItem,
    Avatar,
    ListItemAvatar,
    ListItemText,
    useMediaQuery
} from "@material-ui/core"

import { useTheme } from "@material-ui/core/styles"

import FilterListIcon from "@material-ui/icons/FilterList"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import RefreshIcon from "@material-ui/icons/Refresh"
import InfoIcon from "@material-ui/icons/Info"

import { cleanCut, isEmptyString } from "../../common/utils"
import { DescriptiveCheckboxGroup } from "../../common/components/DescriptiveCheckbox"



function _renderDialog(props) {
    const { state } = props
    return state.dialogComponent === null ? <div/> : state.dialogComponent
}

function _renderListingList(props, isMobileScreen) {
    // this is the list of all locations, selecting one shows the product library
    const { state } = props

    const style = isMobileScreen ? {} : {height: "75vh", display: "flex"}

    return (
        <Grid container>
            <Grid item>
                <Button 
                    onClick={props.onClickRefreshListings}
                    variant="contained" 
                    startIcon={<RefreshIcon/>}>Refresh</Button>   
            </Grid>            
            <Grid item xs={12}>  
                <TextField 
                    variant="outlined"
                    size="small"
                    placeholder="Search Listings..." 
                    fullWidth onChange={(event) => props.onChangeListingSearch(event.target.value)}/>        
                <div style={{height: 10}}/>                
                <Card style={{maxHeight: "100%", overflow: "auto"}}>
                    <CardContent>            
                        <Grid container style={style}>
                            <Grid item xs={12}>{
                                state.listings.length === 0 ? 
                                    <div>
                                        <Typography variant="body1">No listings!</Typography>
                                        <Typography variant="caption">Create your first listing and menu in "Manage Listings"</Typography>
                                    </div> :
                                    <MenuList style={{overflow: "auto"}}>{
                                        state.filteredListings.map((listing, listingIdx) => {
                                            return (
                                                <MenuItem selected={state.placeId === listing.place_id} key={listingIdx} onClick={() => props.onClickListing(listing.place_id)}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="square"
                                                            alt={listing.name}
                                                            src={listing.picture_url}/>
                                                    </ListItemAvatar>
                                                    <ListItemText 
                                                        primary={listing.name}
                                                        secondary={listing.terminal_name}/>
                                                </MenuItem>
                                            )
                                        })

                                    }</MenuList>
                            }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>           
    )

}

function _renderProducts(props) {
    const { state } = props
    return state.filteredProducts.map((product, productIdx) => {
        return (
            <Grid item xs={12} lg={6} xl={4} key={productIdx}>
                <Card>
                    <CardMedia title={product.name}>
                        <Image src={product.media_url} aspectRatio={1.5} style={{objectFit: "contain"}} />
                    </CardMedia>
                    <CardContent style={{height: 100}}>
                        <Typography variant="h6">{product.name}</Typography>
                        {/* <Typography variant="caption" color="primary">{product.tags.join(", ")}</Typography> */}
                        <Typography variant="body2">{cleanCut(product.description, 120)}</Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container alignContent="flex-end" justify="flex-end">
                            <Button onClick={() => props.onClickDuplicateProduct(product.item_id)}>Duplicate</Button>
                            <Button onClick={() => props.onClickEditProduct(product.item_id)} color="primary">Edit</Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        )
    })
}

function _renderFilters(props) {
    const { state } = props

    const productTypes = Object.keys(state.productTypes).map(key => {
        return {
            value: key,
            label: state.productTypes[key].nicename
        }
    })

    const tags = Object.keys(state.productTags).map(key => {
        return {
            value: key,
            label: state.productTags[key].nicename
        }
    })


    return (
        <Grid container>
            <Grid item xs={6} sm={5} md={4} lg={2}>
                <DescriptiveCheckboxGroup
                    options={productTypes}
                    checkedValues={state.filteredProductTypes}
                    onCheckCallback={props.onCheckProductTypeFilter}
                    description="Product Type"/>
            </Grid>
            <Grid item xs={6} sm={5} md={4} lg={2}>
                <DescriptiveCheckboxGroup
                    options={tags}
                    checkedValues={state.filteredProductTags}
                    onCheckCallback={props.onCheckProductTagFilter}
                    description="Tags"/>
            </Grid>                                
        </Grid>
    )
}

function _renderLibrary(props, isMobileScreen) {
    const { state } = props

    if(isEmptyString(state.placeId)) {
        return (
            <Grid container justify="center" alignItems="center" direction="row" style={{height: "50vh"}}>
                <Grid item>
                    <InfoIcon color="primary" fontSize="large"/>
                </Grid>
                <Grid item>
                    <Typography variant="body1">Please select a listing.</Typography>
                </Grid>
                
            </Grid>
        )
    }
    else {
        return (
            <div style={{height: "90vh", overflow: 'auto'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4">{`Product Library: ${state.placeName}`}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={7}>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={12} md={9}>
                                <TextField 
                                    onChange={(event) => props.onChangeSearchPhrase(event.target.value)}
                                    value={state.searchPhrase}
                                    variant="outlined" 
                                    size="small"
                                    placeholder="Product name..." fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button 
                                    variant="contained" 
                                    onClick={props.onClickSearch}
                                    fullWidth={isMobileScreen ? true : false}>Search</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} lg={5}>
                        <Grid container justify={isMobileScreen ? "flex-start" : "flex-end"}>
                            <Grid item>
                                <Button 
                                    onClick={props.onClickRefreshLibrary}
                                    variant="contained" 
                                    startIcon={<RefreshIcon/>}>Refresh</Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    onClick={props.onClickAddProduct}
                                    variant="contained" 
                                    color="primary"
                                    startIcon={<LibraryAddIcon/>}>Add Product</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            onClick={props.onClickFilter}
                            variant="text" 
                            startIcon={<FilterListIcon/>}>Filter</Button>
                        <Collapse in={state.filterOpen} unmountOnExit>
                            {_renderFilters(props, isMobileScreen)}
                        </Collapse>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            { _renderProducts(props) }
                        </Grid>
                    </Grid>
                </Grid>  
            </div>      
        )
    }
}

export const ProductLibrary = (props) => {
    
    const { classes } = props
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <div className={classes.spacerTop}/>
            <Grid container>
                <Grid item xs={12} sm={5} lg={4} xl={3}>
                    { _renderListingList(props, isMobileScreen) }
                </Grid>
                <Grid item xs={12} sm={7} lg={8} xl={9}>
                    { _renderLibrary(props, isMobileScreen) }
                </Grid>
                { _renderDialog(props) }
            </Grid>
        </div>
    )

}

ProductLibrary.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickSearch: PropTypes.func.isRequired,
    onChangeSearchPhrase: PropTypes.func.isRequired, 
    onClickRefreshLibrary: PropTypes.func.isRequired,
    onClickAddProduct: PropTypes.func.isRequired,
    onClickEditProduct: PropTypes.func.isRequired,
    onClickDuplicateProduct: PropTypes.func.isRequired,
    onCheckProductTypeFilter: PropTypes.func.isRequired,
    onCheckProductTagFilter: PropTypes.func.isRequired,
    onClickFilter: PropTypes.func.isRequired
}

ProductLibrary.defaultProps = {
    onClickFilter: () => {"default onClickFilter"}
}
