import * as d3 from "d3"
import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Reviews } from "./views/Reviews"

import { FetchyStyles } from "../common/fetchyfoxtheme"
import { InsightsAPI as AirportInsightsAPI } from "../common/requests/airport/insights"
import FetchyFoxLogo from  "../../assets/logo.png"
import { clearPlaces, removeCredentials, removeEmail, clearAccount } from "../../actions"
import { firebaseApp } from "../../firebase"

const moment = require("moment-timezone")
const stopwords = require("stopword")

const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})

class ReviewsContainer extends Component {
    constructor(props) {
        super(props)

        let startDate = new Date()
        startDate.setUTCDate(startDate.getUTCDate() - 6)
        let endDate = new Date()

        this.state = {
            showDateRangePicker: false,
            dateRangePicker: {
                startDate,
                endDate,
                key: 'selection',
                color: "#FF832D"
            },
            rawReviewData: [], // holds raw data from backend api
            reviewData: [],
            ratingDistribution: [],
            wordCounts: [],

            snackMessage: "",
            drawerItems: props.account.menuItems,
            location: props.location,
            loading: false,
            redirectParams: null,

            credentials: props.credentials,
            account: props.account,
            airport: props.airport
        }
    }

    /** lifecycle  */
    componentDidMount() {
        this._getReviewData()
    }
    
    

    /** END lifecycle */

    /** event listeners */

    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onToggleShowDatePicker = () => this.setState({showDateRangePicker: !this.state.showDateRangePicker})
    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})
    _onSelectDateRange = (range) => this.setState({dateRangePicker: range.selection})
    _onClickUpdateDateRange = () => this.setState({showDateRangePicker: false}, this._getReviewData)
    
    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: error})
        })
    }

    /** END event listeners */

    /** network requests */
    _getReviewData = () => {
        this.setState({loading: true})

        let { startDate, endDate } = this.state.dateRangePicker
        startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCDate()}`
        endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}`

        const api = new AirportInsightsAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestFeedbackData(startDate, endDate,
            (success) => {
                const rawReviewData = success.data
                this.setState({loading: false, rawReviewData}, this._processReviewData)
            },
            (error) => {
                const snackMessage = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage})                
            })
        
    }

    /** END network requests */

    _processReviewData = () => {
        const { rawReviewData } = this.state

        let reviewData = rawReviewData.map(datum => {
            datum.rating_utc = moment.utc(datum.rating_utc)
            return datum
        })

        let ratingDistribution = d3.nest()
            .key(datum => { return datum.numeric_rating} )
            .rollup(rows => {
                return {
                    n: d3.sum(rows, row => { return row.numeric_rating !== null ? 1 : 0})
                }
            })
            .entries(rawReviewData)

        ratingDistribution = ratingDistribution.map((datum) => {
            return {
                label: datum.key,
                x: parseInt(datum.key),
                value: datum.value.n
            }
        })

        let words = rawReviewData.map((datum) => {
            return datum.comment.trim().toLowerCase()
        }).join(" ").split(" ")
        
        words = stopwords.removeStopwords(words)    
            .map(word => {
                return {
                    word
                }
            })

        let wordCounts = d3.nest()
            .key(datum => { return datum.word })
            .rollup(rows => {
                return {
                    n: d3.sum(rows, row => { return 1 })
                }
            })
            .entries(words)
        
        wordCounts = wordCounts.map((datum) => {
            return {
                text: datum.key,
                value: datum.value.n
            }
        }).filter(datum => datum.value >= 5)

        this.setState({reviewData, ratingDistribution, wordCounts})
    }

    render = () => {
        return (
            <Reviews 
                classes={this.props.classes}
                state={this.state} 
                onToggleShowDatePicker={this._onToggleShowDatePicker}
                onSelectDateRange={this._onSelectDateRange}
                onClickUpdateDateRange={this._onClickUpdateDateRange}
                onClickDrawerItem={this._onClickDrawerItem}
                onClickDrawerLogout={this._onClickDrawerLogout}
                onCloseSnack={this._onCloseSnack}/>
        )
    }

}

function mapStateToProps(state) {
    const { server, account, credentials, airport } = state
    return { server, account, credentials, airport }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces,
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(ReviewsContainer)