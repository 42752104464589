import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import { withStyles } from "@material-ui/core/styles"

import WarningIcon from "@material-ui/icons/Warning"

import FetchyFoxLogo from "../../assets/logo.png"
import { isEmptyString } from "../common/utils"
import { MerchantSessionAPI } from "../common/requests/merchant/account"
import { removeCredentials, removeEmail } from "../../actions"
import LoadingDialog from "../common/components/LoadingDialog"
import { FetchyIntercom } from "../common/components/FetchyIntercom"
import { UPDATE_VIEW } from "../globals";

const countryLookup = require("country-data").lookup

const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    warningIcon: {
        height: "72px",
        width: "72px",
        color: "#FF832D"
    }
})

class Unverified extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: "",
            loading: false,
            snackMessage: ""
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    _onClickEditProfile = () => this.setState({redirect: "/merchant/basic"})
    _onClickEditStripe = () => { this.setState({redirect: "/merchant/platform"})}

    _onClickLogout = () => this._logout()

    _logout = () => {
        this.setState({loading: true})
        const api = new MerchantSessionAPI(this.props.server.host)
        api.requestLogout(this.props.credentials.username, this.props.credentials.password,
            (success) => {
                this.props.removeCredentials()
                this.props.removeEmail()
                this.setState({loading: false, redirect: "/"})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    render = () => {
        if(!isEmptyString(this.state.redirect))
            return <Redirect to={{pathname: this.state.redirect, viewMode: UPDATE_VIEW}}/>

        const { classes } = this.props
        let country = countryLookup.countries({ name: this.props.airport.country })[0]

        return (
            <div style={{height: "100vh", width: "100vw"}}>
                <AppBar color="default">
                    <Toolbar>
                        <div className={classes.fetchyFoxLogo}/>
                    </Toolbar>
                </AppBar>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard -- Unverified"}}/>
                <Grid container justify="center" alignItems="center" style={{marginTop: 150}}>
                    <Grid item xs={11} md={10} lg={8}>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h5">Pending Approval</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <WarningIcon className={classes.warningIcon} />
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="body1">
                                    Your application has been submitted and is currently being reviewed by FetchyFox and
                                    {` ${this.props.airport.name}`}.
                                    We'll contact you at <strong>{this.props.credentials.email}</strong> if we need
                                    any additional information from you.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={this._onClickEditProfile}>Update Profile Info</Button>
                                </Grid>
                            </Grid>
                            {
                                country.alpha2 === "US" ?
                                    <Grid container justify="center">
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={this._onClickEditStripe}>Update Stripe Info</Button>
                                        </Grid>
                                    </Grid> : <div/>
                            }
                            <Grid container justify="center">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={this.props.onClickRefresh}>Refresh</Button>
                                </Grid>                            
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={this._onClickLogout}>Logout</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingDialog show={this.state.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={this.state.snackMessage}
                    open={!isEmptyString(this.state.snackMessage)}
                    onClose={this._onCloseSnack}
                    action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account } = state
    return { server, credentials, airport, account }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail
}

export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(Unverified)
