import { findCurrency } from "currency-formatter"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Button,
    Input,
    Checkbox,
    ListItemText,
    Typography,
    TextField,
    Divider,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Switch,
    IconButton
} from "@material-ui/core"

import DeleteIcon from "@material-ui/icons/Delete"

import { StatusSnackbar, StatusSnackbarTypes } from "./common/components/StatusSnackbar"
import Spinner from "./common/components/Spinner"
import LoadingDialog from "./common/components/LoadingDialog"
import AutoCompleteField from "./common/components/AutoCompleteField"
import ImageCropPreview from "./common/components/ImageCropPreview"
import { CurrencyInputField } from "./common/components/CurrencyField"
import { DelayedActionButton } from "./common/components/DelayedDelete"
import { isEmptyString, toTitleCase } from "./common/utils"

import { MenuAPI } from "./common/requests/merchant/menu"
import { ConstantsAPI } from "./common/requests/merchant/constants"
import { ItemAPI } from "./common/requests/merchant/item"

class Sellable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menuOutline: [],
            productLibrary: [],
            dietaryTypes: {},
            productCategories: {},
            searchPhrase: "",
            
            sellableId: props.data === undefined ? "" : props.data.sellable_id || "",
            name: props.data === undefined ? "" : props.data.name || "",
            description: props.data === undefined ? "" : props.data.description || "",
            menuId: props.data === undefined ? "" : props.data.menu_id || "",
            menuCategoryId: props.data === undefined ? "" : props.data.category_id || "",
            price: props.data === undefined || props.data.price === undefined ? 0 : props.data.price.base_unit  || 0,
            type: props.data === undefined ? "" : props.data.type  || "",
            listed: props.data === undefined ? false : props.data.listed || false,
            fulfillmentSeconds: props.data === undefined ? "" : props.data.fulfillment_seconds || "",
            selectedDietaryTypes: props.data === undefined ? [] : props.data.dietary_tags || [],
            mediaUrl: props.data === undefined || props.data.media_urls === undefined ? null : props.data.media_urls[0],
            contents: props.data === undefined ? [] : props.data.contents || [],

            dialogLoading: false, // spinner used to show dialog contents loading
            loading: false, // a spinner dialog that overlays everything
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },

            mediaErrorText: "",
            nameErrorText: "",
            descriptionErrorText: "",
            menuErrorText: "",
            menuSectionErrorText: "",
            fulfillmentErrorText: "",
            contentsErrorText: ""
        }
    }

    componentWillMount = () => {
        this._populateData()
    }

    componentDidUpdate = (prevProps) => {}

    componentDidMount = () => {} 

    _resetErrorText = (callback = ()=>{}) => {
        this.setState({
            mediaErrorText: "",
            nameErrorText: "",
            descriptionErrorText: "",
            menuErrorText: "",
            menuSectionErrorText: "",
            fulfillmentErrorText: "",
            contentsErrorText: ""
        }, callback)
    }

    _resetSnackMessage = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    _onChangeName = name => this.setState({name})

    _onChangeDescription = description => this.setState({description})

    _onSelectMenu = menuId => this.setState({menuId})

    _onSelectMenuSection = menuCategoryId => this.setState({menuCategoryId})

    _onChangePrice = price => this.setState({price})

    _onSelectFulfillmentTime = (fulfillmentSeconds) => this.setState({fulfillmentSeconds})

    _onSelectDietaryTypes = (selectedDietaryTypes) => this.setState({selectedDietaryTypes})

    _onToggleListed = listed => this.setState({listed})


    _onClickDeleteProduct = productIdx => {
        const contents = this.state.contents.filter((product, idx) => idx !== productIdx)
        this.setState({contents})
    }

    _onClickNext = () => {
        this._resetErrorText(() => {
            let { mediaErrorText, nameErrorText, descriptionErrorText, menuErrorText, menuSectionErrorText,
                fulfillmentErrorText, contentsErrorText } = this.state

            if(!this.state.mediaData  && !this.state.mediaUrl && !this.state.mimeType)
                mediaErrorText = "An image is required."
    
            if(isEmptyString(this.state.name))
                nameErrorText = "Required"
            
            if(isEmptyString(this.state.description))
                descriptionErrorText = "Required"
            
            if(isEmptyString(this.state.menuId))
                menuErrorText = "Required"
    
            if(isEmptyString(this.state.menuCategoryId))
                menuSectionErrorText = "Required"
            
            // TODO: NOTE, this could be a check against -1. 0 could be used to mean instant
            // fulfillment in the future
            if(isEmptyString(this.state.fulfillmentSeconds))
                fulfillmentErrorText = "Required"
    
            if(this.state.contents.length === 0)
                contentsErrorText = "At least 1 product must be added."
    
            if([ mediaErrorText, nameErrorText, descriptionErrorText, menuErrorText, menuSectionErrorText,
                fulfillmentErrorText, contentsErrorText ].every(isEmptyString)) {
                // create the json payload to be returned
                const currency = findCurrency(this.props.airport.iso4217)
                
                // the item ids of all the newly added items. these will have undefined
                // item options.. since we need to fetch the details
                const newItemIds = this.state.contents.map(content => { 
                    if(content.options === undefined)
                        return content.item_id 
                    return null
                }).filter(content => content !== null)

                // these are contents that were previously prices, we don't need to pull
                // the details for these items
                const pricedContents = this.state.contents.filter(content => content.options !== undefined)

                this._getItemDetails(newItemIds, (contents) => {
                    const newContents = [...pricedContents, ...contents]
                    
                    // this is the data sent to the FetchyFox backend to create the sellable
                    const payload = {
                        sellable_id: this.state.sellableId,
                        place_id: this.props.placeId,
                        name: this.state.name,
                        description: this.state.description,
                        type: newContents.length === 1 ? "INDIVIDUAL" : "COLLECTION",
                        listed: this.state.listed,
                        fulfillment_seconds: this.state.fulfillmentSeconds,
                        dietary_tags: this.state.selectedDietaryTypes,
                        media_urls: this.props.variant === "create" && this.state.mediaUrl  ? [this.state.mediaUrl] : null,
                        price: {
                            iso_4217: this.props.airport.iso4217,
                            base_unit: this.state.price,
                            exponent: currency.decimalDigits
                        },
                        contents: newContents
                    }
                    this.props.onContinueCallback(this.props.variant, this.state.menuCategoryId, payload, 
                        this.state.mediaData, this.state.mimeType)
                })

            }
            else {
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message: "Please provide all required information"
                }
                this.setState({ mediaErrorText, nameErrorText, descriptionErrorText, menuErrorText, menuSectionErrorText,
                    fulfillmentErrorText, contentsErrorText, snackMessage })
            }
        })
    }


    _onSelectProductListener = itemId => {
        const product = this.state.productLibrary.filter(product => product.item_id === itemId)[0]
        let { contents } = this.state
        if(contents.length >= 5) {
            this.setState({searchPhrase: "", 
                snackMessage: {
                    message: "The item limit has been reached.",
                    level: StatusSnackbarTypes.INFO
                }
            })
        }
        else {
            contents.push(product)
            this.setState({contents, searchPhrase: ""})
        }
    }

    _onChangeSearchListener = searchPhrase => this.setState({searchPhrase})

    _onSelectMediaListener = (mediaData, mimeType) => this.setState({mediaData, mimeType})

    /** Network Requests */

    _populateData = (onSuccess = () => {}) => {
        // gets the product library and menu outline for the given place to populate the UI inputs
        this.setState({dialogLoading: true})
        const itemAPI = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const menuAPI = new MenuAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        const constantsAPI = new ConstantsAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)
        Promise.all([
            itemAPI.promiseGetProductLibrary(this.props.placeId),
            menuAPI.promiseMenusOutline(this.props.placeId),
            constantsAPI.promiseDietaryTypes(),
            constantsAPI.promiseItemTypes()
            ])
            .then(([libraryResponse, menuResponse, dietaryTypesResponse, itemTypesResponse]) => {
                this.setState({
                    dialogLoading: false,
                    menuOutline: menuResponse.data,
                    productLibrary: libraryResponse.data.map(product => {
                        return {
                            label: product.name,
                            value: product.item_id,
                            name: product.name,
                            description: product.description,
                            media_url: product.media_url,
                            item_id: product.item_id
                        }                        
                    }),
                    dietaryTypes: dietaryTypesResponse.data.types,
                    productCategories: itemTypesResponse.data.types,
                })
                onSuccess()
            })
            .catch((error) =>{
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({dialogLoading: false, snackMessage})                
            })
    }

    _getItemDetails = (itemIds, onSuccess = (data) => {}) => {
        this.setState({loading: true})
        const api = new ItemAPI(this.props.server.host, this.props.credentials.username, this.props.credentials.password)

        Promise.all(itemIds.map(itemId => {return api.promiseItemDetails(itemId)}))
            .then(responses => {
                const payload = responses.map(response => {return response.data})
                this.setState({loading: false})
                onSuccess(payload)
            })
            .catch(error => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})                  
            })
    }

    /** END network requests */

    _renderContents = () => {
        return (
            <Grid container>{
                this.state.contents.map((product, productIdx) => {
                    let description = ""
                    if(product.options && (product.options.length === 0 || product.options.length > 1))
                        description = `${product.options.length} Customization Group(s)`

                    return (
                        <Grid item xs={12} key={productIdx}>
                            <Card style={{display: "flex", boxShadow: "none"}}>
                                <CardMedia image={product.media_url} style={{width: "100px"}} />
                                <CardContent style={{flex: "1 1 auto"}}>
                                    <Typography variant="body1">{product.name}</Typography>
                                    <Typography variant="body2">{description}</Typography>
                                </CardContent>
                                <CardActions>
                                    <IconButton onClick={() => this._onClickDeleteProduct(productIdx)}>
                                        <DeleteIcon style={{color: "#f44336"}}/>
                                    </IconButton>
                                </CardActions>                                
                            </Card>
                        </Grid>
                    )

                })                
            }</Grid>
        )
    }

    _renderDeleteButton = () => {
        if(this.props.variant === "update") {
            return (
                <Grid item xs={12}>
                    <Card style={{display: "flex", boxShadow: "none"}}>
                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                            <Typography variant="body1">Delete</Typography>
                            <Typography variant="caption">This action only deletes this sellable, all 
                            products within the sellable will remain in the product library.</Typography>
                        </CardContent>
                        <CardActions style={{paddingRight: "0px"}}>
                            <DelayedActionButton
                                showConfirmDialog={false}
                                onConfirmAction={ () => this.props.onDeleteCallback(this.state.sellableId)}
                                onCancelAction={ () => console.log("CANCELED")}/>
                        </CardActions>
                    </Card>
                </Grid>
            )
        }
        else
            return <div/>
    }

    render = () => {
        const title = toTitleCase(this.props.variant)

        const dietaryTags = Object.keys(this.state.dietaryTypes).map(tag => {
            return {
                value: tag,
                label:this.state.dietaryTypes[tag].nicename,
                description:this.state.dietaryTypes[tag].description
            }
        })

        return (
            <Dialog
                open
                maxWidth="lg"
                fullWidth
                fullScreen={false} // TODO
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>{`${title} Sellable`}</DialogTitle>
                <DialogContent>
                    {
                        this.state.dialogLoading ? 
                            <Spinner label="Getting menu data..."/> :
                            <Grid container justify="center">
                                <ImageCropPreview
                                    pictureUrl={this.state.mediaUrl}
                                    errorText={this.state.mediaErrorText}
                                    onSelectImage={this._onSelectMediaListener}/>
                                <Grid item xs={12}>
                                    <TextField 
                                        label="Name"
                                        error={!isEmptyString(this.state.nameErrorText)}
                                        helperText={this.state.nameErrorText}
                                        value={this.state.name}
                                        onChange={(event) => this._onChangeName(event.target.value)} 
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        label="Description"
                                        error={!isEmptyString(this.state.descriptionErrorText)}
                                        helperText={this.state.descriptionErrorText}
                                        value={this.state.description}
                                        onChange={(event) => this._onChangeDescription(event.target.value)}
                                        rows={2} 
                                        multiline fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Menu</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <FormControl error={!isEmptyString(this.state.menuErrorText)} style={{width: "200px"}}>
                                                <FormHelperText>{this.state.menuErrorText}</FormHelperText>
                                                <Select 
                                                    variant="standard"
                                                    value={this.state.menuId}
                                                    onChange={(event) => this._onSelectMenu(event.target.value)}>{
                                                        this.state.menuOutline.map((menu, menuIdx) => {
                                                            return <MenuItem value={menu.id} key={menuIdx}>{menu.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Menu Section</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <FormControl error={!isEmptyString(this.state.menuSectionErrorText)} style={{width: "200px"}}>
                                                <FormHelperText>{this.state.menuSectionErrorText}</FormHelperText>
                                                <Select
                                                    disabled={isEmptyString(this.state.menuId)}
                                                    variant="standard"
                                                    value={this.state.menuCategoryId}
                                                    onChange={(event) => this._onSelectMenuSection(event.target.value)}>{
                                                        !isEmptyString(this.state.menuId) ? 
                                                            this.state.menuOutline.filter(menu => menu.id === this.state.menuId)[0].sections
                                                                .map((section, sectionIdx) => {
                                                                    return <MenuItem value={section.id} key={sectionIdx}>{section.name}</MenuItem>
                                                                }) :
                                                            <div/>
                                                    }
                                                </Select>
                                            </FormControl>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Price</Typography>
                                            <Typography variant="caption">The base price of this listing. Customizations that may change the price are setup in the next step.</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <CurrencyInputField 
                                                variant="outlined" 
                                                value={this.state.price}
                                                onChange={this._onChangePrice}
                                                iso4217={this.props.airport.iso4217}
                                                size="small"
                                                style={{width: "200px"}}/>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Preparation Estimate</Typography>
                                            <Typography variant="caption">The typical amount of time it takes to prepare this for pickup.</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <FormControl error={!isEmptyString(this.state.fulfillmentErrorText)} style={{width: "200px"}}>
                                                <FormHelperText>{this.state.fulfillmentErrorText}</FormHelperText>
                                                <Select 
                                                    variant="standard"
                                                    value={this.state.fulfillmentSeconds}
                                                    onChange={(event) => this._onSelectFulfillmentTime(event.target.value)}>
                                                    <MenuItem value="60">1 minute</MenuItem>
                                                    <MenuItem value="300">5 minutes</MenuItem>
                                                    <MenuItem value="900">15 minutes</MenuItem>
                                                    <MenuItem value="1600">30 minutes</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Dietary Tags</Typography>
                                            <Typography variant="caption">(optional) Dietary information about this listing as a whole.</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <FormControl style={{width: "200px"}}>
                                                <Select
                                                    multiple
                                                    value={this.state.selectedDietaryTypes}
                                                    renderValue={() => {return `${this.state.selectedDietaryTypes.length} selected`}}
                                                    onChange={(event) => this._onSelectDietaryTypes(event.target.value)}
                                                    input={<Input/>}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 500
                                                            }
                                                        }
                                                    }}>
                                                    {
                                                        dietaryTags.map((tag, tagIdx) => (
                                                            <MenuItem key={tagIdx} value={tag.value}>
                                                                <Checkbox checked={this.state.selectedDietaryTypes.indexOf(tag.value) > -1} color="primary"/>
                                                                <ListItemText primary={tag.label}/>
                                                            </MenuItem>                                                            
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{display: "flex", boxShadow: "none"}}>
                                        <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                                            <Typography variant="body1">Listed</Typography>
                                            <Typography variant="caption">Determines if this listing is available for sale.</Typography>
                                        </CardContent>
                                        <CardActions style={{paddingRight: "0px"}}>
                                            <Switch
                                                checked={this.state.listed}
                                                onChange={(event) => this._onToggleListed(event.target.checked)}
                                                color="primary"/>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                { this._renderDeleteButton() }                               
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Add Products (max: 5)</Typography>
                                    <Typography variant="body2">A listing can contain one or multiple products. Multiple products create a "combo".</Typography>
                                    <FormControl error={!isEmptyString(this.state.contentsErrorText)}>
                                        <FormHelperText>{this.state.contentsErrorText}</FormHelperText>
                                    </FormControl>
                                    <div style={{height: "10px"}}/>
                                    <AutoCompleteField
                                        label=""
                                        size="small"
                                        searchPhrase={this.state.searchPhrase}
                                        suggestions={this.state.productLibrary}
                                        onSelectCallback={this._onSelectProductListener}
                                        onTypeSearchPhrase={this._onChangeSearchListener}/>
                                </Grid>
                                <Grid item xs={12}>
                                    { this._renderContents() }
                                </Grid>
                            </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={this.props.onCancelCallback}>Cancel</Button>
                    <Button variant="text" 
                        color="primary" 
                        disabled={this.state.dialogLoading}
                        onClick={this._onClickNext}>Next: Customization Pricing</Button>
                </DialogActions>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._resetSnackMessage}/>   
                <LoadingDialog
                    show={this.state.loading}
                    message="Loading..."/>
            </Dialog>
        )
    }
}

Sellable.propTypes = {
    placeId: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    onCancelCallback: PropTypes.func.isRequired,
    onDeleteCallback: PropTypes.func.isRequired,
    onContinueCallback: PropTypes.func.isRequired,
    data: PropTypes.object
}

Sellable.defaultProps = {
    placeId: "", 
    onCancelCallback: () => console.log("default onCancelCallback"),
    onDeleteCallback: (sellableId) => console.log("default onDeleteCallback"),
    onContinueCallback: (categoryId, payload, mimeData, mimeType) => console.log("default onContinueCallback")
}

function mapStateToProps(state) {
    const { credentials, server, airport } = state
    return { credentials, server, airport }
}

export default connect(mapStateToProps, null)(Sellable)



