import PropTypes from "prop-types"
import React from "react"
import { Redirect } from "react-router-dom"

import { StatusSnackbar } from "../../common/components/StatusSnackbar"
import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import NavDrawer from "../../common/components/NavDrawer"
import { isEmptyString } from "../../common/utils"
import LoadingDialog from "../../common/components/LoadingDialog"

import { Menu } from "../../pieces/Menu"

export const AirportMenu = (props) => {
    const { classes, state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <FetchyIntercom 
                userId={state.credentials.username}
                email={state.credentials.email} 
                name={state.credentials.username}
                airport={state.airport.name}
                route={state.location.pathname}
                accountType={state.account.accountType} 
                customstate={{source: "Web Dashboard"}}/>    
            <main style={{width: "99vw"}}>
                <div className={classes.spacerTop}/>
                <Menu
                    classes={classes}
                    state={state}

                    onToggleSellableListingStatus={props.onToggleSellableListingStatus}
                    onChangeSellablePrice={props.onChangeSellablePrice}
                    onClickUpdateSellablePrice={props.onClickUpdateSellablePrice}
                    onClickManageSellable={props.onClickManageSellable}
                    onTypeSearch={props.onTypeSearch}
                    onSelectMenu={props.onSelectMenu}
                    onSelectSection={props.onSelectSection}
                    onClickRefresh={props.onClickRefresh}/>
                <LoadingDialog show={state.loading}/>
                <StatusSnackbar
                    open={!isEmptyString(state.snackMessage.message)}
                    variant={state.snackMessage.level}
                    message={state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={props.onCloseSnack}/>                  
            </main>
        </div>
    )
}

AirportMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func,
    onCloseSnack: PropTypes.func,

    onToggleSellableListingStatus: PropTypes.func,
    onChangeSellablePrice: PropTypes.func,
    onClickUpdateSellablePrice: PropTypes.func,
    onClickManageSellable: PropTypes.func,
    onClickRefresh: PropTypes.func
}

AirportMenu.defaultProps = {
    onClickDrawerItem: (redirectParams) => {},
    onClickDrawerLogout: () => {},
    onCloseSnack: () => {},
    onChangeSellablePrice: (sellableId, value) => {},
    onToggleSellableListingStatus: (sellableId, listed) => {},
    onClickUpdateSellablePrice: (sellableId) => {},
    onClickManageSellable: (sellableId) => {},
    onTypeSearch: (value) => {},
    onSelectMenu: (menuId) => {},
    onSelectSection: (sectionId) => {},    
    onClickRefresh: () => {},
}
