import PropTypes from "prop-types"
import React from "react"

import {
    Grid,
    TextField,
    Typography,
    Button,
    Link,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Select,
    Checkbox,
    MenuItem,
    ListItemIcon,
    InputLabel,
    OutlinedInput
} from "@material-ui/core"

import { 
    Autocomplete 
} from '@material-ui/lab';

import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import StoreIcon from "@material-ui/icons/Store"
import LocalAirportIcon from "@material-ui/icons/LocalAirport"

import MaskedPasswordField from "../common/components/MaskedPasswordField"
import { isEmptyString } from "../common/utils"
import { FOX_ACCOUNT, AIRPORT_ACCOUNT, MERCHANT_ACCOUNT } from "../globals"

export const HomeView = (props) => {
    const { classes, data } = props
    return (
        <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
            <Grid item xs={11}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.fetchyFoxLogo}/>
                        <Typography variant="h2">Hey there!</Typography>
                        <div style={{height: 20}}/>
                        <Typography variant="h5">Ready to make airports a happier place?</Typography>
                        <div style={{height: 40}}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField {...params} 
                                    variant="outlined"
                                    label="Your airport"
                                    error={!isEmptyString(data.airportErrorText)}
                                    helperText={data.airportErrorText}/>
                            )}
                            onChange={(event, value, reason) => props.onSelectAirport(value.value)}
                            getOptionLabel={(airport) => airport.label}
                            options={data.airports}
                            autoComplete
                            clearOnEscape/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth error={!isEmptyString(data.roleErrorText)}>
                            <InputLabel>Your Role</InputLabel>
                            <Select
                                value={data.role}
                                onChange={(event) => props.onSelectRole(event.target.value)}
                                input={<OutlinedInput labelWidth={75}/>}
                                >
                                <MenuItem value="admin">
                                    <ListItemIcon>
                                        <LocalAirportIcon color="primary"/>
                                    </ListItemIcon>
                                    <Typography variant="body1">Admin</Typography>
                                </MenuItem>
                                <MenuItem value="merchant">
                                    <ListItemIcon>
                                        <StoreIcon color="primary"/>
                                    </ListItemIcon>
                                    <Typography variant="body1">Merchant</Typography>
                                </MenuItem>
                                <MenuItem value="fox">
                                    <ListItemIcon>
                                        <DirectionsRunIcon color="primary"/>
                                    </ListItemIcon>
                                    <Typography variant="body1">Fox</Typography>
                                </MenuItem>

                            </Select>
                            <FormHelperText>{data.roleErrorText}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            color="primary"
                            value={data.username}
                            onChange={(event) => props.onTypeUsername(event.target.value)}
                            error={!isEmptyString(data.usernameErrorText)}
                            helperText={data.usernameErrorText}
                            fullWidth/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <MaskedPasswordField
                            variant="outlined"
                            label="Passphrase"
                            value={data.passphrase}
                            onTypePassword={(value) => props.onTypePassphrase(value)}
                            errorText={data.passphraseErrorText}
                            fullWidth/>
                    </Grid>
                </Grid>
                {   data.role === FOX_ACCOUNT || data.role === MERCHANT_ACCOUNT ?
                        <Grid container justify="flex-start">
                            <Grid item xs={12} lg={6}>
                                <Link href={`/${data.role}/forgot`} variant="caption">Forgot Password?</Link>
                            </Grid>
                        </Grid> : <div />
                }
                <div style={{height: 20}}/>
                <Grid container justify="flex-end">
                    {
                    data.role !== AIRPORT_ACCOUNT ?
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={props.onClickJoin}
                                fullWidth>Join</Button>
                        </Grid> : <div/>
                    }
                    {
                        data.role === AIRPORT_ACCOUNT ?
                            <Grid item xs={12} sm={8}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.rememberMe}
                                            onChange={(event) => props.onCheckRememberMe(event.target.checked)}
                                            value="rememberMe"
                                            color="primary"/>
                                    }
                                    label="Remember this device"
                                />
                            </Grid> : <div />
                    }
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={props.onClickLogin}
                            fullWidth>Login</Button>
                    </Grid>
                </Grid>
                <div style={{height: 20}}/>
                <Grid container>
                    <Grid item>
                        <Typography variant="caption">{process.env.REACT_APP_VERSION}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">{ process.env.REACT_APP_DEVELOPMENT === "1" ? "DEVELOPMENT" : ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography variant="caption">FetchyFox Inc {new Date().getFullYear()}</Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

HomeView.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,

    onSelectAirport: PropTypes.func.isRequired,
    onTypeSearchPhrase: PropTypes.func.isRequired,
    onSelectRole: PropTypes.func.isRequired,
    onTypeUsername: PropTypes.func.isRequired,
    onTypePassphrase: PropTypes.func.isRequired,
    onClickJoin: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired
}

