/*

    a simple confirm/cancel dialog that's less extreme than the ConfirmActionDialog
    component. Used to confirm less important but nonetheless destructive actions

*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from "@material-ui/core";

class SimpleConfirmDialog extends Component {
    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCloseCallback}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{this.props.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.props.onCloseCallback}>
                        No
                    </Button>
                    <Button onClick={this.props.onConfirmCallback}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    };
}

SimpleConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirmCallback: PropTypes.func.isRequired,
    onCloseCallback: PropTypes.func
};

SimpleConfirmDialog.defaultProps = {
    onCloseCallback: () => {
        console.log("default onCloseCallback");
    }
};

export default SimpleConfirmDialog;
