import axios from "axios"
import { handleFetchyAPIError,
        formatFetchyAPIFailure,
        formatFetchyAPISuccess } from "../base"

export class PrepaidCardAPI {
    constructor(host, fetchyUuid, fetchyToken){
        this.host = host
        this.username = fetchyUuid
        this.password = fetchyToken
    }

    promisePrepaidCard = () => {
        return axios({
            url: this.host + "/v1/concierge/card",
            method: "GET",
            auth: {
                username: this.username,
                password: this.password
            },
        })
    }

    tryPromisePrepaidCard = () => {
        return this.promisePrepaidCard()
            .then(response => {
                return formatFetchyAPISuccess(response)
            })
            .catch(error => {
                return formatFetchyAPIFailure(error)
            })
    }

    requestPrepaidCard = (onResponseSuccess, onResponseError) => {
        this.promisePrepaidCard().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseActivatePrepaidCard = (last4, uniqueId, iataCode) => {
        return axios({
            url: this.host + "/v1/concierge/card",
            method: "POST",
            auth: {
                username: this.username,
                password: this.password
            },
            data: {
                last4,
                iden_code: uniqueId,
                iata_code: iataCode
            }
        })
    }

    tryPromiseActivatePrepaidCard = (last4, uniqueId, iataCode) => {
        return this.promiseActivatePrepaidCard(last4, uniqueId, iataCode).then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestActivatePrepaidCard = (last4, uniqueId, iataCode, onResponseSuccess, onResponseError) => {
        this.promiseActivatePrepaidCard(last4, uniqueId, iataCode).then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }

    promiseBlockPrepaidCard = () => {
        return axios({
            url: this.host + "/v1/concierge/card",
            method: "DELETE",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    tryPromiseBlockPrepaidCard = () => {
        return this.promiseBlockPrepaidCard().then(response => {
            return formatFetchyAPISuccess(response)
        })
        .catch(error => {
            return formatFetchyAPIFailure(error)
        })
    }

    requestBlockPrepaidCard = (onResponseSuccess, onResponseError) => {
        this.promiseBlockPrepaidCard().then(response => {
            onResponseSuccess(response)
        }).catch(error => {
            const result = handleFetchyAPIError(error)
            onResponseError(result)
        })
    }


}



