import axios from "axios"
import { tryPromise, requestPromise } from "../base"


export class CouponAPI {
    constructor(host, merchant_id, fetchy_token) {
        this.host = host
        this.username = merchant_id
        this.password = fetchy_token
    }

    promiseCreateCoupon = (data) => {
        return axios({
            url: `${this.host}/v1/merchant/coupon`,
            method: "post",
            auth: {
                username: this.username,
                password: this.password
            },
            data
        })
    }

    promiseDeleteCoupon = (coupon_id) => {
        return axios({
            url: `${this.host}/v1/merchant/coupon`,
            method: "delete",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                coupon_id
            }
        })
    }

    promiseGetCouponDetails = (coupon_id) => {
        return axios({
            url: `${this.host}/v1/merchant/coupon`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                coupon_id
            }
        })
    }

    promiseUpdateActiveStatus = (coupon_id, active) => {
        return axios({
            url: `${this.host}/v1/merchant/coupon`,
            method: "put",
            auth: {
                username: this.username,
                password: this.password
            },
            params: {
                coupon_id
            },
            data: {
                active
            }
        })        
    }

    promiseGetCoupons = () => {
        return axios({
            url: `${this.host}/v1/merchant/coupons`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            },            
        })
    }



    tryPromiseCreateCoupon = (payload) => {
        tryPromise(this.promiseCreateCoupon, ...[payload])
    }

    requestCreateCoupon = (payload, onRequestSuccess, onRequestError) => {
        requestPromise(this.promiseCreateCoupon, onRequestSuccess, onRequestError, ...[payload])
    }


    tryPromiseGetCoupons = (cou) => {
        tryPromise(this.promiseCreateCoupon)
    }

    requestGetCoupons = (onRequestSuccess, onRequestError) => {
        requestPromise(this.promiseGetCoupons, onRequestSuccess, onRequestError)
    }


    tryPromiseDeleteCoupon = (couponId) => {
        tryPromise(this.promiseDeleteCoupon, ...[couponId])
    }

    requestDeleteCoupon = (couponId, onRequestSuccess, onRequestError) => {
        requestPromise(this.promiseDeleteCoupon, onRequestSuccess, onRequestError, ...[couponId])
    }


    tryPromiseUpdateActiveStatus = (couponId, active) => {
        tryPromise(this.promiseUpdateActiveStatus, ...[couponId])
    }

    requestUpdateActiveStatus = (couponId, active, onRequestSuccess, onRequestError) => {
        requestPromise(this.promiseUpdateActiveStatus, onRequestSuccess, onRequestError, ...[couponId, active])
    }


    tryPromiseGetCouponDetails = (couponId) => {
        tryPromise(this.promiseGetCouponDetails, ...[couponId])
    }

    requestCouponDetails = (couponId, onRequestSuccess, onRequestError) => {
        requestPromise(this.promiseGetCouponDetails, onRequestSuccess, onRequestError, ...[couponId])
    }
}