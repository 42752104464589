import React from "react"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Snackbar from "@material-ui/core/Snackbar"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import { isEmptyString } from "../../common/utils"
import LoadingDialog from "../../common/components/LoadingDialog"
import { getPersonalDataDisclaimer } from "../datacollection"

function _renderTabContents(props) {
    const { data } = props

    switch(data.selectedTabIdx) {
        case 0:
            return (
                <div style={{height: 400, maxHeight: 400, overflow: 'auto'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h5">Personal Data Collection</Typography>
                            <Typography variant="body1">The following describes the personal data we require from you, the reason(s)
                            we collect it, and sharing details. You will be asked for this data during onboarding.</Typography>
                            <div style={{height: 10}}/>
                            <Typography variant="body1">Last Updated: March 2019</Typography>
                        </Grid>
                        {
                            getPersonalDataDisclaimer(props.airportName).map((doc, idx) => {
                                return (
                                    <Grid item xs={12} key={idx}>
                                        <div style={{height: 20}}/>
                                        <Typography variant="subtitle1">{doc.dataType}</Typography>
                                        <Typography variant="body1">{doc.reason}</Typography>
                                        <div style={{height: 10}}/>
                                        {
                                            !isEmptyString(doc.infolink) ? <Button variant="text" rel="noreferrer" href={doc.infolink} color="secondary">More Info</Button> : <div/>
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            )
        case 1:
            return (
                <div style={{height: 400, maxHeight: 400, overflow: 'auto'}}>
                    <Typography variant="h5">FetchyFox Privacy Policy</Typography>
                    <Typography variant="body1">Last Updated: May 2018</Typography>
                    <div style={{height: 20}}/>
                    <Typography variant="body1">
                        {data.privacyText}
                    </Typography>
                </div>
            )
        case 2:
            return (
                <div style={{height: 400, maxHeight: 400, overflow: 'auto'}}>
                    <Typography variant="h5">FetchyFox Terms of Service</Typography>
                    <Typography variant="body1">Last Updated: May 2018 </Typography>
                    <div style={{height: 20}}/>
                    <Typography variant="body1">
                        {data.termsText}
                    </Typography>
                </div>
            )
        default:
            return <div/>
    }
}

export const Legal = (props) => {
    const { classes, data } = props

    if(data.redirectParams && data.redirectParams.pathname !== data.currentPath)
        return <Redirect to={data.redirectParams} />

    return (
        <div>
            <AppBar color="default">
                <Toolbar>
                    <div className={classes.fetchyFoxLogo}/>
                </Toolbar>
            </AppBar>
            <Grid container justify="center" alignItems="center" style={{marginTop: 150}}>
                <Grid item xs={11} md={10} lg={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Toolbar color="secondary" variant="regular">
                                <Tabs value={data.selectedTabIdx} onChange={ props.onChangeTab }>
                                    <Tab value={0} label="1. Personal Data"/>
                                    <Tab value={1} label="2. Privacy Policy"/>
                                    <Tab value={2} label="3. Terms of Service"/>

                                </Tabs>
                            </Toolbar>
                        </Grid>
                        <Grid item xs={12}>
                            { _renderTabContents(props) }
                        </Grid>
                    </Grid>
                    <div style={{height: "40px"}}/>
                    <Divider/>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.dataChecked}
                                        onChange={(event) => props.onCheck(event, event.target.checked)}
                                        value="dataChecked"
                                        color="primary"/>
                                }
                                label="(1) I agree to allow FetchyFox use my personal data as listed and described."/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.privacyChecked}
                                        onChange={(event) => props.onCheck(event, event.target.checked)}
                                        value="privacyChecked"
                                        color="primary"/>
                                }
                                label="(2) I have reviewed and agree to the the Privacy Policy."/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.termsChecked}
                                        onChange={(event) => props.onCheck(event, event.target.checked)}
                                        value="termsChecked"
                                        color="primary"/>
                                }
                                label="(3) I have reviewed and agree to the Terms of Service."/>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button
                                variant="text"
                                onClick={() => props.onClickCancel() }
                                size="large">Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.onClickContinue() }
                                size="large">Continue</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingDialog show={data.loading}/>
            <Snackbar
                autoHideDuration={5000}
                message={data.snackMessage}
                open={!isEmptyString(data.snackMessage)}
                onClose={props.onSnackClose}
                action={<Button onClick={() => props.onSnackClose()} color="primary">Close</Button>} />
        </div>
    )
}
