import { FormattedNumber } from "react-intl"
import { findCurrency } from "currency-formatter"
import uuid from "uuid"
import React from "react"
import CalendarHeatmap from "react-calendar-heatmap"
import ReactTooltip from "react-tooltip"
import PropTypes from "prop-types"

import {
    Grid,
    Typography,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Card,
    CardContent
} from "@material-ui/core"

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import { Histogram } from "../common/components/Histogram"
import DateRangeInput from "../common/components/DateRangeInput"
import TableCard from  "../pieces/TableCard"
import HighlightGrid from "../pieces/HighlightGrid"
import DailySalesGraph from "../pieces/DailySalesGraph"
import { DAILY_DATA, HOURLY_DATA} from "../globals"

import "../common/react-calendar-heatmap.css"

const moment = require("moment-timezone")

function _renderHeatmap(props, isMobileScreen) {
    const { classes, state } = props

    if(isMobileScreen) {
        return <div/>
    }
    else {
        const startDateLabel = state.startHeatmapRange.format("ll")
        const endDateLabel = state.endHeatmapRange.format("ll")

        return (
            <div>
                <div style={{height: "10px"}}/>
                <Typography variant="h4">Deliveries over Time</Typography>
                <IconButton className={classes.margin} aria-label="back 3 months" size="small" edge="start" color="secondary"
                    onClick={() => {
                        props.onClickUpdateHeatmapRange(
                            state.startHeatmapRange.subtract(3, "months"),
                            state.endHeatmapRange.subtract(3, "months")                            
                        )
                    }}>
                    <NavigateBeforeIcon/>
                </IconButton>
                <Typography component="span" variant="button" color="secondary">{`${startDateLabel} - ${endDateLabel}`}</Typography>
                <IconButton className={classes.margin} aria-label="forward 3 months" size="small"
                    color="secondary"
                    onClick={() => {
                        props.onClickUpdateHeatmapRange(
                            state.startHeatmapRange.add(3, "months"),
                            state.endHeatmapRange.add(3, "months")                            
                        )
                    }}>
                    <NavigateNextIcon />
                </IconButton>  
                <Divider/>
                <div style={{height: 10}}/>
                <CalendarHeatmap
                    startDate={state.startHeatmapRange.toDate()}
                    endDate={state.endHeatmapRange.toDate()}
                    showWeekdayLabels
                    onClick={() => {}}
                    values={state.deliveryDistributionData}
                    classForValue={value => {
                        if (!value) {
                        return "color-fetchyfox-0";
                        }
                        else if (value.orders >= 9) {
                            return "color-fetchyfox-max"
                        }
                        else {
                            return `color-fetchyfox-${value.orders}`
                        }
                    }}
                    tooltipDataAttrs={value => {
                        if(value.orders)
                            return {
                                "data-tip": `${value.date}: ${value.orders} deliveries`
                            }
                        else
                            return {
                                "data-tip": "No Deliveries"
                            }
                    }}                                                        
                />
                <ReactTooltip />                
            </div>
        )
    }
}

function _renderHeadline(props, isMobileScreen) {
    const { classes, state } = props
    const currency = findCurrency(state.allTimeSummary.iso_4217 || "USD")
    const updated = `Last Updated: ${moment(state.allTimeSummary.latest_order_utc, "YYYY-MM-DD").calendar()}`
    const total = state.allTimeSummary.total_base_unit || 0

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">All-Time Performance</Typography>
                    <Typography variant="subtitle1">{updated}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="h3" className={classes.highlightText}>
                        <FormattedNumber
                            value={  total / 10**currency.decimalDigits }
                            style={`currency`}
                            currency={ state.allTimeSummary.iso_4217 }/>
                    </Typography>
                    <Typography variant="h6">Sales</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Typography variant="h3" className={classes.highlightText}>{state.allTimeSummary.n_orders !== null ? state.allTimeSummary.n_orders : "-" }</Typography>
                    <Typography variant="h6">Orders</Typography>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                    <Typography variant="h3" className={classes.highlightText}>{state.feedbackSummary.n_ratings !== null ? state.feedbackSummary.n_ratings : "-"}</Typography>
                    <Typography variant="h6">Ratings</Typography>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                    <Typography variant="h3" className={classes.highlightText}>{state.feedbackSummary.avg_rating !== null ? state.feedbackSummary.avg_rating.toFixed(2) : "-"}</Typography>
                    <Typography variant="h6">Avg. Rating</Typography>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                    <Typography variant="h3" className={classes.highlightText}>{state.feedbackSummary.median_rating !== null ? state.feedbackSummary.median_rating.toFixed(2) : "-"}</Typography>
                    <Typography variant="h6">Median Rating</Typography>
                </Grid>
                <Grid item xs={12}>
                    { _renderHeatmap(props, isMobileScreen) }
                </Grid>
            </Grid>
        </div>
    )
}   

function _renderHistoricalToolbar(props) {
    const { state } = props

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{height: 20}}/>
                <Typography variant="h4">Historical Performance</Typography>
            </Grid>
            <Grid item xs={12}>
                <DateRangeInput
                    initStartDate={state.startHistoricalRange}
                    initEndDate={state.endHistoricalRange}
                    onClickUpdate={props.onClickUpdateHistoricalRange}/>
            </Grid>
        </Grid>
    )
}

function _renderTopLeft(props) {
    const { state } = props
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Granularity</InputLabel>
                            <Select
                                value={state.dataGranularity}
                                inputProps={{id: "dataGranularity"}}
                                onChange={(event) => props.onSelectGraphGranularity(event.target.value)}>
                                <MenuItem value={HOURLY_DATA}>Hourly</MenuItem>
                                <MenuItem value={DAILY_DATA}>Daily</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="default"
                                onClick={props.onClickDownloadGraphData}>Download CSV</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <DailySalesGraph
                        hourly={state.dataGranularity === HOURLY_DATA}
                        salesData={state.dailySalesData}
                        ordersData={state.dailyOrdersData}/>
                </Grid>

            </CardContent>
        </Card>
    )
}

function _renderTopRight(props) {
    const { state } = props
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>Day of Week</InputLabel>
                            <Select
                                value={state.selectedDayOfWeek}
                                inputProps={{id: "dataGranularity"}}
                                onChange={(event) => props.onSelectDayOfWeek(event.target.value)}>
                                <MenuItem value={0}>Sunday</MenuItem>
                                <MenuItem value={1}>Monday</MenuItem>
                                <MenuItem value={2}>Tuesday</MenuItem>
                                <MenuItem value={3}>Wednesday</MenuItem>
                                <MenuItem value={4}>Thursday</MenuItem>
                                <MenuItem value={5}>Friday</MenuItem>
                                <MenuItem value={6}>Saturday</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>                        
                    <Grid item xs={12}>
                        <Histogram title="Peak Delivery Hours" data={state.peakDeliveryData} xLabel="Hour" yLabel="" key={uuid()}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

function _renderBottomLeft(props) {
    const { state } = props
    return ( <HighlightGrid data={state.highlightData}/> )
}

function _renderBottomRight(props) {
    const { state } = props
    return (
        <div>
            <TableCard
                title="Location Sales (Top 5)"
                columnTitles={["Location", "Total Orders", "Total Sales"]}
                data={state.salesRankings}/>
            <div style={{height: "20px"}}></div>
            <TableCard
                title="Best Sellers (Top 5)"
                columnTitles={["Item", "Quantity", "Total Sales"]}
                data={state.itemRankings}/>
        </div>
    )
}



export const AirportDashboard = (props) => {

    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Grid container justify="center">
            <Grid item xs={11}>
                <Grid container style={{height: 600}}>
                    <Grid item xs={12}>
                        { _renderHeadline(props, isMobileScreen) }
                    </Grid>
                    <Grid item xs={12}>
                        { _renderHistoricalToolbar(props) }
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        { _renderTopLeft(props) }
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        { _renderTopRight(props) }
                    </Grid>
                    <Grid item xs={12}><Divider style={{margin: 10}}/></Grid>
                    <Grid item xs={12} lg={6}>
                        { _renderBottomLeft(props) }
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        { _renderBottomRight(props) }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


AirportDashboard.propTypes = {
    classes: PropTypes.object,
    state: PropTypes.object,
    onSelectDayOfWeek: PropTypes.func.isRequired,
    onSelectGraphGranularity: PropTypes.func.isRequired,
    onClickDownloadGraphData: PropTypes.func.isRequired,
    onClickUpdateHistoricalRange: PropTypes.func.isRequired,
    onClickUpdateHeatmapRange: PropTypes.func.isRequired
}
