import PropTypes from "prop-types"
import React from "react"
import Image from "material-ui-image"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { Card, CardMedia, CardContent, CardActions } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { FormControl, FormControlLabel } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import Switch from "@material-ui/core/Switch"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"

import { isEmptyString } from "../common/utils"
import { CurrencyInputField } from "../common/components/CurrencyField"

function _renderMenuCard(props, sellable, idx) {
    return (
        <Grid item xs={12} lg={6} xl={4} key={idx}>
            <Card>
                <CardMedia title={sellable.name}>
                    <Image src={sellable.media_urls[0]} aspectRatio={1.5} style={{objectFit: "contain"}} />
                </CardMedia>
                <CardContent>
                    <Grid container>
                        <Grid item>
                            <Grid container align="flex-end" justify="center"  direction="column" style={{ height: 50 }}>
                                <Grid item>
                                    <Typography variant="h6">{sellable.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={sellable.listed}
                                        onChange={(event) => props.onToggleSellableListingStatus(sellable.sellable_id, event.target.checked)}/>
                                }
                                label={<Typography variant="subtitle1">Listed</Typography>}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container alignContent="flex-end" justify="flex-end">
                        <Grid item xs={6} sm={2} lg={3} xl={2}>
                            <Grid container align="flex-end" justify="center"  direction="column" style={{ height: 50 }}>
                                <Grid item>
                                    <CurrencyInputField
                                        errorMessage=""
                                        label=""
                                        value={sellable.price.base_unit}
                                        iso4217={sellable.price.iso_4217}
                                        onChange={(value) => { props.onChangeSellablePrice(sellable.sellable_id, value)}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={3} lg={4} xl={3}>
                            <Grid container alignContent="center" justify="center"  direction="column" style={{ height: 50 }}>
                                <Grid item>
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => props.onClickUpdateSellablePrice(sellable.sellable_id) }>Update Price</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={2} lg={3} xl={2}>
                            <Grid container align="center" justify="center"  direction="column" style={{ height: 50 }}>
                                <Grid item>
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => props.onClickManageSellable(sellable.sellable_id)}>Manage</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
}

function _renderMenu(props) {
    const { state } = props

    return (
        <Grid container justify="center"> {
            state.menus.map((menu, midx) => {
                if( isEmptyString(state.selectedMenuId) || menu.menu_id === state.selectedMenuId )
                    return (
                        <Grid item xs={12}  key={midx}>
                            <Typography variant="h4">{menu.menu_name}</Typography>
                            <Grid container justify="flex-start">
                            {
                                menu.categories.map((category, sidx) => {
                                    if( isEmptyString(state.selectedSectionId) || category.category_id === state.selectedSectionId )
                                        return (
                                            <Grid item key={sidx} xs={12}>
                                                <Typography variant="h5" key={sidx}>{category.category_name}</Typography>
                                                <Grid container>{
                                                    category.sellables.filter((sellable) => {
                                                        return isEmptyString(state.searchText) ||  sellable.name.toLowerCase().includes(state.searchText.toLowerCase())
                                                    })
                                                    .map((sellable, idx) => {
                                                        return _renderMenuCard(props, sellable, idx)
                                                    })
                                                }</Grid>
                                            </Grid>
                                        )
                                    else
                                        return <div/>
                                })
                            }
                            </Grid>
                        </Grid>
                    )
                else
                    return <div/>
            })
        } </Grid>

    )
}

export const Menu = (props) => {
    const { state } = props

    return (
        <Grid container justify="center">
            <Grid item xs={11} md={10}>
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <Typography variant="h4">{state.placeDetails.name}</Typography>
                        <Typography variant="h6">{state.placeDetails.description}</Typography>
                    </Grid>
                </Grid>
                <div style={{height: 20}}/>
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            value={state.searchText}
                            label="Search Items..."
                            onChange={(event) => props.onTypeSearch(event.target.value)}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Menu</InputLabel>
                            <Select
                                value={state.selectedMenuId}
                                onChange={(event) => props.onSelectMenu(event.target.value)}>
                                <MenuItem value="">All</MenuItem>
                                {
                                    state.menus.map((menu, idx) => {
                                        return <MenuItem value={menu.menu_id} key={idx}>{menu.menu_name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Section</InputLabel>
                            <Select
                                value={state.selectedSectionId}
                                onChange={(event) => props.onSelectSection(event.target.value)}>
                                <MenuItem value="">All</MenuItem>
                                {
                                    state.menus.filter((menu) => {
                                        return menu.menu_id === state.selectedMenuId
                                    }).map((menu) => {
                                        return (
                                            menu.sections.map((section, sidx) => {
                                                return <MenuItem value={section.category_id} key={sidx}>{section.name}</MenuItem>
                                            })
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            onClick={props.onClickRefresh}>Refresh</Button>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                </Grid>
                { _renderMenu(props) }
            </Grid>
        </Grid>        
    )
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onToggleSellableListingStatus: PropTypes.func,
    onChangeSellablePrice: PropTypes.func,
    onClickUpdateSellablePrice: PropTypes.func,
    onClickManageSellable: PropTypes.func,
    onTypeSearch: PropTypes.func,
    onSelectMenu: PropTypes.func,
    onSelectSection: PropTypes.func,
    onClickRefresh: PropTypes.func
}

Menu.defaultProps = {
    onToggleSellableListingStatus: (sellableId, listed) => {},
    onChangeSellablePrice: (sellableId, value) => {},
    onClickUpdateSellablePrice: (sellableId) => {},
    onClickManageSellable: (sellableId) => {},
    onTypeSearch: (value) => {},
    onSelectMenu: (menuId) => {},
    onSelectSection: (sectionId) => {},

    onClickRefresh: () => {},
}