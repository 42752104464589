/*
    a material-ui Radio component with an additional description field
*/

import PropTypes from 'prop-types'
import React, { Component } from "react"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Typography from "@material-ui/core/Typography"

import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"

import { isEmptyString } from "../utils"

class DescriptiveRadioGroup extends Component {

    _renderError = () => {
        if(isEmptyString(this.props.errorText))
            return <div/>
        else
            return <FormHelperText>{this.props.errorText}</FormHelperText>
    }

    render = () => {
        return (
            <FormControl error={!isEmptyString(this.props.errorText)}>
                <Typography variant="body2">{this.props.description}</Typography>
                { this._renderError() }
                <div style={{height: "10px"}}/>
                <RadioGroup
                    value={this.props.selectedValue}
                    onChange={(event) => this.props.onChangeCallback(event.target.value)}>
                    {
                        this.props.options.map((option, index) => {
                            return (
                                <div key={index}>
                                    <FormControlLabel
                                        value={option.value}
                                        control={<Radio color="primary"/>}
                                        label={
                                            <span>
                                                <Typography variant="body1">{option.label}</Typography>
                                                <Typography variant="caption">{option.description || ""}</Typography>
                                            </span>
                                        }
                                    />
                                    <div style={{height: "10px"}}/>
                                </div>
                            )
                        })
                    }
                </RadioGroup>
            </FormControl>
        )
    }
}

DescriptiveRadioGroup.propTypes = {
    selectedValue: PropTypes.string.isRequired,
    onChangeCallback: PropTypes.func.isRequired,
    options: PropTypes.array,
    errorText: PropTypes.string,
    description: PropTypes.string
}

DescriptiveRadioGroup.defaultProps = {
    options: [
        {
            value: "HELLO",
            label: "Hello",
            description: "Hello World"
        }
    ],
    onChangeCallback: (value) => { console.log(`default callback ${value}`) },
    errorText: "",
    description: ""

}

export default DescriptiveRadioGroup
