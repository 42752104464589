import PropTypes from "prop-types"
import uuid from "uuid"
import React from "react"
import { DateRangePicker, DateRange } from 'react-date-range'
import ReactWordcloud from 'react-wordcloud'
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import MenuList from "@material-ui/core/MenuList"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import Hidden from "@material-ui/core/Hidden"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Snackbar from "@material-ui/core/Snackbar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import TuneIcon from "@material-ui/icons/Tune"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"

import { useTheme } from "@material-ui/core/styles"

import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import { Histogram } from "../../common/components/Histogram"
import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import { isEmptyString } from "../../common/utils"

const cloudOptions = {
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    enableTooltip: false,
    deterministic: true,
    fontFamily: 'arial',
    fontSizes: [24, 75],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 10,
    rotations: 1,
    rotationAngles: [0],
    scale: 'sqrt',
}

function _renderStars(filled, maximum) {
    let stars = []
    for(let i = 0; i < maximum; i++){
        if(i < filled)
            stars.push(<StarIcon color="primary" key={i}/>)
        else
            stars.push(<StarBorderIcon color="primary" key={i}/>)
    }
    return (
        <div>{
            stars.map((star, idx) => {
                return star
            })
        }</div>
    )
}

function _renderReviews(props, isMobileScreen) {
    const { state } = props
    const style = isMobileScreen ? {height: "300px"} : {height: "75vh"}
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Feedback</Typography>
                <Card style={{maxHeight: "100%", overflow: "auto"}}>
                    <CardContent>
                        <Grid container style={style}>
                            <Grid item xs={12}>
                                <MenuList style={{overflow: "auto"}}>
                                    {
                                        state.reviewData.map((review, idx) => {
                                            return (
                                                <div key={idx}>
                                                    { _renderStars(review.numeric_rating, 5) }
                                                    <Typography variant="caption">{review.rating_utc.format("YYYY-MM-DD")}</Typography>
                                                    <div style={{height: 10}}/>
                                                    <Typography variant="body1">{`"${review.comment}"`}</Typography>
                                                    <div style={{height: 10}}/>
                                                    <Typography variant="body2">{`${review.first_name} @ 🦊 ${review.fox_name} `}</Typography>
                                                    <Divider style={{margin: 5}}/>
                                                </div>
                                            )
                                        })
                                    }
                                </MenuList>
                            </Grid>                         
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>        
    )
}

function _renderHeadline() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4">Customer Feedback</Typography>
            </Grid>
        </Grid>
    )
}

function _renderToolbar(props) {
    const { state } = props
    const year = new Date().getUTCFullYear()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button variant="text" color="secondary" onClick={() => props.onToggleShowDatePicker()}>
                    <TuneIcon/>
                    <Typography variant="button">Date Range</Typography>
                </Button>
                <Collapse in={state.showDateRangePicker} style={{position: "absolute", zIndex: 10}}>
                    <Paper>
                        <Hidden lgUp>
                            <DateRange 
                                ranges={[state.dateRangePicker]}
                                minDate={new Date(`${year-1}-01-01`)}
                                maxDate={new Date(`${year}-12-31`)}
                                onChange={props.onSelectDateRange}/>
                        </Hidden>
                        <Hidden mdDown xlUp>
                            <DateRangePicker 
                                ranges={[state.dateRangePicker]} 
                                months={1}
                                minDate={new Date(`${year-1}-01-01`)}
                                maxDate={new Date(`${year}-12-31`)}
                                direction="vertical"
                                scroll={{ enabled: true }}                                        
                                onChange={props.onSelectDateRange}/>
                        </Hidden>
                        <Hidden lgDown>
                            <DateRangePicker 
                                ranges={[state.dateRangePicker]} 
                                months={2} 
                                direction="horizontal"
                                minDate={new Date(`${year-1}-01-01`)}
                                maxDate={new Date(`${year}-12-31`)}
                                onChange={props.onSelectDateRange}/>
                        </Hidden>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button color="secondary" variant="contained" onClick={props.onClickUpdateDateRange}>Update</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Collapse>         
                <Divider/>
            </Grid>
        </Grid>       
    )
}

function _renderGraphs(props) {
    let { state } = props
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">Phrase Analysis</Typography>
                        <ReactWordcloud options={cloudOptions} words={state.wordCounts}/>
                    </CardContent>
                </Card>

            </Grid>                
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Histogram title="Rating Distribution" data={state.ratingDistribution} xLabel="Rating" yLabel="" key={uuid()}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export const Reviews = (props) => {
    const { classes, state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <FetchyIntercom 
                userId={state.credentials.username}
                email={state.credentials.email} 
                name={state.credentials.username}
                airport={state.airport.name}
                route={state.location.pathname}
                accountType={state.account.accountType} 
                customData={{source: "Web Dashboard"}}/>                   
            <main style={{width: "100vw", height: "99vh"}}>
                <div className={classes.spacerTop}/>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                { _renderHeadline() }
                            </Grid>
                            <Grid item xs={12}>
                                { _renderToolbar(props) }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                { _renderReviews(props, isMobileScreen) }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                { _renderGraphs(props) }                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    autoHideDuration={5000}
                    message={state.snackMessage}
                    open={!isEmptyString(state.snackMessage)}
                    onClose={props.onCloseSnack}
                    action={<Button onClick={() => props.onCloseSnack()} color="primary">Close</Button>} />                
                <LoadingDialog show={state.loading} message="Loading..." />
            </main>
        </div>
    )
}

Reviews.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onToggleShowDatePicker: PropTypes.func,
    onSelectDateRange: PropTypes.func,
    onClickUpdateDateRange: PropTypes.func,
    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func,
    onCloseSnack: PropTypes.func,
}

Reviews.defaultProps = {
    onClickUpdateDateRange: (dateRange) => console.log("default onClickUpdateDateRange"),
    onSelectDateRange: () => console.log("default onSelectDateRange"),
    onToggleShowDatePicker: () => console.log("default onToggleShowDatePicker"),
    onClickDrawerItem: () => console.log("default onClickDrawerItem"),
    onClickDrawerLogout: () => console.log("default onClickDrawerLogout"),
    onCloseSnack: () => console.log("default onCloseSnack"),
}

