import React from "react"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import FilledInput from "@material-ui/core/FilledInput"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import NavDrawer from "../../common/components/NavDrawer"
import ImageSelectPreview from "../../common/components/ImageCropPreview"
import LoadingDialog from "../../common/components/LoadingDialog"
import { isEmptyString } from "../../common/utils"
import { UPDATE_VIEW } from "../../globals"

function _renderBars(props) {
    const { classes, state } = props
    if(state.location.viewMode === UPDATE_VIEW) {
        return (
            <div>
                <NavDrawer
                    listItems={state.menuItems}
                    currentPath={state.location.pathname}
                    onClickDrawerItemCallback={props.onClickDrawerItem}
                    onClickLogoutCallback={props.onClickLogout}/>
                <FetchyIntercom 
                    userId={state.credentials.username}
                    email={state.credentials.email} 
                    name={state.account.name}
                    airport={state.airport.name}
                    route={state.location.pathname}
                    accountType={state.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                    
            </div>
        )
    }
    else
        return (
            <AppBar color="default">
                <Toolbar>
                    <div className={classes.fetchyFoxLogo}/>
                </Toolbar>
            </AppBar>
        )
}


export const BasicInfo = (props) => {
    const { classes, state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} />

    return (
        <div style={{display: "flex"}}>
            { _renderBars(props) }
            <main style={{ width: "99vw"} }>
                <div className={classes.spacerTop}/>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={11} md={10} lg={8}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">Basic Information</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    value={state.firstName}
                                    onChange={(event) => props.onChangeTextField(event.target.id, event.target.value)}
                                    error={!isEmptyString(state.firstNameError)}
                                    helperText={state.firstNameError}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="lastName"
                                    label="Last Initial"
                                    variant="outlined"
                                    value={state.lastName}
                                    onChange={(event) => props.onChangeTextField(event.target.id, event.target.value)}
                                    error={!isEmptyString(state.lastNameError)}
                                    helperText={state.lastNameError}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    id="email"
                                    label="Primary Contact Email"
                                    variant="outlined"
                                    value={state.email}
                                    onChange={(event) => props.onChangeTextField(event.target.id, event.target.value)}
                                    error={!isEmptyString(state.emailError)}
                                    helperText={state.emailError}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="birthdate"
                                    type="date"
                                    label="Birthdate"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    value={state.birthdate}    
                                    onChange={(event) => props.onChangeTextField(event.target.id, event.target.value)}
                                    error={!isEmptyString(state.birthdateError)}
                                    helperText={state.birthdateError}
                                    fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <div style={{height: 20}} />
                            <Grid item xs={12}>
                                <Typography variant="h6">Profile Picture (optional)</Typography>
                                <Typography variant="body1">A good picture is one where your face is clear and centered.</Typography>
                            </Grid>
                            <Grid item>
                                <div style={{height: 10}} />    
                                <ImageSelectPreview
                                    onSelectImage={(imgData, mimeType) => props.onSelectImage(imgData, mimeType)}
                                    maxFilesizeMB={2}
                                    height={300}
                                    width={300}
                                    errorText={state.profilePictureError}
                                    pictureUrl={state.imageUrl}/>
                                <div style={{height: 10}} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">Optional</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">This information is used when giving away FetchyFox freebies and t-shirts.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel>T-Shirt Size</InputLabel>
                                    <Select
                                        value={state.selectedSize}
                                        onChange={(event) => props.onSelectSize(event.target.value)}
                                        input={<FilledInput/>}>
                                        <MenuItem value="na">Prefer not to share.</MenuItem>
                                        <MenuItem value="xs">XS</MenuItem>
                                        <MenuItem value="s">S</MenuItem>
                                        <MenuItem value="m">M</MenuItem>
                                        <MenuItem value="l">L</MenuItem>
                                        <MenuItem value="xl">XL</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel>Gender</InputLabel>
                                    <Select
                                        value={state.selectedGender}
                                        onChange={(event) => {props.onSelectGender(event.target.value)}}
                                        input={<FilledInput/>}>
                                        <MenuItem value="na">Prefer not to share.</MenuItem>
                                        <MenuItem value="f">Female</MenuItem>
                                        <MenuItem value="m">Male</MenuItem>
                                        <MenuItem value="o">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{height: 20}}/>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="text"
                                    size="large"
                                    onClick={() => props.onClickCancel()}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={() => props.onClickSubmit()}>
                                    {
                                        state.location.viewMode === UPDATE_VIEW ? "Update" : "Submit"
                                    }
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingDialog show={state.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={state.snackMessage}
                    open={!isEmptyString(state.snackMessage)}
                    onClose={props.onCloseSnack}
                    action={<Button onClick={() => props.onCloseSnack()} color="primary">Close</Button>} />
            </main>
        </div>
    )
}

BasicInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onClickDrawerItem: PropTypes.func.isRequired,
    onClickLogout: PropTypes.func.isRequired,
    onChangeTextField: PropTypes.func.isRequired,
    onSelectImage: PropTypes.func.isRequired,
    onSelectSize: PropTypes.func.isRequired,
    onSelectGender: PropTypes.func.isRequired,
    onCloseSnack: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
}