import React, { Component } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"

class Spinner extends Component {

    render = () => {
        return (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
                <Grid item>
                    <Typography variant="body1">{this.props.label}</Typography>
                </Grid>
            </Grid>
        )
    }
}

Spinner.defaultProps = {
    label: "Loading..."
}

Spinner.propTypes = {
    label: PropTypes.string
}

export default Spinner
