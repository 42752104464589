import { SET_AIRPORT, CLEAR_AIRPORT } from "../constants"

export default (state = [], action) => {
    switch(action.type) {
        case SET_AIRPORT:
            const { airportId, iataCode, name, iso4217, country, timezone } = action
            return { airportId, iataCode, name, iso4217, country, timezone }
        case CLEAR_AIRPORT:
            return {}
        default:
            return state
    }
}
