import * as d3 from "d3"
import PropTypes from 'prop-types'
import { compose } from "recompose"
import React, { Component } from 'react'
import { connect } from "react-redux"
import FileDownload from "react-file-download"
import { Redirect } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'
import FetchyFoxLogo from "../assets/logo.png"
import FoxUnverified from "./fox/Unverified"
import MerchantUnverified from "./merchant/Unverified"
import { FetchyIntercom } from "./common/components/FetchyIntercom"
import LoadingDialog from "./common/components/LoadingDialog"
import NavDrawer from "./common/components/NavDrawer"
import { StatusSnackbar, StatusSnackbarTypes } from "./common/components/StatusSnackbar"

import { ConciergeDashboard } from "./views/ConciergeDashboard"
import { AirportDashboard } from "./views/AirportDashboard"
import { MerchantDashboard } from "./views/MerchantDashboard"

import { PrepaidCardAPI } from "./common/requests/concierge/prepaidcard"
import { FoxStatisticsAPI } from "./common/requests/concierge/statistics"
import { FoxAccountAPI, FoxSessionAPI } from "./common/requests/concierge/account"
import { MerchantAccountAPI, MerchantSessionAPI } from "./common/requests/merchant/account"
import { InsightsAPI as MerchantInsightsAPI } from "./common/requests/merchant/insights"
import { InsightsAPI as AirportInsightsAPI } from "./common/requests/airport/insights"

import { setDrawerMenu, removeCredentials, removeEmail, clearAccount, clearItems, clearPlaces } from "../actions"
import { firebaseApp } from "../firebase"
import { sleep, isEmptyString } from "./common/utils"
import { FetchyStyles } from "./common/fetchyfoxtheme"
import { DAILY_DATA, FOX_ACCOUNT, MERCHANT_ACCOUNT, AIRPORT_ACCOUNT, drawerItems} from "./globals"



const moment = require("moment-timezone")

const styles = theme => ({
    root: {
        display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    fetchyFoxLogo: {
        margin: "7px",
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: "100%",
        height: theme.mixins.toolbar.minHeight,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    margin: {
        margin: theme.spacing(1),
    },    
    ...FetchyStyles
})



class DashboardContainer extends Component {
    constructor(props) {
        super(props)

        // initial values for the date range picker
        let currentYear = new Date().getUTCFullYear()

        this.state = {
            loading: false,
            dashboardReady: false,
            unverifiedComponent: <div />,
            redirectParams: null,

            // used by merchant and airport dashboards
            highlightData: [],
            dailyOrdersData: [],
            dailySalesData: [],
            bottomRightData: [],

            startHistoricalRange: moment().subtract(6, "days"),
            endHistoricalRange: moment(),

            // initial range of heatmap data is current year
            startHeatmapRange: moment(`${currentYear}-01-01`),
            endHeatmapRange: moment(`${currentYear}-12-31`),

            selectedDayOfWeek: 0,
            heatmapOrderData: [],
            peakDeliveryData: [],
            deliveryDistributionData: [],

            dataGranularity: DAILY_DATA,
            orderData: [],
            itemSalesData: [],
            reviewsData: [],
            allTimeSummary: null,
            feedbackSummary: null,

            // used by concierge dashboard
            deliveryHistory: [],
            deliveryStats: {},

            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            },
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this._checkAccountVerified()
    }

    _onClickUpdateHistoricalRange = (startHistoricalRange, endHistoricalRange) => { 
        this.setState({startHistoricalRange, endHistoricalRange}, this._fetchDashboardData)
    }
    
    _onClickUpdateHeatmapRange = (startHeatmapRange, endHeatmapRange) => {
        this.setState({startHeatmapRange, endHeatmapRange}, this._fetchHeatmapData)
    }

    _onClickGraphRefresh = () => this._fetchDashboardData()
    _onClickDownloadData = () => this._downloadOrderData()
    _onClickDrawerItem = (redirectParams) => this.setState({redirectParams})
    _onChange = (event) => this.setState({[event.target.id]: event.target.value})

    _onSelectGranularity = (dataGranularity) => {
        this.setState({loading: true})
        // more fake loading
        sleep(200).then(() => {
            this.setState({loading: false , dataGranularity})
            this._populateDashboard()
        })
    }

    _onCloseSnack = () => { 
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.STANDARD,
                message: ""
            }
        })
    }

    // used for the "peak delivery times" chart
    _onSelectDayOfWeek = (selectedDayOfWeek) => {
        this.setState({selectedDayOfWeek}, this._populateDeliveryPeakGraph)
    }


    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        if(this.props.account.accountType === FOX_ACCOUNT) {
            const api = new FoxSessionAPI(this.props.server.host)
            api.requestLogout(this.props.credentials.username, this.props.credentials.password,
                (success) => {
                    this.props.clearItems()
                    this.props.clearPlaces()
                    this.props.clearAccount()
                    this.props.removeCredentials()
                    this.props.removeEmail()
                    this.setState({loading: false, redirectParams: {pathname: "/"}})
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }
                    this.setState({loading: false, snackMessage})
                })
        }
        else if(this.props.account.accountType === MERCHANT_ACCOUNT) {
            const api = new MerchantSessionAPI(this.props.server.host)
            api.requestLogout(this.props.credentials.username, this.props.credentials.password,
                (success) => {
                    this.props.removeCredentials()
                    this.props.removeEmail()
                    this.setState({loading: false, redirectParams: {pathname: "/"}})
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else if(this.props.account.accountType === AIRPORT_ACCOUNT) {
            firebaseApp.auth().signOut()
                .then(() => {
                    this.props.removeCredentials()
                    this.props.removeEmail()
                    this.setState({loading: false, redirectParams: {pathname: "/"}})
                })
                .catch(error => {
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message: error
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
    }


    _checkAccountVerified = () => {
        const lookup = require("country-data").lookup;
        let country = lookup.countries({ name: this.props.airport.country })[0];

        this._checkAccountActivated(
            () => {
                // account has access to dashboard
                this.setState({unverifiedComponent: null})
                this._fetchDashboardData()
                this.props.setDrawerMenu( drawerItems(this.props.account.accountType, country.alpha2 ))
            },
            (unverifiedComponent) => {
                // acount does not have access to dashboard
                this.setState({unverifiedComponent})
            })
    }

    _downloadOrderData = () => {
        let dataStartDate = this.state.startHistoricalRange.tz("UTC").format("YYYY-MM-DD"),
            dataEndDate = this.state.endHistoricalRange.tz("UTC").format("YYYY-MM-DD"),
            api = null,
            isCSV = true

        if(this.props.account.accountType === MERCHANT_ACCOUNT) {
            this.setState({loading: true})
            api = new MerchantInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestOrderData(dataStartDate, dataEndDate, isCSV,
                (success) => {
                    this.setState({loading: false})
                    FileDownload(success.data, "orders.csv")
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })

        }
        else if(this.props.account.accountType === AIRPORT_ACCOUNT) {
            this.setState({loading: true})
            api = new AirportInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestOrderData(dataStartDate, dataEndDate, isCSV,
                (success) => {
                    this.setState({loading: false})
                    FileDownload(success.data, "orders.csv", "text/csv")
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else
            return
    }

    _fetchHeatmapData = () => {
        let api
        const startHeatmapDate = this.state.startHeatmapRange.tz("UTC").format("YYYY-MM-DD"),
            endHeatmapDate = this.state.endHeatmapRange.tz("UTC").format("YYYY-MM-DD")
        this.setState({loading: true})

        if(this.props.account.accountType === MERCHANT_ACCOUNT){
            api = new MerchantInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestOrderData(startHeatmapDate, endHeatmapDate, false,
                (success) => {
                    this.setState({
                        loading: false,
                        heatmapOrderData: success.data
                    }, this._populateHeatmap)
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else if(this.props.account.accountType === AIRPORT_ACCOUNT) {
            api = new AirportInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestOrderData(startHeatmapDate, endHeatmapDate, false,
                (success) => {
                    this.setState({
                        loading: false,
                        heatmapOrderData: success.data
                    }, this._populateHeatmap)
                },
                (error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
    }

    _fetchDashboardData = () => {
        let api = null
        let startHistoricalDate = this.state.startHistoricalRange.tz("UTC").format("YYYY-MM-DD"),
            endHistoricalDate = this.state.endHistoricalRange.tz("UTC").format("YYYY-MM-DD"),
            startHeatmapDate = this.state.startHeatmapRange.tz("UTC").format("YYYY-MM-DD"),
            endHeatmapDate = this.state.endHeatmapRange.tz("UTC").format("YYYY-MM-DD")

        let getCSV = false
        if(this.props.account.accountType === MERCHANT_ACCOUNT) {
            this.setState({loading: true})
            api = new MerchantInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            Promise.all([api.promiseItemSalesData(startHistoricalDate, endHistoricalDate),
                            api.promiseOrderData(startHistoricalDate, endHistoricalDate, getCSV),
                            api.promiseOrderData(startHeatmapDate, endHeatmapDate, false),
                            api.promiseAllTimeSalesSummary()])
                .then(([itemSalesResponse, orderResponse, heatmapDataResponse, summaryResponse]) => {
                    this.setState({
                        orderData: orderResponse.data,
                        itemSalesData: itemSalesResponse.data,
                        allTimeSummary: summaryResponse.data,
                        heatmapOrderData: heatmapDataResponse.data,
                        dashboardReady: true,
                        loading: false
                    })
                    this._populateDashboard()
                })
                .catch((error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else if(this.props.account.accountType === AIRPORT_ACCOUNT) {
            this.setState({loading: true})
            api = new AirportInsightsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)

            // two calls to promiseOrderData, the first is for historical data (changes from input) 
            // and the second is only called for the current year once to show in the overall stats
            Promise.all([api.promiseFeedbackData(startHistoricalDate, endHistoricalDate), 
                            api.promiseItemSalesData(startHistoricalDate, endHistoricalDate),
                            api.promiseOrderData(startHistoricalDate, endHistoricalDate, getCSV),
                            api.promiseAllTimeSalesSummary(),
                            api.promiseFeedbackSummary(), 
                            api.promiseOrderData(startHeatmapDate, endHeatmapDate, false)])
                .then(results => {
                    this.setState({
                        reviewsData: results[0].data,
                        itemSalesData: results[1].data,
                        orderData: results[2].data,
                        allTimeSummary: results[3].data,
                        feedbackSummary: results[4].data,
                        heatmapOrderData: results[5].data,
                        dashboardReady: true,
                        loading: false
                    })
                    this._populateDashboard()
                })
                .catch((error) => {
                    const message = (error.response === undefined) ? error.message : error.response.data.error
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else if(this.props.account.accountType === FOX_ACCOUNT) {
            this.setState({loading: true})
            const cardapi = new PrepaidCardAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            const statsapi = new FoxStatisticsAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)

            Promise.all([statsapi.tryPromiseDeliveryHistory(10),
                            statsapi.tryPromiseSummaryStatistics(),
                            cardapi.tryPromisePrepaidCard()])
                .then(results => {
                    this.setState({loading: false})
                    const [deliveries, summary, activated] = results
                    this.setState({
                        loading: false,
                        deliveryHistory: deliveries.data,
                        deliveryStats: summary.data,
                        dashboardReady: true,
                    })
                    if(!activated.is20x) {
                        this.setState({
                            snackMessage: {
                                level: StatusSnackbarTypes.INFO,
                                message: 'Reminder: You need to activate your PEX card by selecting "Payment Card" in the menu.'
                            }
                        })
                    }
                }).catch((error) => {
                    const snackMessage = {
                        level: StatusSnackbarTypes.ERROR,
                        message: "Network Error. see logs"
                    }                    
                    this.setState({loading: false, snackMessage})
                })
        }
        else
            return
    }

    _populateDashboard = () => {
        this._populateHeatmap()
        this._populatePerformanceGraph()
        this._populateDeliveryPeakGraph()
        this._populateHighlightCards()
        this._populateSalesRankings()
    }

    _populateHeatmap = () => {
        if(this.props.account.accountType === MERCHANT_ACCOUNT || this.props.account.accountType === AIRPORT_ACCOUNT) {
            const { heatmapOrderData } = this.state
            
            const keyfunc = (order_date) => { return order_date.clone().format("YYYY-MM-DD") }
            let deliveryDistributionData = heatmapOrderData.map(datum => {
                datum.order_date = moment.utc(datum.order_date)
                return datum
            })

            deliveryDistributionData = d3.nest()
                .key(datum => keyfunc(datum.order_date))
                .rollup(rows => {
                    return {
                        nOrders: rows.filter(row => row.order_id).length || 0
                    }
                })
                .entries(deliveryDistributionData)

            deliveryDistributionData = deliveryDistributionData.map(datum => {
                return {
                    date: datum.key,
                    orders: datum.value.nOrders
                }
            })
            this.setState({deliveryDistributionData})
        }
    }    

    _populateSalesRankings = () => {
        if(this.props.account.accountType === MERCHANT_ACCOUNT || this.props.account.accountType === AIRPORT_ACCOUNT) {
            let { itemSalesData, orderData } = this.state

            let itemSales = itemSalesData.map(datum => {
                datum.purchase_date = moment.utc(datum.purchase_date)
                return datum
            })

            let itemRankings = d3.nest()
                .key(datum => { return datum.item_id } )
                .rollup(rows => {
                    return {
                        itemName: d3.max(rows, row => {return row.item_name}),
                        placeName: d3.max(rows, row => { return row.place_name }),
                        quantity: d3.sum(rows, row => { return row.quantity }) || 0,
                        sales: d3.sum(rows, row => {return row.total_base_unit}) || 0
                    }
                })
                .entries(itemSales)

            itemRankings = itemRankings.map(datum => {
                return {
                    name: `${datum.value.itemName} (${datum.value.placeName})`,
                    quantity: datum.value.quantity,
                    sales: `£${(datum.value.sales / 100.0).toFixed(2)}`
                }
            }).sort((prev, next) => {
                return next.quantity - prev.quantity
            }).slice(0, 5)

            let orders = orderData.map(datum => {
                datum.order_date = moment.utc(datum.order_date)
                return datum
            }).filter(datum => datum.place_id)

            let salesRankings = d3.nest()
                .key(datum => { return datum.place_id } )
                .rollup(rows => {
                    return {
                        placeName: d3.max(rows, row => { return row.place_name }),
                        quantity: rows.length || 0,
                        sales: d3.sum(rows, row => {return row.total_base_unit}) || 0
                    }
                })
                .entries(orders)

            salesRankings = salesRankings.map(datum => {
                return {
                    name: datum.value.placeName,
                    orders: datum.value.quantity,
                    sales: datum.value.sales / 100.0
                }
            }).sort((prev, next) => {
                return next.sales - prev.sales
            }).map(datum => {
                datum.sales = `£${(datum.sales).toFixed(2)}`
                return datum
            }).slice(0, 5)

            this.setState({salesRankings, itemRankings})
        }     
    }

    _populatePerformanceGraph = () => {
        if(this.props.account.accountType === MERCHANT_ACCOUNT || this.props.account.accountType === AIRPORT_ACCOUNT) {
            let { orderData, dataGranularity } = this.state

            orderData = orderData.map(datum => {
                datum.order_date = moment.utc(datum.order_date)
                return datum
            })

            // check the granuairty being used to group the data
            let keyfunc = (order_date) => { return order_date.clone() }
            if(dataGranularity === DAILY_DATA ) {
                keyfunc = (order_date) => { return order_date.clone().startOf("day") }
            }

            orderData = d3.nest()
                .key(row => {
                    return keyfunc(row.order_date)
                })
                .rollup(rows => {
                    return {
                        nOrders:  rows.filter(row => row.order_id).length || 0,
                        totalSales: d3.sum(rows, row => { return row.total_base_unit}) || 0
                    }
                })
                .entries(orderData)

            // again, the labelling changes depending on if the grouping is by hour or day
            let labelfunc = (order_date) => { return moment.unix(order_date / 1000).tz(this.props.airport.timezone).clone().format("DD/MM ha") }
            if(dataGranularity === DAILY_DATA) {
                labelfunc = (order_date) => { return moment.unix(order_date / 1000).tz(this.props.airport.timezone).clone().format("YYYY-MM-DD") }
            }

            let xfunc = (order_date) => { return moment.unix(order_date / 1000).tz(this.props.airport.timezone).startOf("hour").toDate() }
            if(dataGranularity === DAILY_DATA) {
                xfunc = (order_date) => { return moment.unix(order_date / 1000).tz(this.props.airport.timezone).startOf("day").toDate() }
            }

            let dailyOrdersData = orderData.map(datum => {
                return {
                    label: labelfunc(datum.key),
                    x: xfunc(datum.key),
                    y: datum.value.nOrders
                }
            })

            let dailySalesData = orderData.map(datum => {
                return {
                    label: labelfunc(datum.key),
                    x: xfunc(datum.key),
                    y: datum.value.totalSales / 100.0

                }
            })

            this.setState({dailySalesData, dailyOrdersData})
        }
        else {
            return
        }
    }

    _populateDeliveryPeakGraph = () => {
        if(this.props.account.accountType === MERCHANT_ACCOUNT || this.props.account.accountType === AIRPORT_ACCOUNT) {
            let { orderData } = this.state

            let peakDeliveryData = orderData.map(datum => {
                datum.order_date = moment.utc(datum.order_date)
                return datum
            }).filter(datum => datum.day_of_week === this.state.selectedDayOfWeek)
            

            const keyfunc = (utc_date) => { return utc_date.tz(this.props.airport.timezone).hour()}
            peakDeliveryData = d3.nest()
                .key(row => {
                    return keyfunc(row.order_date)
                })
                .rollup(rows => {
                    return {
                        label: d3.max(rows, row => { return row.order_date.format("hA") }),
                        nOrders:  rows.filter(row => row.order_id).length || 0,
                    }
                })
                .entries(peakDeliveryData)
            peakDeliveryData = peakDeliveryData.map(datum => {
                return {
                    x: parseInt(datum.key),
                    label: datum.value.label,
                    value: datum.value.nOrders
                }
            })
            this.setState({peakDeliveryData})
        }
    }

    _populateHighlightCards = () => {
        if(this.props.account.accountType === MERCHANT_ACCOUNT || this.props.account.accountType === AIRPORT_ACCOUNT) {

            let { itemSalesData, reviewsData } = this.state
            let highlightData = []

            // some d3 magic to do DATA SCIENCE.
            let itemSales = itemSalesData.map(datum => {
                datum.purchase_date = moment.utc(datum.purchase_date)
                return datum
            })

            let orderSales = d3.nest()
                .key(datum => { return datum.order_id } )
                .rollup(rows => {
                    return {
                        totalSales: d3.sum(rows, row => {return row.total_base_unit}) || 0
                    }
                })
                .entries(itemSales)

            let aggregateSales = d3.nest()
                .rollup(rows => {
                    return {
                        nOrders: rows.length || 0,
                        largestOrder: d3.max(rows, row => {
                            return row.value.totalSales;
                        })|| 0,
                        avgOrder: d3.mean(rows, row => {
                            return row.value.totalSales;
                        }) || 0,
                        totalSales: d3.sum(rows, row => {
                            return row.value.totalSales;
                        })|| 0
                    }
                })
                .entries(orderSales)

            reviewsData = reviewsData.map(datum => {
                datum.rating_utc = moment.utc(datum.rating_utc)
                return datum
            })

            reviewsData = d3.nest()
                .rollup(rows => {
                    return {
                        nRatings: rows.length || 0,
                        avgRating: d3.mean(rows, row => {
                            return row.numeric_rating * 1.0
                        }) || 0
                    }
                })
                .entries(reviewsData)
            highlightData.push({"title": "Overall Sales", "value": `£${(aggregateSales.totalSales / 100.0).toFixed(2)}` })
            highlightData.push({"title": "Largest Order", "value": `£${(aggregateSales.largestOrder / 100.0).toFixed(2)}`})
            highlightData.push({"title": "Avg. Order", "value": `£${(aggregateSales.avgOrder / 100.0).toFixed(2)}`})
            highlightData.push({"title": "Number of Orders", "value": `${aggregateSales.nOrders}` })

            if(this.props.account.accountType === AIRPORT_ACCOUNT) {
                highlightData.push({"title": "# In-App Ratings", "value": `${reviewsData.nRatings}` })
                highlightData.push({"title": "Avg. Service Rating", "value": `${reviewsData.avgRating.toFixed(2)}`})
            }
            this.setState({highlightData})
        } 
    }


    /** network request */

    _checkAccountActivated = (onVerifySuccessCallback, onVerifyErrorCallback) => {
        this.setState({loading: true})
        if(this.props.account.accountType === FOX_ACCOUNT) {
            const api = new FoxAccountAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestIsVerified(
                (response) => {
                    this.setState({loading: false})
                    onVerifySuccessCallback()
                },
                (error) => {
                    this.setState({loading: false})
                    onVerifyErrorCallback(<FoxUnverified onClickRefresh={this._checkAccountVerified}/>)
                }
            )
        }
        else if(this.props.account.accountType === MERCHANT_ACCOUNT) {
            const api = new MerchantAccountAPI(this.props.server.host, this.props.credentials.username,
                this.props.credentials.password)
            api.requestIsVerified(
                (response) => {
                    this.setState({loading: false})
                    onVerifySuccessCallback()
                },
                (error) => {
                    this.setState({loading: false})
                    onVerifyErrorCallback(<MerchantUnverified onClickRefresh={this._checkAccountVerified}/>)
                }
            )
        }
        else {
            // airport accounts are verified on creation by FetchyFox
            this.setState({loading: false})
            onVerifySuccessCallback()
        }

    }

    /** END network requests */

    render = () => {
        if(this.state.unverifiedComponent)
            return (
                <div>
                    { this.state.unverifiedComponent }
                    <LoadingDialog show={this.state.loading}/>
                </div>
            )

        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect to={this.state.redirectParams} push/>

        let viewComponent = <div/>
        if(this.state.dashboardReady) {
            if(this.props.account.accountType === FOX_ACCOUNT)
                viewComponent =  <ConciergeDashboard classes={this.props.classes}
                                    deliveries={this.state.deliveryHistory} stats={this.state.deliveryStats}/>
            else if(this.props.account.accountType === MERCHANT_ACCOUNT)
                viewComponent = <MerchantDashboard classes={this.props.classes} state={this.state}
                                    onSelectDayOfWeek={this._onSelectDayOfWeek}
                                    onSelectGraphGranularity={this._onSelectGranularity}
                                    onClickDownloadGraphData={this._onClickDownloadData}
                                    onClickUpdateHistoricalRange={this._onClickUpdateHistoricalRange}
                                    onClickUpdateHeatmapRange={this._onClickUpdateHeatmapRange}/>
            else if(this.props.account.accountType === AIRPORT_ACCOUNT)
                viewComponent = <AirportDashboard classes={this.props.classes} state={this.state}
                                    onSelectDayOfWeek={this._onSelectDayOfWeek}
                                    onSelectGraphGranularity={this._onSelectGranularity}
                                    onClickDownloadGraphData={this._onClickDownloadData}
                                    onClickUpdateHistoricalRange={this._onClickUpdateHistoricalRange}
                                    onClickUpdateHeatmapRange={this._onClickUpdateHeatmapRange}/>
        }

        const { classes } = this.props
        return (
            <div className={classes.root}>
                <NavDrawer
                    listItems={this.props.account.menuItems}
                    currentPath={this.props.location.pathname}
                    onClickDrawerItemCallback={this._onClickDrawerItem}
                    onClickLogoutCallback={this._onClickLogoutHandler}/>
                <FetchyIntercom 
                    userId={this.props.credentials.username}
                    email={this.props.credentials.email} 
                    name={this.props.credentials.username}
                    airport={this.props.airport.name}
                    route={this.props.location.pathname}
                    accountType={this.props.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                         
                <main  style={{width: "99vw"}}>
                    <div className={classes.toolbar}/>
                    <StatusSnackbar
                        open={!isEmptyString(this.state.snackMessage.message)}
                        variant={this.state.snackMessage.level}
                        message={this.state.snackMessage.message}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        onClose={this._onCloseSnack}/>   

                    { viewComponent }
                    <LoadingDialog show={this.state.loading}/>
                </main>
            </div>
        )
    }
}

DashboardContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { server, account, credentials, airport } = state
    return { server, account, credentials, airport }
}

const mapActionCreatorsToProps = {
    setDrawerMenu,
    removeCredentials,
    removeEmail,
    clearItems,
    clearPlaces,
    clearAccount
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(DashboardContainer)
