import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Grid,
    Button,
    IconButton,
    LinearProgress,
    CircularProgress
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import { withStyles } from "@material-ui/core/styles";

import SimpleConfirmDialog from "./SimpleConfirmDialog";

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: "#fdd6d3"
    },
    barColorPrimary: {
        backgroundColor: "#f44336"
    }
})(LinearProgress);

const ColorCircularProgress = withStyles({
    root: {
        color: "#f44336"
    }
})(CircularProgress);

class DelayedCircularButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            progress: 0,
            buttonStyle: {},
            dialogComponent: null,
            tickRate: 20,
            progressDelta: 1
        };
    }

    _tick = () => {
        let { progress } = this.state;
        progress += 1;
        if (progress >= 100) progress = 100;

        this.setState({ progress });
    };

    _onClick = () => {
        if (this.props.showConfirmDialog) {
            // this flag means we want to be extra sure and show a separate confirm dialog
            const dialogComponent = (
                <SimpleConfirmDialog
                    open
                    title="Confirm Delete"
                    message="This action is immediate and permanent. Continue?"
                    onConfirmCallback={() => {
                        this.setState({ dialogComponent: null });
                        this.props.onConfirmAction();
                    }}
                    onCloseCallback={() => {
                        this.setState({ dialogComponent: null });
                        this.props.onCancelAction();
                    }}
                />
            );
            this.setState({ dialogComponent });
        } else {
            this.props.onConfirmAction();
        }
    };

    _onStartTimer = () => {
        this.setState({
            timer: setInterval(this._tick, 20)
        });
    };

    _onEndTimer = () => {
        if (this.state.timer !== null) {
            clearInterval(this.state.timer);
            this.setState({
                timer: null,
                progress: 0
            });
        }
    };

    _renderDialog = () => {
        if (this.state.dialogComponent !== null) return this.state.dialogComponent;
        else return <div />;
    };

    render = () => {
        return (
            <Grid container style={{position: "relative"}}>
                <Grid item onMouseEnter={this._onStartTimer} onMouseLeave={this._onEndTimer}>
                    <ColorCircularProgress
                        style={{ position: "absolute"}}
                        size={48}
                        value={this.state.progress}
                        variant="determinate"/>
                    <IconButton
                        onClick={this._onClick}
                        disabled={this.state.progress < 100}>
                        <DeleteIcon
                            style={
                                this.state.progress < 100
                                    ? { color: "#fdd6d3" }
                                    : { color: "#f44336" }
                            }/>
                    </IconButton>
                </Grid>
                {this._renderDialog()}
            </Grid>
        );
    };
}

DelayedCircularButton.propTypes = {
    onConfirmAction: PropTypes.func.isRequired,
    onCancelAction: PropTypes.func,
    showConfirmDialog: PropTypes.bool
};

DelayedCircularButton.defaultProps = {
    showConfirmDialog: false,
    onCancelAction: () => console.log("default onCancelAction")
};

class DelayedActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            progress: 0,
            buttonStyle: {},
            dialogComponent: null,
            tickRate: 400,
            progressDelta: 14
        };
    }

    _tick = () => {
        let { progress } = this.state;
        progress += this.state.progressDelta;
        if (progress >= 100) progress = 100;

        this.setState({ progress });
    };

    _onClick = () => {
        if (this.props.showConfirmDialog) {
            // this flag means we want to be extra sure and show a separate confirm dialog
            const dialogComponent = (
                <SimpleConfirmDialog
                    open
                    title="Confirm Delete"
                    message="This action is immediate and permanent. Continue?"
                    onConfirmCallback={() => {
                        this.setState({ dialogComponent: null });
                        this.props.onConfirmAction();
                    }}
                    onCloseCallback={() => {
                        this.setState({ dialogComponent: null });
                        this.props.onCancelAction();
                    }}
                />
            );
            this.setState({ dialogComponent });
        } else {
            this.props.onConfirmAction();
        }
    };

    _onStartTimer = () => {
        this.setState({
            timer: setInterval(this._tick, this.state.tickRate)
        });
    };

    _onEndTimer = () => {
        if (this.state.timer !== null) {
            clearInterval(this.state.timer);
            this.setState({
                timer: null,
                progress: 0
            });
        }
    };

    _renderDialog = () => {
        if (this.state.dialogComponent !== null) return this.state.dialogComponent;
        else return <div />;
    };

    render = () => {
        return (
            <div>
                <div
                    onMouseEnter={this._onStartTimer}
                    onMouseLeave={this._onEndTimer}>
                    <Button
                        variant="contained"
                        style={
                            this.state.progress < 100
                                ? { backgroundColor: "#fdd6d3", color: "#fff" }
                                : { backgroundColor: "#f44336", color: "#fff" }
                        }
                        onClick={this._onClick}
                        disabled={this.state.progress < 100}
                        fullWidth>
                        { this.props.label !== null ? this.props.label : "Delete"}
                    </Button>
                </div>
                <ColorLinearProgress
                    variant="determinate"
                    value={this.state.progress}
                    style={{ foreground: "#ff0000" }}
                />
                {this._renderDialog()}
            </div>
        );
    };
}

DelayedActionButton.propTypes = {
    onConfirmAction: PropTypes.func.isRequired,
    onCancelAction: PropTypes.func,
    showConfirmDialog: PropTypes.bool,
    label: PropTypes.string
};

DelayedActionButton.defaultProps = {
    showConfirmDialog: false,
    label: null,
    onCancelAction: () => console.log("default onCancelAction")
};

export { DelayedActionButton, DelayedCircularButton };
