import { ADD_PLACE, LOAD_PLACES, CLEAR_PLACES } from "../constants"

const place = (action) => {
    const { place_id, data } = action
    return { place_id, data }
}

export default (state = [], action) => {
    switch(action.type){
        case ADD_PLACE:
            return [ ...state, place(action)]
        case LOAD_PLACES:
            return action.places
        case CLEAR_PLACES:
            return []
        default:
            return state
    }
}