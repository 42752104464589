import axios from "axios"
import { handleFetchyAPIError } from "../base"

export class FleetAPI {
    constructor(host, fetchyUuid, fetchyToken) {
        this.host = host
        this.username = fetchyUuid   // the email address of a firebase account
        this.password = fetchyToken // the accessToken for a firebase account
    }

    promiseActiveFoxes = () => {
        return axios({
            url: `${this.host}/v1/airport/foxes/active`,
            method: "get",
            auth: {
                username: this.username,
                password: this.password
            }
        })
    }

    requestGetActiveFoxes = (onRequestSuccess, onRequestError) => {
        this.promiseActiveFoxes().then((response) => {
            onRequestSuccess(response)
        }).catch((error) => {
            const result = handleFetchyAPIError(error)
            onRequestError(result)
        })
    }
}