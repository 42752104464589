import React from "react"
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid"
import CanvasJSReact from "../canvasjs.react"

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const Histogram = (props) => {

    const dataPoints = props.data.map(datum => {
        return {
            label: datum.label !== null ? datum.label : datum.x,
            x: datum.x !== null ? datum.x : datum.label,
            y: datum.value
        }
    })

    const graphConfig = {
        animationsEnabled: true,
        exportEnabled: true,
        theme: "light2",
        title: { text: props.title },
        axisX: { title: props.xLabel },
        axisY: { title: props.yLabel },
        type: "bar",
        data: [
            {
                type: "column",
                dataPoints,
                color: "#FF832D",
                markerBorderColor: "#FF832D",
                markerBorderThickness: 1,
                fillOpacity: .75
            }
        ],
        ...props.graphOptions
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <CanvasJSChart options={graphConfig} style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}

Histogram.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
    graphOptions: PropTypes.object
}

Histogram.defaultProps = {
    data: [
        { label: "0", value: Math.round(Math.random() * 20) },
        { label: "1", value: Math.round(Math.random() * 20) },
        { label: "2", value: Math.round(Math.random() * 20) },
        { label: "3", value: Math.round(Math.random() * 20) },
        { label: "4", value: Math.round(Math.random() * 20) },
        { label: "5", value: Math.round(Math.random() * 20) },
    ],
    title: "Hello Title",
    xLabel: "Hello xLabel",
    yLabel: "Hello yLabel",
    graphOptions: {}
}

export default Histogram
