import PropTypes from "prop-types"
import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import CircularProgress from "@material-ui/core/CircularProgress"


class LoadingDialog extends Component {

    render = () => {
        return (
            <Dialog open={this.props.show} disableBackdropClick disableEscapeKeyDown>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: "white"}}><CircularProgress /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={this.props.message}/>
                    </ListItem>
                </List>
            </Dialog>
        )
    }
}

LoadingDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.string
}

LoadingDialog.defaultProps = {
    message: "Loading..."
}

export default LoadingDialog
