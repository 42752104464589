
import React from "react"
import PropTypes from "prop-types"
import MomentUtils from '@date-io/moment'
import Image from "material-ui-image"

import {
    Grid,
    Typography,
    TextField,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Switch,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    Input,
    Button,
    Tooltip,
    InputAdornment,
} from "@material-ui/core"

import { 
    Autocomplete 
} from '@material-ui/lab';

import RandomIcon from "@material-ui/icons/Cached"

import { 
    MuiPickersUtilsProvider,
    DatePicker,
}  from "@material-ui/pickers"

import { CurrencyInputField } from "../../common/components/CurrencyField"

import { toTitleCase, isEmptyString } from "../../common/utils"

function _renderDiscountAmountField(props) {
    const { state } = props
    if(state.discountType === "PERCENT") {
        return (
            <TextField
                variant="outlined"
                type="number"
                name="discountAmount"
                value={state.discountAmount}
                onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                error={!isEmptyString(state.discountAmountError)}
                helperText={state.discountAmountError}
                size="small"
                style={{width: "200px"}}
                inputProps={{min: 0, max: 100}}
                InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}/>
        )
    }
    else {
        return (
            <CurrencyInputField 
                variant="outlined" 
                size="small"
                name="discountAmount"
                value={state.discountAmount}
                disabled={state.discountType !== "FIXED"}
                onChange={(value) => props.onChangeForm("discountAmount", value)}
                errorMessage={state.discountAmountError}
                iso4217={"USD"}
                style={{width: "200px"}}/>
        )
    }

}

function _renderSearch(props) {
    const { state } = props
    
    let options = []

    if(state.couponType === "PLACE")
        options = state.places
    else if(state.couponType === "SELLABLE")
        options = state.sellables
    else
        return <div/>

    return (
        <div>
            <Typography variant="subtitle1">{`Applicable ${toTitleCase(state.couponType)}`} </Typography>
            <Autocomplete
                renderInput={(params) => (
                    <TextField {...params} 
                        variant="outlined"
                        label="Search"
                        error={!isEmptyString(state.selectedSearchError)}
                        helperText={state.selectedSearchError}/>
                )}
                onChange={(event, value, reason) => props.onSelectSearch(value)}
                size="small"
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.renderName}
                options={options}
                autoComplete
                clearOnEscape/>
            <div style={{height: "10px"}}/>
            {
                state.selectedSearch ?
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardMedia title={state.selectedSearch.name}>
                        <Image src={state.selectedSearch.imageUrl} aspectRatio={1.5} style={{objectFit: "contain", width: "100px"}} />
                    </CardMedia>
                    <CardContent style={{flex: "1 1 auto"}}>
                        <Typography variant="body1">{state.selectedSearch.name}</Typography>
                    </CardContent>
                </Card> : <div/>
            }
        </div>
    )
}

export const CouponConfig = (props) => {
    const { state } = props
    // const theme = useTheme()
    // const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Coupon Details</Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField 
                    variant="standard" 
                    name="couponCode"
                    size="small"
                    label="Coupon Code"
                    value={state.couponCode}
                    onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                    error={!isEmptyString(state.couponCodeError)}
                    helperText={state.couponCodeError}
                    fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <Tooltip title="Create a random code. Good for one-time use coupons." arrow>
                    <Button 
                        variant="contained" 
                        fullWidth
                        startIcon={<RandomIcon/>}
                        onClick={props.onClickGenerateCode}
                        >Generate</Button>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    variant="standard" 
                    name="description"
                    size="small"
                    label="Brief Description"
                    value={state.description}
                    onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                    error={!isEmptyString(state.descriptionError)}
                    helperText={state.descriptionError}
                    rowsMax={2}
                    rows={2}
                    multiline
                    fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Coupon Type</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <FormControl style={{width: "200px"}} error={!isEmptyString(state.couponTypeError)} fullWidth>
                            <Select
                                value={state.couponType}
                                renderValue={() => state.couponTypes[state.couponType].nicename}
                                name="couponType"
                                onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                                input={<Input/>}>
                                {
                                    Object.keys(state.couponTypes).map((tag, idx) => (
                                        <MenuItem value={tag} key={idx}>
                                            <Typography variant="body2">
                                                {`${state.couponTypes[tag].nicename} - ${state.couponTypes[tag].description}`}
                                            </Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{state.couponTypeError}</FormHelperText>
                        </FormControl>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                { _renderSearch(props) }
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Expiration Date</Typography>
                        <Typography variant="caption">The date this coupon becomes invalid.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                value={state.expirationDate}
                                onChange={(moment) => props.onChangeForm("expirationDate", moment)}
                                error={!isEmptyString(state.expirationDateError)}
                                helperText={state.expirationDateError}
                                color="primary"
                                style={{width: "200px"}}
                                disablePast autoOk fullWidth showTodayButton/>
                        </MuiPickersUtilsProvider>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1" component="span">Promotion Start</Typography>
                        <Typography variant="body2" component="span"> - optional</Typography>
                        <br/>
                        <Typography variant="caption">The date this coupon becomes valid. Defaults to immediatly.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                style={{width: "200px"}}
                                fullWidth
                                color="primary"
                                value={state.startDate}
                                maxDate={state.expirationDate}
                                maxDateMessage="Start date can't be after expiration date."
                                disablePast
                                autoOk
                                onChange={(moment) => props.onChangeForm("startDate", moment)}
                                showTodayButton/>
                        </MuiPickersUtilsProvider>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Active</Typography>
                        <Typography variant="caption">Determines if this coupon can be claimed.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <Switch 
                            color="primary"
                            name="isActive"
                            checked={state.isActive} 
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.checked)}/>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Usage Limit</Typography>
                        <Typography variant="caption">The number of times this coupon can be claimed.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <Switch 
                            color="primary" 
                            name="hasLimit"
                            checked={state.hasLimit}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.checked)}/>
                        <TextField 
                            variant="outlined"
                            size="small"
                            type="number"
                            name="usageLimit"
                            disabled={!state.hasLimit}
                            inputProps={{min: 1}}
                            value={state.usageLimit}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                            color="primary"
                            style={{width: "200px"}}/>
                    </CardActions>
                </Card>
            </Grid>            
            <Grid item xs={12}>
                <Typography variant="h6">Discount Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Discount Type</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <FormControl style={{width: "200px"}} error={!isEmptyString(state.discountTypeError)}fullWidth>
                            <Select
                                value={state.discountType}
                                renderValue={() => state.discountTypes[state.discountType].nicename}
                                name="discountType"
                                onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                                input={<Input/>}>
                                {
                                    Object.keys(state.discountTypes).map((tag, idx) => (
                                        <MenuItem value={tag} key={idx}>
                                            <Typography variant="body2">
                                                {`${state.discountTypes[tag].nicename} - ${state.discountTypes[tag].description}`}
                                            </Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{state.discountTypeError}</FormHelperText>
                        </FormControl>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Discount Amount</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        { _renderDiscountAmountField(props) }
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Minimum Spend</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <CurrencyInputField 
                            variant="outlined" 
                            size="small"
                            name="minSpend"
                            value={state.minSpend}
                            onChange={(value) => props.onChangeForm("minSpend", value)}
                            iso4217={"USD"}
                            style={{width: "200px"}}/>
                </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Maximum Savings</Typography>
                        <Typography variant="caption">This enforces a maximum monetary amount that can be saved with a percentage discount.</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <Switch 
                            color="primary"
                            name="hasMaxSavings"
                            checked={state.hasMaxSavings} 
                            disabled={state.discountType !== "PERCENT"}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.checked)}/>                        
                        <CurrencyInputField 
                            variant="outlined" 
                            size="small"
                            name="maxSavings"
                            value={state.maxSavings}
                            disabled={state.discountType !== "PERCENT" || !state.hasMaxSavings}
                            onChange={(value) => props.onChangeForm("maxSavings", value)}
                            iso4217={"USD"}
                            style={{width: "200px"}}/>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Minimum Quantity</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <TextField 
                            variant="outlined"
                            size="small"
                            type="number"
                            inputProps={{min: 0, max: 9}}
                            name="minQuantity"
                            value={state.minQuantity}
                            disabled={state.couponType !== "SELLABLE"}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                            color="primary"
                            style={{width: "200px"}}/>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{display: "flex", boxShadow: "none"}}>
                    <CardContent style={{flex: "1 1 auto", paddingLeft: "0px"}}>
                        <Typography variant="body1">Maximum Quantity</Typography>
                    </CardContent>
                    <CardActions style={{paddingRight: "0px"}}>
                        <Switch 
                            color="primary"
                            name="hasMaxQuantity"
                            checked={state.hasMaxQuantity} 
                            disabled={state.couponType !== "SELLABLE"}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.checked)}/>
                        <TextField 
                            variant="outlined"
                            type="number"
                            inputProps={{min: 0, max: 9}}
                            style={{width: "200px"}}
                            size="small"
                            name="maxQuantity"
                            disabled={state.couponType !== "SELLABLE" || !state.hasMaxQuantity}
                            value={state.maxQuantity}
                            onChange={(event) => props.onChangeForm(event.target.name, event.target.value)}
                            color="primary"/>
                    </CardActions>
                </Card>
            </Grid>                 
        </Grid>
    )
}

CouponConfig.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onChangeForm: PropTypes.func.isRequired,
    onClickGenerateCode: PropTypes.func.isRequired
}

CouponConfig.defaultProps = {
    onChangeForm: (fieldName, value) => console.log(`${fieldName} => ${value}`),
    onClickGenerateCode: () => {}
}