// 
//  experimenting with UI for configruing sellable details
// 
// 

import PropTypes from "prop-types"
import React, { Component } from "react"
import SwipeableViews from "react-swipeable-views"
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardContent,
    CardActions,
    MobileStepper,
    Button,
    Typography,
    TextField,
    Divider

} from "@material-ui/core"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"

import { CurrencyInputField } from "./common/components/CurrencyField"
import { toTitleCase } from "./common/utils"

class SellableConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentProductIdx: 0,
            sellableData: this.props.data, // sellable details

            // a deep copy of the sellable json that's currently being modified
            dirtyBit: false,
            workingName: "",
            workingDescription: "",
            workingMinQuantity: 1,
            workingMaxQuantity: 1,
            workingPrices: [],
        }
    }

    componentWillMount = () => {
        this._addPriceData()
        this._swapWorkingData(0)
    }

    componentDidUpdate = (prevProps) => {}

    componentDidMount = () => {}    

    _addPriceData = () => {
        // adds price data to choices that are missing that data. default price is 0
        this.state.sellableData.contents.map(item => {
            if(item.min_quantity === undefined)
                item.min_quantity = 1
            if(item.max_quantity === undefined)
                item.max_quantity = 1

            // this field is currently hardcoded to True. False means the item doesn't show in the
            // sellable, could be used for surprise items.
            if(item.visible === undefined)
                item.visible = true 

            item.options.map(group => {
                group.choices.map(choice => {
                    if(choice.price === undefined){
                        choice.price = {
                            base_unit: 0,
                            iso_4217: this.state.sellableData.price.iso_4217,
                            exponent: this.state.sellableData.price.exponent
                        }
                    }
                    return choice
                })
                return group
            })
            return item
        })
    }

    _writeBackData = (callback = () => {}) => {
        // write the working data fields back to the sellableData object
        let { workingName, workingDescription, workingMinQuantity, workingMaxQuantity, 
            workingPrices, currentProductIdx, sellableData} = this.state
        let product = sellableData.contents[currentProductIdx]
        product.name = workingName
        product.description = workingDescription
        product.min_quantity = parseInt(workingMinQuantity)
        product.max_quantity = parseInt(workingMaxQuantity)
        workingPrices.map((price) => {
            product.options[price.groupIdx].choices[price.optionIdx].price.base_unit = price.value
            return price
        })
        this.setState({sellableData}, callback)
    }

    _swapWorkingData = (newIdx, callback= () => {}) => {

        const _readWorkingData = (newIdx) => {
            const product = JSON.parse(JSON.stringify(this.state.sellableData.contents[newIdx]))
            const workingName = product.name
            const workingDescription = product.description 
            const workingMinQuantity = product.min_quantity
            const workingMaxQuantity = product.max_quantity
            const workingPrices = product.options.map((option, groupIdx) => {
                return option.choices.map((choice, optionIdx) => {
                        return {
                        groupIdx,
                        optionIdx,
                        value: choice.price.base_unit
                    }
                })
            }).flat()

            this.setState({workingName, workingDescription, workingPrices, workingMinQuantity, 
                workingMaxQuantity, currentProductIdx: newIdx, dirtyBit: false})
        }

        if(this.state.dirtyBit) {
            this._writeBackData(() => _readWorkingData(newIdx))
        }
        else {
            _readWorkingData(newIdx)
        }
    }

    _onClickSubmit = () => {
        this._writeBackData(() => {
            this.props.onSubmitCallback(this.state.sellableData)
        })
    }

    _onClickStepper = delta => {
        this._swapWorkingData(this.state.currentProductIdx + delta)
    }

    _onChangeStepper = newProductIdx => {
        this._swapWorkingData(newProductIdx)
    } 

    
    _onChangeName = name => this.setState({workingName: name, dirtyBit: true})

    _onChangeDescription = description => this.setState({workingDescription: description, dirtyBit: true})

    _onChangeMinQuantity = minQuantity => {
        // min must be in range [1, 9]
        if(minQuantity < 1)
            minQuantity = 1
        else if(minQuantity > 9)
            minQuantity = 9

        // max quantity must be greater than min quantity
        let { workingMaxQuantity } = this.state
        if(workingMaxQuantity < minQuantity)
            workingMaxQuantity = minQuantity

        this.setState({workingMinQuantity: minQuantity, workingMaxQuantity, dirtyBit: true})
    } 
    

    _onChangeMaxQuantity = maxQuantity => {

        // max must be in range [1, 9]
        if(maxQuantity < 1)
            maxQuantity = 1
        else if(maxQuantity > 9)
            maxQuantity = 9

        // min quantity must be less than max quantity
        let { workingMinQuantity } = this.state
        if(workingMinQuantity > maxQuantity)
            workingMinQuantity = maxQuantity

        this.setState({workingMaxQuantity: maxQuantity, workingMinQuantity, dirtyBit: true})
    }

    _onChangeOptionPrice = (groupIdx, optionIdx, value) => {
        let { workingPrices } = this.state
        workingPrices.map((price) => {
            if(price.groupIdx === groupIdx && price.optionIdx === optionIdx )
                price.value = value
            return price
        })
        this.setState({workingPrices, dirtyBit: true})
    }

    _renderProductOptions = (productIdx, optionGroups) => {
        if(optionGroups.length === 0) {
            return <Typography variant="body1">No customization options.</Typography>
        }
        else {
            const { currentProductIdx } = this.state
            return optionGroups.map((group, groupIdx) => {
                return (
                    <div key={groupIdx}>
                        <Typography variant="h6">{group.name}</Typography>
                        {
                            group.choices.map((option, optionIdx) => {
                                return (
                                    <Card style={{display: "flex", boxShadow: "none"}} key={optionIdx}>
                                        <CardContent style={{flex: "1 1 auto"}}>
                                            <Typography variant="body2">{option.name}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <CurrencyInputField 
                                                variant="outlined" 
                                                label={option.name}
                                                value={ 
                                                    productIdx === currentProductIdx ?  
                                                        this.state.workingPrices.filter(price => price.groupIdx === groupIdx && price.optionIdx === optionIdx)[0].value
                                                        : option.price.base_unit
                                                    }
                                                onChange={(value) => this._onChangeOptionPrice(groupIdx, optionIdx, value)}
                                                iso4217="USD"  // TODO remove hardcoded currency
                                                size="small"/>
                                        </CardActions>
                                    </Card>
                                    
                                )
                            })
                        }                            
                    </div>
                )
            })
        }
    }

    _renderProducts = (products) => {
        const { currentProductIdx } = this.state
        return products.map((product, productIdx) => {
            return (
                <Grid container key={productIdx}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{product.name}</Typography>
                        {/* <TextField 
                            variant="filled" 
                            label="Product Name" 
                            value={ productIdx === currentProductIdx ? this.state.workingName : product.name}
                            onChange={(event) => this._onChangeName(event.target.value)}
                            fullWidth /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{product.description}</Typography>
                        {/* <TextField 
                            variant="filled" 
                            rows={2}
                            label="Description" 
                            value={productIdx === currentProductIdx ? this.state.workingDescription : product.description} 
                            onChange={(event) => this._onChangeDescription(event.target.value)}
                            multiline fullWidth/> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{display: "flex", boxShadow: "none"}}>
                            <CardContent style={{flex: "1 1 auto"}}>
                                <Typography variant="body2">Minimum Quantity</Typography>
                                <Typography variant="caption">The minimum required amount to purchase.</Typography>
                            </CardContent>
                            <CardActions>
                                <TextField 
                                    variant="outlined" 
                                    size="small" 
                                    type="number" 
                                    value={this.state.workingMinQuantity}
                                    // value={ productIdx === currentProductIdx ? this.state.workingMinQuantity : product.min_quantity}
                                    onChange={(event) => this._onChangeMinQuantity(event.target.value)}
                                    style={{width: "100px"}}/>
                            </CardActions>
                        </Card>                        
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{display: "flex", boxShadow: "none"}}>
                            <CardContent style={{flex: "1 1 auto"}}>
                                <Typography variant="body2">Maximum Quantity</Typography>
                                <Typography variant="caption">The maximum allowed amount to purchase.</Typography>
                            </CardContent>
                            <CardActions>
                                <TextField 
                                    variant="outlined" 
                                    size="small" 
                                    type="number"
                                    value={productIdx === currentProductIdx ? this.state.workingMaxQuantity : product.max_quantity} 
                                    onChange={(event) => this._onChangeMaxQuantity(event.target.value)}
                                    style={{width: "100px"}}/>
                            </CardActions>
                        </Card> 
                    </Grid>
                    <Grid item xs={12}><Divider/></Grid>
                    <Grid item xs={12}>
                        { this._renderProductOptions(productIdx, product.options) }
                    </Grid>
                </Grid>
            )
        })
    }

    _renderSteps = () => {
        return (
            <SwipeableViews 
                enableMouseEvents
                index={this.state.currentProductIdx} 
                onChangeIndex={this._onChangeStepper}>
                { this._renderProducts(this.state.sellableData.contents) }
            </SwipeableViews>
        )
    }

    render = () => {
        const title = toTitleCase(this.props.variant)
        return (
            <Dialog
                open
                maxWidth="md"
                fullWidth
                fullScreen={false} // TODO
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>{`${title} Products` }</DialogTitle>
                <DialogContent>
                    { this._renderSteps() }
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end">
                        <Grid item xs={12}>
                            <MobileStepper
                                variant="dots"
                                steps={this.state.sellableData.contents.length}
                                activeStep={this.state.currentProductIdx}
                                position="static"
                                nextButton={
                                    <Button size="small" onClick={() => this._onClickStepper(1)} disabled={this.state.currentProductIdx === this.state.sellableData.contents.length - 1}>
                                        Next <KeyboardArrowRightIcon />
                                    </Button>                            
                                }
                                backButton={
                                    <Button size="small" onClick={() => this._onClickStepper(-1)} disabled={this.state.currentProductIdx === 0}>
                                        <KeyboardArrowLeftIcon /> Back
                                    </Button>
                                }/>
                        </Grid>
                        <Grid item>
                            <Button variant="text" onClick={this.props.onCancelCallback}>Cancel</Button>
                            <Button 
                                variant="text" 
                                color="primary" 
                                onClick={this._onClickSubmit}
                                disabled={this.state.currentProductIdx !== this.state.sellableData.contents.length - 1}>Submit</Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        )
    }
}

SellableConfig.propTypes = {
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    onCancelCallback: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    data: PropTypes.object
}

SellableConfig.defaultProps = {
    onCancelCallback: () => {},
    onSubmitCallback: (data) => console.log(JSON.stringify(data))
}

export default SellableConfig