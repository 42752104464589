import { isNull } from "util"
import PropTypes from "prop-types"
import React from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import MenuList from "@material-ui/core/MenuList"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { isEmptyString, toTitleCase } from "../../common/utils"

import Spinner from "../../common/components/Spinner"
import { LabeledTextField } from "../../common/components/LabeledTextField"
import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import { MERCHANT_ACCOUNT, FOX_ACCOUNT } from "../../globals"


function _renderDenyDialog(props) {
    const { state } = props

    const title = state.accountAction === "deny" ? "Confirm Account Rejection" : "Confirm Account Suspension"

    return (
        <Dialog
            open={state.showDenyDialog}
            disableBackdropClick
            disableEscapeKeyDown>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Any actions taken here will be associated with the account {`${state.username}`}.
                    A reason must be provided before {` ${state.accountAction === "deny"  ? "rejecting" : "suspending" } `} this account:
                </Typography>
                <TextField
                    error={!isEmptyString(state.reasonErrorText)}
                    helperText={state.reasonErrorText}
                    multiline
                    fullWidth
                    label="Reason"
                    value={state.reason}
                    onChange={ (event) => props.onChangeDialogReason(event.target.value) }>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ props.onClickCancelDenial }>Cancel</Button>
                <Button onClick={ props.onClickExecuteDenial }>
                    {state.accountAction === "deny" ? "Reject" : "Suspend"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function _renderApplicantDialogFooter(props) {
    const { state } = props
    if(state.accountDetails.state === "Pending") {
        return (
            <Grid container justify="flex-end">
                <Grid item>
                    <Button
                        onClick={() => props.onClickApplicant(state.accountDetails.fetchy_uuid)}
                        variant="contained"
                        color="default">Refresh</Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => props.onClickDeny()}
                        variant="contained"
                        style={{backgroundColor: "#f44336", color: "#fff"}}>Reject</Button>
                </Grid>
                <Grid item>                        
                    <Button
                        onClick={() => props.onClickApprove(state.accountDetails.fetchy_uuid)}
                        variant="contained"
                        color="primary">Approve</Button>
                </Grid>
            </Grid>
        )
    }
    else if (state.applicantType === MERCHANT_ACCOUNT) {
        return (
            <Grid container justify="flex-end">
                <Grid item xs={12}>
                    <Typography variant="h6">Managed Locations</Typography>
                    {
                        state.accountDetails.locations.length > 0 ? 
                            state.accountDetails.locations.map((location, idx) => {
                                return <Typography variant="body1" key={idx}>{location.name}</Typography>
                            }) :
                            <Typography variant="body1">No Locations</Typography>
                    }
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => props.onClickSuspend()}
                        variant="contained"
                        style={{backgroundColor: "#f44336", color: "#fff"}}>Suspend</Button>                    
                </Grid>
            </Grid>
        )
    }
    else if (state.applicantType === FOX_ACCOUNT) {
        return (
            <Grid container justify="flex-end">
                <Grid item>
                    <Button
                        onClick={() => props.onClickSuspend()}
                        variant="contained"
                        style={{backgroundColor: "#f44336", color: "#fff"}}>Suspend</Button>                    
                </Grid>
            </Grid>
        )
    }
    else
        return <div/>
}

function _renderApplicantDialog(props) {
    const { state } = props

    return (
        <div>
            <Grid container justify="center">
                <Grid item>
                    <Avatar
                        style={{height: "192px", width: "192px"}}
                        src={state.accountDetails.selfie} />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <LabeledTextField label="Fetchy ID:" value={state.accountDetails.fetchy_uuid} />
                    <LabeledTextField label="Full Name:" value={state.accountDetails.fullname} />
                    <LabeledTextField label="Email:" value={state.accountDetails.username} />
                    <LabeledTextField label="Birthdate:" value={state.accountDetails.birthdate} />
                    <LabeledTextField label="Phone Number:" value={state.accountDetails.phone_number} />
                </Grid>
            </Grid>
            { _renderApplicantDialogFooter(props) }
        </div>
    )
}

function _renderAccountDetails(props, isMobileScreen) {
    const { state } = props

    return (
        <Dialog open={state.showAccountDialog}
            fullScreen={isMobileScreen} 
            fullWidth={!isMobileScreen} 
            maxWidth="md" 
            onClose={props.onCloseAccountDetails}>
            <DialogTitle>
                <IconButton edge="start" onClick={props.onCloseAccountDetails} aria-label="close">
                    <CloseIcon />
                </IconButton>
                {`Account Details`}
            </DialogTitle>
            <DialogContent>
            {
                isNull(state.accountDetails) ? 
                    <Spinner label="Fetching Data..."/> :
                    _renderApplicantDialog(props)
            }
            </DialogContent>
        </Dialog>
    )
}

function _renderAccountCard(props, account) {
    return (
        <Card style={{marginBottom: "10px"}}>
            <ListItem>
                <ListItemText
                    primary={account.username}
                    secondary={account.fetchy_uuid}
                    onClick={() => props.onClickApplicant(account.fetchy_uuid)}/>
            </ListItem>
            <CardActions>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="text" 
                            color="primary" 
                            onClick={() => props.onClickApplicant(account.fetchy_uuid)}>
                            View Details
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}


function _renderAccountsColumn(props, columnTitle, columnDesc, accounts, isMobileScreen) {
    const style = isMobileScreen ? {height: "300px"} : {height: "70vh"}
    return (
        <Grid item xs={12} lg={6} xl={4} style={{height: "inherit"}}>
            <Typography variant="h6">{columnTitle}</Typography>
            <Typography variant="body2">{columnDesc}</Typography>
            <Paper style={{overflow: "auto", backgroundColor: "#FFD5B9"}}>
                <Grid container style={style}>
                    <MenuList style={{overflow: "auto", width: "100%"}}>
                    {
                        accounts.map((account, idx) => {
                            return (
                                <Grid item xs={12} key={idx}>
                                {
                                    _renderAccountCard(props, account)
                                }
                                </Grid>
                            )
                        })
                    }
                    </MenuList>
                </Grid>
            </Paper>
        </Grid>        
    )
    
}

function _renderAccounts(props, isMobileScreen) {
    const { state } = props

    if (state.accounts.length === 0) {
        return (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="body1">No accounts :(</Typography>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container justify="flex-start">
                { _renderAccountsColumn(props, "Pending" , "New applicants waiting for approval.", state.pendingAccounts, isMobileScreen)}
                { _renderAccountsColumn(props, "Active", "Operational accounts", state.activeAccounts, isMobileScreen)}
                {/* TODO: implement { _renderAccountsColumn(props, "Suspended", "Temporarily disabled accounts", [], isMobileScreen)} */}
            </Grid>
        )
    }
}

function _renderHeadline(props) {
    const { state } = props
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h4">{`${toTitleCase(state.applicantType)} Accounts`}</Typography>
                <Typography variant="body1">{`Review all ${toTitleCase(state.applicantType)} accounts in the system.`}  </Typography>
                <div style={{height: 10}}/>
                <Divider/>
                <div style={{height: 20}}/>                
            </Grid>
        </Grid>
    )
}

export const Applicants = (props) => {
    const { classes, state } = props

    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.menuItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <main style={{width: "99vw"}}>
                <div className={classes.spacerTop}/>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        { _renderHeadline(props) }
                    </Grid>
                    <Grid item xs={12}>
                        { _renderAccounts(props, isMobileScreen) }
                    </Grid>
                    <Grid item xs={12}>
                        { _renderAccountDetails(props, isMobileScreen) }
                    </Grid>
                </Grid>
                { _renderDenyDialog(props) }
                <LoadingDialog show={state.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={state.snackMessage}
                    open={!isEmptyString(state.snackMessage)}
                    onClose={props.onCloseSnack}
                    action={<Button onClick={() => props.onCloseSnack()} color="primary">Close</Button>} />
            </main>
        </div>
    )    

}

Applicants.propTypes = {
    state: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onCloseAccountDetails: PropTypes.func,
    onChangeDialogReason: PropTypes.func,
    onClickCancelDenial: PropTypes.func,
    onClickExecuteDenial: PropTypes.func,
    onClickApplicant: PropTypes.func,
    onClickDeny: PropTypes.func,
    onClickSuspend: PropTypes.func,
    onClickApprove: PropTypes.func,
    onCloseSnack: PropTypes.func,
    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func
}

Applicants.defaultProps = {
    onCloseAccountDetails: () => console.log(`default onCloseAccountDetails`),
    onChangeDialogReason: (reason) => console.log(`default onChangeDialogReason ${reason}`),
    onClickCancelDenial: () => console.log(`default onClickCancelDenial`),
    onClickExecuteDenial: () => console.log(`default onClickExecuteDenial`),
    onClickApplicant: (fetchyUuid) => console.log(`default onClickApplicant ${fetchyUuid}`),
    onClickDeny: () => console.log(`default onClickDeny`),
    onClickSuspend: () => console.log(`default onClickSuspend`),
    onClickApprove: (fetchyUuid) => console.log(`default onClickApprove ${fetchyUuid}`),
    onClickDrawerItem: (redirectParams) => console.log(`default onClickDrawerItem ${JSON.stringify(redirectParams)}`),
    onClickDrawerLogout: () => console.log("default onClickDrawerLogout"),
    onCloseSnack: () => console.log("default onCloseSnack")
}