import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"
import { Route, Switch, Redirect } from "react-router"
import { createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"


import Home from "./components/Home"
import Dashboard from "./components/Dashboard"
import RequestPasswordReset from "./components/RequestPasswordReset"
import PasswordReset from "./components/PasswordReset"

import VerifyPhone from "./components/fox/VerifyPhone"
import BasicInfo from "./components/fox/BasicInfo"
import FoxLegal from "./components/fox/Legal"
import PrepaidCard from "./components/fox/PrepaidCard"

import MerchantBasicInfo from "./components/merchant/BasicInfo"
import MerchantLegal from "./components/merchant/Legal"
import MerchantListingsContainer from "./components/merchant/Listings"
import ListingContainer from "./components/merchant/Listing"
import ProductLibraryContainer from "./components/merchant/ProductLibrary"
import CouponLibraryContainer from "./components/merchant/CouponLibrary"

import FoxPerformanceContainer from "./components/airport/FoxPerformance"
import Applicants from "./components/airport/Applicants"
import ActivePaymentCards from "./components/airport/ActivePaymentCards"
import ActiveDeliveriesContainer from "./components/airport/ActiveDeliveries"
import OrderLookupContainer from "./components/airport/OrderLookup"
import AirportListingsContainer from "./components/airport/Listings"
import MenuContainer from "./components/airport/Menu"
import ReviewsContainer from "./components/airport/Reviews"

import { MaterialFoxTheme } from "./components/common/fetchyfoxtheme"

import { setCredentials } from "./actions"
import reducer from "./reducers"
import { firebaseApp } from "./firebase"

const store = createStore(reducer)

firebaseApp.auth().onAuthStateChanged(user => {
    if (user) {
        user.getIdToken().then((idToken) => {
            store.dispatch(setCredentials(user.email, idToken))
        })
    }
    else {
        store.dispatch(setCredentials(null, null))
    }
})

function isAuthorized() {
    const state = store.getState()
    return state.credentials.username !== undefined && state.credentials.username !== null
}

const App = () => {
    return (
        <IntlProvider locale={navigator.language}>
            <Provider store={ store }>
                <MuiThemeProvider theme={MaterialFoxTheme}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path ="/dashboard" render={(props) => (
                                isAuthorized() ? <Dashboard {...props}/> : <Redirect to="/"/>
                            )}/>

                            <Route path ="/fox/legal" render={(props) => (
                                isAuthorized() ? <FoxLegal {...props}/> : <Redirect to="/"/>
                            )}/>
                            <Route path ="/fox/mobile" render={(props) => (
                                isAuthorized() ? <VerifyPhone {...props} /> : <Redirect to="/"/>
                            )}/>
                            <Route path ="/fox/basic" render={(props) => (
                                isAuthorized() ? <BasicInfo {...props} /> : <Redirect to="/"/>
                            )}/>
                            <Route path ="/fox/prepaid" render={(props) => (
                                isAuthorized() ? <PrepaidCard {...props}/> : <Redirect to="/"/>
                            )}/>

                            <Route path="/merchant/legal" render={(props) => (
                                isAuthorized() ? <MerchantLegal {...props}/> : <Redirect to="/"/>
                            )}/>
                            <Route path="/merchant/basic" render={(props) => (
                                isAuthorized() ? <MerchantBasicInfo {...props}/> : <Redirect to="/"/>
                            )}/>
                            <Route exact path="/library" render={(props) =>(
                                isAuthorized() ? (<ProductLibraryContainer {...props}/>) : (<Redirect to="/"/>)
                            )}/>
                            <Route path ="/listings" render={(props) => (
                                isAuthorized() ? <MerchantListingsContainer {...props}/> : <Redirect to="/"/>
                            )}/>
                            <Route path="/listing/:placeId" render={(props) => (
                                isAuthorized() ? <ListingContainer {...props} /> : <Redirect to="/"/>
                            )}/>
                            <Route path="/merchant/coupons" render={(props) => (
                                isAuthorized() ? <CouponLibraryContainer {...props}/> : <Redirect to="/"/>
                            )}/>

                            
                            {/* airport admin views */}
                            <Route path="/applicants/:applicantType" render={(props) => (
                                isAuthorized() ? (<Applicants {...props} key={new Date().getUTCSeconds()} />) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/activedeliveries" render={(props) => (
                                isAuthorized() ? (<ActiveDeliveriesContainer {...props}/>) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/activecards" render={(props) => (
                                isAuthorized() ? (<ActivePaymentCards {...props}/>) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/lookup" render={(props) => (
                                isAuthorized() ? (<OrderLookupContainer {...props}/>) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/airport/listings" render={(props) => (
                                isAuthorized() ? (<AirportListingsContainer {...props}/>) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/airport/listing/:placeId" render={(props) => (
                                isAuthorized() ? (<MenuContainer  {...props}/>) : ( <Redirect to="/"/>)
                            )}/>
                            <Route path="/airport/fleet" render={(props) => (
                                isAuthorized() ? (<FoxPerformanceContainer {...props}/>) : (<Redirect to="/"/>)
                            )}/>
                            <Route path="/airport/reviews" render={(props) => (
                                isAuthorized() ? (<ReviewsContainer {...props}/>) : (<Redirect to="/"/>)
                            )}/>                            

                            <Route path="/:accountType/forgot" render={(props) => (
                                <RequestPasswordReset {...props}/>
                            )}/>
                            <Route path="/fox/reset/:token" render={(props) => (
                                <PasswordReset {...props}/>
                            )}/>


                            <Route exact path="/:iata?" render={(props) => ( <Home {...props}/> ) }/>
                        </Switch>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        </IntlProvider>
    )
}

ReactDOM.render(
    <App /> , document.getElementById("root")
)
