/*
    this is a modified version of the code shown here:
    https://material-ui.com/components/snackbars/#customized-snackbars
*/
import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import IconButton from "@material-ui/core/IconButton"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import WarningIcon from "@material-ui/icons/Warning"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import ShortTextIcon from "@material-ui/icons/ShortText"

import { makeStyles } from "@material-ui/core/styles"

const StatusSnackbarTypes = {
    STANDARD: "standard",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}


const variantIcon = {
    standard: ShortTextIcon,
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const styles = makeStyles(theme => ({
    success: {
        backgroundColor: "#43a047",
    },
    error: {
        backgroundColor: "#d32f2f",
    },
    info: {
        backgroundColor: "#1976d2",
    },
    warning: {
        backgroundColor: "#ffa000",
    },
    standard: {
        backgroundColor: "#323232"
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    headerMargin: {
        marginTop: theme.mixins.toolbar.minHeight
    }
}))

const StatusSnackbar = (props) => {
    const classes = styles()
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <Snackbar 
            autoHideDuration={5000}
            onClose={onClose}
            className={clsx(classes.headerMargin, className)} 
            open 
            {...other}>
            <SnackbarContent
                className={classes[variant]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}

StatusSnackbar.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(["error", "warning", "info", "success", "standard"]).isRequired,
    className: PropTypes.string,
};

export { StatusSnackbar, StatusSnackbarTypes }
