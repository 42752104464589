import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"

import {
    Grid,
    TextField,
    Card,
    CardContent,
    MenuList,
    MenuItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Button,
    Typography,
    useMediaQuery
} from "@material-ui/core"

import { useTheme } from "@material-ui/core/styles"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import RefreshIcon from "@material-ui/icons/Refresh"
import InfoIcon from "@material-ui/icons/Info"

import ListingContainer from "../Listing"
import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import { isEmptyString } from "../../common/utils"


function _renderListingList(props, isMobileScreen) {

    const { state } = props
    const style = isMobileScreen ? {} : {height: "75vh", display: "flex"}

    return (
        <Grid container>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={ props.onClickAddListing }                    
                    startIcon={<LibraryAddIcon/>}>Add Listing</Button>
            </Grid>    
            <Grid item>
                <Button 
                    onClick={props.onClickRefresh}
                    variant="contained" 
                    startIcon={<RefreshIcon/>}>Refresh</Button>   
            </Grid>        
            <Grid item xs={12}>
                <div style={{height: 10}}/>
                <TextField 
                    variant="outlined"
                    size="small"
                    placeholder="Search Listings..." 
                    fullWidth 
                    onChange={(event) => props.onTypeSearch(event.target.value)}/>        
            </Grid>
            {/* <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Filter by Terminal</InputLabel>
                    <Select
                        value={state.selectedTerminalId}
                        onChange={(event) => props.onSelectTerminal(event.target.value)}>
                        <MenuItem value="">All</MenuItem>
                        {
                            state.terminals.map((terminal, index) => {
                                return (
                                    <MenuItem key={index} value={terminal.terminalId}>
                                        {terminal.terminalName}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>                
            </Grid>             */}
            <Grid item xs={12}>
                <Card style={{maxHeight: "100%", overflow: "auto"}}>
                    <CardContent>            
                        <Grid container style={style}>
                            <Grid item xs={12}>{
                                state.places.length === 0 ? 
                                    <div>
                                        <Typography variant="body1">No listings!</Typography>
                                        <Typography variant="caption">Create your first listing and menu in "Manage Listings"</Typography>
                                    </div> :
                                    <MenuList style={{overflow: "auto"}}>{
                                        state.filteredPlaces.map((listing, listingIdx) => {
                                            return (
                                                <MenuItem selected={state.selectedPlaceId === listing.place_id} key={listingIdx} 
                                                    onClick={() => props.onClickManageListing(listing.place_id)}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="square"
                                                            alt={listing.name}
                                                            src={listing.picture_url}/>
                                                    </ListItemAvatar>
                                                    <ListItemText 
                                                        primary={listing.name}
                                                        secondary={listing.terminal_name}/>
                                                </MenuItem>
                                            )
                                        })

                                    }</MenuList>
                            }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>    
    )
}


function _renderListing(props, isMobileScreen) {
    const { classes, state } = props
    if(isEmptyString(state.selectedPlaceId)) {
        return (
            <Grid container justify="center" alignItems="center" direction="row" style={{height: "50vh"}}>
                <Grid item>
                    <InfoIcon color="primary" fontSize="large"/>
                </Grid>
                <Grid item>
                    <Typography variant="body1">Please select a listing.</Typography>
                </Grid>
                
            </Grid>            
        )
    }
    else {
        return (
            <ListingContainer 
                classes={classes} 
                placeId={state.selectedPlaceId}
                onDeletePlace={props.onClickDeleteListing}/>
        )
    }
}

export const MerchantListings = (props) => {
    const { classes, state } = props

    if(state.redirectParams && state.redirectParams.pathname !== state.location.pathname)
        return <Redirect to={state.redirectParams} push/>

    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <div style={{display: "flex"}}>
            <NavDrawer
                listItems={state.drawerItems}
                currentPath={state.location.pathname}
                onClickDrawerItemCallback={props.onClickDrawerItem}
                onClickLogoutCallback={props.onClickDrawerLogout}/>
            <main style={{width: "99vw"}}>
                <div className={classes.spacerTop}/>
                <Grid container justify="center">
                    <Grid item xs={12} sm={5} lg={4} xl={3}>
                        { _renderListingList(props, isMobileScreen) }
                    </Grid>
                    <Grid item xs={12} sm={7} lg={8} xl={9}>
                        { _renderListing(props, isMobileScreen) }
                    </Grid>
                </Grid>
                { state.confirmModal ? state.confirmModal : <div/> }
                <LoadingDialog show={state.loading}/>
            </main>
        </div>
    )

}

MerchantListings.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onTypeSearch: PropTypes.func,
    onSelectTerminal: PropTypes.func,
    onClickAddListing: PropTypes.func,
    onClickManageListing: PropTypes.func,
    onClickDeleteListing: PropTypes.func,
    onToggleAvailability: PropTypes.func,
    onClickRefresh: PropTypes.func,
    onClickDrawerItem: PropTypes.func,
    onClickDrawerLogout: PropTypes.func,
    onCloseSnack: PropTypes.func,
}

MerchantListings.defaultProps = {
    onTypeSearch: (value) => { console.log(`${value}`)},
    onSelectTerminal: (value) => { console.log(`${value}`)},
    onClickAddListing: () => {},
    onClickManageListing: (placeId) => { console.log(`${placeId}`)},
    onToggleListed: (placeId, checked) => { console.log(`${placeId} ${checked}`)},
    onClickRefresh: () => {},
    onClickDrawerItem: (redirectParams) => {console.log(`${redirectParams}`)},
    onClickDrawerLogout: () => {},
    onCloseSnack: () => {}
}