import React from "react"
import { Redirect } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"

import ImageSelectPreview from "../../common/components/ImageCropPreview"
import { FetchyIntercom } from "../../common/components/FetchyIntercom"
import LoadingDialog from "../../common/components/LoadingDialog"
import NavDrawer from "../../common/components/NavDrawer"
import CountryAutoCompleteField from "../../common/components/CountryAutoCompleteField"
import { isEmptyString } from "../../common/utils"
import { UPDATE_VIEW } from "../../globals"

function _renderBars(props) {
    const { classes, data } = props
    if(data.viewMode === UPDATE_VIEW) {
        return (
            <div>
                <NavDrawer
                    listItems={data.menuItems}
                    currentPath={data.location.pathname}
                    onClickDrawerItemCallback={props.onClickDrawerItem}
                    onClickLogoutCallback={props.onClickLogout}/>
                <FetchyIntercom 
                    userId={data.credentials.username}
                    email={data.credentials.email} 
                    name={data.firstName}
                    airport={data.airport.name}
                    route={data.location.pathname}
                    accountType={data.account.accountType} 
                    customData={{source: "Web Dashboard"}}/>                    
            </div>
        )
    }
    else
        return (
            <AppBar color="default">
                <Toolbar>
                    <div className={classes.fetchyFoxLogo}/>
                </Toolbar>
            </AppBar>
        )    
}

export const BasicInfo = (props) => {
    const { classes, data } = props

    if(data.redirectParams && data.redirectParams.pathname !== data.location.pathname)
        return <Redirect to={data.redirectParams} />

    return (
        <div style={{display: "flex"}}>
            { _renderBars(props) }
            <main style={{ width: "99vw"} }>
                <div className={classes.spacerTop}/>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xs={11} md={10} lg={8}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">Contact Information</Typography>
                                <Typography variant="body2">This information is used by the airport to approve new vendors. It must represent
                                the primary contact.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="First Name"
                                            variant="outlined"
                                            value={data.firstName}
                                            onChange={(event) => props.onTypeFirstName(event.target.value)}
                                            error={!isEmptyString(data.firstNameErrorText)}
                                            helperText={data.firstNameErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Last Name"
                                            variant="outlined"
                                            value={data.lastName}
                                            onChange={(event) => {props.onTypeLastName(event.target.value)}}
                                            error={!isEmptyString(data.lastNameErrorText)}
                                            helperText={data.lastNameErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="date"
                                            label="Birthdate"
                                            InputLabelProps={{shrink: true}}
                                            value={data.birthdate}
                                            variant="outlined"
                                            onChange={(event) => {props.onSelectBirthdate(event.target.value)}}
                                            error={!isEmptyString(data.birthdateErrorText)}
                                            helperText={data.birthdateErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CountryAutoCompleteField
                                    inputFieldLabel="Phone Number Country"
                                    onSelectCallback={props.onSelectCountry}
                                    errorText={data.countryErrorText}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Phone Number"
                                            onChange={(event) => props.onTypePhoneNumber(event.target.value)}
                                            value={data.phoneNumber}
                                            error={!isEmptyString(data.phoneNumberErrorText)}
                                            helperText={data.phoneNumberErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Primary Contact Email"
                                            variant="outlined"
                                            value={data.email}
                                            onChange={(event) => {props.onTypeEmail(event.target.value)}}
                                            error={!isEmptyString(data.emailErrorText)}
                                            helperText={data.emailErrorText}
                                            fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <div style={{height: 20}} />
                                <Typography variant="subtitle1">Profile Picture</Typography>
                                <Typography variant="body1">A good picture is one where your face is clear and centered.</Typography>
                            </Grid>
                            <Grid item>
                                <div style={{height: 10}} />
                                <ImageSelectPreview
                                    onSelectImage={(imageData, mimeType) => props.onSelectImage(imageData, mimeType)}
                                    maxFilesizeMB={2}
                                    height={300}
                                    width={300}
                                    pictureUrl={data.pictureUrl}
                                    errorText={data.profilePictureErrorText}/>
                                <div style={{height: 10}} />
                            </Grid>                            
                        </Grid>
                        <Grid container justify="flex-end">
                            <div style={{height: 20}}/>
                            <Grid item>
                                <Button
                                    variant="text"
                                    size="large"
                                    onClick={() => props.onClickCancel()}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={() => props.onClickSubmit()}>
                                    {
                                        data.viewMode === UPDATE_VIEW ? "Update" : "Submit"
                                    }
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingDialog show={data.loading}/>
                <Snackbar
                    autoHideDuration={5000}
                    message={data.snackMessage}
                    open={!isEmptyString(data.snackMessage)}
                    onClose={props.onCloseSnack}
                    action={<Button onClick={() => props.onCloseSnack()} color="primary">Close</Button>} />
            </main>
        </div>
    )

}