import { compose } from "recompose"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import DialogFormInput from "../common/components/DialogFormInput"

import { isEmptyString } from "../common/utils"
import { PlaceAPI } from "../common/requests/airport/place"
import { FetchyStyles } from "../common/fetchyfoxtheme"
import { firebaseApp } from "../../firebase"
import { AirportListings } from "./views/Listings"
import FetchyFoxLogo from  "../../assets/logo.png"
import { loadPlaces, removeCredentials, removeEmail, clearAccount, clearPlaces } from "../../actions"

const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }
})


class AirportListingsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: "",
            selectedTerminalId: "",

            places: [],
            filteredPlaces: [],
            terminals: [],

            loading: false,
            snackMessage: "",
            redirectParams: null,
            drawerItems: props.account.menuItems,
            adminEmail: props.credentials.username,
            confirmModal: null,
            location: props.location,

            credentials: props.credentials,
            account: props.account,
            airport: props.airport            
        }
    }

    componentWillMount = () => {
        this._getListings()
    }

    componentDidMount = () => {
    }


    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onClickDrawerItemHandler = (redirectParams) => this.setState({redirectParams})
    _onTypeSearch = (searchText) => this.setState({searchText}, this._updateFilter)
    _onSelectTerminalFilter = (selectedTerminalId) => this.setState({selectedTerminalId}, this._updateFilter)
    _onClickResetFilter = () => this.setState({searchText: "", selectedTerminalId: ""}, this._updateFilter)
    _onClickRefresh = () => this.setState({searchText: "", selectedTerminalId: ""}, this._getListings)
    _onClickManageListing = (placeId) => { this.setState({redirectParams: {pathname: `/airport/listing/${placeId}`}})}

    _onToggleAvailability = (placeId, listed) => {
        if(!listed) {
            this.setState({
                confirmModal: <DialogFormInput
                                    title="Confirm Unlisting"
                                    description="You must provide a reason for unlisting a location. This reason will be shared
                                    with the owner."
                                    multiline={true}
                                    type="text"
                                    onUpdateCallback={(reason) => this._onConfirmUnlistHandler(placeId, reason)}
                                    onCancelCallback={this._onCancelUnlistHandler}
                                    key={new Date().getUTCSeconds()}/>
            })
        }
        else {
            this._updateListedStatus(placeId, listed, "Toggled back to listed.")
        }
    }

    _onClickLogoutHandler = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearAccount()
            this.props.clearPlaces()
            this.props.removeCredentials()
            this.props.removeEmail()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: error})
        })
    }

    _onConfirmUnlistHandler = (placeId, reason) =>  this._updateListedStatus(placeId, false, reason)
    _onCancelUnlistHandler = () => this.setState({confirmModal: null})

    _updateFilter = () => {
        let filteredPlaces = this.state.places.filter((place) => {
            return isEmptyString(this.state.searchText) || place.name.toLowerCase().includes(this.state.searchText.toLowerCase())
        })

        if(!isEmptyString(this.state.selectedTerminalId)) {
            filteredPlaces = filteredPlaces.filter((place) => {
                return place.terminal_id === this.state.selectedTerminalId
            })
        }
        this.setState({filteredPlaces})
    }

    /** API requests */

    _getListings = () => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestAllPlaces(this.props.airport.airportId,
            (success) => {
                const places = success.data

                const terminals = []
                const map = new Map()
                for( const place of places ){
                    if(!map.has(place.terminal_id)){
                        map.set(place.terminal_id, true)
                        terminals.push({
                            terminalId: place.terminal_id,
                            terminalName: place.terminal_name
                        })
                    }
                }
                this.props.loadPlaces(places)
                this.setState({loading: false, places, terminals, filteredPlaces: places})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})
            })
    }

    _updateListedStatus = (placeId, listed, reason) => {
        this.setState({loading: true})
        const api = new PlaceAPI(this.props.server.host, this.props.credentials.username,
            this.props.credentials.password)
        api.requestUpdateListingStatus(this.props.airport.airportId, placeId, this.props.credentials.username,
            listed, reason,
            (success) => {
                // an in-memory update of the listing
                const places = this.state.places.map((place, idx) => {
                    if(place.place_id === placeId) {
                        place.listed = listed
                    }
                    return place
                })
                const filteredPlaces = this.state.filteredPlaces.map((place, idx) => {
                    if(place.place_id === placeId) {
                        place.listed = listed
                    }
                    return place
                })
                this.setState({loading: false, snackMessage: "Listing updated.", places, filteredPlaces, confirmModal: null})
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({snackMessage: message, loading: false})
            })
    }

    /** END api requests */

    render = () => {
        return (
            <AirportListings
                classes={this.props.classes}
                state={this.state}
                onToggleAvailability={ this._onToggleAvailability }
                onClickManageListing={ this._onClickManageListing }
                onClickDrawerItemHandler={ this._onClickDrawerItemHandler }
                onClickLogoutHandler={ this._onClickLogoutHandler }
                onTypeSearch={ this._onTypeSearch }
                onSelectTerminalFilter={ this._onSelectTerminalFilter }
                onClickRefresh={ this._onClickRefresh }
                onClickResetFilter={ this._onClickResetFilter }
                onCloseSnack={ this._onCloseSnack }/>                
        )
    }
}

function mapStateToProps(state) {
    const { server, credentials, airport, account } = state
    return { server, credentials, airport, account }
}

const mapActionCreatorsToProps = {
    removeCredentials,
    removeEmail,
    clearAccount,
    clearPlaces,
    loadPlaces

}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(AirportListingsContainer);
