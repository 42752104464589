import React from "react"
import Intercom from "react-intercom"
import PropTypes from 'prop-types'

export const FetchyIntercom = (props) => {
    const settings = {
        user_id: props.userId,
        email: props.email,
        airport: props.airport,
        name: props.name,
        accountType: props.accountType,
        route: props.route,
        ...props.customData
    }

    return (
        <Intercom appID={process.env.REACT_APP_INTERCOM_APPID} {...settings}/>
    )
}

FetchyIntercom.propTypes = {
    userId: PropTypes.string.isRequired, // a unique value representing the user
    name: PropTypes.string.isRequired,   // the customers name, or a nickname
    airport: PropTypes.string,
    accountType: PropTypes.string,
    email: PropTypes.string,  
    route: PropTypes.string,  // the route the chat widget is being used from
    customData: PropTypes.object,
}
