import React, { Component } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Snackbar from "@material-ui/core/Snackbar"
import { withStyles } from "@material-ui/core/styles"

import FetchyFoxLogo from "../assets/logo.png"
import LoadingDialog from "./common/components/LoadingDialog"
import AutoCompleteField from "./common/components/AutoCompleteField"
import MaskedPasswordField from "./common/components/MaskedPasswordField"
import { PasswordResetAPI } from "./common/requests/admin/password"
import { AdminAirportAPI } from "./common/requests/admin/airport"
import { isEmptyString } from "./common/utils"
import { setBackendHost } from "../actions"


const styles = theme => ({
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }
})

class PasswordReset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            airports: [],
            searchPhrase: "",
            iataCode: "",

            email: "",
            emailErrorText: "",
            password: "",
            retypedPassword: "",
            passwordErrorText: "",
            token: this.props.match.params.token,

            loading: false,
            snackMessage: ""
        }
    }

    componentWillMount = () => {
        this.props.setBackendHost(process.env.REACT_APP_DEVELOPMENT === "1")
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate = (prevProps) => {
        // api info set up
        if(prevProps.server.host !== this.props.server.host) {
            this._getActiveAirportList(() => {
                let iataCode = this.props.match.params.iata ? this.props.match.params.iata.toUpperCase() : ""
                let searchPhrase = ""

                if(!isEmptyString(iataCode)) {
                    const airport = this.state.airports.filter((airport) => {
                        return airport.value === iataCode
                    })

                    if(airport.length === 1) {
                        searchPhrase = airport[0].label
                    }
                    else {
                        iataCode =  ""
                    }
                }
                this.setState({iataCode, searchPhrase})
            })
        }
    }

    _onCloseSnack = () => this.setState({snackMessage: ""})
    _onSelectAirport = (iataCode) => this.setState({iataCode})
    _onTypeSearchPhrase = (searchPhrase) => this.setState({searchPhrase})
    _onTypeEmail = (email) => this.setState({email})
    _onTypePassword = (password) => this.setState({password})


    _onClickReset = () => {
        this.setState({emailErrorText: "", passwordErrorText: ""})
        if(isEmptyString(this.state.iataCode))
            this.setState({airportErrorText: "Please select the airport you work in."})
        else if(isEmptyString(this.state.email))
            this.setState({emailErrorText: "Your account email is required"})
        else if(this.state.password.length < 10)
            this.setState({passwordErrorText: "Must be at least 10 characters/digits long."})
        else
            this._resetPassword()
    }

    /** START network requests */
    _getActiveAirportList = (successCallback = () => {}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(this.props.server.host, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestAllAirports(
            (success) => {
                const data  = success.data
                const airports = data.map((airport) => {
                    return {
                        value: airport.iata_code,
                        label: `${airport.iata_code} - ${airport.name}`
                    }
                })
                this.setState({loading: false, airports}, successCallback)
            },
            (error) => {
                const message = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage: message})                
            }
        )
    }

    /** END network requests */

    _resetPassword = () => {
        this.setState({loading: true})
        const api = new PasswordResetAPI(this.props.server.host)
        api.requestResetPassword(this.state.email, this.state.password, this.state.iataCode, this.state.token,
            (success) => {
                const snackMessage = "Password successfully reset!"
                this.setState({loading: false, snackMessage, email: "", password: "", retypedPassword: ""})
            },
            (error) => {
                const snackMessage = `${error.responseCode}: ${error.message}`
                this.setState({loading: false, snackMessage })
            }
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div style={{height: "100vh", width: "100vw"}}>
                <AppBar color="default">
                    <Toolbar>
                        <div className={classes.fetchyFoxLogo}/>
                    </Toolbar>
                </AppBar>
                <main>
                    <Grid container justify="center" alignItems="center" style={{marginTop: 150}}>
                        <Grid item xs={10} md={8} lg={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Password Reset</Typography>
                                    <Typography variant="body1">
                                        This reset link only works for one attempt, failure or success. If a problem occurs during reset
                                        you will have to request a new reset link. A successful password change takes effect immediately.
                                    </Typography>
                                    <div style={{height: 20}}/>
                                </Grid>
                                <AutoCompleteField
                                    label="Your airport"
                                    searchPhrase={this.state.searchPhrase}
                                    suggestions={this.state.airports}
                                    onSelectCallback={(iataCode) => this._onSelectAirport(iataCode)}
                                    onTypeSearchPhrase={(phrase) => this._onTypeSearchPhrase(phrase)}
                                    errorText={this.state.airportErrorText}/>                                
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email Address" 
                                        value={this.state.email}
                                        variant="outlined"
                                        error={!isEmptyString(this.state.emailErrorText)}
                                        helperText={this.state.emailErrorText}
                                        fullWidth
                                        onChange={(event) => {this._onTypeEmail(event.target.value)}}/>                                
                                </Grid>
                                <Grid item xs={12}>
                                    <MaskedPasswordField
                                        variant="outlined"
                                        label="Passphrase"
                                        value={this.state.password}
                                        onTypePassword={(value) => this._onTypePassword(value)}
                                        errorText={this.state.passwordErrorText}
                                        fullWidth/>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => this._onClickReset()}>Reset Password</Button>
                                </Grid>                
                            </Grid>                            
                        </Grid>
                    </Grid>
                    <LoadingDialog show={this.state.loading} message="Resetting Password..."/>
                    <Snackbar
                        autoHideDuration={10000}
                        message={this.state.snackMessage}
                        open={!isEmptyString(this.state.snackMessage)}
                        onClose={this._onCloseSnack}
                        action={<Button onClick={() => this._onCloseSnack()} color="primary">Close</Button>} />                    
                </main>
            </div>            
        )
    }
}

function mapStateToProps(state) {
    const { server } = state
    return { server }
}

const mapActionCreatorsToProps = {
    setBackendHost
}


export default compose(
    connect(mapStateToProps, mapActionCreatorsToProps),
    withStyles(styles)
)(PasswordReset)
