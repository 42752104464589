import { combineReducers } from "redux"

import server from "./server"
import credentials from "./credentials"
import account from "./account"

import airport from "./airport"
import listings from "./listings"
import itemlist from "./items"
import placelist from "./places"
import placedashboard from "./placedashboard"

export default combineReducers({
    server,
    credentials,
    account,
    airport,
    listings,
    itemlist,
    placelist,
    placedashboard
})

